import { RouteComponentProps } from '@reach/router'
import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import {
  AdminClientService,
  ClientService,
  ResponseUserClients,
} from '@ads/front-core/api'
import { ADSApiError } from '@ads/front-core/interfaces'
import { setUserStatus } from '@ads/front-core/store/reducers/user/setUserStatusReducer'
import { apiError } from '@ads/front-core/store/sagas/apiErrorSaga'
import { getCurrentAdmin } from '@ads/front-core/store/selectors/getCurrentAdmin'
import { setClients } from '../reducers/client/setClientsReducer'
import { setCurrentClientId } from '../reducers/client/setCurrentClientIdReducer'
import { setCurrentShopId } from '../reducers/client/setCurrentShopIdReducer'
import { cacheDictionaries } from './cacheDictionaries'
import { fetchBalance } from './fetchBalanceSaga'
import { fetchClientData } from './fetchClientDataSaga'
import { fetchFeedsData } from './fetchFeedsDataSaga'
import { fetchShopData } from './fetchShopDataSaga'
import { fetchClientShopsData } from './fetchClientShopsDataSaga'

interface FetchUserDataProps {
  navigate: RouteComponentProps['navigate']
}

type FetchUserDataSaga = ReturnType<
  PayloadActionCreator<FetchUserDataProps, string>
>

export function* sagaFetchUserData(): Generator {
  yield takeEvery(fetchUserData.type, fetchUserDataSaga)
}

export const fetchUserData = createAction<FetchUserDataProps>(
  'saga/fetchUserData',
)

function* getClients(admin: ReturnType<typeof getCurrentAdmin>) {
  if (admin?.role) {
    return yield call(AdminClientService.getAdminClients, {
      search: '',
      page: 1,
      limit: 10000,
      status: 'ACTIVE',
      sortDir: 'asc',
      sortBy: 'name',
    })
  }
  return yield call(ClientService.getUserClients)
}

export function* fetchUserDataSaga({ payload }: FetchUserDataSaga): Generator {
  const { navigate } = payload
  try {
    const queryParams =
      window.document.location.hash.match(/#(\d+):(\d+)/) ||
      window.document.location.search.match(/\?(\d+):(\d+)/) ||
      []
    const currentAdminSelect = yield select(getCurrentAdmin)

    const currentAdmin = currentAdminSelect as ReturnType<
      typeof getCurrentAdmin
    >

    const responseClients = yield call(getClients, currentAdmin)
    const clients = responseClients as ResponseUserClients

    let clientId = 0
    let shopId = 0

    if (!clients?.length) {
      return
    }

    const lsClientId = Number(
      queryParams[1] || window.localStorage.getItem('currentClientId') || 0,
    )

    const lsShopId = Number(
      queryParams[2] || window.localStorage.getItem('currentShopId') || 0,
    )

    if (currentAdmin?.role) {
      clientId = lsClientId || clients[0]?.id
      shopId = lsShopId || clients.find(c => c.id === clientId)?.shops?.[0]?.id
    } else {
      clientId = clients.some(c => c.id === lsClientId)
        ? lsClientId
        : clients[0].id

      let currentClient = clients.find(c => c.id === clientId)

      if (!currentClient?.shops?.length) {
        currentClient = clients.find(item => item.shops?.length > 0)
      }

      shopId =
        currentClient?.shops?.find(s => s.id === lsShopId)?.id ||
        currentClient?.shops?.[0]?.id
    }

    yield put(fetchClientData({ clientId }))
    yield put(setClients(clients))
    yield put(setCurrentClientId(clientId))
    yield put(fetchClientShopsData({ clientId }))
    yield put(fetchShopData({ shopId }))
    yield put(setCurrentShopId(shopId))
    yield put(fetchFeedsData({ shopId }))
    yield put(fetchBalance({ clientId }))
  } catch (error) {
    if ((error as ADSApiError).status === 403) {
      yield put(setUserStatus('NOT_VERIFIED'))
      navigate('/register/')
      return
    }
    yield put(apiError({ error }))
  }

  try {
    yield put(cacheDictionaries())
  } catch (error) {
    yield put(apiError({ error }))
  }
}
