import React, { forwardRef, Ref } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

import { useNanoid } from '../../../utility'
import { ILogoSVG } from './types'

const sizes = {
  s: 20,
  m: 36,
  l: 36,
}

export const LogoSVG = forwardRef(
  (props: ILogoSVG, ref: Ref<SVGSVGElement>) => {
    const { size = 'l', paths, ...rest } = props

    const dimension = sizes[size]

    const [ids] = useNanoid(paths.length)

    return (
      <StyledLogoSvg
        ref={ref}
        width={dimension}
        height={dimension}
        viewBox={`0 0 ${dimension} ${dimension}`}
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {paths.map((p, index) => (
          <LogoSvgPath
            fill={p.fill ? p.fill : 'currentColor'}
            fillRule="evenodd"
            d={p.path}
            key={index + ids[index]}
          />
        ))}
      </StyledLogoSvg>
    )
  },
)

const StyledLogoSvg = styled.svg`
  display: inline-flex;
  ${space};
  ${color};
  /* Fix rendering bugs in Chrome for hdpi */
  shape-rendering: 'inherit';
  transform: 'translate3d(0,0,0)';
`

const LogoSvgPath = styled.path``
