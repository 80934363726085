export const TIMEZONES = {
  'Europe/Andorra': ['Andorra'],
  'Asia/Dubai': [
    'United Arab Emirates',
    'Oman',
    'Réunion',
    'Seychelles',
    'French S. Terr.',
  ],
  'Asia/Kabul': ['Afghanistan'],
  'America/Puerto_Rico': [
    'Antigua & Barbuda',
    'Anguilla',
    'Aruba',
    'St Barthelemy',
    'Caribbean NL',
    'Canada',
    'Curaçao',
    'Dominica',
    'Grenada',
    'Guadeloupe',
    'St Kitts & Nevis',
    'St Lucia',
    'St Martin (French)',
    'Montserrat',
    'Puerto Rico',
    'St Maarten (Dutch)',
    'Trinidad & Tobago',
    'St Vincent',
    'Virgin Islands (UK)',
    'Virgin Islands (US)',
  ],
  'America/Antigua': ['Antigua & Barbuda'],
  'America/Anguilla': ['Anguilla'],
  'Europe/Tirane': ['Albania'],
  'Asia/Yerevan': ['Armenia'],
  'Africa/Lagos': [
    'Angola',
    'Benin',
    'Congo (Dem. Rep.)',
    'Central African Rep.',
    'Congo (Rep.)',
    'Cameroon',
    'Gabon',
    'Equatorial Guinea',
    'Niger',
    'Nigeria',
  ],
  'Africa/Luanda': ['Angola'],
  'Antarctica/Casey': ['Antarctica'],
  'Antarctica/Davis': ['Antarctica'],
  'Antarctica/Mawson': ['Antarctica'],
  'Antarctica/Palmer': ['Antarctica'],
  'Antarctica/Rothera': ['Antarctica'],
  'Antarctica/Troll': ['Antarctica'],
  'Antarctica/Vostok': ['Antarctica'],
  'Pacific/Auckland': ['Antarctica', 'New Zealand'],
  'Pacific/Port_Moresby': ['Antarctica', 'Micronesia', 'Papua New Guinea'],
  'Asia/Riyadh': ['Antarctica', 'Kuwait', 'Saudi Arabia', 'Yemen'],
  'Antarctica/McMurdo': ['Antarctica'],
  'Antarctica/DumontDUrville': ['Antarctica'],
  'Antarctica/Syowa': ['Antarctica'],
  'America/Argentina/Buenos_Aires': ['Argentina'],
  'America/Argentina/Cordoba': ['Argentina'],
  'America/Argentina/Salta': ['Argentina'],
  'America/Argentina/Jujuy': ['Argentina'],
  'America/Argentina/Tucuman': ['Argentina'],
  'America/Argentina/Catamarca': ['Argentina'],
  'America/Argentina/La_Rioja': ['Argentina'],
  'America/Argentina/San_Juan': ['Argentina'],
  'America/Argentina/Mendoza': ['Argentina'],
  'America/Argentina/San_Luis': ['Argentina'],
  'America/Argentina/Rio_Gallegos': ['Argentina'],
  'America/Argentina/Ushuaia': ['Argentina'],
  'Pacific/Pago_Pago': ['Samoa (American)', 'US minor outlying islands'],
  'Europe/Vienna': ['Austria'],
  'Australia/Lord_Howe': ['Australia'],
  'Antarctica/Macquarie': ['Australia'],
  'Australia/Hobart': ['Australia'],
  'Australia/Melbourne': ['Australia'],
  'Australia/Sydney': ['Australia'],
  'Australia/Broken_Hill': ['Australia'],
  'Australia/Brisbane': ['Australia'],
  'Australia/Lindeman': ['Australia'],
  'Australia/Adelaide': ['Australia'],
  'Australia/Darwin': ['Australia'],
  'Australia/Perth': ['Australia'],
  'Australia/Eucla': ['Australia'],
  'America/Aruba': ['Aruba'],
  'Europe/Helsinki': ['Åland Islands', 'Finland'],
  'Europe/Mariehamn': ['Åland Islands'],
  'Asia/Baku': ['Azerbaijan'],
  'Europe/Belgrade': [
    'Bosnia & Herzegovina',
    'Croatia',
    'Montenegro',
    'North Macedonia',
    'Serbia',
    'Slovenia',
  ],
  'Europe/Sarajevo': ['Bosnia & Herzegovina'],
  'America/Barbados': ['Barbados'],
  'Asia/Dhaka': ['Bangladesh'],
  'Europe/Brussels': ['Belgium', 'Luxembourg', 'Netherlands'],
  'Africa/Abidjan': [
    'Burkina Faso',
    "Côte d'Ivoire",
    'Ghana',
    'Gambia',
    'Guinea',
    'Iceland',
    'Mali',
    'Mauritania',
    'St Helena',
    'Sierra Leone',
    'Senegal',
    'Togo',
  ],
  'Africa/Ouagadougou': ['Burkina Faso'],
  'Europe/Sofia': ['Bulgaria'],
  'Asia/Qatar': ['Bahrain', 'Qatar'],
  'Asia/Bahrain': ['Bahrain'],
  'Africa/Maputo': [
    'Burundi',
    'Botswana',
    'Congo (Dem. Rep.)',
    'Malawi',
    'Mozambique',
    'Rwanda',
    'Zambia',
    'Zimbabwe',
  ],
  'Africa/Bujumbura': ['Burundi'],
  'Africa/Porto-Novo': ['Benin'],
  'America/St_Barthelemy': ['St Barthelemy'],
  'Atlantic/Bermuda': ['Bermuda'],
  'Asia/Kuching': ['Brunei', 'Malaysia'],
  'Asia/Brunei': ['Brunei'],
  'America/La_Paz': ['Bolivia'],
  'America/Kralendijk': ['Caribbean NL'],
  'America/Noronha': ['Brazil'],
  'America/Belem': ['Brazil'],
  'America/Fortaleza': ['Brazil'],
  'America/Recife': ['Brazil'],
  'America/Araguaina': ['Brazil'],
  'America/Maceio': ['Brazil'],
  'America/Bahia': ['Brazil'],
  'America/Sao_Paulo': ['Brazil'],
  'America/Campo_Grande': ['Brazil'],
  'America/Cuiaba': ['Brazil'],
  'America/Santarem': ['Brazil'],
  'America/Porto_Velho': ['Brazil'],
  'America/Boa_Vista': ['Brazil'],
  'America/Manaus': ['Brazil'],
  'America/Eirunepe': ['Brazil'],
  'America/Rio_Branco': ['Brazil'],
  'America/Toronto': ['Bahamas', 'Canada'],
  'America/Nassau': ['Bahamas'],
  'Asia/Thimphu': ['Bhutan'],
  'Africa/Gaborone': ['Botswana'],
  'Europe/Minsk': ['Belarus'],
  'America/Belize': ['Belize'],
  'America/St_Johns': ['Canada'],
  'America/Halifax': ['Canada'],
  'America/Glace_Bay': ['Canada'],
  'America/Moncton': ['Canada'],
  'America/Goose_Bay': ['Canada'],
  'America/Iqaluit': ['Canada'],
  'America/Winnipeg': ['Canada'],
  'America/Resolute': ['Canada'],
  'America/Rankin_Inlet': ['Canada'],
  'America/Regina': ['Canada'],
  'America/Swift_Current': ['Canada'],
  'America/Edmonton': ['Canada'],
  'America/Cambridge_Bay': ['Canada'],
  'America/Inuvik': ['Canada'],
  'America/Dawson_Creek': ['Canada'],
  'America/Fort_Nelson': ['Canada'],
  'America/Whitehorse': ['Canada'],
  'America/Dawson': ['Canada'],
  'America/Vancouver': ['Canada'],
  'America/Panama': ['Canada', 'Cayman Islands', 'Panama'],
  'America/Phoenix': ['Canada', 'United States'],
  'America/Blanc-Sablon': ['Canada'],
  'America/Atikokan': ['Canada'],
  'America/Creston': ['Canada'],
  'Asia/Yangon': ['Cocos (Keeling) Islands', 'Myanmar (Burma)'],
  'Indian/Cocos': ['Cocos (Keeling) Islands'],
  'Africa/Kinshasa': ['Congo (Dem. Rep.)'],
  'Africa/Lubumbashi': ['Congo (Dem. Rep.)'],
  'Africa/Bangui': ['Central African Rep.'],
  'Africa/Brazzaville': ['Congo (Rep.)'],
  'Europe/Zurich': ['Switzerland', 'Germany', 'Liechtenstein'],
  'Pacific/Rarotonga': ['Cook Islands'],
  'America/Santiago': ['Chile'],
  'America/Punta_Arenas': ['Chile'],
  'Pacific/Easter': ['Chile'],
  'Africa/Douala': ['Cameroon'],
  'Asia/Shanghai': ['China'],
  'Asia/Urumqi': ['China'],
  'America/Bogota': ['Colombia'],
  'America/Costa_Rica': ['Costa Rica'],
  'America/Havana': ['Cuba'],
  'Atlantic/Cape_Verde': ['Cape Verde'],
  'America/Curacao': ['Curaçao'],
  'Asia/Bangkok': [
    'Christmas Island',
    'Cambodia',
    'Laos',
    'Thailand',
    'Vietnam',
  ],
  'Indian/Christmas': ['Christmas Island'],
  'Asia/Nicosia': ['Cyprus'],
  'Asia/Famagusta': ['Cyprus'],
  'Europe/Prague': ['Czech Republic', 'Slovakia'],
  'Europe/Berlin': [
    'Germany',
    'Denmark',
    'Norway',
    'Sweden',
    'Svalbard & Jan Mayen',
  ],
  'Europe/Busingen': ['Germany'],
  'Africa/Nairobi': [
    'Djibouti',
    'Eritrea',
    'Ethiopia',
    'Kenya',
    'Comoros',
    'Madagascar',
    'Somalia',
    'Tanzania',
    'Uganda',
    'Mayotte',
  ],
  'Africa/Djibouti': ['Djibouti'],
  'Europe/Copenhagen': ['Denmark'],
  'America/Dominica': ['Dominica'],
  'America/Santo_Domingo': ['Dominican Republic'],
  'Africa/Algiers': ['Algeria'],
  'America/Guayaquil': ['Ecuador'],
  'Pacific/Galapagos': ['Ecuador'],
  'Europe/Tallinn': ['Estonia'],
  'Africa/Cairo': ['Egypt'],
  'Africa/El_Aaiun': ['Western Sahara'],
  'Africa/Asmara': ['Eritrea'],
  'Europe/Madrid': ['Spain'],
  'Africa/Ceuta': ['Spain'],
  'Atlantic/Canary': ['Spain'],
  'Africa/Addis_Ababa': ['Ethiopia'],
  'Pacific/Fiji': ['Fiji'],
  'Atlantic/Stanley': ['Falkland Islands'],
  'Pacific/Kosrae': ['Micronesia'],
  'Pacific/Guadalcanal': ['Micronesia', 'Solomon Islands'],
  'Pacific/Chuuk': ['Micronesia'],
  'Pacific/Pohnpei': ['Micronesia'],
  'Atlantic/Faroe': ['Faroe Islands'],
  'Europe/Paris': ['France', 'Monaco'],
  'Africa/Libreville': ['Gabon'],
  'Europe/London': ['Britain (UK)', 'Guernsey', 'Isle of Man', 'Jersey'],
  'America/Grenada': ['Grenada'],
  'Asia/Tbilisi': ['Georgia'],
  'America/Cayenne': ['French Guiana'],
  'Europe/Guernsey': ['Guernsey'],
  'Africa/Accra': ['Ghana'],
  'Europe/Gibraltar': ['Gibraltar'],
  'America/Nuuk': ['Greenland'],
  'America/Danmarkshavn': ['Greenland'],
  'America/Scoresbysund': ['Greenland'],
  'America/Thule': ['Greenland'],
  'Africa/Banjul': ['Gambia'],
  'Africa/Conakry': ['Guinea'],
  'America/Guadeloupe': ['Guadeloupe'],
  'Africa/Malabo': ['Equatorial Guinea'],
  'Europe/Athens': ['Greece'],
  'Atlantic/South_Georgia': ['South Georgia & the South Sandwich Islands'],
  'America/Guatemala': ['Guatemala'],
  'Pacific/Guam': ['Guam', 'Northern Mariana Islands'],
  'Africa/Bissau': ['Guinea-Bissau'],
  'America/Guyana': ['Guyana'],
  'Asia/Hong_Kong': ['Hong Kong'],
  'America/Tegucigalpa': ['Honduras'],
  'Europe/Zagreb': ['Croatia'],
  'America/Port-au-Prince': ['Haiti'],
  'Europe/Budapest': ['Hungary'],
  'Asia/Jakarta': ['Indonesia'],
  'Asia/Pontianak': ['Indonesia'],
  'Asia/Makassar': ['Indonesia'],
  'Asia/Jayapura': ['Indonesia'],
  'Europe/Dublin': ['Ireland'],
  'Asia/Jerusalem': ['Israel'],
  'Europe/Isle_of_Man': ['Isle of Man'],
  'Asia/Kolkata': ['India'],
  'Indian/Chagos': ['British Indian Ocean Territory'],
  'Asia/Baghdad': ['Iraq'],
  'Asia/Tehran': ['Iran'],
  'Atlantic/Reykjavik': ['Iceland'],
  'Europe/Rome': ['Italy', 'San Marino', 'Vatican City'],
  'Europe/Jersey': ['Jersey'],
  'America/Jamaica': ['Jamaica'],
  'Asia/Amman': ['Jordan'],
  'Asia/Tokyo': ['Japan'],
  'Asia/Bishkek': ['Kyrgyzstan'],
  'Asia/Phnom_Penh': ['Cambodia'],
  'Pacific/Tarawa': [
    'Kiribati',
    'Marshall Islands',
    'Tuvalu',
    'US minor outlying islands',
    'Wallis & Futuna',
  ],
  'Pacific/Kanton': ['Kiribati'],
  'Pacific/Kiritimati': ['Kiribati'],
  'Indian/Comoro': ['Comoros'],
  'America/St_Kitts': ['St Kitts & Nevis'],
  'Asia/Pyongyang': ['Korea (North)'],
  'Asia/Seoul': ['Korea (South)'],
  'Asia/Kuwait': ['Kuwait'],
  'America/Cayman': ['Cayman Islands'],
  'Asia/Almaty': ['Kazakhstan'],
  'Asia/Qyzylorda': ['Kazakhstan'],
  'Asia/Qostanay': ['Kazakhstan'],
  'Asia/Aqtobe': ['Kazakhstan'],
  'Asia/Aqtau': ['Kazakhstan'],
  'Asia/Atyrau': ['Kazakhstan'],
  'Asia/Oral': ['Kazakhstan'],
  'Asia/Vientiane': ['Laos'],
  'Asia/Beirut': ['Lebanon'],
  'America/St_Lucia': ['St Lucia'],
  'Europe/Vaduz': ['Liechtenstein'],
  'Asia/Colombo': ['Sri Lanka'],
  'Africa/Monrovia': ['Liberia'],
  'Africa/Johannesburg': ['Lesotho', 'Eswatini (Swaziland)', 'South Africa'],
  'Africa/Maseru': ['Lesotho'],
  'Europe/Vilnius': ['Lithuania'],
  'Europe/Luxembourg': ['Luxembourg'],
  'Europe/Riga': ['Latvia'],
  'Africa/Tripoli': ['Libya'],
  'Africa/Casablanca': ['Morocco'],
  'Europe/Monaco': ['Monaco'],
  'Europe/Chisinau': ['Moldova'],
  'Europe/Podgorica': ['Montenegro'],
  'America/Marigot': ['St Martin (French)'],
  'Indian/Antananarivo': ['Madagascar'],
  'Pacific/Kwajalein': ['Marshall Islands'],
  'Pacific/Majuro': ['Marshall Islands'],
  'Europe/Skopje': ['North Macedonia'],
  'Africa/Bamako': ['Mali'],
  'Asia/Ulaanbaatar': ['Mongolia'],
  'Asia/Hovd': ['Mongolia'],
  'Asia/Choibalsan': ['Mongolia'],
  'Asia/Macau': ['Macau'],
  'Pacific/Saipan': ['Northern Mariana Islands'],
  'America/Martinique': ['Martinique'],
  'Africa/Nouakchott': ['Mauritania'],
  'America/Montserrat': ['Montserrat'],
  'Europe/Malta': ['Malta'],
  'Indian/Mauritius': ['Mauritius'],
  'Indian/Maldives': ['Maldives', 'French S. Terr.'],
  'Africa/Blantyre': ['Malawi'],
  'America/Mexico_City': ['Mexico'],
  'America/Cancun': ['Mexico'],
  'America/Merida': ['Mexico'],
  'America/Monterrey': ['Mexico'],
  'America/Matamoros': ['Mexico'],
  'America/Chihuahua': ['Mexico'],
  'America/Ciudad_Juarez': ['Mexico'],
  'America/Ojinaga': ['Mexico'],
  'America/Mazatlan': ['Mexico'],
  'America/Bahia_Banderas': ['Mexico'],
  'America/Hermosillo': ['Mexico'],
  'America/Tijuana': ['Mexico'],
  'Asia/Singapore': ['Malaysia', 'Singapore'],
  'Asia/Kuala_Lumpur': ['Malaysia'],
  'Africa/Windhoek': ['Namibia'],
  'Pacific/Noumea': ['New Caledonia'],
  'Africa/Niamey': ['Niger'],
  'Pacific/Norfolk': ['Norfolk Island'],
  'America/Managua': ['Nicaragua'],
  'Europe/Amsterdam': ['Netherlands'],
  'Europe/Oslo': ['Norway'],
  'Asia/Kathmandu': ['Nepal'],
  'Pacific/Nauru': ['Nauru'],
  'Pacific/Niue': ['Niue'],
  'Pacific/Chatham': ['New Zealand'],
  'Asia/Muscat': ['Oman'],
  'America/Lima': ['Peru'],
  'Pacific/Tahiti': ['French Polynesia'],
  'Pacific/Marquesas': ['French Polynesia'],
  'Pacific/Gambier': ['French Polynesia'],
  'Pacific/Bougainville': ['Papua New Guinea'],
  'Asia/Manila': ['Philippines'],
  'Asia/Karachi': ['Pakistan'],
  'Europe/Warsaw': ['Poland'],
  'America/Miquelon': ['St Pierre & Miquelon'],
  'Pacific/Pitcairn': ['Pitcairn'],
  'Asia/Gaza': ['Palestine'],
  'Asia/Hebron': ['Palestine'],
  'Europe/Lisbon': ['Portugal'],
  'Atlantic/Madeira': ['Portugal'],
  'Atlantic/Azores': ['Portugal'],
  'Pacific/Palau': ['Palau'],
  'America/Asuncion': ['Paraguay'],
  'Indian/Reunion': ['Réunion'],
  'Europe/Bucharest': ['Romania'],
  'Europe/Kaliningrad': ['Russia'],
  'Europe/Moscow': ['Russia'],
  'Europe/Simferopol': ['Russia', 'Ukraine'],
  'Europe/Kirov': ['Russia'],
  'Europe/Volgograd': ['Russia'],
  'Europe/Astrakhan': ['Russia'],
  'Europe/Saratov': ['Russia'],
  'Europe/Ulyanovsk': ['Russia'],
  'Europe/Samara': ['Russia'],
  'Asia/Yekaterinburg': ['Russia'],
  'Asia/Omsk': ['Russia'],
  'Asia/Novosibirsk': ['Russia'],
  'Asia/Barnaul': ['Russia'],
  'Asia/Tomsk': ['Russia'],
  'Asia/Novokuznetsk': ['Russia'],
  'Asia/Krasnoyarsk': ['Russia'],
  'Asia/Irkutsk': ['Russia'],
  'Asia/Chita': ['Russia'],
  'Asia/Yakutsk': ['Russia'],
  'Asia/Khandyga': ['Russia'],
  'Asia/Vladivostok': ['Russia'],
  'Asia/Ust-Nera': ['Russia'],
  'Asia/Magadan': ['Russia'],
  'Asia/Sakhalin': ['Russia'],
  'Asia/Srednekolymsk': ['Russia'],
  'Asia/Kamchatka': ['Russia'],
  'Asia/Anadyr': ['Russia'],
  'Africa/Kigali': ['Rwanda'],
  'Indian/Mahe': ['Seychelles'],
  'Africa/Khartoum': ['Sudan'],
  'Europe/Stockholm': ['Sweden'],
  'Atlantic/St_Helena': ['St Helena'],
  'Europe/Ljubljana': ['Slovenia'],
  'Arctic/Longyearbyen': ['Svalbard & Jan Mayen'],
  'Europe/Bratislava': ['Slovakia'],
  'Africa/Freetown': ['Sierra Leone'],
  'Europe/San_Marino': ['San Marino'],
  'Africa/Dakar': ['Senegal'],
  'Africa/Mogadishu': ['Somalia'],
  'America/Paramaribo': ['Suriname'],
  'Africa/Juba': ['South Sudan'],
  'Africa/Sao_Tome': ['Sao Tome & Principe'],
  'America/El_Salvador': ['El Salvador'],
  'America/Lower_Princes': ['St Maarten (Dutch)'],
  'Asia/Damascus': ['Syria'],
  'Africa/Mbabane': ['Eswatini (Swaziland)'],
  'America/Grand_Turk': ['Turks & Caicos Is'],
  'Africa/Ndjamena': ['Chad'],
  'Indian/Kerguelen': ['French S. Terr.'],
  'Africa/Lome': ['Togo'],
  'Asia/Dushanbe': ['Tajikistan'],
  'Pacific/Fakaofo': ['Tokelau'],
  'Asia/Dili': ['East Timor'],
  'Asia/Ashgabat': ['Turkmenistan'],
  'Africa/Tunis': ['Tunisia'],
  'Pacific/Tongatapu': ['Tonga'],
  'Europe/Istanbul': ['Turkey'],
  'America/Port_of_Spain': ['Trinidad & Tobago'],
  'Pacific/Funafuti': ['Tuvalu'],
  'Asia/Taipei': ['Taiwan'],
  'Africa/Dar_es_Salaam': ['Tanzania'],
  'Europe/Kyiv': ['Ukraine'],
  'Africa/Kampala': ['Uganda'],
  'Pacific/Midway': ['US minor outlying islands'],
  'Pacific/Wake': ['US minor outlying islands'],
  'America/New_York': ['United States'],
  'America/Detroit': ['United States'],
  'America/Kentucky/Louisville': ['United States'],
  'America/Kentucky/Monticello': ['United States'],
  'America/Indiana/Indianapolis': ['United States'],
  'America/Indiana/Vincennes': ['United States'],
  'America/Indiana/Winamac': ['United States'],
  'America/Indiana/Marengo': ['United States'],
  'America/Indiana/Petersburg': ['United States'],
  'America/Indiana/Vevay': ['United States'],
  'America/Chicago': ['United States'],
  'America/Indiana/Tell_City': ['United States'],
  'America/Indiana/Knox': ['United States'],
  'America/Menominee': ['United States'],
  'America/North_Dakota/Center': ['United States'],
  'America/North_Dakota/New_Salem': ['United States'],
  'America/North_Dakota/Beulah': ['United States'],
  'America/Denver': ['United States'],
  'America/Boise': ['United States'],
  'America/Los_Angeles': ['United States'],
  'America/Anchorage': ['United States'],
  'America/Juneau': ['United States'],
  'America/Sitka': ['United States'],
  'America/Metlakatla': ['United States'],
  'America/Yakutat': ['United States'],
  'America/Nome': ['United States'],
  'America/Adak': ['United States'],
  'Pacific/Honolulu': ['United States'],
  'America/Montevideo': ['Uruguay'],
  'Asia/Samarkand': ['Uzbekistan'],
  'Asia/Tashkent': ['Uzbekistan'],
  'Europe/Vatican': ['Vatican City'],
  'America/St_Vincent': ['St Vincent'],
  'America/Caracas': ['Venezuela'],
  'America/Tortola': ['Virgin Islands (UK)'],
  'America/St_Thomas': ['Virgin Islands (US)'],
  'Asia/Ho_Chi_Minh': ['Vietnam'],
  'Pacific/Efate': ['Vanuatu'],
  'Pacific/Wallis': ['Wallis & Futuna'],
  'Pacific/Apia': ['Samoa (western)'],
  'Asia/Aden': ['Yemen'],
  'Indian/Mayotte': ['Mayotte'],
  'Africa/Lusaka': ['Zambia'],
  'Africa/Harare': ['Zimbabwe'],
}
