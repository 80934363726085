import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { AppState } from '../../appState'

export const setFormTemplates = createAction<AppState['form']['templates']>(
  'form/setFormTemplates',
)

export const setFormTemplatesReducer = (
  state: AppState['form'],
  action: ReturnType<typeof setFormTemplates>,
): void => {
  state.templates = cloneDeep(action.payload)
}
