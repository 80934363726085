import { createReducer } from '@reduxjs/toolkit'
import { preloadedState } from '../../appState'
import {
  clearRunningConditions,
  clearRunningConditionsReducer,
} from './clearRunningConditionsReducer'
import {
  setAvailableCampaignTypesLoading,
  setAvailableCampaignTypesLoadingReducer,
} from './setAvailableCampaignTypesLoadingReducer'
import {
  setAvailableCampaignTypes,
  setAvailableCampaignTypesReducer,
} from './setAvailableCampaignTypesReducer'
import { setCampaignIAB, setCampaignIABReducer } from './setCampaignIABReducer'
import { setCampaign, setCampaignReducer } from './setCampaignReducer'
import {
  setCampaignSegments,
  setCampaignSegmentsReducer,
} from './setCampaignSegmentsReducer'
import {
  setCampaignsToArchive,
  setCampaignsToArchiveReducer,
} from './setCampaignsToArchiveReducer'
import {
  setRunningConditions,
  setRunningConditionsReducer,
} from './setRunningConditionsReducer'
import {
  setRunCampaignAfterSave,
  setRunCampaignAfterSaveReducer,
} from './setRunCampaignAfterSaveReducer'

const campaignReducers = createReducer(preloadedState.campaign, builder => {
  builder.addCase(
    setAvailableCampaignTypes.type,
    setAvailableCampaignTypesReducer,
  )
  builder.addCase(
    setAvailableCampaignTypesLoading.type,
    setAvailableCampaignTypesLoadingReducer,
  )
  builder.addCase(setCampaignsToArchive.type, setCampaignsToArchiveReducer)
  builder.addCase(setCampaign.type, setCampaignReducer)
  builder.addCase(setCampaignSegments.type, setCampaignSegmentsReducer)
  builder.addCase(setRunningConditions.type, setRunningConditionsReducer)
  builder.addCase(clearRunningConditions.type, clearRunningConditionsReducer)
  builder.addCase(setCampaignIAB.type, setCampaignIABReducer)
  builder.addCase(setRunCampaignAfterSave.type, setRunCampaignAfterSaveReducer)
})

export default campaignReducers
