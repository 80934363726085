import { uuid4 } from 'uuid4'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const getIds = (length: number) => {
  const ids = []

  for (let index = 0; index < length; index++) {
    ids.push(uuid4())
  }

  return ids
}

export const useNanoid = (
  length: number,
): [string[], Dispatch<SetStateAction<string[]>>] => {
  const [ids, setIds] = useState<string[]>(getIds(length))

  useEffect(() => {
    setIds(getIds(length))
  }, [length])

  return React.useMemo(() => [ids, setIds], [ids, setIds])
}
