import React, { Children } from 'react'

import { IBreadcrumbs, IBreadcrumbsItem } from './types'
import { Flex } from '../Flex'
import { Text } from '../Text'
import styled from 'styled-components'

export const Breadcrumbs = ({
  children,
}: React.PropsWithChildren<IBreadcrumbs>) => {
  const childCount = Children.toArray(children).length

  return (
    <Flex
      alignItems="center"
      aria-label="breadcrumb"
      as="nav"
      role="navigation"
    >
      <StyledBreadcrumbsFlex alignItems="center">
        {React.Children.map(
          children as string[],
          (child: IBreadcrumbsItem['children'], index: number) => {
            if (React.isValidElement(child)) {
              const isLastChild = index === childCount - 1
              if (isLastChild) {
                return child
              }
              return (
                <>
                  {child}
                  <Text mr="xxs" color="grey50">
                    \
                  </Text>
                </>
              )
            } else {
              return null
            }
          },
        )}
      </StyledBreadcrumbsFlex>
    </Flex>
  )
}

const StyledBreadcrumbsFlex = styled(Flex)`
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
