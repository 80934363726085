import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { delay, put, takeEvery } from 'redux-saga/effects'
import { hideToast } from '../reducers/toast/hideToastReducer'
import { showToast } from '../reducers/toast/showToastReducer'

interface ToastSagaProps {
  message: string
}

export type ToastSaga = ReturnType<PayloadActionCreator<ToastSagaProps, string>>

export function* sagaToast(): Generator {
  yield takeEvery(toast.type, toastSaga)
}

export const toast = createAction<ToastSagaProps>('saga/toast')

export function* toastSaga({ payload }: ToastSaga): Generator {
  const id = String(Math.round(Date.now() + Math.random() * 1000))
  yield put(showToast({ id, ...payload }))
  yield delay(5000)
  yield put(hideToast({ id }))
}
