import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setDemoDialogVisibility = createAction<boolean>(
  'setDemoDialogVisibility',
)

export const setDemoDialogVisibilityReducer = (
  state: RootState['app'],
  action: ReturnType<typeof setDemoDialogVisibility>,
): void => {
  state.isDemoDialogVisible = action.payload
}
