import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setUserStatus = createAction<RootState['user']['status']>(
  'user/status',
)

export const setUserStatusReducer = (
  state: RootState['user'],
  action: ReturnType<typeof setUserStatus>,
): void => {
  state.status = action.payload
}
