import { getTimezoneOffset } from 'date-fns-tz'
import { sortBy } from 'lodash'
import { ResponseClient } from '@ads/front-core/api'
import { TIMEZONES } from '@ads/front-core/constants/timezones'

type TimezoneListItem = {
  name: string
  country: string
  offset: number
  offsetTime: string
  label: string
}

const getTimezoneListNames = (): string[] => Object.keys(TIMEZONES)

export const getTimezoneList = (): TimezoneListItem[] => {
  const list: TimezoneListItem[] = []
  getTimezoneListNames().forEach(timezoneName => {
    const countries = TIMEZONES[timezoneName]
    if (countries?.length > 0) {
      countries.forEach(country => {
        const offset = getTimezoneOffsetMinutes(timezoneName)
        const offsetTime = formatOffset(offset)
        list.push({
          name: timezoneName,
          country,
          offset,
          offsetTime,
          label: `${country} - UTM ${offsetTime} ${timezoneName}`,
        })
      })
    }
  })
  return sortBy(list, 'offset')
}

export const getTimezoneLabel = (timezoneName?: string): string => {
  const timezones = getTimezoneList()
  return timezones.find(t => t.name === timezoneName)?.label
}

export const getTimezoneOffsetMinutes = (timezoneName: string): number => {
  const offset = getTimezoneOffset(timezoneName)
  if (isNaN(offset)) {
    return offset
  }
  return offset / (1000 * 60)
}

export const getBrowserTimezoneName = (): string => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (e) {
    console.error(e)
  }
  const offset = -new Date().getTimezoneOffset()
  return getTimezoneList().find(t => t.offset === offset)?.name
}

export const getClientTimezone = (client?: ResponseClient): string =>
  client?.settings?.timezone || getBrowserTimezoneName()

export const getDiffOffsetBrowserTimezone = (timezoneName: string): number => {
  const offset = getTimezoneOffsetMinutes(timezoneName)
  const browserOffset = getTimezoneOffsetMinutes(getBrowserTimezoneName())
  return offset - browserOffset
}

const formatOffset = (offset: number): string => {
  const sign = offset > 0 ? '+' : '-'
  const hour = Math.abs(Math.floor(offset / 60))
  const minute = Math.abs(offset % 60)
  return `${sign}${String(hour).padStart(2, '0')}:${String(minute).padStart(
    2,
    '0',
  )}`
}
