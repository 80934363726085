export const DEFAULT_CURRENCY: Currency = 'pln'

export type Currency =
  | 'pln'
  | 'ron'
  | 'bgn'
  | 'usd'
  | 'gbp'
  | 'eur'
  | 'chf'
  | 'czk'

export type CurrencyDetails = {
  key: Currency
  symbol: string
  label: string
  rate?: number
  date?: Date
}

export type ExchangeApiResponse = {
  currency: Currency
  value: number
  effective_date: string
}[]
