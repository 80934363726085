import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

type SetStorage = {
  key: string
  value: unknown
}

export const setStorage = createAction<SetStorage>('app/setStorage')

export const setStorageReducer = (
  state: RootState['storage'],
  action: ReturnType<typeof setStorage>,
): void => {
  state[action.payload.key] = action.payload.value
}
