import { createAction } from '@reduxjs/toolkit'
import { ResponseStudioTemplates } from '@ads/front-core/api'
import { AppState } from '../../appState'

export const setNewsletterPreviewTemplate = createAction<
  ResponseStudioTemplates[number]['template']
>('common/setNewsletterPreviewTemplate')

export const setNewsletterPreviewTemplateReducer = (
  state: AppState['common'],
  action: ReturnType<typeof setNewsletterPreviewTemplate>,
): void => {
  state.newsletterPreviewTemplate = action.payload
}
