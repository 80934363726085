import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface ClearFetchCacheParams {
  cachePrefix: string
}

export const clearFetchCache = createAction<ClearFetchCacheParams>(
  'clearFetchCache',
)

export const clearFetchCacheReducer = (
  state: RootState['fetchCache'],
  action: ReturnType<typeof clearFetchCache>,
): void => {
  state.list = Object.keys(state.list).reduce((acc, key) => {
    if (key.indexOf(action.payload.cachePrefix) === 0) {
      return acc
    }
    return { ...acc, [key]: state.list[key] }
  }, {})
}
