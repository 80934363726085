import { SetRunningConditions } from 'store/reducers/campaign/setRunningConditionsReducer'

export const getRunningConditionsKey = ({
  campaignId,
  campaignStatus,
  modifyType,
}: Pick<
  SetRunningConditions,
  'campaignId' | 'modifyType' | 'campaignStatus'
>): string => `${campaignId}-${campaignStatus}-${modifyType}`
