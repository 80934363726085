import { createAction } from '@reduxjs/toolkit'
import { TDictionary } from '@ads/front-core/interfaces'
import { RootState } from '../../rootState'

export interface SetDictionaryParams {
  category: string
  dictionary: TDictionary[]
}

export const setDictionary = createAction<SetDictionaryParams>(
  'user/setDictionary',
)

export const setDictionaryReducer = (
  state: RootState['dictionary'],
  action: ReturnType<typeof setDictionary>,
): void => {
  const { category, dictionary } = action.payload
  state[category] = dictionary
}
