import React, {
  FocusEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import { TextButton } from '../Buttons'
import { Textarea } from '../Textarea'
import { Container } from '../TextInput/TextInput'
import { IActionTextarea, IButtonHolder } from './types'

export const ActionTextarea = forwardRef(
  (props: IActionTextarea, ref: React.Ref<HTMLDivElement>) => {
    const buttonHolder = useRef<HTMLDivElement>(null)
    const [buttonWidth, setButtonWidth] = useState<number | null>(null)
    const [isFocused, setFocus] = useState(false)

    const {
      buttonText,
      value,
      defaultValue,
      placeholder,
      id,
      isDisabled,
      isButtonDisabled,
      isError,
      isReadOnly,
      onChange,
      onActionClick,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      rows,
      isAutoresize,
      isEnterKeyDisabled,
      ...rest
    } = props

    const textareaProps = {
      ref,
      value,
      defaultValue,
      placeholder,
      id,
      offsetRight: buttonWidth,
      disabled: isDisabled,
      isError,
      readOnly: isReadOnly,
      onChange,
      onKeyDown,
      onKeyUp,
      isAutoresize,
      isEnterKeyDisabled,
      'aria-label': (rest['aria-label'] = 'Textarea'),
      rows,
    }

    useEffect(() => {
      setButtonWidth(
        buttonHolder.current ? buttonHolder.current.offsetWidth : null,
      )
    }, [buttonText])

    const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
      setFocus(false)

      if (onBlur) onBlur(e)
    }

    const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
      setFocus(true)

      if (onFocus) onFocus(e)
    }

    return (
      <Container {...rest} isVisible={!!buttonWidth}>
        <StyledTextarea
          {...textareaProps}
          isDisabled={isDisabled}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />
        <ButtonHolder ref={buttonHolder} isVisible={!!value || isFocused}>
          <TextButton
            variant="primary"
            isDisabled={isButtonDisabled || isDisabled}
            onClick={onActionClick}
          >
            {buttonText}
          </TextButton>
        </ButtonHolder>
      </Container>
    )
  },
)

const StyledTextarea = styled(p => <Textarea {...p} />)`
  ${p => css`
    width: 100%;
    ${p.offsetRight &&
    css`
      padding-right: ${p.offsetRight + p.theme.space.l - 1}px;
    `};
  `};
`

const ButtonHolder = styled.div<IButtonHolder>`
  position: absolute;

  ${p => css`
    top: ${p.theme.space.xxs}px;
    right: ${p.theme.space.s - 1}px;
    opacity: ${p.isVisible ? 1 : 0};
  `};
`
