import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Box, IBox } from '../../Box'
import { Text } from '../../Text'
import { CheckboxControl } from '../CheckboxControl'
import { ICheckbox, ILabel } from './types'

export const Checkbox = ({
  value,
  isChecked = false,
  isIndeterminate,
  children,
  isError,
  isDisabled,
  id,
  name,
  isRequired,
  onChange,
  ...rest
}: ICheckbox) => {
  const [isHovered, setHover] = useState(false)

  const inputProps = {
    value,
    isChecked,
    isIndeterminate,
    isError,
    isDisabled,
    id,
    name,
    isRequired,
    isHover: isHovered,
    onChange,
  }

  return (
    <StyledCheckbox
      isDisabled={isDisabled}
      forwardedAs={'label' as IBox['forwardedAs']}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      aria-label={rest['aria-label']}
      {...rest}
    >
      {/* TODO: fix this type*/}
      <StyledControl forwardedAs="span" {...(inputProps as any)} />
      <Label pl={'xxs'} forwardedAs="span" isDisabled={isDisabled}>
        {children}
      </Label>
    </StyledCheckbox>
  )
}

const StyledCheckbox = styled(Box)<ICheckbox>`
  display: inline-flex;
  align-items: flex-start;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `};
`

const StyledControl = styled(CheckboxControl)`
  ${({ theme }) => css`
    margin: ${theme.space.xxxs / 2}px 0;
  `};
`

const Label = styled(Text)<ILabel>`
  cursor: pointer;
  color: ${({ theme, isDisabled }) =>
    theme.components[isDisabled ? 'checkboxDisabledColor' : 'checkboxColor']};
`
