import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setFeedsLoading = createAction<Required<boolean>>(
  'feeds/setFeedsLoading',
)

export const setFeedsLoadingReducer = (
  state: AppState['feeds'],
  action: ReturnType<typeof setFeedsLoading>,
): void => {
  state.isLoading = action.payload
}
