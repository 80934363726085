import { createAction } from '@reduxjs/toolkit'
import { ResponseUserClients } from '@ads/front-core/api'
import { AppState } from '../../appState'

export const setClients = createAction<ResponseUserClients>('user/setClients')

export const setClientsReducer = (
  state: AppState['client'],
  action: ReturnType<typeof setClients>,
): void => {
  state.list = action.payload
}
