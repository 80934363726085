import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { adjustPaddings, checkIntent, setTransition } from '../../utility'
import { Box } from '../Box'
import { Text } from '../Text'
import { Icon } from '../Icon'
import { INewItemField, IBar, IContainer } from './types'
import { Spinner } from '../Loaders/Spinner'

export const NewItemField: FC<React.PropsWithChildren<INewItemField>> = ({
  label,
  variant = 'horizontal',
  as = 'button',
  icon = '20-plus',
  loading,
  progress = 0,
  isError,
  ...rest
}) => {
  if (loading === 'bar' && !progress && !isError)
    throw new Error('You have to add value number of progress property.')

  const [mouseDown, setMouseDown] = useState(false)

  const loadingState = (
    <>
      {loading === 'bar' ? (
        <Bar
          progress={progress as number}
          isFixed={variant === 'horizontal' || !label}
          my={label && variant === 'vertical' ? 'xxs' : undefined}
        />
      ) : (
        <Spinner />
      )}
      <Text
        fontSize="m"
        lineHeight="m"
        ml={label && variant === 'horizontal' ? 'xxs' : undefined}
        mt={label && variant === 'vertical' ? 'xxs' : undefined}
      >
        {!label && loading === 'bar' ? `${progress}%` : label}
      </Text>
    </>
  )

  const normalState = (
    <>
      {icon && (
        <Icon
          name={icon}
          mr={label && variant === 'horizontal' ? 'xxs' : undefined}
          mb={label && variant === 'vertical' ? 'xxs' : undefined}
        />
      )}

      <Text fontSize="m" lineHeight="m" fontWeight="medium">
        {label}
      </Text>
    </>
  )

  return (
    <StyledNewItemField
      forwardedAs={as ? as : 'button'}
      borderRadius="rounded"
      variant={variant}
      loading={loading}
      isError={isError}
      hasLabel={!!label}
      aria-label={label ? label : 'Dodaj'}
      isMouseDown={mouseDown}
      onMouseDown={() => setMouseDown(true)}
      onBlur={() => setMouseDown(false)}
      {...rest}
    >
      {loading && !isError ? loadingState : normalState}
    </StyledNewItemField>
  )
}

const StyledNewItemField = styled(Box)<IContainer>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  border: 0;
  appearance: none;
  font-family: inherit;
  letter-spacing: 0.2px;
  text-decoration: none;
  cursor: pointer;
  ${setTransition(
    ['border-color', 'background-color', 'color', 'box-shadow'],
    'productive',
  )};

  ${p => css`
    width: ${checkIntent('fitted', p.intent) ? '100%' : 'auto'};
    width: ${!p.hasLabel && '36px'};
    align-items: ${!p.isError && p.variant === 'vertical' && p.loading === 'bar'
      ? 'stretch'
      : 'center'};
    pointer-events: ${p.loading && 'none'};
    background-color: ${p.theme.components.newItemFieldBackGroundColor};
    color: ${p.theme.components[
      p.loading ? 'newItemFieldLoadingColor' : 'newItemFieldColor'
    ]};

    &:active {
      opacity: ${p.theme.aliases.defaultActiveOpacity};
    }

    ${!p.hasLabel
      ? adjustPaddings(['xxs', 'none'], 'thinDashedGrey20')
      : adjustPaddings(
          [p.variant === 'horizontal' ? 'xxs' : 's', 's'],
          'thinDashedGrey20',
        )}

    ${!p.isDisabled &&
    css`
      &:hover {
        border-color: ${p.theme.components.newItemFieldHoverBorderColor};
        color: ${p.theme.components.newItemFieldHoverColor};
      }

      &:focus {
        outline: none;
        border-color: ${!p.isMouseDown && 'transparent'};
        box-shadow: ${!p.isMouseDown &&
        p.theme.focuses[p.isError ? 'error' : 'normal']};
      }
    `};

    ${p.variant === 'vertical' &&
    css`
      flex-direction: column;
    `};

    ${p.isError &&
    css`
      border-color: ${p.theme.components.newItemFieldErrorColor};
    `};

    ${p.isDisabled &&
    css`
      border-color: ${p.theme.components.newItemFieldDisabledBorderColor};
      color: ${p.theme.components.newItemFieldDisabledColor};
      cursor: not-allowed;
      pointer-events: none; /* fix hiding tooltip when button is disabled */
    `};

    ${checkIntent('fitted', p.intent) &&
    css`
      width: 100%;
    `};
  `};
`

const Bar = styled(Box)<IBar>`
  width: 100%;
  height: 2px;
  overflow: hidden;

  ${p => css`
    position: ${p.isFixed ? 'absolute' : 'relative'};
    background-color: ${!p.isFixed &&
    rgba(p.theme.components.newItemFieldBarBackgroundColor, 0.2)};

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: ${p.progress}%;
      height: 100%;
      background-color: ${p.theme.components.newItemFieldBarBackgroundColor};
      content: '';
      transition: width ${p.theme.motion.expressive};
    }

    ${p.isFixed &&
    css`
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      border-radius: ${p.theme.radii.rounded} ${p.theme.radii.rounded} 0 0;
    `};
  `};
`
