/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { GenericCampaignPaymentStatus } from '../models/GenericCampaignPaymentStatus'
import type { GenericCampaignStatus } from '../models/GenericCampaignStatus'
import type { GenericCampaignType } from '../models/GenericCampaignType'
import type { RequestCreateGenericCampaign } from '../models/RequestCreateGenericCampaign'
import type { RequestGenericCampaignCalculator } from '../models/RequestGenericCampaignCalculator'
import type { RequestGenericCampaignValidationRequirements } from '../models/RequestGenericCampaignValidationRequirements'
import type { RequestRejectCampaign } from '../models/RequestRejectCampaign'
import type { RequestSetGenericCampaignsAttributes } from '../models/RequestSetGenericCampaignsAttributes'
import type { ResponseAdminGenericCampaignList } from '../models/ResponseAdminGenericCampaignList'
import type { ResponseEntityCreated } from '../models/ResponseEntityCreated'
import type { ResponseGenericCampaignCalculator } from '../models/ResponseGenericCampaignCalculator'
import type { ResponseGenericCampaignValidationRequirements } from '../models/ResponseGenericCampaignValidationRequirements'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class GenericCampaignService {
  /**
   * Wylicza parametry kampanii generycznej
   * @returns ResponseGenericCampaignCalculator lista wariantów
   * @throws ApiError
   */
  public static getGeneticCampaignVariants({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody?: RequestGenericCampaignCalculator
  }): CancelablePromise<ResponseGenericCampaignCalculator> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/generic-campaign/calculator/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca wymagania walidacyjne kampanii generycznej
   * @returns ResponseGenericCampaignValidationRequirements wymagania walidacyjne
   * @throws ApiError
   */
  public static getGenericCampaignValidationRequirements({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody?: RequestGenericCampaignValidationRequirements
  }): CancelablePromise<ResponseGenericCampaignValidationRequirements> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/generic-campaign/validation-requirements/',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy nową kampanię generyczną
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kampania utworzona
   * @throws ApiError
   */
  public static createGenericCampaign({
    requestBody,
  }: {
    /**
     * Dane kampanii
     */
    requestBody: RequestCreateGenericCampaign
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/generic-campaign',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Ustawia atrybuty kampanii z poziomu admina
   * @returns Error unexpected error
   * @returns any atrybuty zmodyfikowane
   * @throws ApiError
   */
  public static setGenericCampaignAttributes({
    requestBody,
  }: {
    /**
     * Atrybuty kampanii
     */
    requestBody: RequestSetGenericCampaignsAttributes
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/generic-campaign-attributes',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca listę kampanii generycznych
   *
   * @returns ResponseAdminGenericCampaignList lista kampanii
   * @throws ApiError
   */
  public static adminGetGenericCampaigns({
    page,
    limit,
    status,
    type,
    paymentStatus,
    onlyArchive,
    sortDir,
    sortBy = 'name',
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * status kampanii
     */
    status?: GenericCampaignStatus
    /**
     * typ kampanii
     */
    type?: GenericCampaignType
    /**
     * status płatności kampanii
     */
    paymentStatus?: GenericCampaignPaymentStatus
    /**
     * tylko zarchiwizowane kampanie
     */
    onlyArchive?: boolean
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
    /**
     * pole po którym będą sortowane wyniki zapytania
     */
    sortBy?:
      | 'name'
      | 'shopName'
      | 'type'
      | 'id'
      | 'startDate'
      | 'admin'
      | 'paymentStatus'
  }): CancelablePromise<ResponseAdminGenericCampaignList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/generic-campaigns',
      query: {
        status: status,
        type: type,
        paymentStatus: paymentStatus,
        onlyArchive: onlyArchive,
        page: page,
        limit: limit,
        sortDir: sortDir,
        sortBy: sortBy,
      },
    })
  }

  /**
   * Odrzuca kampanię
   * @returns void
   * @throws ApiError
   */
  public static campaignReject({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    requestBody?: RequestRejectCampaign
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/generic-campaign/{campaignId}/reject',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * wstrzymuje kampanię generyczną o wybranym ID
   *
   * @returns Error unexpected error
   * @returns any kampania zatrzymana
   * @throws ApiError
   */
  public static stopGenericCampaign({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/generic-campaign/{campaignId}/stop',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * ponownie uruchamia zapauzowaną kampanię generyczną o wybranym ID
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static startCampaign({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/generic-campaign/{campaignId}/start',
      path: {
        campaignId: campaignId,
      },
    })
  }
}
