import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setHeaderBannerVisibility = createAction<boolean>(
  'setHeaderBannerVisibility',
)

export const setHeaderBannerVisibilityReducer = (
  state: RootState['app'],
  action: ReturnType<typeof setHeaderBannerVisibility>,
): void => {
  state.isHeaderBannerDisabled = action.payload
}
