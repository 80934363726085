import React, { FC } from 'react'
import { Flex, Text, TextButton, IFlex } from '@ads/front-ds'
import { lng } from '@ads/front-core/locales'

interface IInputLabelWithClear {
  label: string
  isArchived?: boolean
  showClearButton: boolean
  onClick: () => void
}

export const InputLabelWithClear: FC<IInputLabelWithClear & IFlex> = ({
  label,
  isArchived,
  showClearButton,
  onClick,
  ...flexProps
}) => (
  <Flex
    gap="s"
    alignItems="baseline"
    justifyContent="space-between"
    {...flexProps}
  >
    <Text fontWeight="medium" fontSize="m">
      {label}
    </Text>
    {!isArchived && showClearButton && (
      <TextButton onClick={onClick} variant="secondary">
        {lng('campaign:clearList')}
      </TextButton>
    )}
  </Flex>
)
