import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Box, IBox } from '@ads/front-ds'

interface HorizontalLineProps extends IBox {
  extraMargin?: number
}

const HorizontalLine: FC<HorizontalLineProps> = ({ extraMargin, ...rest }) => (
  <Line extraMargin={extraMargin} {...rest} />
)

const Line = styled(Box)<HorizontalLineProps>`
  ${p => css`
    width: ${p.extraMargin ? `calc(100% + ${2 * p.extraMargin}px)` : '100%'};
    border-bottom: ${p.theme.borders.thinGrey20};
    height: 1px;
    margin-left: -${p.extraMargin || 0}px;
    ${p.color &&
    css`
      border-color: ${p.theme.colors[p.color]};
    `}
  `};
`

export default HorizontalLine
