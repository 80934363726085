import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface ToggleSidebarParams {
  isOpen: boolean
}

export const toggleSidebar = createAction<ToggleSidebarParams>(
  'sidebar/toggleSidebar',
)

export const toggleSidebarReducer = (
  state: RootState['sidebar'],
  action: ReturnType<typeof toggleSidebar>,
): void => {
  const { isOpen } = action.payload
  state['isOpen'] = isOpen
}
