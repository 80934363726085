/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Created } from '../models/Created'
import type { Error } from '../models/Error'
import type { RequestSendEmail } from '../models/RequestSendEmail'
import type { ResponseCDPAttributesList } from '../models/ResponseCDPAttributesList'
import type { ResponseNewsletterStatus } from '../models/ResponseNewsletterStatus'
import type { ResponseSegmentsList } from '../models/ResponseSegmentsList'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class MailingCrmService {
  /**
   * Wysyła testowego emaila
   * @returns Created Email wysłany
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static sendEmail({
    requestBody,
  }: {
    requestBody?: RequestSendEmail
  }): CancelablePromise<Created | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/mailingcrm/send',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca listę segmentów
   * @returns ResponseSegmentsList Lista segmentów zwrócona pomyślnie
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getSegmentList({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponseSegmentsList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mailingcrm/{shopId}/segment',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * Zwraca listę atrybutów
   * @returns ResponseCDPAttributesList Lista atrybutów zwrócona pomyślnie
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAttributeList({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponseCDPAttributesList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mailingcrm/{shopId}/attribute',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * Zwraca statusy konfiguracji poszczególnych elementów systemu
   * @returns ResponseNewsletterStatus status poszczególnych elementów systemu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getNewsletterStatus({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponseNewsletterStatus | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/mailingcrm/{shopId}/status',
      path: {
        shopId: shopId,
      },
    })
  }
}
