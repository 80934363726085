/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestAddFlatFeeCampaignCreations } from '../models/RequestAddFlatFeeCampaignCreations'
import type { ResponseEntityCreated } from '../models/ResponseEntityCreated'
import type { ResponseFlatFeeCalendar } from '../models/ResponseFlatFeeCalendar'
import type { ResponseFlatFeeSite } from '../models/ResponseFlatFeeSite'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class FlatFeeService {
  /**
   * Pobiera pełną serwisów z reklamami Flat Fee
   * @returns ResponseFlatFeeSite Lista serwisów
   * @throws ApiError
   */
  public static flatFeeSiteList(): CancelablePromise<
    Array<ResponseFlatFeeSite>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/flat-fee/sites',
    })
  }

  /**
   * Pobiera pełną serwisów z reklamami Flat Fee
   * @returns ResponseFlatFeeCalendar Lista serwisów
   * @throws ApiError
   */
  public static flatFeeProducts({
    siteId,
    startDate,
    endDate,
  }: {
    /**
     * identyfikator serwisu(modułu) flat fee
     */
    siteId: number
    /**
     * data początkowa
     */
    startDate: string
    /**
     * data końcowa
     */
    endDate: string
  }): CancelablePromise<ResponseFlatFeeCalendar> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/flat-fee/products/{siteId}',
      path: {
        siteId: siteId,
      },
      query: {
        startDate: startDate,
        endDate: endDate,
      },
    })
  }

  /**
   * dodaje kreacje do kampanii FF
   * @returns Error unexpected error
   * @returns ResponseEntityCreated kreacje dodane
   * @throws ApiError
   */
  public static addFlatFeeCreations({
    campaignId,
    requestBody,
  }: {
    campaignId: number
    /**
     * Dane kreacji
     */
    requestBody: RequestAddFlatFeeCampaignCreations
  }): CancelablePromise<Error | ResponseEntityCreated> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/flat-fee/{campaignId}/creations',
      path: {
        campaignId: campaignId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
