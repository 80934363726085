import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import {
  borderRadius,
  boxShadow,
  borderColor,
  borderWidth,
  color,
  space,
} from 'styled-system'

import { IBox } from './types'

export const Box = forwardRef((props: IBox, ref: React.Ref<HTMLDivElement>) => {
  const { styleCss, isDisabled, borderColor, borderWidth, ...rest } = props

  const borderWidthProps =
    borderColor && borderWidth ? borderWidth : borderColor ? 1 : 0

  return (
    <StyledBox
      ref={ref}
      disabled={isDisabled}
      style={styleCss}
      $borderColor={borderColor}
      $borderWidth={borderWidthProps}
      {...rest}
    />
  )
})

const StyledBox = styled.div<IBox>`
  ${space};
  ${color};
  ${borderRadius};
  ${boxShadow};
  ${borderColor};
  ${borderWidth};
  box-sizing: border-box;
  letter-spacing: 0.2px;

  ${p => css`
    ${p.borderColor && p.borderWidth && 'border-style: solid;'}
  `}
`
