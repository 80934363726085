import React from 'react'
import styled, { css } from 'styled-components'
import { adjustPaddings } from '../../../utility'
import { IconButton, TextButton } from '../../Buttons'
import { Flex } from '../../Flex'
import { Icon } from '../../Icon'
import { Text } from '../../Text'
import { IInlineBanner, IVariantIcon } from './types'

const getIconProps = (
  variant: IInlineBanner['variant'],
): {
  name: IVariantIcon['name']
  iconColor: IVariantIcon['iconColor']
} => {
  switch (variant) {
    case 'informative':
      return {
        name: '20-info',
        iconColor: 'inlineBannerInformativeIconColor',
      }
    case 'positive':
      return {
        name: '20-check-stroke',
        iconColor: 'inlineBannerPositiveIconColor',
      }
    case 'warning':
      return {
        name: '20-warning',
        iconColor: 'inlineBannerWarningIconColor',
      }
    case 'error':
      return {
        name: '20-alert',
        iconColor: 'inlineBannerErrorIconColor',
      }
    case 'suggestion':
      return {
        name: '20-info',
        iconColor: 'inlineBannerSuggestionIconColor',
      }
    default:
      return {
        name: '20-info',
        iconColor: 'inlineBannerInformativeIconColor',
      }
  }
}

const getBg = (variant?: IInlineBanner['variant']) => {
  if (!variant) {
    return 'inlineBannerInformativeBackgroundColor'
  }

  switch (variant) {
    case 'informative':
      return 'inlineBannerInformativeBackgroundColor'
    case 'positive':
      return 'inlineBannerPositiveBackgroundColor'
    case 'warning':
      return 'inlineBannerWarningBackgroundColor'
    case 'error':
      return 'inlineBannerErrorBackgroundColor'
    case 'suggestion':
      return 'inlineBannerSuggestionBackgroundColor'
    case 'neutral':
      return 'inlineBannerNeutralBackgroundColor'
  }
}

export const InlineBanner = ({
  variant = 'neutral',
  title,
  description,
  icon,
  action,
  isActionBelow = true,
  role = 'alert',
  onCloseClick,
  ...rest
}: IInlineBanner) => {
  if (!title && !description)
    throw new Error('InlineBanner should have title or description!')

  return (
    <Container
      p="s"
      isInline
      justifyContent="flex-start"
      borderRadius="rounded"
      variant={variant}
      role={role}
      {...rest}
    >
      {icon && variant !== 'neutral' && (
        <VariantIcon {...getIconProps(variant)} />
      )}
      {icon && typeof icon === 'string' && variant === 'neutral' && (
        <VariantIcon name={icon} iconColor="inlineBannerNeutralIconColor" />
      )}
      <StyledFlex
        isActionBelow={isActionBelow}
        ml="s"
        mr={onCloseClick ? 's' : undefined}
        gap="xs"
      >
        <Flex flexDirection="column" gap="xxs">
          {title && (
            <Text fontSize="m" lineHeight="m" as="strong" fontWeight="medium">
              {title}
            </Text>
          )}
          {description &&
            (typeof description === 'string' ? (
              <Description fontSize="m" lineHeight="m">
                {description}
              </Description>
            ) : (
              <Flex alignItems="center" flexWrap="wrap">
                {description}
              </Flex>
            ))}
        </Flex>

        {action && (
          <TextButton
            variant="primary"
            onClick={action.onClick}
            aria-label={action['aria-label']}
            style={{ flexShrink: '0' }}
          >
            {action.label}
          </TextButton>
        )}
      </StyledFlex>
      {onCloseClick && (
        <Close
          aria-label="Zamknij"
          variant="bareDark"
          name="20-close"
          onClick={onCloseClick}
        />
      )}
    </Container>
  )
}

const StyledFlex = styled(Flex)<IInlineBanner>`
  flex: 1;
  align-items: flex-start;
  ${p => css`
    flex-direction: ${p.isActionBelow ? 'column' : 'row'};
    justify-content: ${p.isActionBelow ? 'flex-start' : 'space-between'};
  `};
`

const Container = styled(Flex)<IInlineBanner>`
  position: relative;
  width: 100%;

  ${p => css`
    ${p.variant === 'neutral' && adjustPaddings('s', 'thinGrey20')};
    background-color: ${p.theme.components[getBg(p.variant)]};
    color: ${p.theme.components.inlineBannerColor};
  `};
`

const VariantIcon = styled(Icon)<IVariantIcon>`
  flex: 0 0 20px;
  color: ${p => p.theme.components[p.iconColor]};
`

const Close = styled(IconButton)`
  flex: 0 0 20px;
  align-self: flex-start;
  margin-left: auto;
`

const Description = styled(Text).attrs({ forwardedAs: 'p' })`
  white-space: pre-line;
`
