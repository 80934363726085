import { rgba } from 'polished'

import { Focuses } from './interfaces'
import { colors } from './colors'

export const focuses: Focuses = {
  normal: `0 0 0 4px ${rgba(colors.primary50, 0.2)}, 0 0 0 1px ${
    colors.primary20
  }`,
  inverted: `0 0 0 4px ${colors.inverted20}, 0 0 0 1px ${colors.inverted50}`,
  error: `0 0 0 4px ${rgba(colors.alert50, 0.2)}, 0 0 0 1px ${colors.alert20}`,
}
