import React, { MouseEvent, useState } from 'react'
import styled, { css } from 'styled-components'

import { setTransition } from '../../utility'
import { Avatar } from '../Avatars'
import { IconButton } from '../Buttons'
import { Icon } from '../Icon'
import { convertLabel } from '../Icon/Icon'
import { Image } from '../Image'
import { IText, Text } from '../Text'
import { Tooltip } from '../Tooltip'
import { IStyledTag, ITag, ITagAvatar, ITagIcon, ITagPhoto } from './types'

export const getIcon = (
  icon: ITag['iconBefore'] | ITag['iconAfter'],
  isDisabled?: boolean,
  isAfter?: boolean,
) => {
  if (
    typeof icon === 'object' &&
    Object.hasOwnProperty.call(icon, 'onClick') &&
    isAfter
  ) {
    const i: ITag['iconAfter'] = icon

    const handleOnClick = (e?: MouseEvent) => {
      if (!e) return
      e.stopPropagation()
      if (i.onClick) i.onClick(e)
    }

    return (
      <IconButtonWrapper
        variant="bareDark"
        aria-label={convertLabel(i.name as string)}
        name={i.name}
        isDisabled={isDisabled}
        onClick={handleOnClick}
        ml="xxs"
      />
    )
  }

  if (!icon) {
    return null
  }

  return (
    <TagIcon
      isDisabled={isDisabled}
      name={typeof icon === 'object' ? icon.name : icon}
      color={typeof icon === 'object' && !isDisabled ? icon.color : undefined}
      mr={!isAfter ? 'xxs' : undefined}
      ml={isAfter ? 'xxs' : undefined}
    />
  )
}

export const Tag = React.memo(
  ({
    as = 'span',
    label,
    caption,
    isDisabled,
    iconBefore,
    iconAfter,
    backgroundColor,
    src,
    isAvatar,
    ...rest
  }: ITag) => {
    const [mouseDown, setMouseDown] = useState(false)

    if (process.env.NODE_ENV !== 'production' && src && iconBefore)
      console.warn("You shouldn't set photo and iconBefore at the same time.")

    const onMouseDown = React.useCallback(() => setMouseDown(true), [])
    const onBlur = React.useCallback(() => setMouseDown(false), [])

    return (
      <StyledTag
        fontSize="m"
        lineHeight="m"
        py="xxxs"
        px="xxs"
        forwardedAs={
          (iconAfter && Object.hasOwnProperty.call(iconAfter, 'onClick')
            ? 'span'
            : as) as IText['forwardedAs']
        }
        isDisabled={isDisabled}
        isMouseDown={mouseDown}
        isImage={Boolean(src)}
        onMouseDown={onMouseDown}
        onBlur={onBlur}
        tabIndex={
          iconAfter &&
          Object.hasOwnProperty.call(iconAfter, 'onClick') &&
          as === 'button'
            ? 0
            : undefined
        }
        {...rest}
      >
        {isAvatar && src && (
          <StyledAvatar
            name={typeof label === 'object' ? label.value : label}
            size="xs"
            mr="xxs"
            isDisabled={isDisabled}
            backgroundColor={backgroundColor}
            src={src}
          />
        )}
        {iconBefore && typeof iconBefore === 'object' && iconBefore.tooltip ? (
          <Tooltip {...iconBefore.tooltip}>
            {getIcon(iconBefore, isDisabled)}
          </Tooltip>
        ) : (
          iconBefore && getIcon(iconBefore, isDisabled)
        )}
        {!isAvatar && src && (
          <StyledImg
            src={src}
            alt={typeof label === 'object' ? label.value : label}
            mr="xxs"
          />
        )}

        {label && typeof label === 'object' && label.tooltip ? (
          <Tooltip {...label.tooltip}>{label.value}</Tooltip>
        ) : (
          label
        )}

        {caption && (
          <Caption color="grey50" pl="xxs">
            {caption}
          </Caption>
        )}
        {iconAfter && typeof iconAfter === 'object' && iconAfter.tooltip ? (
          <Tooltip {...iconAfter.tooltip}>
            {getIcon(iconAfter, isDisabled, true)}
          </Tooltip>
        ) : (
          iconAfter && getIcon(iconAfter, isDisabled, true)
        )}
      </StyledTag>
    )
  },
)

Tag.displayName = 'Tag'

const IconButtonWrapper = styled(IconButton)`
  display: flex;
`

const TagIcon = styled(Icon)<ITagIcon>`
  z-index: 1;
  display: flex;
  color: ${p => !p.color && p.theme.components.tagIconColor};
  flex-shrink: 0;
`

const StyledTag = styled(Text)<IStyledTag>`
  display: inline-flex;
  align-items: center;
  ${setTransition(
    ['background-color', 'border-color', 'color', 'box-shadow'],
    'productive',
  )};
  font-family: inherit;
  border: 0;

  ${p => css`
    background-color: ${p.theme.components.tagBackgroundColor};
    border-radius: ${p.theme.radii.rounded};
    cursor: ${p.onClick && 'pointer'};

    &:active {
      opacity: ${p.onClick && p.theme.aliases.defaultActiveOpacity};
    }

    &:focus {
      outline: none;

      ${!p.isMouseDown &&
      css`
        box-shadow: ${p.theme.focuses.normal};
        border-color: transparent;
      `};
    }

    ${p.type === 'isSelected' &&
    css`
      background-color: ${p.theme.components.tagSelectedBackgroundColor};
    `};

    ${p.type === 'isSuggested' &&
    css`
      background-color: ${p.theme.colors.weakSuggestion05};
    `};

    ${p.isDisabled &&
    css`
      opacity: 0.5;
    `};
    ${p.isImage &&
    css`
      padding-left: ${p.theme.space.xxxs}px;
    `};
  `};
`

const StyledAvatar = styled(Avatar)<ITagAvatar>`
  ${p => css`
    opacity: ${p.isDisabled && 0.55};
  `};
`

const StyledImg = styled(Image)<ITagPhoto>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  object-fit: contain;
  flex-shrink: 0;

  ${p => css`
    opacity: ${p.isDisabled && 0.55};
  `};
`

const Caption = styled(Text)`
  opacity: 0.7;
`
