import React, { PropsWithChildren, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { IStyledTagsPath, ITagsPath } from './types'

import { checkIntent, setTransition } from '../../utility'

import { Tooltip } from '../Tooltip'
import { getIcon } from '../Tag'
import { useTagsPathSettings } from './tagsPathContext'

export const TagsWrapper = ({
  type,
  iconAfter,
  pathTooltip,
  lastPathTooltip,
  children,
  ...rest
}: PropsWithChildren<ITagsPath>) => {
  const [mouseDown, setMouseDown] = useState(false)

  const { hoveredLastTag } = useTagsPathSettings()

  const isSelected = checkIntent('isSelected', type)
  const isSuggested = checkIntent('isSuggested', type)

  return (
    <StyledTagsWrapper
      py="xxxs"
      px="xxs"
      isSelected={isSelected}
      isSuggested={isSuggested}
      isMouseDown={mouseDown}
      onMouseDown={() => setMouseDown(true)}
      onBlur={() => setMouseDown(false)}
      {...rest}
    >
      {hoveredLastTag || lastPathTooltip ? (
        <Tooltip
          text={hoveredLastTag ? lastPathTooltip?.text : pathTooltip?.text}
          caption={
            hoveredLastTag ? lastPathTooltip?.caption : pathTooltip?.caption
          }
          placement="start"
        >
          {children}
        </Tooltip>
      ) : (
        children
      )}

      {iconAfter && typeof iconAfter === 'object' && iconAfter.tooltip ? (
        <StyledIconBox>
          <Tooltip {...iconAfter.tooltip}>
            {getIcon(iconAfter, false, true)}
          </Tooltip>
        </StyledIconBox>
      ) : (
        iconAfter && (
          <StyledIconBox mt="xxxxs">
            {getIcon(iconAfter, false, true)}
          </StyledIconBox>
        )
      )}
    </StyledTagsWrapper>
  )
}

const StyledTagsWrapper = styled(Box)<IStyledTagsPath>`
  display: flex;
  align-items: flex-start;
  ${setTransition(
    ['background-color', 'border-color', 'color', 'box-shadow'],
    'productive',
  )};
  font-family: inherit;
  border: 0;

  ${p => css`
    background-color: ${p.theme.components.tagBackgroundColor};
    border-radius: ${p.theme.radii.rounded};

    &:focus {
      outline: none;

      ${!p.isMouseDown &&
      css`
        box-shadow: ${p.theme.focuses.normal};
        border-color: transparent;
      `};
    }

    ${p.isSelected &&
    `background-color: ${p.theme.components.tagSelectedBackgroundColor};`};

    ${p.isSuggested &&
    `background-color: ${p.theme.components.tagSuggestedBackgroundColor}`}
  `};
`

const StyledIconBox = styled(Box)`
  margin-top: ${({ theme }) => `${theme.space.xxxs / 2}px`};
`
