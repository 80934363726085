import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import { setStorage, setStorageReducer } from './setStorageReducer'

const setStorageReducers = createReducer(
  preloadedRootState.storage,
  builder => {
    builder.addCase(setStorage.type, setStorageReducer)
  },
)

export default setStorageReducers
