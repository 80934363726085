import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { Box, IBox } from '../../Box'
import { Text } from '../../Text'
import { ToggleControl } from '../ToggleControl'

import { checkIntent } from '../../../utility'
import { IToggle } from './types'

export const Toggle = ({
  value,
  isChecked = false,
  children,
  isDisabled,
  id,
  name,
  intent,
  size = 'm',
  forwardedAs = 'label',
  onChange,
  onFocus,
  onBlur,
  ...rest
}: PropsWithChildren<IToggle>) => {
  const inputProps = {
    value,
    isChecked,
    isDisabled,
    id,
    name,
    onChange,
    onFocus,
    onBlur,
  }

  return (
    <StyledToggle
      isDisabled={isDisabled}
      intent={intent}
      forwardedAs={forwardedAs}
      {...rest}
    >
      <Label>
        <Text
          pr={children && !checkIntent('reverse', intent) ? 's' : 'none'}
          pl={children && checkIntent('reverse', intent) ? 's' : 'none'}
          as="div"
          fontSize={size === 's' ? 's' : 'm'}
          lineHeight={size === 's' ? 's' : 'm'}
        >
          {children}
        </Text>
      </Label>
      <StyledControl
        forwardedAs={'div' as IBox['forwardedAs']}
        size={size}
        {...inputProps}
      />
    </StyledToggle>
  )
}

const StyledToggle = styled(Box)<IToggle>`
  display: inline-flex;
  align-items: flex-start;
  color: ${p => p.theme.components.toggleColor};

  ${p => css`
    ${p.isDisabled &&
    css`
      color: ${p.theme.components.toggleDisabledColor};
      pointer-events: none;
    `};

    ${checkIntent('fitted', p.intent) &&
    css`
      display: flex;
      width: 100%;
    `};

    ${checkIntent('reverse', p.intent) &&
    css`
      flex-direction: row-reverse;
    `};
  `};
`

const StyledControl = styled(ToggleControl)`
  ${p => css`
    margin: ${p.theme.space.xxxs / 2}px 0;
  `};
`

const Label = styled.span`
  cursor: pointer;
  flex: 1 1 auto;
`
