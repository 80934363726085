import React from 'react'
import { useRect } from '@reach/rect'
import { forwardRef, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { setTransition } from '../../utility'
import { Box } from '../Box'
import { Portal } from '../Portal/Portal'
import { IContainer, IDropdown, IDropdownMenuContainer } from './types'

export const Dropdown = forwardRef<HTMLDivElement, IDropdown>((props, ref) => {
  const {
    toggle,
    menu,
    container,
    isOpen,
    direction = 'down',
    align = 'start',
    zIndex,
    zIndexMenu,
    isBlockOnOpen,
    isInPortal = true,
    keyNavigationProps,
    ...rest
  } = props

  const containerRef = useRef(null)

  const containerRect = useRect(containerRef)

  return (
    <Container
      direction={direction}
      align={align}
      zIndex={zIndex}
      tabIndex={0}
      ref={containerRef}
      {...rest}
    >
      <DropdownToggle>{toggle}</DropdownToggle>
      <Box {...keyNavigationProps} styleCss={{ position: 'absolute' }}>
        <CSSTransition
          in={isOpen}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          {isInPortal ? (
            <Portal
              isOpen={isOpen}
              isBlockOnOpen={isBlockOnOpen}
              container={container}
            >
              {containerRect && (
                <DropdownMenu
                  direction={direction}
                  ref={ref}
                  align={align}
                  zIndexMenu={zIndexMenu}
                  containerBottom={containerRect.bottom}
                  containerTop={containerRect.top}
                  containerLeft={containerRect.left}
                  containerRight={containerRect.right}
                  containerWidth={containerRect.width}
                  isInPortal={isInPortal}
                >
                  {menu}
                </DropdownMenu>
              )}
            </Portal>
          ) : (
            <DropdownMenu
              direction={direction}
              ref={ref}
              align={align}
              zIndexMenu={zIndexMenu}
              isInPortal={isInPortal}
            >
              {menu}
            </DropdownMenu>
          )}
        </CSSTransition>
      </Box>
    </Container>
  )
})

const getOriginX = (align: IDropdown['align']) => {
  switch (align) {
    case 'start':
      return 'left'
    case 'end':
      return 'right'
    default:
      return 'center'
  }
}

const getOriginY = (direction: IDropdown['direction']) =>
  direction === 'down' ? 'top' : 'bottom'

const getAlignCssInPortal = (
  align: IDropdown['align'],
  right: IDropdownMenuContainer['containerRight'],
  left: IDropdownMenuContainer['containerLeft'],
  width: IDropdownMenuContainer['containerWidth'],
  direction: IDropdown['direction'],
) => {
  switch (align) {
    case 'start':
      return css`
        left: ${left}px;
      `
    case 'end':
      return css`
        right: calc(100% - ${right}px);
      `
    default:
      return css`
        left: ${right && width && right - width / 2}px;
        ${direction === 'up'
          ? `transform: translateX(-50%) translateY(-100%);`
          : `transform: translateX(-50%);`}
      `
  }
}

const getAlignCss = (align: IDropdown['align']) => {
  switch (align) {
    case 'start':
      return css`
        left: 0;
      `
    case 'end':
      return css`
        right: 0;
      `
    default:
      return css`
        left: 50%;
        transform: translateX(-50%);
      `
  }
}

const Container = styled(Box)<IContainer>`
  position: relative;
  &:focus {
    outline: none;
  }

  ${p => css`
    z-index: ${p.zIndex};
    ${p.intent && p.intent === 'fitted'
      ? css`
          display: block;
        `
      : css`
          display: inline-flex;
        `}
  `};
`

const DropdownToggle = styled(Box)`
  position: relative;
  z-index: 2;
`

const getDirectionCss = (direction: IDropdown['direction']) => {
  if (direction === 'up') {
    return css`
      bottom: 100%;
    `
  }

  return css`
    top: 100%;
  `
}

const DropdownMenu = styled(Box)<IDropdownMenuContainer>`
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  position: fixed;

  ${p => css`
    z-index: ${p.theme.zIndex.dropdown};

    ${p.isInPortal
      ? p.direction === 'up'
        ? `top: ${(p.containerTop as number) - p.theme.space.xxs}px;
        transform: translateY(-100%);`
        : `top: ${(p.containerBottom as number) + p.theme.space.xxs}px;`
      : getDirectionCss(p.direction)}

    ${p.isInPortal
      ? getAlignCssInPortal(
          p.align,
          p.containerRight,
          p.containerLeft,
          p.containerWidth,
          p.direction,
        )
      : getAlignCss(p.align)}

    transform-origin: ${getOriginX(p.align)} ${getOriginY(p.direction)};
    z-index: ${p.zIndexMenu};

    &.fade-enter {
      opacity: 0;
      transform: scale(0.9)
        ${p.direction === 'up'
          ? 'translateY(-100%)'
          : `translateY(-${p.theme.space.xxxs}px)`}
        ${p.align === 'center' && 'translateX(-50%)'};
    }

    &.fade-enter-active {
      opacity: 1;
      transform: scale(1)
        ${p.direction === 'up' ? 'translateY(-100%)' : 'translateY(0)'}
        ${p.align === 'center' && 'translateX(-50%)'};
      ${setTransition(['opacity', 'transform'], 'productive')};
    }

    &.fade-exit {
      opacity: 1;
      transform: scale(1)
        ${p.direction === 'up' ? 'translateY(-100%)' : 'translateY(0)'}
        ${p.align === 'center' && 'translateX(-50%)'};
    }

    &.fade-exit-active {
      opacity: 0;
      transform: scale(0.9)
        ${p.direction === 'up'
          ? 'translateY(-100%)'
          : `translateY(-${p.theme.space.xxxs}px)`}
        ${p.align === 'center' && 'translateX(-50%)'};
      ${setTransition(['opacity', 'transform'], 'productive')};
    }
    ${!p.isInPortal &&
    css`
      position: absolute;
      margin: ${p.theme.space.xxs}px 0;
    `};
  `};
`
