import styled, { css } from 'styled-components'

import { checkIntent, setTransition } from '../../../utility'
import { Text } from '../../Text'
import { IVariant } from './types'

const IconButton = styled(Text)<IVariant>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  appearance: none;
  background: transparent;
  font-family: inherit;
  letter-spacing: 0.2px;
  text-decoration: none;
  ${setTransition(
    ['border-color', 'background-color', 'color', 'box-shadow'],
    'productive',
  )};

  ${p => css`
    border-radius: ${p.theme.radii.rounded};

    &:focus {
      outline: none;

      ${!p.isMouseDown &&
      css`
        box-shadow: ${!checkIntent('bareInverted', p.variant) &&
        p.theme.focuses.normal};
        border-color: transparent;
      `};
    }

    ${!p.isDisabled &&
    css`
      &:active {
        opacity: ${p.theme.aliases.defaultActiveOpacity};
      }
    `};

    ${(p.isDisabled || p.isLoading) &&
    css`
      background-color: transparent;
      cursor: not-allowed;
    `};

    ${checkIntent(['bareDark', 'bareInverted'], p.variant) &&
    css`
      padding: 0;
    `};

    ${checkIntent(['bareDark', 'bareInverted'], p.variant) &&
    checkIntent('selected', p.intent) &&
    css`
      border-radius: 0;

      &::after {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        transition: box-shadow ${p.theme.motion.productive};
        border: ${p.theme.space.xxs}px solid transparent;
        width: 100%;
        height: 100%;
        border-radius: ${p.theme.radii.rounded};
        content: '';
      }
    `};
  `};
`

export const Dark = styled(IconButton)`
  ${p => css`
    color: ${p.isLoading
      ? 'transparent'
      : p.theme.components.iconButtonDarkColor};

    ${!p.isDisabled &&
    css`
      &:hover {
        background-color: ${!checkIntent('selected', p.intent) &&
        p.theme.components.iconButtonDarkHoverBackgroundColor};
        color: ${!checkIntent('selected', p.intent) &&
        p.theme.components.iconButtonDarkHoverColor};
      }
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.iconButtonDarkDisabledColor};
    `};

    ${checkIntent('selected', p.intent) &&
    !p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .iconButtonDarkSelectedBackgroundColor};
      color: ${p.theme.components.iconButtonDarkSelecteColor};
    `};

    ${checkIntent('selected', p.intent) &&
    p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .iconButtonDarkSelecteDisabledBackgroundColor};
    `};
  `};
`

export const Inverted = styled(IconButton)`
  ${p => css`
    color: ${p.isLoading
      ? 'transparent'
      : p.theme.components.iconButtonInvertedColor};

    ${!p.isDisabled &&
    css`
      &:hover {
        background-color: ${!checkIntent('selected', p.intent) &&
        p.theme.components.iconButtonInvertedHoverBackgroundColor};
        color: ${!checkIntent('selected', p.intent) &&
        p.theme.components.iconButtonInvertedHoverColor};
      }

      &:focus {
        box-shadow: ${!p.isMouseDown && p.theme.focuses.inverted};
      }
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.iconButtonInvertedDisabledColor};
    `};

    ${checkIntent('selected', p.intent) &&
    !p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .iconButtonInvertedSelectedBackgroundColor};
    `};

    ${checkIntent('selected', p.intent) &&
    p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .iconButtonInvertedSelectedDisabledBackgroundColor};
    `};
  `};
`

export const Destructive = styled(IconButton)`
  ${p => css`
    color: ${p.isLoading
      ? 'transparent'
      : p.theme.components.iconButtonDestructiveColor};

    ${!p.isLoading &&
    css`
      &:hover {
        background-color: ${p.theme.components
          .iconButtonDestructiveHoverBackgroundColor};
        color: ${p.theme.components.iconButtonDestructiveHoverColor};
      }
    `}

    &:focus {
      box-shadow: ${p.theme.focuses.error};
    }

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.iconButtonDestructiveDisabledColor};
    `};
  `};
`

export const BareDark = styled(IconButton)`
  padding: 0;

  ${p => css`
    color: ${p.isLoading
      ? 'transparent'
      : p.theme.components.iconButtonBareDarkColor};

    ${!p.isDisabled &&
    css`
      &:hover {
        color: ${!checkIntent('selected', p.intent) &&
        p.theme.components.iconButtonBareDarkHoverColor};
      }
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.iconButtonBareDarkDisabledColor};
    `};

    ${checkIntent('selected', p.intent) &&
    !p.isDisabled &&
    css`
      color: ${p.theme.components.iconButtonBareDarkSelectedColor};
    `};
  `};
`

export const BareInverted = styled(IconButton)`
  padding: 0;

  ${p => css`
    color: ${p.isLoading
      ? 'transparent'
      : p.theme.components.iconButtonBareInvertedColor};

    ${!p.isDisabled &&
    !p.isLoading &&
    css`
      &:hover {
        color: ${!checkIntent('selected', p.intent) &&
        p.theme.components.iconButtonBareInvertedHoverColor};
      }

      &:focus {
        box-shadow: ${!p.isMouseDown &&
        !checkIntent('selected', p.intent) &&
        p.theme.focuses.inverted};
      }
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.iconButtonBareInvertedDisabledColor};
    `};

    ${checkIntent('selected', p.intent) &&
    !p.isDisabled &&
    css`
      color: ${p.theme.components.iconButtonBareInvertedSelectedColor};

      &:focus::after {
        box-shadow: ${!p.isMouseDown && p.theme.focuses.inverted};
      }
    `};
  `};
`

export const BareDestructive = styled(IconButton)`
  padding: 0;

  ${p => css`
    color: ${p.isLoading
      ? 'transparent'
      : p.theme.components.iconButtonBareDestructiveColor};

    ${!p.isDisabled &&
    !p.isLoading &&
    css`
      &:hover {
        color: ${p.theme.components.iconButtonBareDestructiveHoverColor};
      }

      &:focus {
        box-shadow: ${p.theme.focuses.error};
      }
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.iconButtonBareDestructiveDisabledColor};
    `};
  `};
`
