/**
 * Function add correct paddings if component has a border
 */

import { css } from 'styled-components'

import { space, borders } from '../theme'
import { Borders, Space } from '../theme/interfaces'

const setPaddings = (paddings: TSpace[]) => {
  return paddings
    .slice(0, 4)
    .map(p => `${space[p] === 0 ? 0 : space[p] - 1}px`)
    .join(' ')
}

type TSpace = keyof Space

export const adjustPaddings = (
  padding: TSpace | TSpace[],
  border?: keyof Borders,
) => {
  if (typeof padding === 'object') {
    return css`
      padding: ${setPaddings(padding as [])};
      border: ${border && borders[border]};
    `
  }

  return css`
    padding: ${space[padding] - 1}px;
    border: ${border && borders[border]};
  `
}
