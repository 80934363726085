import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { setTransition } from '../../utility'

import { TextButton } from '../Buttons'
import { Icon } from '../Icon'

import * as DOMPurify from 'dompurify'
import { ICascaderMenuItem } from './types'

export const CascaderMenuItem: FC<
  React.PropsWithChildren<ICascaderMenuItem>
> = ({ label, hasChildren, isSelected, isMainOption, onClick, ...rest }) => {
  const [isHovered, setHover] = useState(false)

  const buttonProps = {
    isMainOption,
    isHovered,
    isSelected,
    onClick,
    ...rest,
  }

  const sanitizedLabel = sanitize(label)

  return (
    <StyledButton
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...buttonProps}
    >
      <span dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />
      {hasChildren && (
        <ConstSizeIcon
          name="20-arrowhead-right"
          color={
            isHovered || (isSelected && !isMainOption)
              ? 'inverted100'
              : 'grey50'
          }
        />
      )}
    </StyledButton>
  )
}

const ConstSizeIcon = styled(Icon)`
  flex-shrink: 0;
`

const StyledButton = styled(TextButton)<ICascaderMenuItem>`
  display: flex;
  text-align: left;
  justify-content: space-between;
  border: none;
  cursor: pointer;
  background-color: transparent;
  ${setTransition(['color', 'background-color'], 'productive')};

  &:hover {
    ${p => css`
    background-color ${p.theme.components.cascaderItemBackgroundActive};
    color:${p.theme.components.cascaderItemLabelActive};
  `};
  }

  ${p => css`
    font-weight: ${p.theme.fontWeights.regular};
    color: ${p.theme.components.cascaderItemLabel};
    padding: 6px ${p.theme.space.xxs}px;

    ${p.isSelected &&
    css`
    background-color ${p.theme.components.cascaderItemBackgroundActive};
    color:${p.theme.components.cascaderItemLabelActive};
    `}

    ${p.isMainOption &&
    css`
      color: ${p.theme.components.cascaderMainItemLabel};
      &:hover {
        ${p => css`
        background-color ${p.theme.components.cascaderMainItemBackgroundActive};
        color:${p.theme.components.cascaderMainItemLabelActive};
      `};
      }
    `}
    ${p.isSelected &&
    p.isMainOption &&
    css`
      background-color ${p.theme.components.cascaderMainItemBackgroundActive};
      color:${p.theme.components.cascaderMainItemLabelActive};
      `}
  `}
`

const sanitize = (value: string): string => {
  return DOMPurify.sanitize(value, {
    USE_PROFILES: { html: true },
  })
}
