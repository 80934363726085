import React from 'react'
import { useSyncExternalStore } from 'use-sync-external-store/shim'
import { objectKeys } from '../../../utils'
import { AnimatePresence, Variants } from 'framer-motion'
import { DefaultPortal } from '../../Portal'
import { ToastComponent, ToastComponentProps } from './toast.component'
import type {
  CloseAllToastsOptions,
  ToastId,
  ToastMessage,
  ToastOptions,
} from './toast.types'
import type { UseToastOptions } from './useToast'
import { toastStore } from './toast.store'
import { getToastListStyle } from './toast.utils'

export interface ToastMethods {
  /**
   * Function to actually create a toast and add it
   * to state at the specified position
   */
  notify: (message: ToastMessage, options?: CreateToastOptions) => ToastId
  /**
   * Close all toasts at once.
   * If given positions, will only close those.
   */
  closeAll: (options?: CloseAllToastsOptions) => void
  /**
   * Requests to close a toast based on its id and position
   */
  close: (id: ToastId) => void
  /**
   * Update a specific toast with new options based on the
   * passed `id`
   */
  update: (id: ToastId, options: Omit<UseToastOptions, 'id'>) => void
  isActive: (id: ToastId) => boolean
}

export type CreateToastOptions = Partial<
  Pick<ToastOptions, 'duration' | 'position' | 'id' | 'onCloseComplete'>
>

export type ToastProviderProps = React.PropsWithChildren<{
  /**
   * Customize the default motion config to animate the toasts your way
   *
   * @example
   * const motionVariants =
   * <ToastProvider motionVariants={motionVariants} />
   */
  motionVariants?: Variants

  /**
   * Are you looking for a way to style the toast? Use a custom `Alert` variant in the theme.
   * This property overrides the default ToastComponent with your own implementation.
   *
   * @example
   * const CustomToastComponent = (props: ToastComponentProps) => ...
   * <ToastProvider component={CustomToastComponent} />
   *
   * @default ToastComponent
   */
  component?: React.FC<ToastComponentProps>
}>

/**
 * Manages the creation, and removal of toasts
 * across all corners ("top", "bottom", etc.)
 */
export const ToastProvider = (props: ToastProviderProps) => {
  const state = useSyncExternalStore(
    toastStore.subscribe,
    toastStore.getState,
    toastStore.getState,
  )

  const {
    children,
    motionVariants,
    component: Component = ToastComponent,
  } = props

  const toastList = objectKeys(state).map(position => {
    const toasts = state[position]

    return (
      <ul
        role="region"
        aria-live="polite"
        key={String(position)}
        id={`ds-toast-manager-${position as any}`}
        style={getToastListStyle(position as any)}
      >
        <AnimatePresence initial={false}>
          {toasts.map(
            (toast: JSX.IntrinsicAttributes & ToastComponentProps) => (
              <Component
                key={toast.id}
                motionVariants={motionVariants}
                {...toast}
              />
            ),
          )}
        </AnimatePresence>
      </ul>
    )
  })

  return (
    <>
      {children}
      <DefaultPortal>{toastList}</DefaultPortal>
    </>
  )
}
