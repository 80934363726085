import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import { hideBanner, hideBannerReducer } from './hideBannerReducer'
import {
  hideBannersByPrefix,
  hideBannersByPrefixReducer,
} from './hideBannersByPrefixReducer'
import { overBanner, overBannerReducer } from './overBannerReducer'
import { showBanner, showBannerReducer } from './showBannerReducer'

const bannerReducers = createReducer(preloadedRootState.banner, builder => {
  builder.addCase(showBanner.type, showBannerReducer)
  builder.addCase(hideBanner.type, hideBannerReducer)
  builder.addCase(overBanner.type, overBannerReducer)
  builder.addCase(hideBannersByPrefix.type, hideBannersByPrefixReducer)
})

export default bannerReducers
