import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { delay, put, takeEvery } from 'redux-saga/effects'
import { IInlineBanner } from '@ads/front-ds'
import { hideBanner } from '../reducers/banner/hideBannerReducer'
import { showBanner } from '../reducers/banner/showBannerReducer'

export interface IBannerSaga extends IInlineBanner {
  id?: string
  variant: IInlineBanner['variant']
  showOnTop?: boolean
  disableAutoHide?: boolean
  disableClose?: boolean
  overBanner?: boolean
}

export type BannerSaga = ReturnType<PayloadActionCreator<IBannerSaga, string>>

export function* sagaBanner(): Generator {
  yield takeEvery(banner.type, bannerSaga)
}

export const banner = createAction<IBannerSaga>('saga/banner')

export function* bannerSaga({ payload }: BannerSaga): Generator {
  const id = payload.id || String(Math.round(Date.now() + Math.random() * 1000))
  yield put(showBanner({ id, ...payload }))
  yield delay(payload.variant === 'error' ? 10000 : 6000)
  if (!payload.disableAutoHide) {
    yield put(hideBanner({ id }))
  }
}
