import { createAction } from '@reduxjs/toolkit'
import { ResponseClient } from '@ads/front-core/api'
import { AppState } from '../../appState'

export const setClient = createAction<ResponseClient>('user/setClient')

export const setClientReducer = (
  state: AppState['client'],
  action: ReturnType<typeof setClient>,
): void => {
  state.currentClient = action.payload
}
