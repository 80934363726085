import admin from './reducers/admin'
import app from './reducers/app'
import banner from './reducers/banner'
import currency from './reducers/currency'
import dictionary from './reducers/dictionary'
import fetchCache from './reducers/fetchCache'
import reloadComponents from './reducers/reloadComponents'
import sidebar from './reducers/sidebar'
import sidebarItem from './reducers/sidebarItem'
import toast from './reducers/toast'
import user from './reducers/user'
import storage from './reducers/storage'

const rootReducer = {
  admin,
  dictionary,
  user,
  toast,
  banner,
  reloadComponents,
  sidebar,
  sidebarItem,
  fetchCache,
  app,
  currency,
  storage,
}

export default rootReducer
