import { Dispatch } from 'redux'
import { lng } from '@ads/front-core/locales'
import { toast } from '@ads/front-core/store/sagas/toastSaga'

const MESSAGE_CLIPBOARD_SUCCESS = lng('common:copyToClipboard')
const MESSAGE_CLIPBOARD_ERROR = lng('common:errorCopyToClipboard')

export const copyToClipboard = async (
  content: string,
  dispatch: Dispatch<any>,
): Promise<void> => {
  try {
    await navigator.clipboard.writeText(content)
    dispatch(
      toast({
        message: MESSAGE_CLIPBOARD_SUCCESS,
      }),
    )
  } catch (err) {
    console.error('Clipboard error', err)
    dispatch(
      toast({
        message: MESSAGE_CLIPBOARD_ERROR,
      }),
    )
  }
}
