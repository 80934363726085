import { createAction } from '@reduxjs/toolkit'
import { ResponseShop } from '@ads/front-core/api'
import { AppState } from '../../appState'

export const setShop = createAction<Required<ResponseShop>>('shop/setShop')

export const setShopReducer = (
  state: AppState['shop'],
  action: ReturnType<typeof setShop>,
): void => {
  if (!action.payload) {
    state = {}
    return
  }
  state[action.payload?.id] = action.payload
}
