import React, { ChangeEvent, forwardRef, KeyboardEvent, Ref } from 'react'
import styled, { css } from 'styled-components'

import { adjustPaddings, setTransition } from '../../utility'
import { Text } from '../Text'
import { ITextarea } from './types'

export const Textarea = forwardRef(
  (
    {
      onChange,
      onKeyDown,
      isAutoresize,
      isEnterKeyDisabled,
      isReadOnly,
      rows = 3,
      'aria-label': ariaLabel = 'Textarea',
      ...rest
    }: ITextarea,
    ref: Ref<HTMLInputElement>,
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e)

      if (!isAutoresize) return
      if (!e || !e?.currentTarget) return

      const computedStyle = window.getComputedStyle(e.currentTarget)
      e.currentTarget.style.height = 'inherit'

      const borders =
        parseInt(computedStyle.getPropertyValue('border-top-width'), 10) +
        parseInt(computedStyle.getPropertyValue('border-bottom-width'), 10)
      const height = borders + e.currentTarget.scrollHeight
      e.currentTarget.style.height = `${height}px`
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDown) onKeyDown(e)
      if (!isEnterKeyDisabled) return

      if (!e || !e?.currentTarget) return
      const { currentTarget, key, shiftKey } = e

      if (!shiftKey && key === 'Enter') {
        currentTarget.blur()
      }
    }

    return (
      <Input
        fontSize="m"
        lineHeight="m"
        ref={ref}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        readOnly={isReadOnly}
        rows={rows}
        aria-label={ariaLabel}
        {...rest}
      />
    )
  },
)

export const Input = styled(Text).attrs({ forwardedAs: 'textarea' })<ITextarea>`
  appearance: none;
  font-family: inherit;
  letter-spacing: 0.2px;
  resize: none;
  word-wrap: break-word;
  width: 100%;
  ${setTransition(['border-color', 'box-shadow'], 'productive')};
  ${adjustPaddings(['xxs', 's'])};

  ${p => css`
    border: ${p.theme.components.textareaBorder};
    border-radius: ${p.theme.radii.rounded};
    color: ${p.theme.components.textareaColor};

    &:hover {
      border-color: ${p.theme.components[
        p.isError ? 'textareaErrorHoverBorderColor' : 'textareaHoverBorderColor'
      ]};
    }

    &:focus {
      outline: none;
      box-shadow: ${p.theme.focuses[p.isError ? 'error' : 'normal']};
      border-color: transparent;
    }

    &::placeholder {
      color: ${p.theme.components[
        p.isDisabled
          ? 'textareaDisabledPlaceholderColor'
          : 'textareaPlaceholderColor'
      ]};
    }

    ${p.isError &&
    !p.isDisabled &&
    css`
      border-color: ${p.theme.components.textareaErrorBorderColor};
    `};

    ${p.isDisabled &&
    css`
      background-color: ${p.theme.components.textareaDisabledBackgroundColor};
      color: ${p.theme.components.textareaDisabledColor};
      pointer-events: none;
    `};
  `};
`
