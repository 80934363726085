import { createAction } from '@reduxjs/toolkit'
import { ResponseCampaignRunningConditions } from '@ads/front-core/api'
import { Campaign } from '@ads/front-core/interfaces'
import { getRunningConditionsKey } from 'utils/getRunningConditionsKey'
import { AppState } from '../../appState'

export type SetRunningConditions = {
  campaignId: number
  campaignStatus?: Campaign['status']
  modifyType: 'CREATE' | 'UPDATE' | 'RUN'
  runningConditions: ResponseCampaignRunningConditions
}

export const setRunningConditions = createAction<SetRunningConditions>(
  'campaign/setRunningConditions',
)

export const setRunningConditionsReducer = (
  state: AppState['campaign'],
  action: ReturnType<typeof setRunningConditions>,
): void => {
  const {
    campaignId,
    campaignStatus,
    modifyType,
    runningConditions,
  } = action.payload
  const key = getRunningConditionsKey({
    campaignId,
    campaignStatus,
    modifyType,
  })
  state.runningConditions[key] = runningConditions
}
