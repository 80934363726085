import { ResponseMaintenance } from '@ads/front-core/api'
import {
  Currency,
  DEFAULT_CURRENCY,
  GetConfigResponse,
  TDictionary,
  UserDataResponse,
} from '@ads/front-core/interfaces'
import { IBannerSaga } from './sagas/bannerSaga'

export interface RootState {
  user: UserDataResponse['user']
  admin: UserDataResponse['admin']
  toast: {
    [id in string]: {
      message: string
    }
  }
  banner: {
    [id in string]: IBannerSaga
  }
  sidebar: {
    isLock: boolean
    isOpen: boolean
  }
  sidebarItem: {
    showLabels: boolean
  }
  dictionary: {
    [key in string]: TDictionary[]
  }
  reloadComponents: { [key in string]: string }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchCache: {
    list: { [key in string]: any }
  }
  app: {
    maintenance: ResponseMaintenance
    releaseVersion: GetConfigResponse['release']
    formDraft: Record<string, string>
    formSubmittingState: Record<
      string,
      {
        isSubmitting: boolean
        withOverlay?: boolean
      }
    >
    isMobile: boolean
    isHeaderBannerDisabled: boolean
    isDemoDialogVisible: boolean
  }
  currency: {
    current: Currency
    loading: boolean
    data: Record<string, Record<Currency, number>>
  }
  storage: Record<string, unknown>
}

export const preloadedRootState: RootState = {
  user: {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    createDate: null,
    avatar_url: '',
    agreements: [],
    clients: [],
  },
  admin: {
    id: null,
    role: null,
    email: '',
    clients: [],
  },
  toast: {},
  banner: {},
  sidebar: {
    isLock: false,
    isOpen: true,
  },
  sidebarItem: {
    showLabels: false,
  },
  dictionary: {},
  reloadComponents: {},
  fetchCache: {
    list: {},
  },
  app: {
    maintenance: null,
    releaseVersion: {
      frontend: '?',
      backend: '?',
    },
    formDraft: {},
    formSubmittingState: {},
    isMobile: false,
    isHeaderBannerDisabled: false,
    isDemoDialogVisible: false,
  },
  currency: {
    current: DEFAULT_CURRENCY,
    loading: false,
    data: {},
  },
  storage: {},
}
