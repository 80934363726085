import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const clearRunningConditions = createAction(
  'campaign/clearRunningConditions',
)

export const clearRunningConditionsReducer = (
  state: AppState['campaign'],
): void => {
  state.runningConditions = {}
}
