import React, { FC, PropsWithChildren } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { rgba } from 'polished'

import { Box } from '../../Box'
import { ISpinner } from './types'

export const Spinner: FC<PropsWithChildren<ISpinner>> = ({
  variant = 'normal',
  role = 'presentation',
  ...props
}) => (
  <StyledBox
    borderRadius="fullRounded"
    variant={variant}
    role={role}
    {...props}
  />
)

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`

const StyledBox = styled(Box)<ISpinner>`
  animation: ${spinnerAnimation} 1s infinite linear;

  ${p => css`
    width: ${p.theme.components.spinnerSize}px;
    height: ${p.theme.components.spinnerSize}px;
    border: 2px solid
      ${rgba(
        p.theme.components[
          p.variant === 'normal' ? 'spinnerColor' : 'spinnerInvertedColor'
        ],
        0.2,
      )};
    border-left-color: ${p.theme.components[
      p.variant === 'normal' ? 'spinnerColor' : 'spinnerInvertedColor'
    ]};
  `};
`
