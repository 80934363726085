import styled, { css } from 'styled-components'
import { Box } from '@ads/front-ds'

export const Separator = styled(Box)<{ fitHeight?: boolean }>`
  ${p => css`
    border-left: ${p.theme.borders.thinGrey20};
    width: 1px;
    ${p.fitHeight &&
    css`
      height: 100%;
    `}
  `}
`
