import { createReducer } from '@reduxjs/toolkit'
import { preloadedState } from '../../appState'
import {
  setCampaignDraft,
  setCampaignDraftReducer,
} from './setCampaignDraftReducer'
import {
  setCreationDraft,
  setCreationDraftReducer,
} from './setCreationDraftReducer'
import {
  setNewsletterDraft,
  setNewsletterDraftReducer,
} from './setNewsletterDraftReducer'
import { setFormDraft, setFormDraftReducer } from './setFormDraftReducer'
import {
  setFormTemplatesDraft,
  setFormTemplatesDraftReducer,
} from './setFormTemplatesDraftReducer'

const draftReducers = createReducer(preloadedState.draft, builder => {
  builder.addCase(setCampaignDraft.type, setCampaignDraftReducer)
  builder.addCase(setNewsletterDraft.type, setNewsletterDraftReducer)
  builder.addCase(setCreationDraft.type, setCreationDraftReducer)
  builder.addCase(setFormDraft.type, setFormDraftReducer)
  builder.addCase(setFormTemplatesDraft.type, setFormTemplatesDraftReducer)
})

export default draftReducers
