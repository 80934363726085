import { NewsletterStatItem } from '@ads/front-core/api'
import { AudienceCampaignMetric } from '@ads/front-core/interfaces'
import { decimalSeparator, lng } from '@ads/front-core/locales'

type OrderOfMagnitude = '' | 'k' | 'mln' | 'mld'

const FACTORS: {
  [key in OrderOfMagnitude]: number
} = {
  '': 1,
  k: 10 ** 3,
  mln: 10 ** 6,
  mld: 10 ** 9,
}

export const autoOrderOfMagnitude = (value: number): OrderOfMagnitude =>
  (Object.entries(FACTORS)
    .map(([key, factor]) => ({ key, factor }))
    .sort((o1, o2) => o2.factor - o1.factor)
    .filter(order => order.factor <= Math.abs(value))[0]
    ?.key as OrderOfMagnitude) || ''

export interface FormattingOptions {
  fractionDigits?: number
  orderOfMagnitude?: OrderOfMagnitude | 'auto'
}

export const formatNumber = (
  val = 0,
  options: FormattingOptions = {},
): string => {
  const {
    fractionDigits = 2,
    orderOfMagnitude: incomingOrderOfMagnitude = '',
  } = options

  if (incomingOrderOfMagnitude === 'auto' && val < 1000) {
    return val.toFixed(0)
  }

  const orderOfMagnitude =
    incomingOrderOfMagnitude === 'auto'
      ? autoOrderOfMagnitude(val)
      : incomingOrderOfMagnitude

  try {
    const parts = (val / FACTORS[orderOfMagnitude])
      .toFixed(fractionDigits)
      .toString()
      .split('.')
    if (parts[0].length > 3) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00a0')
    }
    return (
      parts.join(decimalSeparator) +
      (orderOfMagnitude &&
        `\u00a0${
          orderOfMagnitude === 'k' ? lng('common:thousand') : orderOfMagnitude
        }`)
    )
  } catch (e) {
    return val.toString()
  }
}

const FORMATTING_DEFAULTS: { [key in string]: FormattingOptions } = {
  number: { fractionDigits: 0 },
  duration: { fractionDigits: 0 },
  currency: { fractionDigits: 2 },
  percent: { fractionDigits: 2 },
  ratio: { fractionDigits: 2 },
}

export const formatNumberByDataType = (
  metric?: NewsletterStatItem | AudienceCampaignMetric,
  options?: FormattingOptions,
): string => {
  if (!metric) {
    return ''
  }
  if (metric.name === 'ROAS') {
    return formatNumber(Number(metric.value), {
      ...FORMATTING_DEFAULTS['number'],
      ...options,
    })
  }
  return formatNumber(Number(metric.value), {
    ...FORMATTING_DEFAULTS[metric.dataType],
    ...options,
  })
}
