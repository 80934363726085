import { useRect } from '@reach/rect'
import React, { FC, useRef } from 'react'
import styled from 'styled-components'

import { zIndex } from '../../../theme'
import { Portal } from '../../Portal'
import { Datepicker } from './Datepicker'
import { IDatepicker } from './types'
import { useDatepickerDropdownEdgeCasePosition } from './useDatepickerDropdownEdgeCasePosition'
import { useClickOutside, useKeyDown } from '../../../hooks'

interface PortalDatepickerProps extends IDatepicker {
  containerRect: DOMRect | null | undefined
  closeAction: () => void
}
const CLOSE_ACTION_KEYS = ['Escape']

const PortalDatepicker: FC<React.PropsWithChildren<PortalDatepickerProps>> = ({
  containerRect,
  closeAction,
  ...rest
}) => {
  const datepickerRef = useRef(null)
  const datepickerRect = useRect(datepickerRef)

  const datepickerDropdownPosition = useDatepickerDropdownEdgeCasePosition({
    containerRect: containerRect,
    dropdownRect: datepickerRect,
  })

  useKeyDown({
    keys: CLOSE_ACTION_KEYS,
    handler: closeAction,
  })

  useClickOutside({
    refs: datepickerRef,
    handler: closeAction,
  })

  return (
    <Portal>
      <StyledPortalDatepicker
        ref={datepickerRef}
        styleCss={{
          top: `${datepickerDropdownPosition.top}px`,
          left: `${datepickerDropdownPosition.left}px`,
          zIndex: zIndex.dropdown,
        }}
        {...rest}
      />
    </Portal>
  )
}

export { PortalDatepicker }

const StyledPortalDatepicker = styled(Datepicker)`
  position: fixed;
  z-index: ${p => p.theme.components.datepickerZIndex};
`
