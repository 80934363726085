import React from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { ColorPreviewProps } from './types'

import { normalizeColor, defaultRGBaColorString } from '../../utils'

export const ColorPreview = ({
  onClick,
  backgroundColor = defaultRGBaColorString,
  ...rest
}: ColorPreviewProps) => {
  const normalizedColor = normalizeColor(backgroundColor)

  return (
    <StyledButton
      onClick={onClick}
      {...rest}
      styleCss={{ cursor: onClick ? 'pointer' : 'default' }}
      {...(onClick && {
        role: 'button',
        tabIndex: 0,
        'aria-label': 'Color preview',
      })}
    >
      <StyledColor styleCss={{ backgroundColor: normalizedColor }} />
    </StyledButton>
  )
}

const StyledButton = styled(Box)<ColorPreviewProps>`
  ${p => css`
    width: 20px;
    height: 20px;
    padding: 0;
    position: relative;
    display: inline-flex;
    border-radius: ${p.theme.radii.rounded};
    overflow: hidden;
    z-index: 1;
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
      linear-gradient(-45deg, #ccc 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #ccc 75%),
      linear-gradient(-45deg, transparent 75%, #ccc 75%);
    background-size: 10px 10px;
    background-position: 0 0, 10px 0, 10px -10px, 0px 10px;
    &:focus {
      box-shadow: ${p.theme.focuses.normal};
    }
  `}
`

const StyledColor = styled(Box)`
  z-index: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
