import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { Flex } from '../Flex'
import { Icon } from '../Icon'
import { IText, Text } from '../Text'
import { ICaption, IFormGroup, ILabel } from './types'

interface ISwitchCaption
  extends Pick<IFormGroup, 'caption' | 'error' | 'warning' | 'isDisabled'> {}

const switchCaption = ({
  error,
  isDisabled,
  caption,
  warning,
}: ISwitchCaption) => {
  switch (true) {
    case !!error && !isDisabled:
      return (
        <>
          <Icon name="16-alert-solid" mr="xxxs" />
          <Text fontSize="s" lineHeight="s">
            {error}
          </Text>
        </>
      )
    case !!warning && !isDisabled:
      return (
        <>
          <Icon name="16-warning-solid" mr="xxxs" />
          <Text fontSize="s" lineHeight="s">
            {warning}
          </Text>
        </>
      )
    case !!caption:
      return (
        <Text fontSize="s" lineHeight="s">
          {caption}
        </Text>
      )
    default:
      return false
  }
}

export const FormGroup = forwardRef<HTMLDivElement, IFormGroup>(
  (
    {
      children,
      isDisabled,
      forwardedAs,
      label,
      htmlFor,
      caption,
      error,
      warning,
      ...rest
    },
    ref,
  ) => {
    const captionContent = switchCaption({
      error,
      isDisabled,
      caption,
      warning,
    })

    return (
      <Container
        flexDirection="column"
        forwardedAs={forwardedAs}
        htmlFor={forwardedAs === 'label' ? htmlFor : undefined}
        ref={ref}
        {...rest}
      >
        {label !== undefined && (
          <Label
            forwardedAs={
              (forwardedAs !== 'label' && 'label') as IText['forwardedAs']
            }
            fontWeight="medium"
            htmlFor={forwardedAs !== 'label' ? htmlFor : undefined}
            isDisabled={isDisabled}
            mb="xxs"
          >
            {label}
          </Label>
        )}
        {children}

        {captionContent && (
          <Caption
            alignItems="flex-start"
            error={error}
            warning={warning}
            mt={children ? 'xxs' : undefined}
          >
            {captionContent}
          </Caption>
        )}
      </Container>
    )
  },
)

const Container = styled(Flex)<ILabel>``

const Label = styled(Text)<ILabel>`
  min-height: 20px;
  color: ${p =>
    p.theme.components[
      p.isDisabled ? 'formGroupDisabledLabelColor' : 'formGroupLabelColor'
    ]};
`

const Caption = styled(Flex)<ICaption>`
  ${p => css`
    color: ${p.theme.components[
      p.isDisabled ? 'formGroupDisabledCaptionColor' : 'formGroupCaptionColor'
    ]};

    ${p.error &&
    css`
      color: ${p.theme.components.formGroupErrorCaptionColor};
    `};

    ${p.warning &&
    css`
      color: ${p.theme.components.formGroupWarningCaptionColor};
    `};
  `};
`
