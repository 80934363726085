import React, { FC } from 'react'
import { Trans as TransI18n } from 'react-i18next'
import { TTranslationsKeys } from '@ads/front-core/locales'

interface ITrans {
  i18nKey: TTranslationsKeys
  components?:
    | readonly React.ReactElement[]
    | { readonly [tagName: string]: React.ReactElement }
  values?: Record<string, string | number>
}

export const Trans: FC<ITrans> = ({ i18nKey, components, values }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <TransI18n i18nKey={i18nKey} components={components} values={values} />
)
