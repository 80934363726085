import { AnimatePresence, motion } from 'framer-motion'
import { decode } from 'html-entities'
import omit from 'lodash/omit'
import React, { FC, useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { Portal } from '../Portal'
import { Text } from '../Text'

import { space } from '../../theme'
import { ICaption, IHolder, ITooltip } from './types'

import { useTooltip } from './use-tooltip'

//move to utils

const htmlPhrase = (phrase: string) => {
  // const entities = new XmlEntities();
  const html = decode(phrase)
  return html
}

export const Tooltip: FC<React.PropsWithChildren<ITooltip>> = React.memo(
  ({
    children,
    text,
    animatable,
    'aria-label': ariaLabel,
    caption,
    light = false,
    offset,
    defaultIsOpen,
    placement,
    theme,
    styleCss,
    zIndex,
    maxWidth,
    wordBreak,
    hide = false,
    link,
    onMouseEnter,
    onMouseLeave,
    'data-testid': dataTestId,
    'data-tooltip-testid': dataTooltipTestId,
    isOpen,
    ...rest
  }) => {
    const tooltipRef = useRef<HTMLDivElement>(null)

    const [textWrap, setWrap] = useState(false)

    const tooltipDefaultProps = {
      isOpen,
      defaultIsOpen,
      placement,
      theme,
      styleCss,
      textWrap,
      zIndex,
    }

    const tooltip = useTooltip({
      ...tooltipDefaultProps,
      direction: 'ltr',
      offset: offset ? [space[offset], space[offset]] : [0, 8],
    })

    const trigger = (
      <Box data-testid={dataTestId} {...tooltip.getTriggerProps()}>
        {children}
      </Box>
    )

    const hasAriaLabel = !!ariaLabel

    const _tooltipProps = tooltip.getTooltipProps({}, tooltipRef)

    const tooltipProps = hasAriaLabel
      ? omit(_tooltipProps, ['role', 'id'])
      : _tooltipProps

    useLayoutEffect(() => {
      setWrap(
        tooltip.isOpen &&
          !!tooltipRef.current &&
          tooltipRef.current.offsetWidth >= 264,
      )
    }, [tooltip.isOpen])

    const TooltipWrapper = () => {
      return (
        <StyledTooltip
          pt="xxs"
          pr="xxxs"
          pb="xxxs"
          pl="xxs"
          borderRadius="rounded"
          textWrap={textWrap}
          wordBreak={wordBreak}
          maxWidth={maxWidth}
          light={light}
          data-testid={dataTooltipTestId}
          {...(tooltipProps as any)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {text && (
            <Text fontSize="s" lineHeight="s" as="span" mr="xxxs" mb="xxxs">
              <div dangerouslySetInnerHTML={{ __html: htmlPhrase(text) }} />
            </Text>
          )}
          {caption && (
            <Caption
              fontSize="s"
              lineHeight="s"
              mr="xxxs"
              mb="xxxs"
              light={light}
            >
              {caption}
            </Caption>
          )}
          {link && (
            <Text fontSize="s" lineHeight="s" as="span" mr="xxxs" mb="xxxs">
              <StyledLink
                onMouseDown={() => window.open(`https://${link}`, '_blank')}
              >
                {link}
              </StyledLink>
            </Text>
          )}
          <Box data-popper-arrow>
            <Box data-popper-arrow-inner />
          </Box>
        </StyledTooltip>
      )
    }

    return (
      <Wrapper {...rest}>
        <>
          {trigger}
          <AnimatePresence>
            {tooltip.isOpen && !hide && (
              <Portal>
                <StyledBox
                  {...tooltip.getTooltipPositionerProps()}
                  ref={
                    tooltip.getTooltipPositionerProps()
                      ?.ref as React.RefObject<any>
                  }
                >
                  {animatable ? (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <TooltipWrapper />
                    </motion.div>
                  ) : (
                    <TooltipWrapper />
                  )}
                </StyledBox>
              </Portal>
            )}
          </AnimatePresence>
        </>
      </Wrapper>
    )
  },
)

const Wrapper = styled(Box)`
  position: relative;
  display: inline-block;
`

const StyledBox = styled(Box)`
  z-index: 1000;
`

const StyledTooltip = styled(Box)<IHolder>`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;

  ${p => css`
    white-space: ${!p.textWrap && 'nowrap'};
    width: ${p.textWrap && !p.maxWidth ? '264px' : 'auto'};
    ${p.maxWidth &&
    css`
      min-width: ${p.maxWidth}px;
    `}
    flex-direction: ${p.textWrap ? 'column' : 'row'};
    flex-wrap: ${p.textWrap ? 'wrap' : 'nowrap'};
    z-index: ${p.zIndex};

    background-color: ${p.light
      ? p.theme.components.tooltipLightBackgroundColor
      : p.theme.components.tooltipBackgroundColor};
    --popper-arrow-bg: ${p.light
      ? p.theme.components.tooltipLightBackgroundColor
      : p.theme.components.tooltipBackgroundColor};
    color: ${p.light
      ? p.theme.components.tooltipLightColor
      : p.theme.components.tooltipColor};

    ${p.textWrap &&
    p.wordBreak &&
    css`
      word-break: break-all;
      min-width: ${p.maxWidth || p.theme.components.tooltipMaxWidth}px;
    `}
  `};
`

const Caption = styled(Text).attrs({ forwardedAs: 'span' })<ICaption>`
  color: ${p =>
    p.light
      ? p.theme.components.tooltipLightCaptionColor
      : p.theme.components.tooltipCaptionColor};
`

const StyledLink = styled.span`
  cursor: pointer;
`
