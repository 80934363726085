export default {
  TOOLTIP: 3,
  SUCCESS_OVERLAY: 101,
  SIDEBAR: 10,
  SIDEPANEL: 90,
  HEADER: 100,
  SIDEBAR_LARGE: 105,
  MODAL_CONTENT: 110,
  MODAL_ACCEPT_REGULATIONS: 112,
  BANNER: 200,
  DIALOG: 500,
  DROPDOWN_MENU: 501,
  BLOCKING_SCREEN: 502,
  TOAST: 1000,
  DEMO: 2000,
}
