import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { delay, put, select, takeEvery } from 'redux-saga/effects'
import { SidebarItemProps } from '@ads/front-core/components/Sidebar/SidebarItem'
import transition from '@ads/front-core/constants/transition'
import { toggleLabels } from '../reducers/sidebarItem/toggleLabelsReducer'
import { RootState } from '../rootState'
import { getSidebar } from '../selectors/getSidebar'
import { getSidebarItem } from '../selectors/getSidebarItem'

export type SidebarItemSaga = ReturnType<
  PayloadActionCreator<SidebarItemProps, string>
>

export const sidebarItem = createAction('saga/sidebarItem')

export function* sagaSidebarItem(): Generator {
  yield takeEvery(sidebarItem.type, toggleLabelsSaga)
}

export function* toggleLabelsSaga(): Generator {
  const { isOpen } = (yield select(getSidebar)) as RootState['sidebar']
  const { showLabels } = (yield select(
    getSidebarItem,
  )) as RootState['sidebarItem']

  if (showLabels) {
    yield delay(transition.SIDEBARITEM)
    yield put(toggleLabels({ showLabels: false }))
  } else if (isOpen) {
    yield put(toggleLabels({ showLabels: true }))
  }
}
