import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { Campaign } from '@ads/front-core/interfaces'
import { AppState } from '../../appState'

export const setCampaignDraft = createAction<Campaign>('draft/setCampaignDraft')

export const setCampaignDraftReducer = (
  state: AppState['draft'],
  action: ReturnType<typeof setCampaignDraft>,
): void => {
  state.campaign = cloneDeep(action.payload)
}
