import React, { forwardRef, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box, IBox } from '../Box'
import { TextButton } from '../Buttons'

import { IMenu, IMenuContent } from './types'

export const Menu = forwardRef<HTMLDivElement, IMenu>((props, ref) => {
  const {
    children,
    maxHeight,
    minWidth = 240,
    cta,
    ctaIcon,
    onCtaClick,
    ...rest
  } = props

  if (ctaIcon && !cta)
    throw new Error(
      'Property "cta" is missing. You shouldn\'t set only "ctaIcon".',
    )
  if (cta && !onCtaClick) throw new Error('Property "onCtaClick" is missing.')

  const content = useRef<HTMLDivElement>(null)
  const [hasShadow, setHasShadow] = useState(false)

  const handleOnScroll = () => {
    if (content.current) {
      const { scrollHeight, clientHeight, scrollTop } = content.current
      setHasShadow(scrollHeight - clientHeight !== scrollTop)
    }
  }

  return (
    <Container
      ref={ref}
      forwardedAs={'div' as IBox['forwardedAs']}
      borderRadius="rounded"
      boxShadow="l"
      tabIndex={0}
      minWidth={minWidth}
      {...rest}
    >
      <Content
        ref={content}
        p="xxs"
        maxHeight={maxHeight}
        onScroll={handleOnScroll}
      >
        {children}
      </Content>
      {cta && (
        <Footer p="xxs" boxShadow={maxHeight && hasShadow ? 'l' : undefined}>
          <Cta iconBefore={ctaIcon} onClick={onCtaClick}>
            {cta}
          </Cta>
        </Footer>
      )}
    </Container>
  )
})

const Container = styled(Box)<IMenu>`
  display: flex;
  flex-direction: column;

  &:focus {
    outline: none;
  }

  ${p => css`
    background-color: ${p.theme.components.menuBackgroundColor};
    min-width: ${p.minWidth}px;

    ${p.maxWidth &&
    css`
      max-width: ${p.maxWidth}px;
    `};
  `};
`

const Content = styled(Box)<IMenuContent>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ${p => css`
    max-height: ${p.maxHeight && `${p.maxHeight}px`};
  `};
`

const Footer = styled(Box)`
  ${p => css`
    transition: box-shadow ${p.theme.motion.productive};
    border-top: 1px solid ${p.theme.components.menuDividerColor};
  `};
`

const Cta = styled(TextButton)`
  width: 100%;
  justify-content: flex-start;

  &:focus {
    box-shadow: none;
  }

  ${p => css`
    padding: ${p.theme.space.xs / 2}px ${p.theme.space.xxs - 1}px;

    &:hover {
      background-color: ${p.theme.components.menuChildHoverBackgroundColor};
    }
  `};
`
