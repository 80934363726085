import React, { FC } from 'react'
import styled from 'styled-components'

import { Box } from '../../Box'
import { IMenuDivider } from './types'

export const MenuDivider: FC<React.PropsWithChildren<IMenuDivider>> = props => (
  <Line backgroundColor="grey20" {...props} />
)

const Line = styled(Box)`
  height: 1px;
  min-height: 1px;
  width: calc(100% + ${p => 2 * p.theme.space.xxs}px);
  margin: ${p => p.theme.space.xxs}px -${p => p.theme.space.xxs}px;
  background-color: ${p => p.theme.components.menuDividerColor};
`
