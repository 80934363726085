import { aliases } from './aliases'
import { borders } from './borders'
import { colors } from './colors'
import { components } from './components'
import { focuses } from './focuses'
import { fontSizes } from './fontSizes'
import { fontWeights } from './fontWeights'
import { lineHeights } from './lineHeights'
import { motion } from './motion'
import { radii } from './radii'
import { shadows } from './shadows'
import { space } from './space'
import { zIndex } from './zIndex'
import { Theme } from './interfaces'

export type { Theme } from './interfaces'
export { aliases } from './aliases'
export { borders } from './borders'
export { colors } from './colors'
export { components } from './components'
export { focuses } from './focuses'
export { fontSizes } from './fontSizes'
export { fontWeights } from './fontWeights'
export { lineHeights } from './lineHeights'
export { motion } from './motion'
export { radii } from './radii'
export { shadows } from './shadows'
export { space } from './space'
export { zIndex } from './zIndex'

export const theme: Theme = {
  aliases,
  borders,
  colors,
  components,
  focuses,
  fontSizes,
  fontWeights,
  lineHeights,
  motion,
  radii,
  space,
  shadows,
  zIndex,
}
