import { borders } from './borders'
import { colors } from './colors'
import { Aliases } from './interfaces'

export const aliases: Aliases = {
  /**
   * Default
   */
  defaultContent: colors.grey100,
  defaultContentSecondary: colors.grey50,
  defaultContentInverted: colors.inverted100,
  defaultContentSecondaryInverted: colors.inverted50,
  defaultBackgroundColor: colors.inverted100,
  defaultBackgroundColorSublte: colors.inverted05,
  defaultBackgroundColorInverted: colors.grey100,
  defaultBackgroundColorLight: colors.grey20,
  defaultBackgroundColorInvertedSubtle: colors.grey05,
  defaultBackgroundColorTransparent: colors.greyTransparent,
  defaultBorder: borders.thinGrey20,
  defaultBorderColor: colors.grey20,
  defaultActiveOpacity: 0.7,
  /**
   * Disabled
   */
  disabledBorder: borders.thinGrey20,
  disabledBorderColor: colors.grey20,
  disabledBackgroundColor: colors.grey05,
  disabledBackgroundColorStrong: colors.greyDisabled,
  disabledBackgroundColorInverted: colors.inverted20,
  disabledContent: colors.greyDisabled,
  disabledContentStrong: colors.inverted50,
  disabledContentInverted: colors.invertedDisabled,
  disabledOpacity: 0.5,
  /**f
   * Cta
   */
  ctaContent: colors.primary50,
  ctaContentHover: colors.primary100,
  ctaContentInverted: colors.inverted100,
  ctaBorder: borders.thinPrimary20,
  ctaBorderColor: colors.primary20,
  ctaBorderColorHover: colors.primary20,
  ctaBackgroundColor: colors.primary50,
  ctaBackgroundColorSubtle: colors.primary05,
  ctaBackgroundColorInverted: colors.inverted100,
  ctaBackgroundColorHover: colors.primary100,
  /**
   * Secondary
   */
  secondaryContent: colors.grey100,
  secondaryContentHover: colors.grey50,
  secondaryBorder: borders.thinGrey20,
  secondaryBackgroundColor: colors.inverted100,
  secondarySubtleContent: colors.grey50,
  secondarySubtleContentHover: colors.grey100,
  secondarySubtleContentSelected: colors.primary50,
  secondaryInvertedContent: colors.inverted50,
  secondaryInvertedContentHover: colors.inverted100,
  secondaryInvertedContentSelected: colors.inverted100,
  /**
   * Ghost
   */

  ghostContent: colors.grey100,
  ghostContentHover: colors.grey50,
  ghostContentSelected: colors.primary50,
  ghostBackgroundColorHover: colors.grey05,
  ghostBackgroundColorSelected: colors.primary05,
  /**
   * Ghost Inverted
   */
  ghostInvertedContent: colors.inverted100,
  ghostInvertedContentHover: colors.inverted50,
  ghostInvertedContentSelected: colors.inverted100,
  ghostInvertedBackgroundColorHover: colors.inverted50,
  ghostInvertedBackgroundColorSelected: colors.inverted20,
  /**
   * Destructive
   */
  destructiveContent: colors.alert50,
  destructiveContentHover: colors.alert100,
  destructiveContentActive: colors.alert20,
  destructiveContentInverted: colors.inverted100,
  destructiveBorderColor: colors.alert50,
  destructiveBorderColorHover: colors.alert100,
  destructiveBackgroundColorHover: colors.alert100,
  /**
   * DestructivePrimary
   */
  destructivePrimaryBorder: borders.thinAlert20,
  destructivePrimaryDisabledBorder: borders.thinGrey20,
  /**
   * Error
   */
  errorColor: colors.alert50,
  errorBorderColor: colors.alert50,
  errorBorderColorHover: colors.alert100,
  errorContent: colors.alert50,
  errorBackgroundColor: colors.alert50,
  errorBackgroundColorSubtle: colors.alert05,
  /**
   * Warning
   */
  warningColor: colors.warning50,
  warningBorderColor: colors.warning50,
  warningBorderColorHover: colors.warning100,
  warningContent: colors.warning50,
  warningBackgroundColor: colors.warning50,
  warningBackgroundColorSubtle: colors.warning05,
  warningIconColor: colors.warning50,
  /**
   * Success
   */
  successColor: colors.success50,
  successBorderColor: colors.success50,
  successContent: colors.success50,
  successBackgroundColor: colors.success50,
  successBackgroundColorSubtle: colors.success05,
  /**
   * Weak Suggestion
   */
  weakSuggestionColor: colors.weakSuggestion50,
  weakSuggestionBorderColor: colors.weakSuggestion50,
  weakSuggestionContent: colors.weakSuggestion50,
  weakSuggestionBackgroundColor: colors.weakSuggestion50,
  weakSuggestionBackgroundColorSubtle: colors.weakSuggestion05,
  /**
   * Form
   */
  formBorder: borders.thinGrey20,
  formBorderColorHover: colors.primary20,
}
