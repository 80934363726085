import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setClientBalance = createAction<AppState['client']['balance']>(
  'user/setClientBalance',
)

export const setClientBalanceReducer = (
  state: AppState['client'],
  action: ReturnType<typeof setClientBalance>,
): void => {
  state.balance = action.payload
}
