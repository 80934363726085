import React from 'react'
import styled, { css, DefaultTheme, keyframes } from 'styled-components'
import { theme } from '../../../theme'
import { isFunction, MaybeFunction, runIfFn } from '../../../utils'
import { Button, IconButton } from '../../Buttons'
import { Flex } from '../../Flex'
import { Icon } from '../../Icon'
import { defaultIcons } from '../../Icon/icons'
import { Spinner } from '../../Loaders'
import { Text } from '../../Text'
import { Tooltip } from '../../Tooltip'
import { getToastPlacement } from './toast.placement'
import { toastStore } from './toast.store'
import type { RenderProps, ToastContainerProps, ToastId } from './toast.types'
import type { ToastAction, UseToastOptions } from './useToast'

const getBeforeElement = (
  b: ToastProps['before'],
  theme: DefaultTheme | undefined,
) => {
  switch (b) {
    case 'loading':
      return <Spinner variant="inverted" theme={theme} />
    case 'error':
      return <ErrorIcon name="20-fail" theme={theme} />
    default: {
      const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/
      const isEmoji = b ? emojiRegex.test(b) : false

      if (!isEmoji)
        throw new Error('String should be an emoji! (eg. https://getemoji.com/')

      return <Text color="inverted100">{b}</Text>
    }
  }
}

export const getButton = (action: ToastAction, index?: number) => {
  const key = `${action.label}${index}`

  const tooltip = action.tooltip

  const actionItem = Object.keys(defaultIcons).includes(
    action.label as string,
  ) ? (
    <IconButton
      theme={theme as DefaultTheme}
      variant="inverted"
      name={action.label}
      aria-label="toast-button"
      onClick={action.onClick}
      ml={!tooltip ? 'xxs' : undefined}
      key={key}
    />
  ) : (
    <Button
      theme={theme as DefaultTheme}
      variant="ghostInverted"
      ml={!tooltip ? 'xxs' : undefined}
      onClick={action.onClick}
      key={key}
    >
      {action.label}
    </Button>
  )

  return tooltip ? (
    <Tooltip {...tooltip} key={key} offset="xxs" theme={theme as DefaultTheme}>
      {actionItem}
    </Tooltip>
  ) : (
    actionItem
  )
}

const getButtons = (action: ToastAction | ToastAction[]) => {
  if (Array.isArray(action)) {
    return action.map((i, k) => getButton(i, k))
  }

  return getButton(action)
}

export interface ToastProps extends UseToastOptions {
  onClose?: () => void
}

export const Toast: React.FC<ToastProps> = props => {
  const { id, action, label, before, isClosable, onClose, ...rest } = props

  return (
    <Container
      isInline
      theme={theme as DefaultTheme}
      borderRadius="rounded"
      alignItems="center"
      pl="s"
      py={action || isClosable ? 'xxs' : 's'}
      pr={action || isClosable ? 'xxs' : 's'}
      mx="xxs"
      my="xxxs"
      id={`ds-toast-${id}`}
      {...rest}
    >
      {before && getBeforeElement(before, theme as DefaultTheme)}
      <Text
        ml={before && 'xxs'}
        mr={action && 'xs'}
        theme={theme as DefaultTheme}
        color="inverted50"
        role="alert"
      >
        {label}
      </Text>
      {action && getButtons(action)}
      {isClosable &&
        onClose &&
        getButton({ label: '20-close', onClick: () => onClose() })}
    </Container>
  )
}

export function createRenderToast(
  options: UseToastOptions & {
    toastComponent?: React.FC<ToastProps>
  } = {},
) {
  const { render, toastComponent: ToastComponent = Toast } = options
  const renderToast: React.FC<RenderProps> = props => {
    if (isFunction(render)) {
      return render(props) as JSX.Element
    }
    return <ToastComponent {...props} {...options} />
  }
  return renderToast
}

type UseToastPromiseOption = UseToastOptions

export function createToastFn(defaultOptions?: UseToastOptions) {
  const normalizeToastOptions = (options?: UseToastOptions) => ({
    ...defaultOptions,
    ...options,
    position: getToastPlacement(
      options?.position ?? defaultOptions?.position,
      'ltr',
    ),
  })

  const toast = (options?: UseToastOptions) => {
    const normalizedToastOptions = normalizeToastOptions(options)
    const Message = createRenderToast(normalizedToastOptions)
    return toastStore.notify(Message, normalizedToastOptions)
  }

  toast.update = (id: ToastId, options: Omit<UseToastOptions, 'id'>) => {
    toastStore.update(id, normalizeToastOptions(options))
  }

  toast.promise = <Result, Err extends Error = Error>(
    promise: Promise<Result>,
    options: {
      success: MaybeFunction<UseToastPromiseOption, [Result]>
      error: MaybeFunction<UseToastPromiseOption, [Err]>
      loading: UseToastPromiseOption
    },
  ) => {
    const id = toast({
      ...options.loading,
      duration: null,
    })

    promise
      .then(data =>
        toast.update(id, {
          duration: 5_000,
          ...runIfFn(options.success, data),
        }),
      )
      .catch(error =>
        toast.update(id, {
          duration: 5_000,
          ...runIfFn(options.error, error),
        }),
      )
  }

  toast.closeAll = toastStore.closeAll
  toast.close = toastStore.close
  toast.isActive = toastStore.isActive

  return toast
}

export type CreateToastFnReturn = ReturnType<typeof createToastFn>

const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const Container = styled(Flex)<ToastContainerProps>`
  ${({ theme: { components } }) => css`
    background-color: ${components.toastBackgroundColor};
    color: ${components.toastColor};
    pointer-events: auto;
  `};

  ${p =>
    p.shake &&
    css`
      animation: ${shakeAnimation} 1s cubic-bezier(0.36, 0.07, 0.19, 0.97)
        infinite;
    `};
`

const ErrorIcon = styled(Icon)`
  color: ${({ theme: { components } }) => components.toastErrorIconColor};
`
