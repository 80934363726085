import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import {
  toggleLabels,
  toggleLabelsReducer,
} from '../sidebarItem/toggleLabelsReducer'

const sidebarItemReducers = createReducer(
  preloadedRootState.sidebarItem,
  builder => {
    builder.addCase(toggleLabels.type, toggleLabelsReducer)
  },
)

export default sidebarItemReducers
