import { format as dateFormat } from 'date-fns'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Flex } from '../../Flex'
import { Text } from '../../Text'

import { ICell, ICellCircle, ICellContainer } from './types'
import pl from 'date-fns/locale/pl'

const switchFormat = (format: ICell['format']) => {
  switch (format) {
    case 'years':
      return 'yyyy'
    case 'months':
      return 'MMM'
    case 'days':
      return 'd'
  }
}

export const Cell: FC<React.PropsWithChildren<ICell>> = ({
  date,
  isSameMonth,
  isDisabled,
  isToday,
  selected,
  ranged,
  isHidden,
  format = 'days',
  onClick,
  ...rest
}) => {
  return (
    <Container
      ranged={ranged}
      selected={selected}
      backgroundColor={ranged ? 'primary05' : 'inverted100'}
      isHidden={isHidden}
      isSameMonth={isSameMonth}
      {...rest}
    >
      <Circle
        isToday={isToday}
        isSameMonth={isSameMonth}
        ranged={ranged}
        isDisabled={isDisabled}
        selected={selected}
        justifyContent="center"
        alignItems="center"
        onClick={!isDisabled ? onClick : undefined}
      >
        <Text
          textAlign="center"
          fontWeight={isToday ? 'medium' : 'regular'}
          textTransform="capitalize"
        >
          {dateFormat(date, switchFormat(format), {
            locale: pl,
          })}
        </Text>
      </Circle>
    </Container>
  )
}

const Container = styled(Flex)<ICellContainer>`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.components.datepickerCellHeight};
  overflow: hidden;

  ${({ isHidden, isSameMonth }) =>
    isHidden &&
    !isSameMonth &&
    css`
      pointer-events: none;
      opacity: 0;
    `};

  ${p =>
    (p.selected === 'start' || p.ranged === 'start') &&
    css`
      border-radius: ${p.theme.components.datepickerCellBorderRadius}px 0 0
        ${p.theme.components.datepickerCellBorderRadius}px;
    `};

  ${p =>
    (p.selected === 'end' || p.ranged === 'end') &&
    css`
      border-radius: 0 ${p.theme.components.datepickerCellBorderRadius}px
        ${p.theme.components.datepickerCellBorderRadius}px 0;
    `};
`

const Circle = styled(Flex)<ICellCircle>`
  position: relative;
  width: 100%;
  cursor: pointer;

  ${p => css`
    height: ${p.theme.components.datepickerCellHeight}px;
    border-radius: ${p.theme.components.datepickerCellBorderRadius}px;
  `};

  ${({ theme, selected, isDisabled }) =>
    !selected &&
    !isDisabled &&
    css`
      color: ${theme.components.datepickerCellCircleColor};

      &:hover {
        background-color: ${theme.components
          .datepickerCellCircleHoverBackgroundColor};
      }
    `};

  ${({ theme, isSameMonth }) =>
    !isSameMonth &&
    css`
      color: ${theme.components.datepickerCellCircleGreyColor};
    `};

  ${({ isToday, selected, theme }) =>
    isToday &&
    !selected &&
    css`
      border: ${theme.components.datepickerCellCircleTodayBorder};
    `};

  ${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      color: ${theme.components.datepickerCellCircleyDisabledColor};
      cursor: not-allowed;
    `};

  ${({ theme, isToday, isDisabled }) =>
    isToday &&
    isDisabled &&
    css`
      background-color: ${theme.components
        .datepickerCellCircleTodaySelectedDisabledBackgroundColor};
      border: ${theme.components
        .datepickerCellCircleTodaySelectedDisabledBorder};
    `};

  ${({ theme, selected }) =>
    selected &&
    css`
      background-color: ${theme.components
        .datepickerCellCircleTodaySelectedBackgroundColor};
      color: ${theme.components.datepickerCellCircleTodaySelectedColor};
    `};
`
