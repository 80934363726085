import React from 'react'
import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import IconEn from '@ads/front-core/Icons/IconEn'
import IconPl from '@ads/front-core/Icons/IconPl'
import en from './en/translation.json'
import ITranslations from './interface'
import pl from './pl/translation.json'

export const resources = {
  en,
  pl,
}

export type TSupportedLanguages = keyof typeof resources
export type TTranslationsKeys = keyof ITranslations

export type TLanguages = {
  lang: TSupportedLanguages
  nameToDisplay: string
  nameToDisplayUCFirst: string
  icon?: React.ReactElement
}

export const languages: TLanguages[] = [
  {
    lang: 'pl',
    nameToDisplay: 'polski',
    nameToDisplayUCFirst: 'Polski',
    icon: <IconPl />,
  },
  {
    lang: 'en',
    nameToDisplay: 'english',
    nameToDisplayUCFirst: 'English',
    icon: <IconEn />,
  },
]

export const i18n = i18next

const DEFAULT_LANGUAGE: TSupportedLanguages = 'pl'

if (typeof window !== 'undefined') {
  i18n.use(LanguageDetector)
}

i18n.use(initReactI18next).init({
  supportedLngs: Object.keys(resources),
  fallbackLng: DEFAULT_LANGUAGE,
  detection: {
    caches: ['cookie'],
  },
  resources,
})

export const currentLanguage = (i18n.language ||
  DEFAULT_LANGUAGE) as TSupportedLanguages

export const decimalSeparator = currentLanguage === 'pl' ? ',' : '.'

export default i18n
