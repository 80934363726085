import * as React from 'react'

type IdContextValue = {
  prefix: number
  current: number
}

const defaultIdContext: IdContextValue = {
  prefix: Math.round(Math.random() * 10000000000),
  current: 0,
}

const IdContext = React.createContext<IdContextValue>(defaultIdContext)

export function useId(idProp?: string, prefix?: string): string {
  const context = React.useContext(IdContext)
  return React.useMemo(
    () =>
      idProp ||
      [prefix, context.prefix, ++context.current].filter(Boolean).join('-'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idProp, prefix],
  )
}
