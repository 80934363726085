import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { format } from 'date-fns'

import { checkHover, checkScope, getCells, getHeaderLabel } from './utils'

import { Box } from '../../Box'
import { IconButton } from '../../Buttons'
import { Flex } from '../../Flex'
import { Grid } from '../../Grid'
import { Text } from '../../Text'
import { Cell } from './Cell'

import { checkIntent } from '../../../utility'
import { ICalendar, ICalendarArea, IHeaderLabel } from './types'

export const Calendar: FC<React.PropsWithChildren<ICalendar>> = ({
  picked,
  currentDate,
  hovered,
  intent,
  display,
  disabledDates,
  disabledFrom,
  disabledTo,
  view,
  onHeaderClick,
  onCellClick,
  onCellHover,
  onPreviousClick,
  onNextClick,
  onMouseLeave,
  ...rest
}) => (
  <Container {...rest}>
    <Header columns={3} mb="s">
      <Flex alignItems="center">
        {onPreviousClick && (
          <IconButton
            aria-label="Previous button"
            variant="bareDark"
            name="20-arrowhead-left"
            onClick={onPreviousClick}
          />
        )}
      </Flex>
      <Flex justifyContent="center">
        <HeaderLabel onClick={onHeaderClick}>
          {getHeaderLabel(currentDate, display)}
        </HeaderLabel>
      </Flex>
      <Flex alignItems="center">
        {onNextClick && (
          <IconButton
            aria-label="Next button"
            variant="bareDark"
            name="20-arrowhead-right"
            onClick={onNextClick}
          />
        )}
      </Flex>
    </Header>
    {display === 'days' && (
      <Grid columns={7}>
        {['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'].map(i => (
          <DayName textAlign="center" color="greyDisabled" key={i}>
            {i}
          </DayName>
        ))}
      </Grid>
    )}
    <Cells columns={display === 'days' ? 7 : 4} onMouseLeave={onMouseLeave}>
      {getCells(currentDate, display, intent).map(i => (
        <Cell
          {...i}
          format={display}
          ranged={hovered ? checkHover(i.date, hovered, display) : false}
          {...checkScope(
            i.date,
            display,
            disabledDates,
            disabledFrom,
            disabledTo,
            picked,
            view,
          )}
          key={format(i.date, 'T')}
          isHidden={checkIntent('dual', intent)}
          onClick={() => onCellClick && onCellClick(i.date)}
          onMouseEnter={() => onCellHover && onCellHover(i.date)}
          onMouseLeave={checkIntent('dual', intent) ? onMouseLeave : undefined}
        />
      ))}
    </Cells>
  </Container>
)

const Container = styled(Box)`
  display: grid;
  width: ${({ theme }) => theme.components.datepickerCalendarContainerWidth}px;
`

const Header = styled(Grid)`
  grid-template-columns: 20px auto 20px;
`

const HeaderLabel = styled(Text).attrs({
  fontSize: 'm',
  fontWeight: 'medium',
  textTransform: 'capitalize',
})<IHeaderLabel>`
  ${p => css`
    cursor: ${p.onClick && 'pointer'};
  `};
`

const DayName = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${p => css`
    width: ${p.theme.components.datepickerDayNameWidth}px;
    height: ${p.theme.components.datepickerDayNameHeight}px;
  `};
`

const Cells = styled(Grid)<ICalendarArea>``
