import React, { FC, ReactNode, useEffect } from 'react'
import { StandardConfig } from '@newhope/onelogin-widget'
import { lng } from '@ads/front-core/locales'
import getEnv from '@ads/front-core/utils/getEnv'

const oneLoginConfig: StandardConfig & { locale: Record<string, string> } = {
  clientId: 'ads',
  flow: 'STANDARD',
  authUrl: `${getEnv('REACT_APP_SSO', '/sso')}/auth`,
  locale: {
    LOGIN_BUTTON: lng('oneLogin:LOGIN_BUTTON'),
    BUTTON: lng('oneLogin:BUTTON'),
    LOGOUT: lng('oneLogin:LOGOUT'),
    MANAGE_ACCOUNT: lng('oneLogin:MANAGE_ACCOUNT'),
    SWITCH_ACCOUNT: lng('oneLogin:SWITCH_ACCOUNT'),
  },
  logout: {
    url: `${getEnv('REACT_APP_SSO', '/sso')}/logout`,
    method: 'GET',
  },
}

interface IOneLoginProvider {
  children?: ReactNode
}

const OneLoginProvider: FC<IOneLoginProvider> = ({ children }) => {
  useEffect(() => {
    let handler: NodeJS.Timeout | undefined = undefined

    const initOneLogin = () => {
      if (!window.OneLogin) {
        handler = setTimeout(() => initOneLogin(), 500)
        return
      }

      if (!window.OneLoginWidget) {
        window.OneLoginWidget = window.OneLogin.createWidget(oneLoginConfig)
      }
    }

    initOneLogin()

    return () => {
      if (handler) {
        clearTimeout(handler)
      }
    }
  }, [])
  return <>{children}</>
}

export default OneLoginProvider
