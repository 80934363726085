import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { call, put, takeEvery } from 'redux-saga/effects'
import { ResponseShopFeeds, ShopService } from '@ads/front-core/api'
import { apiError } from '@ads/front-core/store/sagas/apiErrorSaga'
import { setFeedsLoading } from '../reducers/feeds/setFeedsLoadingReducer'
import { setFeeds } from '../reducers/feeds/setFeedsReducer'

interface FetchFeedsDataProps {
  shopId: number
}

type FetchFeedsDataSaga = ReturnType<
  PayloadActionCreator<FetchFeedsDataProps, string>
>

export const fetchFeedsData = createAction<FetchFeedsDataProps>(
  'saga/fetchFeedsData',
)

export function* sagaFetchFeedsData(): Generator {
  yield takeEvery(fetchFeedsData.type, fetchFeedsDataSaga)
}

export function* fetchFeedsDataSaga({
  payload,
}: FetchFeedsDataSaga): Generator {
  const { shopId } = payload
  try {
    if (!shopId) {
      yield put(setFeeds([]))
      yield put(setFeedsLoading(false))
      return
    }

    yield put(setFeedsLoading(true))

    const res = yield call(ShopService.getShopFeeds, { shopId })
    const feeds = res as Required<ResponseShopFeeds>

    yield put(setFeeds(feeds))
    yield put(setFeedsLoading(false))
  } catch (error) {
    yield put(setFeeds([]))
    yield put(setFeedsLoading(false))
    yield put(apiError({ error }))
  }
}
