import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setCurrencyLoading = createAction<boolean>(
  'currency/setCurrencyLoading',
)

export const setCurrencyLoadingReducer = (
  state: RootState['currency'],
  action: ReturnType<typeof setCurrencyLoading>,
): void => {
  state.loading = action.payload
}
