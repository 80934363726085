import { isAfter, isBefore, isSameDay, set, startOfMinute } from 'date-fns'

import { MultiInputType } from './DateInput/types'

export const checkDisabledDates = (date: Date, disabled: Date | Date[]) => {
  if (Array.isArray(disabled)) {
    return !!disabled.find(d => isSameDay(date, d))
  }

  return isSameDay(date, disabled)
}

export const MAX_DAY = 31
export const MAX_MONTH = 12
export const MAX_YEAR = 9999
export const MAX_HOUR = 23
export const MAX_MINUTE = 59

export const defaultDate = new Date('2023-01-01T00:00:00.000')

interface DateUpdater {
  value: string
  type: string | undefined
  oldDate: Date
}
export const dateUpdater = ({ value, type, oldDate }: DateUpdater) => {
  switch (type) {
    case 'day':
      return set(oldDate, { date: parseInt(value) })
    case 'month':
      return set(oldDate, { month: parseInt(value) - 1 })
    case 'year':
      return set(oldDate, { year: parseInt(value) })
    case 'hour':
      return set(oldDate, { hours: parseInt(value) })
    case 'minute':
      return set(oldDate, { minutes: parseInt(value) })
    default:
      return oldDate
  }
}

type CheckIsAvailableDate = {
  disabledDates?: Date | Date[]
  disabledFrom?: Date
  disabledTo?: Date
  currentDate: Date
}

export const checkIsAvailableDate = ({
  disabledDates,
  disabledFrom,
  disabledTo,
  currentDate,
}: CheckIsAvailableDate): boolean => {
  const current = startOfMinute(currentDate)

  const isBeforeDisabledFrom =
    !!disabledFrom && isBefore(current, startOfMinute(disabledFrom))
  const isAfterDisabledTo =
    !!disabledTo && isAfter(current, startOfMinute(disabledTo))
  const isNotDisabledDate =
    !disabledDates || !checkDisabledDates(current, disabledDates)
  return (
    ((!disabledFrom && !disabledTo) ||
      isBeforeDisabledFrom ||
      isAfterDisabledTo) &&
    isNotDisabledDate
  )
}

export const getCurrentInputParams = (type: MultiInputType) => {
  switch (type) {
    case 'day':
      return {
        maxValue: MAX_DAY,
        maxLength: 2,
        format: 'dd',
        maxFirstDigit: 3,
      }
    case 'month':
      return {
        maxValue: MAX_MONTH,
        maxLength: 2,
        format: 'MM',
        maxFirstDigit: 1,
      }
    case 'year':
      return {
        maxValue: MAX_YEAR,
        maxLength: 4,
        format: 'yyyy',
      }
    case 'hour':
      return {
        maxValue: MAX_HOUR,
        maxLength: 2,
        format: 'HH',
        maxFirstDigit: 2,
      }
    case 'minute':
      return {
        maxValue: MAX_MINUTE,
        maxLength: 2,
        format: 'mm',
        maxFirstDigit: 5,
      }
  }
}
