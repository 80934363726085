export const LOCAL_HOSTS = ['127.0.0.1', 'localhost']

export const CANARY_HOSTS = [
  'ads-frontend-canary-app-http.kube.dev.dcwp.pl',
  'ads-frontend-canary-1-app-http.kube.dev.dcwp.pl',
  'ads-frontend-canary-2-app-http.kube.dev.dcwp.pl',
  'ads-frontend-canary-3-app-http.kube.dev.dcwp.pl',
  'ads-frontend-canary-admin-http.kube.dev.dcwp.pl',
  'ads-frontend-canary-1-admin-http.kube.dev.dcwp.pl',
  'ads-frontend-canary-2-admin-http.kube.dev.dcwp.pl',
  'ads-frontend-canary-3-admin-http.kube.dev.dcwp.pl',
]

export const DEV_HOSTS = [
  'adswppl-dev.grupawp.pl',
  'adswppl-admin-dev.grupawp.pl',
]

const ALL_DEV_HOSTS = [...LOCAL_HOSTS, ...DEV_HOSTS, ...CANARY_HOSTS]

export const isDev = (): boolean =>
  typeof window !== 'undefined' &&
  ALL_DEV_HOSTS.includes(window.document.location.hostname)
