/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestHomebookRegister } from '../models/RequestHomebookRegister'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class HomebookService {
  /**
   * Rejesruje zgłoszenie klienta homebook
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static homebookRegister({
    requestBody,
  }: {
    /**
     * Dane rejestracyjne klienta
     */
    requestBody: RequestHomebookRegister
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/homebook/register',
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
