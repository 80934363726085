import {
  EnhancedStore,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import appReducer from './appReducer'
import { preloadedState } from './appState'
import appSaga from './sagas'

export const createAppStore = (): EnhancedStore => {
  const devTools = process.env.NODE_ENV !== 'production'

  const sagaMiddleware = createSagaMiddleware()

  const middleware = [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
  ]

  const store = configureStore({
    reducer: appReducer,
    middleware,
    devTools,
    preloadedState,
  })

  sagaMiddleware.run(appSaga)

  return store
}
