import styled, { css } from 'styled-components'
import { Menu, colors, radii, space } from '@ads/front-ds'
import { getScrollbarStyles } from '@ads/front-core/utils/scrollbar'

type Variant = 'default' | 'primary'

type VariantConfig = {
  backgroundColor: keyof typeof colors
  backgroundHoveredColor: keyof typeof colors
  textColor: keyof typeof colors
}

const Variants: Record<Variant, VariantConfig> = {
  default: {
    backgroundColor: 'inverted100',
    backgroundHoveredColor: 'grey05',
    textColor: 'grey100',
  },
  primary: {
    backgroundColor: 'primary50',
    backgroundHoveredColor: 'primary100',
    textColor: 'inverted100',
  },
}

const getVariantConfig = (variant: Variant): VariantConfig => Variants[variant]

const MenuWithoutScroll = styled(Menu)<{
  disableScroll?: boolean
  borderRadius?: keyof typeof radii
  gap?: keyof typeof space
  withSearch?: boolean
  variant?: Variant
}>`
  ${p => css`
    & > :first-child {
      ${p.gap
        ? css`
            gap: ${p.theme.space[p.gap]}px;
          `
        : ''}
      overflow-y: ${p.disableScroll ? 'visible' : 'auto'};
      ${p.withSearch &&
      css`
        padding-top: 0;
      `}
      ${p.borderRadius &&
      css`
        border-radius: ${p.theme.radii[p.borderRadius]};
      `}
      ${p.variant &&
      css`
        background-color: ${p.theme.colors[
          getVariantConfig(p.variant).backgroundColor
        ]};

        span {
          color: ${p.theme.colors[getVariantConfig(p.variant).textColor]};

          :hover {
            background-color: ${p.theme.colors[
              getVariantConfig(p.variant).backgroundHoveredColor
            ]};
          }
        }
      `}
      ${getScrollbarStyles()}
    }
  `}
`

export default MenuWithoutScroll
