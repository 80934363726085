import { XAxisProps, YAxisProps } from 'recharts'
import { StatItem } from '@ads/front-core/interfaces'

interface ChartConfig {
  X_AXIS: Omit<XAxisProps, 'ref'>
  Y_AXIS: Omit<YAxisProps, 'ref'>
}

export const LINE_CHART_COLORS = ['#489DFF', '#4901FF']

export const unitByDataType = (
  dataType?: StatItem['type'],
  currency?: string,
): string | undefined => {
  switch (dataType) {
    case 'currency':
      return currency || 'zł'
    case 'percent':
      return '%'
    default:
      return undefined
  }
}

export const getLineChartConfig = (): ChartConfig => ({
  X_AXIS: {
    stroke: '#f2f2f4',
    textAnchor: 'start',
    type: 'category',
  },
  Y_AXIS: {
    axisLine: false,
    tickLine: false,
  },
})
