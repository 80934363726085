import {
  GenericCampaignCreationPropertiesType,
  Device,
} from '@ads/front-core/api'

export type CreationFormatType = 'FLAT_FORMAT' | 'RICH_MEDIA'

export type CreationSpecification = {
  id: GenericCampaignCreationPropertiesType
  name: string
  device: Device[]
  size: string
  fileSize: string
  formatType: CreationFormatType
}

export const creationsSpecifications: CreationSpecification[] = [
  {
    id: 'RECTANGLE_300x250',
    name: 'Rectangle',
    device: ['DESKTOP', 'MOBILE'],
    size: '300x250',
    fileSize: '40kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'MEGA_DOUBLE_BILLBOARD_970x300',
    name: 'Mega double billboard',
    device: ['DESKTOP'],
    size: '970x300',
    fileSize: '70kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'DOUBLE_BILLBOARD_750x200',
    name: 'Double billboard',
    device: ['DESKTOP'],
    size: '750x200',
    fileSize: '50kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'HALFPAGE_300x600',
    name: 'Halfpage',
    device: ['DESKTOP', 'MOBILE'],
    size: '300x600',
    fileSize: '60kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'TRIPLE_BILLBOARD_750x300',
    name: 'Triple Billboard',
    device: ['DESKTOP'],
    size: '750x300',
    fileSize: '60kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'BANNER_300x100',
    name: 'Banner',
    device: ['MOBILE'],
    size: '300x100',
    fileSize: '20kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'WIDEBOARD_970x200',
    name: 'Wideboard',
    device: ['DESKTOP'],
    size: '970x200',
    fileSize: '60kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'SKYSCRAPER_160x600',
    name: 'Skyscraper',
    device: ['DESKTOP'],
    size: '160x600',
    fileSize: '40kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'BILLBOARD_750x100',
    name: 'Billboard',
    device: ['DESKTOP'],
    size: '750x100',
    fileSize: '40kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'SCALABLE_XL_BANNER_600x400',
    name: 'Banner skalowalny XL',
    device: ['MOBILE'],
    size: '600x400',
    fileSize: '40kB',
    formatType: 'FLAT_FORMAT',
  },
  {
    id: 'CUBE_3D',
    name: 'Cube 3D',
    device: ['DESKTOP', 'MOBILE'],
    size: undefined,
    fileSize: '150kB',
    formatType: 'RICH_MEDIA',
  },
  {
    id: 'MOTION_AD',
    name: 'Motion AD',
    device: ['MOBILE'],
    size: undefined,
    fileSize: '150kB',
    formatType: 'RICH_MEDIA',
  },
  {
    id: 'SCRATCH',
    name: 'Scratch',
    device: ['DESKTOP', 'MOBILE'],
    size: undefined,
    fileSize: '150kB',
    formatType: 'RICH_MEDIA',
  },
  {
    id: 'SLASH',
    name: 'Splash',
    device: ['DESKTOP', 'MOBILE'],
    size: undefined,
    fileSize: '150kB',
    formatType: 'RICH_MEDIA',
  },
]

export const getCreationsSpecifications = (
  type: GenericCampaignCreationPropertiesType,
): CreationSpecification =>
  creationsSpecifications.find(specification => specification.id === type)
