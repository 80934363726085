import { ResponseUser } from '@ads/front-core/api'
import { ROLES } from '@ads/front-core/constants/roles'
import { TDictionary } from '@ads/front-core/interfaces/dictionary'

type ExcludeRoleResult = {
  id: ResponseUser['roleId']
  value: TDictionary['value']
  description?: TDictionary['description']
}

export const excludeRole = (
  roles: TDictionary[] = [],
  rolesIdToExclude: ResponseUser['roleId'][],
): ExcludeRoleResult[] =>
  roles
    .map(r => ({
      id: r.id as ResponseUser['roleId'],
      value: r.value,
      description: ROLES[r.id].description,
    }))
    .filter(r => !rolesIdToExclude.includes(r.id))
