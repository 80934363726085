import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { IForm } from 'components/views/MAPushConsentCreator'
import { AppState } from '../../appState'

export const setFormTemplatesDraft = createAction<IForm>(
  'draft/setFormTemplatesDraft',
)

export const setFormTemplatesDraftReducer = (
  state: AppState['draft'],
  action: ReturnType<typeof setFormTemplatesDraft>,
): void => {
  state.formTemplates = cloneDeep(action.payload)
}
