import { RequestAudienceGQL } from '@ads/front-core/api'

type AudienceDataType = 'number' | 'percent' | 'ratio' | 'duration' | 'currency'

export type TDimension = { id: string; name: string }

export enum MetricEnum {
  CR = '1',
  INCOME = '2',
  CLICKS = '3',
  TRANSACTIONS = '4',
  AOV = '5',
  COST = '6',
  ROAS = '7',
  COS = '8',
  VIMP = '9',
  VIEWS = '10',
  CTR = '11',
  V_CTR = '12',
  VIEWABILITY_RATE = '13',
  eCPC = '14',
  DELIVERY = '22',
  OPEN = '23',
  O_CTR = '24',
  OPENRATE = '25',
}

export type TMetric = {
  id: MetricEnum
  name: string
  type: AudienceDataType
}

export interface AudienceGQLLabels extends RequestAudienceGQL {
  data?: {
    adsDimensions?: {
      dataType?: AudienceDataType
      id?: string
      name?: string
    }[]
    adsMetrics?: {
      dataType?: AudienceDataType
      id?: string
      name?: string
    }[]
  }
  errors?: {
    message: string
  }[]
}

export interface AudienceGQLTable extends RequestAudienceGQL {
  data?: {
    adsCampaignTable?: {
      dimensions?: {
        dimensionRef?: {
          id?: number | string
        }
        value?: string
      }[]
      metrics?: {
        metricRef?: {
          dataType?: AudienceDataType
          id?: number | string
          name?: string
        }
        value?: number
      }[]
    }[]
  }
  errors?: {
    message: string
  }[]
}

export interface AudienceGQLCategoriesRank extends RequestAudienceGQL {
  data?: {
    adsCategoriesRank?: {
      categoryId?: number | string
      metrics?: {
        metricRef?: {
          dataType?: AudienceDataType
          id?: number | string
          name?: string
        }
        value?: number
      }[]
    }[]
  }
  errors?: {
    message: string
  }[]
}

export type AudienceCampaignMetric = {
  id: string
  dataType: AudienceDataType
  name: string
  value: number
}

export type AudienceCampaign = {
  id: number
  metrics: AudienceCampaignMetric[]
}

export type AudienceTableMetric = {
  [key in string]: {
    dataType: AudienceDataType
    name: string
    value: number
  }
}

export type AudienceTable = {
  categoryId: number
  metrics: AudienceTableMetric
}

export type AudienceReChartData = { [key in string]: string | number }
