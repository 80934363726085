import React, { PropsWithChildren, cloneElement, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Box } from '../Box'
import { ICardTooltip, ICardIHolder } from './types'

export const CardTooltip = ({
  children,
  side = 'right',
  align = 'center',
  animatable = true,
  hide = false,
  styleCss,
  zIndex,
  offset,
  tooltipElement,
  ...rest
}: PropsWithChildren<ICardTooltip>) => {
  const cardTooltipRef = useRef(null)
  const [isHovered, setHover] = useState(false)

  const cardTooltip = (
    <StyledCardTooltip
      borderRadius="rounded"
      side={side}
      boxShadow="l"
      align={align}
      ref={cardTooltipRef}
      zIndex={zIndex}
      styleCss={styleCss}
      children={tooltipElement}
    />
  )

  return !children ? (
    cloneElement(cardTooltip, { ...rest })
  ) : (
    <Wrapper {...rest}>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {children}
      </div>
      {!hide && (
        <Holder
          side={side}
          align={align}
          hover={isHovered}
          zIndex={zIndex}
          offset={offset}
        >
          {cloneElement(cardTooltip, { animatable, hover: isHovered })}
        </Holder>
      )}
    </Wrapper>
  )
}

const sides = {
  top: (p: ICardIHolder) => css`
    ${({ theme }) => css`
      bottom: calc(
        100% + ${theme.space.xxs + (p.offset ? theme.space[p.offset] : 0)}px
      );
    `}
  `,
  bottom: (p: ICardIHolder) => css`
    ${({ theme }) => css`
      top: calc(
        100% + ${theme.space.xxs + (p.offset ? theme.space[p.offset] : 0)}px
      );
    `}
  `,
  left: (p: ICardIHolder) => css`
    ${({ theme }) => css`
      right: calc(
        100% + ${theme.space.xxs + (p.offset ? theme.space[p.offset] : 0)}px
      );
    `}
  `,
  right: (p: ICardIHolder) => css`
    ${({ theme }) => css`
      left: calc(
        100% + ${theme.space.xxs + (p.offset ? theme.space[p.offset] : 0)}px
      );
    `}
  `,
}

const alignes = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
}

const Wrapper = styled(Box)`
  position: relative;
  display: inline-block;
`

const Holder = styled.div<ICardIHolder>`
  position: absolute;
  display: flex;
  width: 100%;

  ${p => css`
    z-index: ${p.zIndex};
    justify-content: ${alignes[p.align ? p.align : 'center']};

    ${sides[p.side ? p.side : 'top']};
    visibility: ${!p.hover && p.animatable && 'hidden'};
    ${p.zIndex ? `z-index: ${p.zIndex}` : ''};

    ${['left', 'right'].includes(p.side ? p.side : 'top') &&
    css`
      top: 0;
      width: auto;
      height: 100%;
      flex-direction: column;
    `}

    ${['top', 'bottom'].includes(p.side ? p.side : 'top') &&
    css`
      width: auto;

      ${p.align === 'center' &&
      css`
        left: 50%;
        transform: translateX(-50%);
      `}

      ${p.align === 'start' &&
      css`
        left: 0;
      `}

        ${p.align === 'end' &&
      css`
        right: 0;
      `}
    `}
  `};
`

const StyledCardTooltip = styled(Box)<ICardIHolder>`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;

  ${p => css`
    background-color: ${p.theme.components.cardTooltipBackgroundColor};

    &::after {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';

      ${p.align === 'start' &&
      ['top', 'bottom'].includes(p.side ? p.side : 'top') &&
      css`
        left: 10px;
      `}

      ${p.align === 'center' &&
      ['top', 'bottom'].includes(p.side ? p.side : 'top') &&
      css`
        left: 50%;
        transform: translateX(-50%);
      `}

      ${p.align === 'end' &&
      ['top', 'bottom'].includes(p.side ? p.side : 'top') &&
      css`
        right: 10px;
      `}

      ${p.align === 'start' &&
      ['left', 'right'].includes(p.side ? p.side : 'top') &&
      css`
        top: 10px;
      `}

      ${p.align === 'center' &&
      ['left', 'right'].includes(p.side ? p.side : 'top') &&
      css`
        top: 50%;
        transform: translateY(-50%);
      `}

      ${p.align === 'end' &&
      ['left', 'right'].includes(p.side ? p.side : 'top') &&
      css`
        bottom: 10px;
      `}

       ${p.side === 'top' &&
      css`
        top: 100%;
        border-width: 4px 5px 0 5px;
        border-color: ${p.theme.components.cardTooltipBackgroundColor}
          transparent transparent transparent;
      `}

       ${p.side === 'bottom' &&
      css`
        bottom: 100%;
        border-width: 0 5px 4px 5px;
        border-color: transparent transparent
          ${p.theme.components.cardTooltipBackgroundColor} transparent;
      `}

       ${p.side === 'left' &&
      css`
        left: 100%;
        border-width: 5px 0 5px 4px;
        border-color: transparent transparent transparent
          ${p.theme.components.cardTooltipBackgroundColor};
      `}

       ${p.side === 'right' &&
      css`
        right: 100%;
        border-width: 5px 4px 5px 0;
        border-color: transparent
          ${p.theme.components.cardTooltipBackgroundColor} transparent
          transparent;
      `}
    }

    ${p.animatable &&
    css`
      opacity: 0;
      transition: all ${p.theme.motion.productive};

      ${p.side === 'top' &&
      css`
        transform: translateY(${p.theme.space.xxxs}px);
      `}

      ${p.side === 'bottom' &&
      css`
        transform: translateY(-${p.theme.space.xxxs}px);
      `}

       ${p.side === 'left' &&
      css`
        transform: translateX(${p.theme.space.xxxs}px);
      `}

       ${p.side === 'right' &&
      css`
        transform: translateX(-${p.theme.space.xxxs}px);
      `}

      ${p.hover &&
      css`
        opacity: 1;
        transform: none;
      `};
    `};
  `};
`
