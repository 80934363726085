/**
 * Function add multiple transition property value
 */

import { css } from 'styled-components'

import { motion as motions } from '../theme'
import { Motion } from '../theme/interfaces'

const setValues = (values: string[], motion: keyof Motion) => {
  return values.map(v => `${v} ${motions[motion]}`).join(',')
}

export const setTransition = (
  value: string | string[],
  motion: keyof Motion,
) => {
  if (typeof value === 'object') {
    return css`
      transition: ${setValues(value, motion)};
    `
  }

  return css`
    transition: ${value} ${motion};
  `
}
