import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../../Box'
import { Flex } from '../../Flex'
import { Text, IText } from '../../Text'
import { Avatar, getSize } from '../Avatar/Avatar'
import { useNanoid, setTransition } from '../../../utility'
import { IAvatarsGroup, IContainer, IItem, ICounter } from './types'

export const AvatarsGroup: FC<React.PropsWithChildren<IAvatarsGroup>> = ({
  items,
  size = 'm',
  view = 'condensed',
  stroke = 'inverted100',
  max,
  onMoreClick,
  onMoreHover,
  ...rest
}) => {
  const [ids] = useNanoid(items.length)

  return (
    <Box {...rest}>
      <Container size={size} view={view}>
        {items.slice(0, max).map((item, index) => (
          <Item
            view={view}
            index={items.length - index}
            size={size}
            key={ids[index]}
          >
            <Avatar {...item} size={size} stroke={stroke} />
          </Item>
        ))}
        {max && items.length > max && (
          <Item view={view} size={size}>
            <Counter
              size={size}
              borderRadius="fullRounded"
              alignItems="center"
              justifyContent="center"
              onClick={onMoreClick}
              onMouseEnter={onMoreHover}
            >
              <Text
                fontSize={getSize(size).fontSize as IText['fontSize']}
                fontWeight={getSize(size).fontWeight as IText['fontWeight']}
              >{`+${items.length - max}`}</Text>
            </Counter>
          </Item>
        )}
      </Container>
    </Box>
  )
}

const Counter = styled(Flex)<ICounter>`
  ${p => css`
    width: ${p.theme.components[getSize(p.size).dimensions]}px;
    height: ${p.theme.components[getSize(p.size).dimensions]}px;
    flex: 0 0 ${p.theme.components[getSize(p.size).dimensions]}px;
    box-shadow: ${p.stroke && `0 0 0 2px ${p.theme.colors[p.stroke]}`};
    background-color: ${p.theme.components.avatarsGroupCounterBackgroundColor};
    color: ${p.theme.components.avatarsGroupCounterColor};
    cursor: pointer;
    ${setTransition(['opacity'], 'productive')};

    &:hover {
      opacity: ${p.theme.aliases.defaultActiveOpacity};
    }
  `};
`

const Item = styled(Box)<IItem>`
  ${p => css`
    ${p.view === 'condensed' &&
    css`
      background-color: #ffffff;
      border-radius: 50%;
      position: relative;
      z-index: ${p.index};
    `};

    ${p.view === 'grid' &&
    css`
      margin-left: ${p.theme.space[p.size === 'm' ? 'xxs' : 'xxxs']}px;
      margin-bottom: ${p.theme.space[p.size === 'm' ? 'xxs' : 'xxxs']}px;
    `};
  `};
`

const Container = styled(Flex)<IContainer>`
  ${p => css`
    ${p.view === 'condensed' &&
    css`
      ${Item} + ${Item} {
        margin-left: -${p.theme.space[p.size === 'm' ? 'xxs' : 'xxxs']}px;
      }
    `};

    ${p.view === 'grid' &&
    css`
      flex-wrap: wrap;
      margin-left: -${p.theme.space[p.size === 'm' ? 'xxs' : 'xxxs']}px;
      margin-bottom: -${p.theme.space[p.size === 'm' ? 'xxs' : 'xxxs']}px;
    `};
  `};
`
