import styled from 'styled-components'

import { Box } from '../Box'
import { IImage } from './types'

export const Image = styled(Box).attrs({ forwardedAs: 'img' })<IImage>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`
