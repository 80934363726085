import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setIsOpenPromotion1000Rules = createAction<boolean>(
  'common/isOpenPromotion1000Rules',
)

export const setIsOpenPromotion1000RulesReducer = (
  state: AppState['common'],
  action: ReturnType<typeof setIsOpenPromotion1000Rules>,
): void => {
  state.isOpenPromotion1000Rules = action.payload
}
