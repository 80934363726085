import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../../Box'
import { Icon } from '../../Icon'
import { Text } from '../../Text'
import { IMenuInput, IMenuItemContainer, IMenuSearch } from './types'

export const MenuSearch: FC<React.PropsWithChildren<IMenuSearch>> = ({
  isDisabled,
  level,
  tabIndex = 0,
  onKeyDown,
  onKeyUp,
  id,
  placeholder,
  value,
  defaultValue,
  isReadOnly,
  onChange,
  inputRef,
  ...rest
}) => (
  <StyledBox forwardedAs="div">
    <StyledText fontSize="m" lineHeight="m" level={level} tabIndex={tabIndex}>
      <Icon name="20-search" color="grey50" mr="xxs" />
      <ItemInput
        id={id}
        ref={inputRef}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        isReadOnly={isReadOnly}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        isDisabled={isDisabled}
        aria-label={rest['aria-label']}
      />
    </StyledText>
  </StyledBox>
)

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${p => css`
    transition: background-color ${p.theme.motion.productive};
  `};
`

const StyledText = styled(Text)<IMenuItemContainer>`
  display: flex;
  ${p => css`
    padding: ${p.theme.space.xs / 2}px ${p.theme.space.xxs}px;
    color: ${p.theme.components.menuChildColor};
  `};
`

const ItemInput = styled(Text).attrs({
  forwardedAs: 'input',
  fontSize: 'm',
  lineHeight: 'm',
  color: 'grey100',
})<IMenuInput>`
  border: none;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  display: inline-block;

  &:focus {
    outline: none;
  }
  ${p => css`
    width: calc(100% - ${p.theme.space.xxs}px - 20px);
  `}
`
