import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { breakpoints } from '@ads/front-core/constants/breakpoints'
import { setIsMobile } from '@ads/front-core/store/reducers/app/setIsMobileReducer'
import { fetchConfig } from '@ads/front-core/store/sagas/fetchConfigSaga'
import useSurvicateScript from './useSurvicateScript'

const Page: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  useSurvicateScript()

  const storeUTMSource = () => {
    if (!window.location.search) {
      return
    }

    try {
      const sourceUTMs = new URLSearchParams(
        window.localStorage.getItem('source') || '',
      )
      const search = new URLSearchParams(window.location.search)
      if (search?.get('utm_source') === 'samito') {
        sourceUTMs.set('utm_ma', search.get('utm_campaign'))
        if (!sourceUTMs.get('utm_source') && !sourceUTMs.get('utm_campaign')) {
          sourceUTMs.set('utm_source', search.get('utm_source'))
          sourceUTMs.set('utm_campaign', search.get('utm_campaign'))
        }
      } else {
        Array.from(search)
          .filter(s => s[0].match(/^utm_(source|campaign)/))
          .forEach(item => sourceUTMs.set(item[0], item[1]))
      }

      const source = sourceUTMs.toString()
      if (source) {
        window.localStorage.setItem('source', source)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const onResize = () => {
      dispatch(setIsMobile(window.innerWidth <= breakpoints.MOBILE_PX))
    }

    window.addEventListener('resize', onResize)
    onResize()

    dispatch(fetchConfig())

    storeUTMSource()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return <>{children}</>
}

export default Page
