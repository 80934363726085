/**
 * Function checks if component has specific value in prop
 */

export const checkIntent = (
  value: string | string[],
  intent: string | string[] | undefined,
) => {
  if (intent === undefined) return false

  switch (true) {
    case typeof intent === 'string' && typeof value === 'string':
      return value === intent
    case typeof intent === 'object' && typeof value === 'object':
      return (intent as []).some(i => value.includes(i))
    case typeof intent === 'object' && typeof value === 'string':
      return intent.includes(value as string)
    case typeof intent === 'string' && typeof value === 'object':
      return value.includes(intent as string)
    default:
      return false
  }
}
