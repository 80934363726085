import {
  CampaignStats,
  NewsletterStatItem,
  ChartPrecision,
  Device,
} from '@ads/front-core/api'
import {
  RelativeRange,
  StatItem,
  StatItemKey,
  StatListItem,
  StatDashboard,
} from '@ads/front-core/interfaces'
import { lng } from '@ads/front-core/locales'
import { getDate, getDateTime, parseISODate } from '@ads/front-core/utils'

const METRICS: Record<StatItemKey, StatItem> = {
  CR: {
    name: lng('metrics:metric.cr'),
    statKey: 'cr',
    type: 'percent',
    tooltip: lng('metrics:crDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  INCOME: {
    name: lng('metrics:metric.income'),
    statKey: 'income',
    type: 'currency',
    tooltip: lng('metrics:incomeDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  CLICKS: {
    name: lng('metrics:metric.clicks'),
    statKey: 'clicks',
    type: 'number',
    tooltip: lng('metrics:clicksCount'),
    showOnDashboard: [StatDashboard.GENERAL, StatDashboard.MA],
  },
  TRANSACTIONS: {
    name: lng('metrics:metric.transactions'),
    statKey: 'transactions',
    type: 'number',
    tooltip: lng('metrics:transactionsDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  AOV: {
    name: lng('metrics:metric.aov'),
    statKey: 'aov',
    type: 'currency',
    tooltip: lng('metrics:aovDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  COSTS: {
    name: lng('metrics:metric.costs'),
    statKey: 'cost',
    type: 'currency',
    tooltip: lng('metrics:costDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  ROAS: {
    name: lng('metrics:metric.roas'),
    statKey: 'roas',
    type: 'percent',
    tooltip: lng('metrics:roasDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  COS: {
    name: lng('metrics:metric.cos'),
    statKey: 'cos',
    type: 'percent',
    tooltip: lng('metrics:cosDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  VIMP: {
    name: lng('metrics:metric.vimp'),
    statKey: 'vimp',
    type: 'number',
    tooltip: lng('metrics:vimpDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  VIEWS: {
    name: lng('metrics:metric.views'),
    statKey: 'views',
    type: 'number',
    tooltip: lng('metrics:viewsDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  CTR: {
    name: lng('metrics:metric.ctr'),
    statKey: 'ctr',
    type: 'percent',
    tooltip: lng('metrics:ctrDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  VCTR: {
    name: lng('metrics:metric.vctr'),
    statKey: 'vctr',
    type: 'percent',
    tooltip: lng('metrics:vctrDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  VRATE: {
    name: lng('metrics:metric.vrate'),
    statKey: 'vrate',
    type: 'percent',
    tooltip: lng('metrics:vrateDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  ECPC: {
    name: lng('metrics:metric.ecpc'),
    statKey: 'ecpc',
    type: 'currency',
    tooltip: lng('metrics:ecpcDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  ERROR: {
    name: lng('metrics:metric.error'),
    statKey: 'error',
    type: 'number',
    defaultHidden: true,
    showOnDashboard: [StatDashboard.MA],
  },
  DELIVERY: {
    name: lng('metrics:metric.delivery'),
    statKey: 'delivery',
    type: 'number',
    defaultHidden: true,
    showOnDashboard: [StatDashboard.GENERAL, StatDashboard.MA],
  },
  OPEN: {
    name: lng('metrics:metric.open'),
    statKey: 'open',
    type: 'number',
    defaultHidden: true,
    showOnDashboard: [StatDashboard.GENERAL],
  },
  SENT: {
    name: lng('metrics:metric.sent'),
    statKey: 'sent',
    type: 'number',
    defaultHidden: true,
    showOnDashboard: [StatDashboard.GENERAL, StatDashboard.MA],
  },
  OCTR: {
    name: lng('metrics:metric.octr'),
    statKey: 'octr',
    type: 'percent',
    defaultHidden: true,
    showOnDashboard: [StatDashboard.GENERAL],
  },
  OPENRATE: {
    name: lng('metrics:metric.openrate'),
    statKey: 'openrate',
    type: 'percent',
    defaultHidden: true,
    showOnDashboard: [StatDashboard.GENERAL],
  },
  SESSIONS: {
    name: lng('metrics:metric.sessions'),
    statKey: 'sessions',
    type: 'number',
    showOnDashboard: [StatDashboard.GENERAL],
  },
  SESSION_CLICK_RATIO: {
    name: lng('metrics:metric.sessionClickRatio'),
    statKey: 'sessionClickRatio',
    type: 'percent',
    tooltip: lng('metrics:sessionClickRatioDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  AVG_SESSION_COST: {
    name: lng('metrics:metric.avgSessionCost'),
    statKey: 'avgSessionCost',
    type: 'currency',
    tooltip: lng('metrics:avgSessionCostDescription'),
    showOnDashboard: [StatDashboard.GENERAL],
  },
  BUDGET: {
    name: lng('campaign:realization'),
    statKey: null,
    type: 'tableitem',
    showOnDashboard: [StatDashboard.GENERAL],
  },
}

export const getMetrics = (): Record<StatItemKey, StatItem> => METRICS

export const getMetricsList = (): StatListItem[] =>
  Object.keys(METRICS).map(getMetricListItem)

export const getMetric = (id: StatItemKey): StatItem => METRICS[id]

export const getMetricListItem = (id: StatItemKey): StatListItem => ({
  id,
  ...METRICS[id],
})

export const getMetricByStatKey = (statKey: StatItem['statKey']): StatItem =>
  Object.values(METRICS).find(m => m.statKey === statKey)

export const DEVICES: {
  type?: Device
  label: string
}[] = [
  { type: undefined, label: lng('dashboard:allDevices') },
  { type: 'DESKTOP', label: lng('common:desktop') },
  { type: 'MOBILE', label: lng('common:mobile') },
]

export const getChartXLabel = (
  dateTime: string,
  precision: ChartPrecision,
): string => {
  const date = parseISODate(dateTime)
  if (!date) {
    return dateTime
  }
  switch (precision) {
    case 'DAY':
      return getDate(dateTime)
    case 'HOUR':
    case 'MINUTE10':
      return getDateTime(dateTime)
  }
}

export const relativeRanges: RelativeRange[] = [
  'TODAY',
  'YESTERDAY',
  'THIS_WEEK',
  'LAST_7_DAYS',
  'THIS_MONTH',
  'PREV_MONTH',
  'LAST_30_DAYS',
  'THIS_YEAR',
]

export const getMetricsValue = (
  stats: CampaignStats,
  key: StatItemKey,
): NewsletterStatItem | undefined => {
  const keyToStat = getMetric(key)?.statKey

  for (const [statKey, value] of Object.entries(stats || {})) {
    if (keyToStat === statKey) {
      return value
    }
  }
}
