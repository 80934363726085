import React, { ChangeEvent, FC, FocusEvent, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../../Box'
import { IInput, IOval, IToggleControl } from './types'

export const ToggleControl: FC<React.PropsWithChildren<IToggleControl>> = ({
  isChecked = false,
  value,
  name,
  isDisabled,
  size = 'm',
  forwardedAs = 'label',
  onChange,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [isFocused, setFocus] = useState(false)
  const [mouseDown, setMouseDown] = useState(false)

  const attributes = {
    value,
    checked: isChecked,
    disabled: isDisabled,
    name,
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()

    if (onChange) onChange(e)
  }

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(true)
    if (onFocus) onFocus(e)
  }

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(false)
    setMouseDown(false)
    if (onBlur) onBlur(e)
  }

  const handleOnClick = () => setMouseDown(true)

  return (
    <StyledControl isDisabled={isDisabled} forwardedAs={forwardedAs} {...rest}>
      <Input
        type="checkbox"
        {...attributes}
        isMouseDown={mouseDown}
        onClick={handleOnClick}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      <Oval
        forwardedAs="span"
        isChecked={isChecked}
        isFocus={isFocused}
        isDisabled={isDisabled}
        size={size}
      >
        <Dot
          isChecked={isChecked}
          borderRadius="fullRounded"
          pl={size === 's' ? 'xxs' : 'xs'}
          pt={size === 's' ? 'xxs' : 'xs'}
          size={size}
        />
      </Oval>
    </StyledControl>
  )
}

const StyledControl = styled(Box)<IToggleControl>`
  position: relative;
  display: inline-flex;
  align-items: center;

  ${p => css`
    ${p.isDisabled &&
    css`
      pointer-events: none;
    `};
  `};
`

const Dot = styled(Box)<IOval>`
  position: absolute;

  ${p => css`
    transition: transform ${p.theme.motion.productive};
    transform: translateX(${!p.isChecked ? '2px' : '14px'});
    background-color: ${p.theme.components.toggleControlDotBackgroundColor};

    ${p.size === 's' &&
    css`
      transform: translateX(${!p.isChecked ? '2px' : '10px'});
    `};
  `};
`

const Oval = styled(Box)<IOval>`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${p => css`
    width: ${p.theme.components[
      p.size === 's'
        ? 'toggleControlOvalSizeSmallWidth'
        : 'toggleControlOvalSizeMediumWidth'
    ]}px;
    height: ${p.theme.components[
      p.size === 's'
        ? 'toggleControlOvalSizeSmallHeight'
        : 'toggleControlOvalSizeMediumHeight'
    ]}px;
    background-color: ${p.theme.components.toggleControlOvalBackgroundColor};
    transition: all ${p.theme.motion.expressive};
    border-radius: ${p.theme.components.toggleControlOvalBorderRadius}px;

    ${p.isChecked &&
    css`
      background-color: ${p.theme.components
        .toggleControlOvalCheckedBackgroundColor};

      &:hover {
        background-color: ${p.theme.components
          .toggleControlOvalCheckedHoverBackgroundColor};
      }
    `};

    ${p.isDisabled &&
    css`
      opacity: 0.55;
      background-color: ${p.theme.components[
        p.isChecked
          ? 'toggleControlOvalDisabledCheckedBackgroundColor'
          : 'toggleControlOvalDisabledBackgroundColor'
      ]};
    `};
  `};
`

const Input = styled.input<IInput>`
  position: absolute;
  opacity: 0;

  ${p => css`
    z-index: ${p.theme.zIndex.hide};
    &:focus + ${Oval} {
      box-shadow: ${!p.isMouseDown && p.theme.focuses.normal};
    }

    &:active + ${Oval} {
      opacity: ${p.theme.aliases.defaultActiveOpacity};
    }
  `};
`
