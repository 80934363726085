import React, { FC } from 'react'

const IconPl: FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
      <circle cx="10.3345" cy="10.5" r="9.6665" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3715_3144)">
      <rect
        x="-2.2832"
        y="-1.49915"
        width="25.2364"
        height="11.9992"
        fill="white"
      />
      <rect
        x="-2.2832"
        y="10.5"
        width="25.2364"
        height="11.9992"
        fill="#FF382C"
      />
    </g>
  </svg>
)

export default IconPl
