import { createAction } from '@reduxjs/toolkit'
import { ResponseMaintenance } from '@ads/front-core/api'
import { RootState } from '../../rootState'

export interface SetMaintenanceParams {
  maintenance: ResponseMaintenance
}

export const setMaintenance = createAction<SetMaintenanceParams>(
  'setMaintenance',
)

export const setMaintenanceReducer = (
  state: RootState['app'],
  action: ReturnType<typeof setMaintenance>,
): void => {
  state.maintenance = action.payload.maintenance
}
