import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../../Text'
import { Icon } from '../../Icon'

import { IItem, IItemLabel, ICaption } from './types'
import { Box } from '../../Box'
import { RadioControl, IRadioControl } from '../RadioControl'
import { adjustPaddings, setTransition } from '../../../utility'

export const RadioBoxItem: FC<React.PropsWithChildren<IItem>> = ({
  label,
  value,
  caption,
  isError,
  isDisabled,
  position,
  isChecked,
  icon,
  onChange,
}) => (
  <StyledItem
    forwardedAs="label"
    isError={isError}
    isDisabled={isDisabled}
    position={position}
    isIcon={!!icon}
    aria-label={label}
  >
    <Label>
      {icon && <Icon name={icon} mr="xxs" />}
      <Text pr="s" as="div">
        {label}
      </Text>
    </Label>
    {caption && (
      <Caption fontSize="s" lineHeight="s" isDisabled={isDisabled} mt="xxs">
        {caption}
      </Caption>
    )}
    <StyledControl
      forwardedAs={'span' as IRadioControl['forwardedAs']}
      isChecked={isChecked}
      value={value}
      isDisabled={isDisabled}
      onChange={onChange}
    />
  </StyledItem>
)

const StyledItem = styled(Box)<IItemLabel>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  ${adjustPaddings(['s', 'xxl', 's', 's'])};
  ${setTransition(['background-color', 'color'], 'productive')};

  & + & {
    margin-top: -1px;
  }

  &:focus,
  &:hover {
    z-index: 1;
    outline: none;
  }

  ${p => css`
    border: ${p.theme.components.radioBoxItemBorder};
  `};

  ${p => css`
    ${!p.isDisabled &&
    css`
      &:hover {
        border-color: ${p.theme.components[
          p.isError
            ? 'radioBoxItemErrorHoverBorderColor'
            : 'radioBoxItemHoverBorderColor'
        ]};
      }
    `}

    ${p.isError &&
    !p.isDisabled &&
    css`
      position: relative;
      z-index: 1;
    `};

    ${p.isError &&
    css`
      border-color: ${p.theme.components.radioBoxItemErrorBorderColor};
    `};

    ${p.isDisabled &&
    css`
      cursor: not-allowed;
      border-color: ${p.theme.components.radioBoxItemDisabledBorderColor};
      color: ${p.theme.components.radioBoxItemDisabledrColor};
    `}

    ${p.position === 'first' &&
    css`
      border-radius: ${p.theme.radii.rounded} ${p.theme.radii.rounded} 0 0;
    `};

    ${p.position === 'last' &&
    css`
      border-radius: 0 0 ${p.theme.radii.rounded} ${p.theme.radii.rounded};
    `};
  `};
`

const StyledControl = styled(RadioControl)`
  position: absolute;

  ${p => css`
    right: ${p.theme.space.s}px;
    margin: ${p.theme.space.xxxs / 2}px 0;
  `};
`

const Label = styled(Box)`
  display: flex;
`

const Caption = styled(Text)<ICaption>`
  color: ${p =>
    p.theme.components[
      p.isDisabled
        ? 'radioBoxItemCaptionDisabledColor'
        : 'radioBoxItemCaptionColor'
    ]};
`
