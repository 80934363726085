import {
  addDays,
  addYears,
  compareAsc,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfDecade,
  endOfMonth,
  endOfWeek,
  format,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  isToday,
  isWithinInterval,
  setHours,
  setMinutes,
  setMonth,
  startOfMonth,
  startOfWeek,
  subYears,
} from 'date-fns'
import { checkIntent } from '../../../utility'
import { checkDisabledDates } from '../utils'
import { ICalendar, ICell } from './types'
import pl from 'date-fns/locale/pl'

export const checkRange = (
  date: Date,
  dates: Date[],
  display: ICalendar['display'],
) => {
  if (!dates.length) return false

  const [start, end] = dates.sort(compareAsc)

  const isInInterval = isWithinInterval(date, {
    start: start,
    end: end,
  })

  switch (display) {
    case 'days':
      return isInInterval || isSameDay(date, start) || isSameDay(date, end)
    case 'months':
      return isInInterval || isSameMonth(date, start) || isSameMonth(date, end)
    case 'years':
      return isInInterval || isSameYear(date, start) || isSameYear(date, end)
  }
}

export const checkSelection = (
  date: Date,
  dates: Date[],
  display: ICalendar['display'],
): ICell['selected'] => {
  switch (true) {
    case (display === 'days' && isSameDay(date, dates[0])) ||
      (display === 'months' && isSameMonth(date, dates[0])) ||
      (display === 'years' && isSameYear(date, dates[0])):
      return 'start'
    case (display === 'days' && isSameDay(date, dates[1])) ||
      (display === 'months' && isSameMonth(date, dates[1])) ||
      (display === 'years' && isSameYear(date, dates[1])):
      return 'end'
    default:
      return false
  }
}

export const getDays = (date: Date, isIntent: boolean) => {
  const currentMonth = date
  const currentHours = getHours(date)
  const currentMinutes = getMinutes(date)
  const monthStart = startOfMonth(currentMonth)
  const monthEnd = endOfMonth(monthStart)
  const startDate = startOfWeek(monthStart, { weekStartsOn: 1 })
  const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 })

  const days: { date: Date; isSameMonth: boolean; isToday: boolean }[] = []

  const cellsLength = 41

  let day = startDate

  const createDaysArray = () => {
    days.push({
      date: setMinutes(setHours(day, currentHours), currentMinutes),
      isSameMonth: isSameMonth(day, monthStart),
      isToday: isToday(day),
    })

    day = addDays(day, 1)
  }

  if (isIntent) {
    while (day <= endDate) {
      createDaysArray()
    }
  } else {
    while (days.length <= cellsLength) {
      createDaysArray()
    }
  }

  return days
}

export const getMonths = (date: Date) => {
  const months = eachMonthOfInterval({
    start: setMonth(date, 0),
    end: setMonth(date, 11),
  })

  return months.map(month => {
    return {
      date: month,
      isToday: isSameMonth(month, new Date()) && isSameYear(month, new Date()),
    }
  })
}

export const getYears = (date: Date) => {
  const endYear = addYears(endOfDecade(date), 1)

  const years = eachYearOfInterval({
    start: subYears(endYear, 11),
    end: endYear,
  })

  return years.map(year => {
    return {
      date: year,
      isToday: isSameYear(year, new Date()),
    }
  })
}

export const getCells = (
  date: Date,
  display: ICalendar['display'],
  intent: ICalendar['intent'],
) => {
  switch (display) {
    case 'days':
      return getDays(date, checkIntent('dual', intent))
    case 'months':
      return getMonths(date)
    case 'years':
      return getYears(date)
  }
}

export const isSame = (
  date: Date,
  display: ICalendar['display'],
  scope?: Date,
) => {
  if (!scope) {
    return false
  }

  switch (display) {
    case 'days':
      return isSameDay(date, scope)
    case 'months':
      return isSameMonth(date, scope)
    case 'years':
      return isSameYear(date, scope)
  }
}

export const isDisabledDate = (
  date: Date,
  disabledFrom?: Date,
  disabledTo?: Date,
  view?: ICalendar['view'],
) => {
  const viewIsTime = view === 'time'

  if (disabledFrom && disabledTo)
    if (viewIsTime) {
      return (
        (isAfter(date, disabledFrom) &&
          !isSameDay(date, disabledFrom) &&
          isBefore(date, disabledTo) &&
          format(disabledTo, 'HH:mm:ss') !== '23:59:59' &&
          !isSameDay(date, disabledTo)) ||
        (format(disabledFrom, 'HH:mm:ss') === '00:00:00' &&
          isSameDay(date, disabledFrom))
      )
    } else {
      return (
        (isAfter(date, disabledFrom) && isBefore(date, disabledTo)) ||
        isSameDay(date, disabledFrom) ||
        isSameDay(date, disabledTo)
      )
    }

  if (disabledFrom) {
    if (viewIsTime) {
      return isAfter(date, disabledFrom) && !isSameDay(date, disabledFrom)
    } else {
      return isAfter(date, disabledFrom) || isSameDay(date, disabledFrom)
    }
  }

  if (disabledTo) {
    if (viewIsTime) {
      return !isSameDay(date, disabledTo) && isBefore(date, disabledTo)
    } else {
      return isSameDay(date, disabledTo) || isBefore(date, disabledTo)
    }
  }

  return false
}

export const checkScope = (
  date: Date,
  display: ICalendar['display'],
  disabled?: Date | Date[],
  disabledFrom?: Date,
  disabledTo?: Date,
  scope?: Date | Date[],
  view?: ICalendar['view'],
) => {
  const disabledFromToValue =
    disabledFrom || disabledTo
      ? isDisabledDate(date, disabledFrom, disabledTo, view)
      : false

  const disabledValue = disabled ? checkDisabledDates(date, disabled) : false

  if (Array.isArray(scope)) {
    const range = scope.sort(compareAsc)

    return {
      selected: checkSelection(date, range, display),
      ranged: checkRange(date, range, display),
      isDisabled: disabledFromToValue || disabledValue,
    }
  }

  return {
    selected: isSame(date, display, scope),
    isDisabled: disabledFromToValue || disabledValue,
  }
}

export const checkHover = (
  date: Date,
  scope: Date[],
  display: ICalendar['display'],
) => {
  const range = scope.sort(compareAsc)

  const isInInterval = isWithinInterval(date, {
    start: range[0],
    end: range[1],
  })

  switch (display) {
    case 'days':
      if (isSameDay(date, range[0])) {
        return 'start'
      } else if (isSameDay(date, range[1])) {
        return 'end'
      } else {
        return isInInterval
      }
    case 'months':
      if (isSameMonth(date, range[0])) {
        return 'start'
      } else if (isSameMonth(date, range[1])) {
        return 'end'
      } else {
        return isInInterval
      }
    case 'years':
      if (isSameYear(date, range[0])) {
        return 'start'
      } else if (isSameYear(date, range[1])) {
        return 'end'
      } else {
        return isInInterval
      }
  }
}

export const getHeaderLabel = (date: Date, display: ICalendar['display']) => {
  const years = getYears(date)

  switch (display) {
    case 'days':
      return format(date, 'LLLL yyyy', {
        locale: pl,
      })
    case 'months':
      return format(date, 'yyyy')
    case 'years':
      return `${format(years[0].date, 'yyyy')} - ${format(
        years[years.length - 1].date,
        'yyyy',
      )}`
  }
}
