/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestAddFavourite } from '../models/RequestAddFavourite'
import type { RequestDeleteFavourite } from '../models/RequestDeleteFavourite'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class FavouriteService {
  /**
   * Dodaje do polubionych
   * @returns any element dodany do polubionych
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static addFavourite({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Dane potrzebne do dodania do polubionych
     */
    requestBody: RequestAddFavourite
  }): CancelablePromise<any | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/shop/{shopId}/favourite',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Usuwa z polubionych
   * @returns any element usunięty z polubionych
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static deleteFavourite({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * Dane potrzebne do usunięcia z polubionych
     */
    requestBody: RequestDeleteFavourite
  }): CancelablePromise<any | Error> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/shop/{shopId}/favourite',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
