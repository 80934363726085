import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface HideBannersByPrefixParams {
  prefixId: string
}

export const hideBannersByPrefix = createAction<HideBannersByPrefixParams>(
  'banner/hideBannersByPrefix',
)

export const hideBannersByPrefixReducer = (
  state: RootState['banner'],
  action: ReturnType<typeof hideBannersByPrefix>,
): void => {
  Object.keys(state).forEach(id => {
    if (id.startsWith(action.payload.prefixId)) {
      delete state[id]
    }
  })
}
