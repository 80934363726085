import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Box, IBox } from '../../Box'
import { Text } from '../../Text'
import { RadioControl } from '../RadioControl'
import { IRadio } from './types'

export const Radio = ({
  value,
  isChecked = false,
  children,
  isError,
  isDisabled,
  name,
  isRequired,
  onChange,
  ...rest
}: IRadio) => {
  const [isHovered, setHover] = useState(false)

  const inputProps = {
    value,
    isChecked,
    isError,
    isDisabled,
    name,
    isRequired,
    hover: isHovered,
    onChange,
  }

  return (
    <StyledCheckbox
      isDisabled={isDisabled}
      forwardedAs={'label' as IBox['forwardedAs']}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      aria-label={rest['aria-label']}
      {...rest}
    >
      {/*  TODO: fix this type */}
      <StyledControl {...(inputProps as any)} forwardedAs="div" />
      <Label>
        <Text pl="xxs" as="div">
          {children}
        </Text>
      </Label>
    </StyledCheckbox>
  )
}

const StyledCheckbox = styled(Box)<IRadio>`
  display: inline-flex;
  align-items: flex-start;
  color: ${p =>
    p.theme.components[p.isDisabled ? 'radioDisabledColor' : 'radioColor']};

  ${p =>
    p.isDisabled &&
    css`
      pointer-events: none;
    `};
`

const StyledControl = styled(RadioControl)`
  ${p => css`
    margin: ${p.theme.space.xxxs / 2}px 0;
  `};
`

const Label = styled.span`
  cursor: pointer;
`
