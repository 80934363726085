import styled, { css } from 'styled-components'
import { setTransition } from '../../../utility'
import { Text } from '../../Text'
import { CircleButtonProps, StyleCircleButtonProps } from './types'

export const getSize = (size: CircleButtonProps['size']) => {
  switch (size) {
    case 'large':
      return 44
    case 'medium':
      return 36
    case 'small':
      return 24
    default: {
      return 44
    }
  }
}

const StyledCircleButton = styled(Text)<StyleCircleButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  ${setTransition(
    ['border-color', 'background-color', 'color', 'box-shadow'],
    'productive',
  )};

  ${p => css`
    width: ${getSize(p.size)}px;
    height: ${getSize(p.size)}px;

    &:focus {
      outline: none;
      ${!p.isMouseDown &&
      css`
        box-shadow: ${p.theme.focuses.normal};
        border-color: transparent;
      `};
    }

    ${p.isDisabled &&
    css`
      opacity: ${p.theme.aliases.disabledOpacity};
      cursor: not-allowed;
    `};
  `};
`

export const Primary = styled(StyledCircleButton)`
  ${p => css`
    background-color: ${p.isSelected
      ? p.theme.components.CircleButtonPrimarySelectedColor
      : p.theme.components.CircleButtonPrimaryColor};

    ${!p.isDisabled &&
    !p.isSelected &&
    css`
      &:hover {
        background-color: ${p.theme.components.CircleButtonPrimaryHoverColor};
      }
      &:active {
        background-color: ${p.theme.components.CircleButtonPrimaryColor};
        opacity: ${p.theme.aliases.defaultActiveOpacity};
      }
    `};
  `}
`

export const Secondary = styled(StyledCircleButton)`
  ${p => css`
    border: ${p.isSelected
      ? p.theme.components.CircleButtonSecondarySelectedBorder
      : p.theme.components.CircleButtonSecondaryBorder};

    background-color: ${p.isSelected
      ? p.theme.components.CircleButtonSecondarySelectedColor
      : p.theme.components.CircleButtonSecondaryColor};

    ${!p.isDisabled &&
    !p.isSelected &&
    css`
      &:hover {
        svg {
          path {
            fill: ${p.theme.colors.grey50};
          }
        }
      }
      &:active {
        opacity: ${p.theme.aliases.defaultActiveOpacity};
      }
    `};
  `}
`
