import styled, { css } from 'styled-components'
import {
  Button as ButtonDS,
  IButton as IButtonDS,
  fontWeights,
} from '@ads/front-ds'

export interface IButton extends IButtonDS {
  fontWeight?: keyof typeof fontWeights
}

export const Button = styled(ButtonDS)<IButton>`
  ${p => css`
    font-weight: ${p.fontWeight
      ? p.theme.fontWeights[p.fontWeight]
      : p.theme.fontWeights.regular};
  `}
`
