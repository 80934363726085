import React, { PropsWithChildren } from 'react'
import {
  DefaultTheme,
  ThemeProvider,
  createGlobalStyle,
} from 'styled-components'

import { extractTokens, createThemeVars } from './themeTokens'
import { theme as defaultTheme } from '../theme'
import { Theme } from '../theme/interfaces'
import {
  ToastProvider,
  ToastProviderProps,
} from '../components/Announcements/Toast'

export interface N2crProviderProps {
  /**
   * Your application content
   */

  theme?: Theme
  toastOptions?: ToastProviderProps
}

export interface IGlobalStyle {
  styleCss?: string
}

const GlobalStyle = createGlobalStyle<IGlobalStyle>`
  html {
    ${props => props && props.styleCss}
  }
`
export const N2crDsProvider = (props: PropsWithChildren<N2crProviderProps>) => {
  const { children, theme = defaultTheme, toastOptions } = props

  const tokens = extractTokens(theme)
  const { cssVars } = createThemeVars(tokens)

  const styleCss = Object.entries(cssVars)
    .map(([key, value]) => {
      return `${key}: ${value};`
    })
    .join('')

  return (
    <ThemeProvider theme={theme as DefaultTheme}>
      <GlobalStyle styleCss={styleCss} />
      {children}
      <ToastProvider {...toastOptions} />
    </ThemeProvider>
  )
}
