import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Components } from '../../theme/interfaces'
import { Flex } from '../Flex'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { IBadge, IContainer } from './types'

const getVariantColors = (
  variant: IBadge['variant'],
  appearance: IBadge['appearance'],
  onClick: boolean,
) => {
  const variantKey =
    variant && variant.charAt(0).toUpperCase() + variant.slice(1)
  const strongContrastColor = `badge${variantKey}StrongContrastColor` as keyof Components
  const strongHoverContrastColor = `badgeHover${variantKey}StrongContrastColor` as keyof Components
  const subtleHoverContrastColor = `badgeHover${variantKey}SubtleContrastColor` as keyof Components
  const lowContrast = `badge${variantKey}LowContrastColor` as keyof Components

  switch (appearance) {
    case 'solid':
      return css`
        ${({ theme }) => css`
          background-color: ${theme.components[strongContrastColor]};
          color: ${theme.components.badgeInvertedColor};
          ${onClick &&
          `
          &:hover {
            background-color: ${theme.components[strongHoverContrastColor]};
          }
        `}
        `}
      `
    case 'inverted':
      return css`
        ${({ theme }) => css`
          background-color: ${theme.components.badgeInvertedColor};
          color: ${theme.components[strongContrastColor]};

          ${onClick &&
          `&:hover {
              color: ${theme.components[strongHoverContrastColor]};
            }
            `}
        `}
      `
    default:
      return css`
        ${({ theme }) => css`
          background-color: ${theme.components[lowContrast]};
          color: ${theme.components.badgeSubtleColor};
          ${
            onClick &&
            `&:hover {
            background-color: ${theme.components[subtleHoverContrastColor]};
            `
          }
          }
        `}
      `
  }
}

const getIconColor = (
  variant: IBadge['variant'],
  appearance: IBadge['appearance'],
  isMouseHover: IContainer['isMouseHover'],
  hasOnClick: boolean,
) => {
  if (appearance !== 'subtle') return undefined

  switch (variant) {
    case 'default':
      if (hasOnClick && isMouseHover) {
        return 'grey100'
      } else {
        return 'grey50'
      }
    case 'neutral':
      if (hasOnClick && isMouseHover) {
        return 'primary100'
      } else {
        return 'primary50'
      }
    case 'success':
      if (hasOnClick && isMouseHover) {
        return 'success100'
      } else {
        return 'success50'
      }
    case 'warning':
      if (hasOnClick && isMouseHover) {
        return 'warning100'
      } else {
        return 'warning50'
      }
    case 'alert':
      if (hasOnClick && isMouseHover) {
        return 'alert100'
      } else {
        return 'alert50'
      }
    default:
      return 'grey100'
  }
}

export const Badge = React.memo(
  ({
    label,
    iconBefore,
    variant = 'default',
    appearance = 'subtle',
    onClick,
    ...rest
  }: IBadge) => {
    if (process.env.NODE_ENV !== 'production' && !label && !iconBefore)
      console.warn('You should set iconBefore or label.')

    if (variant === 'transparent' && appearance !== 'solid')
      throw new Error('Transparent variant should have solid appearance')

    const [isMouseHover, setIsMouseHover] = useState(false)

    return (
      <Container
        isIconBefore={!!iconBefore}
        isLabel={!!label}
        borderRadius="rounded"
        alignItems="center"
        justifyContent="center"
        variant={variant}
        appearance={appearance}
        onClick={onClick}
        forwardedAs={onClick ? 'button' : 'div'}
        onMouseEnter={() => setIsMouseHover(true)}
        onMouseLeave={() => setIsMouseHover(false)}
        {...rest}
      >
        {iconBefore && (
          <Icon
            name={iconBefore}
            color={getIconColor(variant, appearance, isMouseHover, !!onClick)}
            mr={label && 'xxxs'}
          />
        )}
        <Text
          fontSize="m"
          lineHeight="m"
          textAlign="center"
          aria-label={rest['aria-label']}
        >
          {label}
        </Text>
      </Container>
    )
  },
)

Badge.displayName = 'Badge'

const Container = styled(Flex)<IContainer>`
  display: inline-flex;
  border: none;
  padding: 0;
  font-family: inherit;
  letter-spacing: 0.2px;
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s;

  ${p => getVariantColors(p.variant, p.appearance, !!p.onClick)};

  ${p => css`
    padding-left: ${p.isIconBefore
      ? p.theme.space.xxxs
      : p.theme.space.xs / 2}px;
    padding-right: ${p.isLabel
      ? p.theme.space.xs / 2
      : p.theme.space.xxxs / 2}px;

    ${!p.isLabel &&
    p.isIconBefore &&
    css`
      padding: ${p.theme.space.xxxs / 2}px;
    `}
    ${p.onClick &&
    css`
      cursor: pointer;

      &:focus {
        opacity: 0.7;

        &:active {
          opacity: 0.5;
        }
      }
    `}
  `};
`
