import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import { lockSidebar, lockSidebarReducer } from './lockSidebarReducer'
import { toggleSidebar, toggleSidebarReducer } from './toggleSidebarReducer'

const sidebarReducers = createReducer(preloadedRootState.sidebar, builder => {
  builder.addCase(lockSidebar.type, lockSidebarReducer)
  builder.addCase(toggleSidebar.type, toggleSidebarReducer)
})

export default sidebarReducers
