/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestAcceptAgreements } from '../models/RequestAcceptAgreements'
import type { RequestAcceptClientAgreements } from '../models/RequestAcceptClientAgreements'
import type { RequestCreateUser } from '../models/RequestCreateUser'
import type { RequestDeleteUser } from '../models/RequestDeleteUser'
import type { RequestUpdateUser } from '../models/RequestUpdateUser'
import type { ResponseShop } from '../models/ResponseShop'
import type { ResponseUser } from '../models/ResponseUser'
import type { ResponseUserInfo } from '../models/ResponseUserInfo'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class UserService {
  /**
   * Zwraca informacje o zalogowanym użytkowniku
   *
   * @returns ResponseUserInfo informacje powiązane z uwierzytelnionym użytkownikiem zarówno jako adminie, jak i userze
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static userInfo(): CancelablePromise<ResponseUserInfo | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/userInfo',
    })
  }

  /**
   * Zwraca listę użytkowników mających uprawnienia do pracy z klientem na podstawie parameru i uprawnień z authTokena. Działa wyłącznie dla użytkownika o roli admin
   *
   * @returns ResponseUser podstawowe dane użytkowników (id, email, rola)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getUsers({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<Array<ResponseUser> | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user',
      query: {
        clientId: clientId,
      },
    })
  }

  /**
   * Tworzy nowego, lub przypisuje istniejącego usera do klienta
   * @returns ResponseShop dane zapisane
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static addUser({
    requestBody,
  }: {
    /**
     * Dane użytkownika
     */
    requestBody: RequestCreateUser
  }): CancelablePromise<ResponseShop | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Kasuje użytkownika (a raczej usuwa przypisanie usera do klienta)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static deleteUser({
    requestBody,
  }: {
    /**
     * Dane użytkownika
     */
    requestBody: RequestDeleteUser
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/user',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Modyfikuje dane użytkownika. W praktyce można zmienić wyłącznie rolę
   * @returns ResponseShop dane zapisane
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static modifyUser({
    requestBody,
  }: {
    /**
     * Dane użytkownika
     */
    requestBody: RequestUpdateUser
  }): CancelablePromise<ResponseShop | Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/user',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zapsiuje akceptacje regulaminu
   * @returns any Akceptacja regulaminu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static acceptAgreements({
    requestBody,
  }: {
    requestBody?: RequestAcceptAgreements
  }): CancelablePromise<any | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/user/accept-agreements',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zapsiuje akceptacje regulaminów dla klienta
   * @returns any Akceptacja regulaminu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static acceptClientAgreements({
    clientId,
    requestBody,
  }: {
    clientId: number
    requestBody?: RequestAcceptClientAgreements
  }): CancelablePromise<any | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/client/{clientId}/accept-agreements',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
