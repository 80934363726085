import React, { FC, useEffect } from 'react'
import { Dialog as DialogDS, IDialog as IDialogDS } from '@ads/front-ds'
import { IDialog } from './interfaces'

export { Key } from 'ts-key-enum'

const Dialog: FC<IDialog> = ({ actions, isShown, ...restDialog }) => {
  const useEffectKey = actions?.map(a => a.keyAction)?.join(',')

  useEffect(() => {
    const handleOnKey = (e?: KeyboardEvent) => {
      actions?.map(action => {
        if (isShown && action.keyAction === e.key && !action.isDisabled) {
          action.onClick()
          e.stopPropagation()
        }
      })
    }

    if (isShown) {
      window.addEventListener('keydown', handleOnKey)
    }

    return () => {
      window.removeEventListener('keydown', handleOnKey)
    }
  }, [useEffectKey, isShown])

  if (!isShown) {
    return null
  }

  return (
    <DialogDS
      actions={actions as IDialogDS['actions']}
      isShown={isShown}
      {...restDialog}
    />
  )
}

export default Dialog
