import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { checkIntent } from '../../../utility'
import { Box } from '../../Box'
import { Icon } from '../../Icon'
import { Text } from '../../Text'
import { Tick } from './MenuItem'
import { ILabel, IMenuIcon, IMenuItemContainer } from './types'

export const MenuIcon: FC<React.PropsWithChildren<IMenuIcon>> = ({
  label,
  icon,
  caption,
  isDisabled,
  customIconColor,
  isSelected,
  intent,
  ...rest
}) => {
  return (
    <Container tabIndex={0} {...rest}>
      {checkIntent('selectable', intent) && (
        <Tick
          name="20-check"
          isDisabled={isDisabled}
          isVisible={isSelected}
          mr="xxs"
        />
      )}
      <Glyph
        name={icon}
        mr="xxs"
        isDisabled={isDisabled}
        customIconColor={customIconColor}
      />
      <Label fontSize="m" lineHeight="m" isDisabled={isDisabled}>
        {label}
      </Label>
      {caption && (
        <Caption color="grey50" fontSize="m" lineHeight="m" pl="xxs">
          {caption}
        </Caption>
      )}
    </Container>
  )
}

const Container = styled(Box)<IMenuItemContainer>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${p => css`
    padding: ${p.theme.space.xs / 2}px ${p.theme.space.xxs}px;
    pointer-events: ${p.isDisabled && 'none'};
    border-radius: ${p.theme.radii.rounded};

    &:hover {
      background-color: ${p.theme.components.menuChildHoverBackgroundColor};
    }

    &:focus {
      background-color: ${p.theme.components.menuChildFocusBackgroundColor};
      outline: none;
    }
  `};
`

const Glyph = styled(Icon)<ILabel>`
  ${p => css`
    color: ${p.customIconColor
      ? p.customIconColor
      : p.theme.components[
          p.isDisabled ? 'menuChildDisabledColor' : 'menuIconGlyphColor'
        ]};
  `}
`

const Label = styled(Text)<ILabel>`
  ${p => css`
    padding: ${p.theme.space.xxxs / 2}px 0;
    color: ${p.theme.components[
      p.isDisabled ? 'menuChildDisabledColor' : 'menuChildColor'
    ]};
  `};
`

const Caption = styled(Text)`
  margin-left: auto;
`
