import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { Text } from '../Text'
import { TableHeadCellProps, TableProps } from './types'

export const Table = ({ children }: TableProps) => {
  return <StyledTable>{children}</StyledTable>
}

export const StyledTable = styled.table<TableProps>`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  ${p => css`
    border: ${p.theme.borders.thinGrey20};
    border-radius: ${p.theme.radii.rounded};
  `};
`

export const TableBody = styled('tbody')``

export const TableHead = styled('thead')`
  ${p => css`
    border-bottom: ${p.theme.borders.thinGrey20};
  `}
`

export const TableHeadRow = styled.tr<{ enableHover?: boolean }>`
  ${p => css`
    background: ${p.theme.colors.grey0};
  `}
`

export const TableHeadCell = React.memo(({ children }: TableHeadCellProps) => (
  <TableCell>
    <Text>{children}</Text>
  </TableCell>
))

interface TableCellProps extends HTMLAttributes<HTMLTableCellElement> {
  textAlign?: 'left' | 'center' | 'right'
  verticalAlign?: 'top' | 'middle' | 'bottom'
}

export const TableCell = styled.td<TableCellProps>`
  ${p => css`
    font-size: ${p.theme.fontSizes.s};
    text-align: ${p.textAlign || 'left'};
    vertical-align: ${p.verticalAlign || 'middle'};
    padding: ${p.theme.space.s}px;
    border-bottom: 1px solid ${p.theme.colors.grey20};
    line-height: ${p.theme.lineHeights.m};
    color: ${p.theme.colors.grey100};
    position: relative;

    &:first-child {
      font-weight: ${p.theme.fontWeights.medium};
    }
  `}
`

export const TableRow = styled.tr<{ disableHover?: boolean }>`
  ${p => css`
    ${!p.disableHover &&
    css`
      &:hover:not(:focus),
      &:focus-within {
        background-color: ${p.theme.components.menuChildHoverBackgroundColor};
      }

      &:focus {
        background-color: ${p.theme.components.menuChildFocusBackgroundColor};
        outline: none;
      }
    `}
  `}
`
