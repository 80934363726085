import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { ClientService, ResponseBalance } from '@ads/front-core/api'
import { ClientBalance } from '@ads/front-core/interfaces'
import { getIsDemo } from '@ads/front-core/store/selectors/getIsDemo'
import { setClientBalanceLoading } from 'store/reducers/client/setClientBalanceLoadingReducer'
import { setClientBalance } from 'store/reducers/client/setClientBalanceReducer'

interface FetchBalanceProps {
  clientId: number
}

type FetchBalanceSaga = ReturnType<
  PayloadActionCreator<FetchBalanceProps, string>
>

export function* sagaFetchBalance(): Generator {
  yield takeEvery(fetchBalance.type, fetchBalanceSaga)
}

export const fetchBalance = createAction<FetchBalanceProps>('saga/fetchBalance')

export function* fetchBalanceSaga({ payload }: FetchBalanceSaga): Generator {
  const { clientId } = payload

  if (!clientId) {
    return
  }

  const isDemo = yield select(getIsDemo)

  if (isDemo) {
    yield put(
      setClientBalance({
        balance: 9970,
        blockedFunds: 200,
        monthLimit: 5000,
        monthlyBalance: 6000,
        isLoading: false,
      }),
    )

    return
  }

  yield put(setClientBalanceLoading(true))

  try {
    const res = yield call(ClientService.getClientBalance, {
      clientId,
    })

    const responseBalance = res as ResponseBalance

    const clientBalance: ClientBalance = {
      blockedFunds: responseBalance?.blockedFunds || 0,
      monthLimit: responseBalance?.monthLimit || 0,
      monthlyBalance: responseBalance?.monthlyBalance || 0,
      balance: responseBalance?.balance || 0,
      isLoading: false,
    }

    yield put(setClientBalance(clientBalance))
  } catch (error) {
    //
  }

  yield put(setClientBalanceLoading(false))
}
