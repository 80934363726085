/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { ResponseWorkflowLabel } from '../models/ResponseWorkflowLabel'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class WorkflowLabelService {
  /**
   * Zwraca listę etykiet
   * @returns ResponseWorkflowLabel lista etykiet
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getWorkflowLabelsList(): CancelablePromise<
    Array<ResponseWorkflowLabel> | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/creation/label',
    })
  }
}
