import { ZIndex } from './interfaces'

export const zIndex: ZIndex = {
  hide: -1,
  auto: 'auto',
  base: 0,
  dropdown: 100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  toast: 1700,
  tooltip: 1800,
}
