import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import { markFirstVisit, markFirstVisitReducer } from './markFirstVisitReducer'
import { setUser, setUserReducer } from './setUserReducer'
import { setUserStatus, setUserStatusReducer } from './setUserStatusReducer'

const userReducers = createReducer(preloadedRootState.user, builder => {
  builder.addCase(setUser.type, setUserReducer)
  builder.addCase(setUserStatus.type, setUserStatusReducer)
  builder.addCase(markFirstVisit.type, markFirstVisitReducer)
})

export default userReducers
