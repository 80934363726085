import React, { useState, MouseEvent } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../Text'
import { Flex } from '../Flex'
import { IconButton } from '../Buttons'
import { Avatar } from '../Avatars'
import { Icon, IIcon } from '../Icon'
import { IPill, IContainer, IPrefix } from './types'

import { setTransition, checkIntent } from '../../utility'
import { ILabel } from '../Menu/children/types'
import { StatusDot } from '../StatusDot'
import { convertLabel } from '../Icon/Icon'

export const Pill = ({
  as = 'button',
  label,
  isDisabled,
  intent,
  prefix,
  icon,
  size,
  status,
  backgroundColor,
  src,
  avatarLabel,
  ...rest
}: IPill) => {
  const [mouseDown, setMouseDown] = useState(false)

  const getIcon = (icon: IPill['icon'], isDisabled?: boolean) => {
    if (
      typeof icon === 'object' &&
      Object.hasOwnProperty.call(icon, 'onClick')
    ) {
      const i: IPill['icon'] = icon

      const handleOnClick = (e?: MouseEvent) => {
        if (!e) return
        e.stopPropagation()
        if (i.onClick) i.onClick()
      }

      return (
        <IconButton
          aria-label={convertLabel(i.name as string)}
          variant="bareDark"
          name={i.name}
          isDisabled={isDisabled}
          onClick={handleOnClick}
          ml="xxs"
        />
      )
    }

    return <Icon name={icon as IIcon['name']} ml="xxs" />
  }

  const smallSize = checkIntent('small', size)

  if (process.env.NODE_ENV !== 'production' && status && src)
    console.warn("You shouldn't set status and src at the same time.")

  return (
    <Container
      forwardedAs={as}
      isSmallSize={smallSize}
      isAvatar={!!src}
      isPrefix={!!prefix}
      borderRadius="rounded"
      alignItems="center"
      justifyContent="center"
      isSelected={checkIntent('selected', intent)}
      isDisabled={isDisabled}
      isMouseDown={mouseDown}
      onMouseDown={() => setMouseDown(true)}
      onBlur={() => setMouseDown(false)}
      {...rest}
    >
      {prefix && (
        <Prefix
          fontSize="m"
          lineHeight="m"
          isDisabled={isDisabled}
          mr={status || src ? '' : 'xxxs'}
        >
          {prefix}:
        </Prefix>
      )}

      {status && (
        <StatusContainer mr="xxs" ml={prefix ? 'xxs' : ''}>
          <StatusDot {...status} />
        </StatusContainer>
      )}

      {src && (
        <StyledAvatar
          name={avatarLabel as string}
          size={smallSize ? 'xs' : 's'}
          ml={smallSize && !prefix ? 'xxxs' : 'xxs'}
          mr="xxs"
          isDisabled={isDisabled}
          backgroundColor={backgroundColor}
          src={src}
        />
      )}

      <Text fontSize="m" lineHeight="m" textAlign="center" fontWeight="regular">
        {label}
      </Text>
      {icon && getIcon(icon, isDisabled)}
    </Container>
  )
}

const Container = styled(Flex)<IContainer>`
  display: inline-flex;
  align-items: center;
  ${setTransition(
    ['background-color', 'border-color', 'color', 'box-shadow'],
    'productive',
  )};
  font-family: inherit;
  border: 0;

  ${p => css`
    background-color: ${p.theme.components.pillBackgroundColor};
    border-radius: ${p.theme.components.pillBorderRadius}px;
    color: ${p.theme.components.pillColor};
    cursor: ${p.onClick && 'pointer'};
    padding: 8px 16px;

    ${p.isAvatar &&
    css`
      padding: 6px ${p.theme.space.s}px;
    `};

    ${p.isSmallSize &&
    css`
      padding: ${p.theme.space.xxxs}px ${p.theme.space.xs}px;
    `};

    ${p.isAvatar &&
    !p.isPrefix &&
    css`
      padding-left: 0;
    `};

    &:active {
      opacity: ${p.onClick && p.theme.aliases.defaultActiveOpacity};
    }

    &:focus {
      outline: none;

      ${!p.isMouseDown &&
      css`
        box-shadow: ${p.theme.focuses.normal};
        border-color: transparent;
      `};
    }

    ${p.isSelected &&
    css`
      background-color: ${p.theme.components.pillSelectedBackgroundColor};

      ${p.isDisabled &&
      css`
        background-color: ${p.theme.components
          .pillSelecteDisabledBackgroundColor};
      `};
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.pillDisabledColor};
      pointer-events: none;
    `};
  `};
`

const StatusContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  width: 12px;
  height: 12px;
`

const Prefix = styled(Text)<IPrefix>`
  color: ${p =>
    p.theme.components[p.isDisabled ? 'pillDisabledColor' : 'pillPrefixColor']};
`

const StyledAvatar = styled(Avatar)<ILabel>`
  ${p => css`
    opacity: ${p.isDisabled && 0.55};
    color: ${p.theme.components[
      p.isDisabled ? 'menuChildDisabledColor' : 'menuChildColor'
    ]};
  `};
`
