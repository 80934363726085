import React, { FC } from 'react'
import styled from 'styled-components'

import { Box } from '../Box'
import { SegmentedControl } from './SegmentedControl'
import { ISegmentedControls } from './types'

const getPosition = (index: number, length: number) => {
  switch (index) {
    case 0:
      return 'first'
    case length - 1:
      return 'last'
    default:
      return undefined
  }
}

const checkSelected = (id: string, array?: string | string[]) => {
  if (!array) return false

  if (typeof array === 'string') return id === array

  return array.includes(id)
}

export const SegmentedControls: FC<
  React.PropsWithChildren<ISegmentedControls>
> = ({ controls, selected, isError, isDisabled, ...rest }) => (
  <Container {...rest}>
    {controls.map((control, index) => (
      <Item key={control.id}>
        <SegmentedControl
          {...control}
          position={getPosition(index, controls.length)}
          isActive={checkSelected(control.id, selected)}
          isError={
            typeof isError === 'boolean'
              ? isError
              : checkSelected(control.id, isError)
          }
          isDisabled={
            typeof isDisabled === 'boolean'
              ? isDisabled
              : checkSelected(control.id, isDisabled)
          }
        >
          {control.label}
        </SegmentedControl>
      </Item>
    ))}
  </Container>
)

const Container = styled(Box)`
  display: flex;
`

const Item = styled.div`
  flex: 1 1 auto;

  & + & {
    margin-left: -1px;
  }
`
