import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import { hideToast, hideToastReducer } from './hideToastReducer'
import { showToast, showToastReducer } from './showToastReducer'

const toastReducers = createReducer(preloadedRootState.toast, builder => {
  builder.addCase(showToast.type, showToastReducer)
  builder.addCase(hideToast.type, hideToastReducer)
})

export default toastReducers
