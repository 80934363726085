import React, { useState } from 'react'
import styled from 'styled-components'

import { Icon } from '../../Icon/Icon'
import {
  BareDark,
  BareDestructive,
  BareInverted,
  Dark,
  Destructive,
  Inverted,
} from './variants'

import { Loader } from '../Loader'
import { IIconButton } from './types'

// change above to object
const variantComponentByVariant: Record<
  NonNullable<IIconButton['variant']>,
  any
> = {
  inverted: Inverted,
  destructive: Destructive,
  bareDark: BareDark,
  bareInverted: BareInverted,
  bareDestructive: BareDestructive,
  dark: Dark,
}

export const IconButton = React.memo(
  ({
    name,
    variant = 'dark',
    as = 'button',
    isLoading,
    ...rest
  }: IIconButton) => {
    const StyledIconButton = React.useMemo(() => {
      return (variant && variantComponentByVariant[variant]) || Dark
    }, [variant])
    const [isMouseDown, setIsMouseDown] = useState(false)

    if (
      (name as string).includes('16-') &&
      variant &&
      !variant.includes('bare')
    )
      throw new Error(
        `You set ${name} as name, but only bare variants ('bareDark' | 'bareInverted' | 'bareDestructive') of IconButton could use 16px-size icons.`,
      )

    const onMouseDown = React.useCallback(() => setIsMouseDown(true), [])
    const onBlur = React.useCallback(() => setIsMouseDown(false), [])

    return (
      <StyledIconButton
        fontSize="m"
        lineHeight="m"
        fontWeight="medium"
        p="xxs"
        forwardedAs={as ? as : 'button'}
        isMouseDown={isMouseDown}
        onMouseDown={onMouseDown}
        onBlur={onBlur}
        isLoading={isLoading}
        {...rest}
      >
        <StyledIcon name={name} aria-hidden="true" />
        {isLoading && <Loader variant="primary" />}
      </StyledIconButton>
    )
  },
)

IconButton.displayName = 'IconButton'

const StyledIcon = styled(Icon)`
  position: relative;
`
