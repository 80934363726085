import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Toggle } from '../Toggle'
import { Flex } from '../../Flex'
import { Text } from '../../Text'
import { Icon } from '../../Icon'

import { adjustPaddings } from '../../../utility'
import { IToggleBox, IFooter } from './types'

export const ToggleBox: FC<React.PropsWithChildren<IToggleBox>> = ({
  caption,
  warning,
  error,
  isChecked,
  value,
  isDisabled,
  id,
  name,
  children,
  onChange,
  onFocus,
  onBlur,
  ...rest
}) => {
  const inputProps = { value, isDisabled, id, name, onFocus, onBlur }

  const footerContent = () => {
    switch (true) {
      case !!error && !isDisabled:
        return (
          <>
            <StyledIcon name="16-alert-solid" mr="xxxs" />
            <Text fontSize="s" lineHeight="s">
              {error}
            </Text>
          </>
        )
      case !!warning && !isDisabled:
        return (
          <>
            <StyledIcon name="16-warning-solid" mr="xxxs" />
            <Text fontSize="s" lineHeight="s">
              {warning}
            </Text>
          </>
        )
      case !!caption:
        return (
          <Text fontSize="s" lineHeight="s">
            {caption}
          </Text>
        )
      default:
        return false
    }
  }

  return (
    <StyledBox
      forwardedAs="label"
      borderRadius="rounded"
      flexDirection="column"
      error={error}
      warning={warning}
      isDisabled={isDisabled}
      {...rest}
    >
      <Toggle
        onChange={onChange}
        onClick={e => e?.stopPropagation()}
        isChecked={isChecked}
        intent="fitted"
        forwardedAs="div"
        {...inputProps}
      >
        {children}
      </Toggle>

      {(!!error || !!warning || !!caption) && (
        <Footer
          error={error}
          warning={warning}
          isDisabled={isDisabled}
          mt="xxs"
        >
          {footerContent()}
        </Footer>
      )}
    </StyledBox>
  )
}

const StyledBox = styled(Flex)<IToggleBox>`
  ${adjustPaddings(['s'])}
  cursor: pointer;

  ${p => css`
    border: ${p.theme.components.toggleBoxBorder};
    transition: border-color ${p.theme.motion.productive};
    pointer-events: ${p.isDisabled && 'none'};

    &:hover {
      border-color: ${p.theme.components.toggleBoxHoverBorderColor};
    }

    ${p.error &&
    css`
      border-color: ${p.theme.components.toggleBoxErrorBorderColor};

      &:hover {
        border-color: ${p.theme.components.toggleBoxHoverErrorBorderColor};
      }
    `};

    ${p.warning &&
    css`
      border-color: ${p.theme.components.toggleBoxWarningBorderColor};

      &:hover {
        border-color: ${p.theme.components.toggleBoxHoverWarningBorderColor};
      }
    `};
  `}
`

const Footer = styled(Flex)<IFooter>`
  ${p => css`
    padding-right: ${p.theme.space.l}px;
    color: ${p.theme.components[
      p.isDisabled ? 'toggleBoxDisabledCaptionColor' : 'toggleBoxCaptionColor'
    ]};

    ${p.error &&
    css`
      color: ${p.theme.components.toggleBoxErrorColor};
    `};

    ${p.warning &&
    css`
      color: ${p.theme.components.toggleBoxWarningColor};
    `};
  `};
`

const StyledIcon = styled(Icon)`
  ${p => css`
    flex: 0 0 ${p.theme.space.s}px;
  `};
`
