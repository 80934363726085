import React, { ReactNode, useEffect } from 'react'

import styled, { css } from 'styled-components'
import { Box } from '../Box'
import { useModalContext } from './'

interface ModalBodyProps {
  /** Use to place modal main content. */
  children?: ReactNode
}

export const ModalBody = (props: ModalBodyProps) => {
  const { children } = props
  const { setBodyMounted } = useModalContext()

  useEffect(() => {
    setBodyMounted(true)
    return () => setBodyMounted(false)
  }, [setBodyMounted])

  return <ModalBodyStyled mt="m">{children}</ModalBodyStyled>
}

const ModalBodyStyled = styled(Box)`
  ${p => css`
    line-height: ${p.theme.lineHeights.l};
    font-size: ${p.theme.fontSizes.l}px;
  `}
`
