import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { delay, put, takeEvery } from 'redux-saga/effects'
import { setCurrentClientId } from '../reducers/client/setCurrentClientIdReducer'
import { setCurrentShopId } from '../reducers/client/setCurrentShopIdReducer'
import { fetchBalance } from './fetchBalanceSaga'
import { fetchClientData } from './fetchClientDataSaga'
import { fetchFeedsData } from './fetchFeedsDataSaga'
import { fetchShopData } from './fetchShopDataSaga'
import { fetchClientShopsData } from './fetchClientShopsDataSaga'

interface SetCurrentShopProps {
  clientId: number
  shopId: number
}

export type SetCurrentShopSaga = ReturnType<
  PayloadActionCreator<SetCurrentShopProps, string>
>

export function* sagaSetCurrentShop(): Generator {
  yield takeEvery(setCurrentShop.type, setCurrentShopSaga)
}

export const setCurrentShop = createAction<SetCurrentShopProps>(
  'saga/setCurrentShop',
)

export function* setCurrentShopSaga({
  payload,
}: SetCurrentShopSaga): Generator {
  const { clientId, shopId } = payload

  yield delay(100)
  yield put(fetchClientShopsData({ clientId }))
  yield put(setCurrentShopId(shopId))
  yield put(setCurrentClientId(clientId))
  yield put(fetchShopData({ shopId }))
  yield put(fetchFeedsData({ shopId }))
  yield put(fetchClientData({ clientId }))
  yield put(fetchBalance({ clientId }))
}
