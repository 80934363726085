import React from 'react'
import styled, { css } from 'styled-components'

import { Flex } from '../../Flex'
import { Text, IText } from '../../Text'
import { IAvatar, IAvatarContainer } from './types'
import { Components, FontSizes, FontWeights } from '../../../theme/interfaces'

type GetSize = (
  size: IAvatar['size'],
) => {
  dimensions: keyof Components
  fontSize: keyof FontSizes
  fontWeight: keyof FontWeights
}

export const getSize: GetSize = size => {
  switch (size) {
    case 'xs':
      return {
        dimensions: 'avatarSizeExtraSmall',
        fontSize: 'xs',
        fontWeight: 'bold',
      }
    case 's':
      return {
        dimensions: 'avatarSizeSmall',
        fontSize: 'xs',
        fontWeight: 'bold',
      }
    case 'm':
      return {
        dimensions: 'avatarSizeMedium',
        fontSize: 's',
        fontWeight: 'medium',
      }
    case 'l':
      return {
        dimensions: 'avatarSizeLarge',
        fontSize: 'm',
        fontWeight: 'medium',
      }
    case 'xl':
      return {
        dimensions: 'avatarSizeExtraLarge',
        fontSize: 'xl',
        fontWeight: 'bold',
      }
    default: {
      return {
        dimensions: 'avatarSizeExtraLarge',
        fontSize: 'xl',
        fontWeight: 'bold',
      }
    }
  }
}

const getInitials = (name: string) => {
  const nameArray = name.split(' ')

  if (nameArray.length > 1) return nameArray[0][0] + nameArray[1][0]

  return nameArray[0][0]
}

const getBackgroundColor = (name: string) => {
  if (!name) return 'cornflowerBlue'

  const avatarColors = [
    'cornflowerBlue',
    'monteCarlo',
    'amaranth',
    'orchid',
    'porsche',
    'amethyst',
  ]
  let hash = 0

  for (const i in name.split('')) {
    hash = name.toLowerCase().charCodeAt(+i) + hash
  }

  return avatarColors[
    Math.abs(hash % avatarColors.length)
  ] as IAvatar['backgroundColor']
}

export const Avatar = ({
  name,
  backgroundColor,
  size = 'xl',
  src,
  ...rest
}: IAvatar) => {
  const initial = getInitials(name)
  const bgColor = !backgroundColor ? getBackgroundColor(name) : backgroundColor

  return (
    <StyledAvatar
      backgroundColor={bgColor}
      size={size}
      alignItems="center"
      justifyContent="center"
      aria-label={!src ? name : undefined}
      {...rest}
    >
      <Initials
        fontSize={getSize(size).fontSize as IText['fontSize']}
        fontWeight={getSize(size).fontWeight as IText['fontWeight']}
      >
        {initial}
      </Initials>
      {src && <Photo src={src} alt={name} />}
    </StyledAvatar>
  )
}

const StyledAvatar = styled(Flex)<IAvatarContainer>`
  position: relative;
  overflow: hidden;
  border: 0;
  border-radius: 50%;

  ${p => css`
    width: ${p.theme.components[getSize(p.size).dimensions]}px;
    height: ${p.theme.components[getSize(p.size).dimensions]}px;
    flex: 0 0 ${p.theme.components[getSize(p.size).dimensions]}px;
    box-shadow: ${p.stroke && `0 0 0 2px ${p.theme.colors[p.stroke]}`};
    color: ${p.theme.components.avatarColor};

    ${p.onClick &&
    css`cursor:  pointer;
        &:hover {
        opacity: ${p.theme.aliases.defaultActiveOpacity}; `}
  `};
`

const Initials = styled(Text)`
  line-height: 1em;
  color: ${p => p.theme.components.avatarColor};
`

const Photo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
