import { combineReducers } from 'redux'
import rootReducer from '@ads/front-core/store/rootReducer'
import campaign from './reducers/campaign'
import client from './reducers/client'
import common from './reducers/common'
import draft from './reducers/draft'
import feeds from './reducers/feeds'
import shop from './reducers/shop'
import form from './reducers/form'

const appReducer = combineReducers({
  ...rootReducer,
  client,
  shop,
  feeds,
  campaign,
  common,
  draft,
  form,
})

export default appReducer
