import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setUser = createAction<RootState['user']>('user/setUser')

export const setUserReducer = (
  state: RootState['user'],
  action: ReturnType<typeof setUser>,
): void => {
  const {
    first_name,
    last_name,
    avatar_url,
    email,
    clients,
    status,
    agreements,
    createDate,
    isDemo,
  } = action.payload
  state.first_name = first_name
  state.last_name = last_name
  state.avatar_url = avatar_url
  state.email = email
  state.clients = clients
  state.status = status
  state.agreements = agreements
  state.createDate = createDate
  state.isDemo = isDemo
}
