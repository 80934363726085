import React, { forwardRef, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Box } from '../Box'
import { useRect } from '@reach/rect'
import { ICascader, IContainer, ICascaderMenuContainer } from './types'
import { setTransition } from '../../utility'
import { Portal } from '../Portal/Portal'

export const Cascader = forwardRef<HTMLDivElement, ICascader>((props, ref) => {
  const {
    pill,
    children,
    container,
    direction = 'down',
    zIndex,
    zIndexMenu,
    isBlockOnOpen,
    isOpen,
    ...rest
  } = props

  const containerRef = useRef(null)

  const containerRect = useRect(containerRef)

  return (
    <Container
      direction={direction}
      zIndex={zIndex}
      ref={containerRef}
      {...rest}
    >
      {pill}
      {isOpen && (
        <Portal isBlockOnOpen={isBlockOnOpen} container={container}>
          {containerRect && (
            <CascaderMenu
              direction={direction}
              ref={ref}
              zIndexMenu={zIndexMenu}
              containerBottom={containerRect.bottom}
              containerTop={containerRect.top}
              containerLeft={containerRect.left}
              containerRight={containerRect.right}
              containerWidth={containerRect.width}
            >
              {children}
            </CascaderMenu>
          )}
        </Portal>
      )}
    </Container>
  )
})

const getOriginY = (direction: ICascader['direction']) =>
  direction === 'down' ? 'top' : 'bottom'

const Container = styled(Box)<IContainer>`
  position: relative;
  &:focus {
    outline: none;
  }
`

const CascaderMenu = styled(Box)<ICascaderMenuContainer>`
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  position: fixed;
  z-index: 100;

  ${p => css`
    ${p.direction === 'up'
      ? `top: ${(p.containerTop as number) - p.theme.space.xxs}px;
        transform: translateY(-100%);`
      : `top: ${(p.containerBottom as number) + p.theme.space.xxs}px;`}

    transform-origin: ${getOriginY(p.direction)};
    z-index: ${p.zIndexMenu};

    left: ${p.containerLeft}px;

    &.fade-enter {
      opacity: 0;
      transform: scale(0.9)
        ${p.direction === 'up'
          ? 'translateY(-100%)'
          : `translateY(-${p.theme.space.xxxs}px)`};
    }

    &.fade-enter-active {
      opacity: 1;
      transform: scale(1)
        ${p.direction === 'up' ? 'translateY(-100%)' : 'translateY(0)'}
        ${setTransition(['opacity', 'transform'], 'productive')};
    }

    &.fade-exit {
      opacity: 1;
      transform: scale(1)
        ${p.direction === 'up' ? 'translateY(-100%)' : 'translateY(0)'};
    }

    &.fade-exit-active {
      opacity: 0;
      transform: scale(0.9)
        ${p.direction === 'up'
          ? 'translateY(-100%)'
          : `translateY(-${p.theme.space.xxxs}px)`}
        ${setTransition(['opacity', 'transform'], 'productive')};
    }
  `};
`
