import React, {
  FocusEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import { TextButton } from '../Buttons/TextButton'
import { Container, getPrefix, Input, Prefix } from '../TextInput/TextInput'
import { IActionInput, IButtonHolder } from './types'

export const ActionInput = React.memo(
  forwardRef((props: IActionInput, ref: React.Ref<HTMLDivElement>) => {
    const prefixRef = useRef<HTMLDivElement>(null)
    const buttonHolder = useRef<HTMLDivElement>(null)
    const [prefixWidth, setPrefixWidth] = useState<number>()
    const [buttonWidth, setButtonWidth] = useState<number>()
    const [isFocused, setFocus] = useState(false)

    const {
      buttonText,
      value,
      defaultValue,
      placeholder,
      type,
      id,
      prefix,
      isDisabled,
      isButtonDisabled,
      isError,
      isReadOnly,
      onChange,
      onActionClick,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      ...rest
    } = props

    const inputProps = {
      ref,
      value,
      defaultValue,
      placeholder,
      type,
      id,
      offsetLeft: prefixWidth,
      offsetRight: buttonWidth,
      disabled: isDisabled,
      isDisabled,
      isError,
      isReadOnly,
      onChange,
      onKeyDown,
      onKeyUp,
      'aria-label': rest['aria-label'],
    }

    useEffect(() => {
      setPrefixWidth(
        prefixRef.current ? prefixRef.current.offsetWidth : undefined,
      )
    }, [prefix])

    useEffect(() => {
      setButtonWidth(
        buttonHolder.current ? buttonHolder.current.offsetWidth : undefined,
      )
    }, [buttonText])

    const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
      setFocus(false)

      if (onBlur) onBlur(e)
    }

    const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
      setFocus(true)

      if (onFocus) onFocus(e)
    }

    return (
      <Container {...rest} isVisible={!!prefixWidth || !!buttonWidth}>
        {(prefix || (prefix && isError && value)) && (
          <Prefix ref={prefixRef} isDisabled={isDisabled}>
            {getPrefix(prefix)}
          </Prefix>
        )}
        <StyledInput
          {...inputProps}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />
        <ButtonHolder ref={buttonHolder} isVisible={!!value || isFocused}>
          <TextButton
            variant="primary"
            isDisabled={isDisabled || isButtonDisabled}
            onClick={onActionClick}
          >
            {buttonText}
          </TextButton>
        </ButtonHolder>
      </Container>
    )
  }),
)

ActionInput.displayName = 'ActionInput'

const StyledInput = styled(Input)`
  ${p => css`
    ${p.offsetLeft &&
    css`
      padding-left: ${p.offsetLeft +
      p.theme.space.m -
      p.theme.space.xxxs -
      1}px;
    `};

    ${p.offsetRight &&
    css`
      padding-right: ${p.offsetRight + p.theme.space.l - 1}px;
    `};
  `};
`

const ButtonHolder = styled.div<IButtonHolder>`
  position: absolute;

  ${p => css`
    right: ${p.theme.space.s - 1}px;
    opacity: ${p.isVisible ? 1 : 0};
  `};
`
