import { createReducer } from '@reduxjs/toolkit'
import { preloadedState } from '../../appState'
import {
  setIsOpenLeadModal,
  setIsOpenLeadModalReducer,
} from './setIsOpenLeadModal'
import {
  setIsOpenPromotion1000About,
  setIsOpenPromotion1000AboutReducer,
} from './setIsOpenPromotion1000AboutReducer'
import {
  setIsOpenPromotion1000Rules,
  setIsOpenPromotion1000RulesReducer,
} from './setIsOpenPromotion1000RulesReducer'
import {
  setNewsletterListUrl,
  setNewsletterListUrlReducer,
} from './setNewsletterListUrlReducer'
import {
  setNewsletterPreviewTemplate,
  setNewsletterPreviewTemplateReducer,
} from './setNewsletterPreviewTemplateReducer'
import {
  setForceSelectedTab,
  setForceSelectedTabReducer,
} from './setForceSelectedTabReducer'

const commonReducers = createReducer(preloadedState.common, builder => {
  builder.addCase(setIsOpenLeadModal.type, setIsOpenLeadModalReducer)
  builder.addCase(setNewsletterListUrl.type, setNewsletterListUrlReducer)
  builder.addCase(
    setNewsletterPreviewTemplate.type,
    setNewsletterPreviewTemplateReducer,
  )
  builder.addCase(
    setIsOpenPromotion1000Rules.type,
    setIsOpenPromotion1000RulesReducer,
  )
  builder.addCase(
    setIsOpenPromotion1000About.type,
    setIsOpenPromotion1000AboutReducer,
  )
  builder.addCase(setForceSelectedTab.type, setForceSelectedTabReducer)
})

export default commonReducers
