import React, { forwardRef, Ref } from 'react'
import styled, { css } from 'styled-components'

import { Box, IBox } from '../Box'

import { ICascaderMenuColumn } from './types'

export const CascaderMenuColumn = forwardRef(
  (props: ICascaderMenuColumn, ref: Ref<HTMLDivElement>) => {
    const { isOpen, children, ...rest } = props

    if (!isOpen) {
      return null
    }

    return (
      <Container
        ref={ref}
        forwardedAs={'div' as IBox['forwardedAs']}
        p="xxs"
        {...rest}
      >
        {children}
      </Container>
    )
  },
)

const Container = styled(Box)<ICascaderMenuColumn>`
  display: flex;
  flex-direction: column;
  min-width: 161px;
  max-height: 100%;
  overflow-x: auto;
  text-align: left;

  &:focus {
    outline: none;
  }

  ${p => css`
    & + & {
      border-left: 1px solid ${p.theme.components.cascaderColumnBorder};
    }
  `};
`
