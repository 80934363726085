import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { typography } from 'styled-system'

import { Box } from '../Box'
import { IText } from './types'

export const Text = forwardRef(
  (props: IText, ref: React.Ref<HTMLDivElement>) => {
    const {
      as = 'span',
      fontSize = 'm',
      lineHeight = 'm',
      fontWeight = 'regular',
      ...rest
    } = props

    return (
      <StyledText
        ref={ref}
        forwardedAs={as}
        fontSize={fontSize}
        lineHeight={lineHeight}
        fontWeight={fontWeight}
        {...rest}
      />
    )
  },
)

const StyledText = styled(Box)<IText>`
  ${p =>
    p.textTransform &&
    css`
      text-transform: ${p.textTransform};
    `};

  ${typography};
`
