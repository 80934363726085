import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface LockSidebarParams {
  isLock: boolean
}

export const lockSidebar = createAction<LockSidebarParams>(
  'sidebar/lockSidebar',
)

export const lockSidebarReducer = (
  state: RootState['sidebar'],
  action: ReturnType<typeof lockSidebar>,
): void => {
  const { isLock } = action.payload
  state['isLock'] = isLock
}
