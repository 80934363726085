import React, { forwardRef, Ref, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { Flex } from '../Flex'
import { Text } from '../Text'
import { Icon } from '../Icon'
import { ISearchInput, IInput, IPrefix } from './types'
import { setTransition } from '../../utility'

export const SearchInput = forwardRef(
  (props: ISearchInput, ref: Ref<HTMLInputElement>) => {
    const captionRef = useRef<HTMLDivElement>(null)
    const [captionWidth, setCaptionWidth] = useState<number | undefined>(
      undefined,
    )

    const {
      value,
      defaultValue,
      placeholder,
      id,
      isDisabled,
      isError,
      isReadOnly,
      onChange,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      caption,
      ...rest
    } = props

    const inputProps = {
      ref,
      value,
      offsetRight: captionWidth,
      defaultValue,
      placeholder,
      id,
      isDisabled,
      disabled: isDisabled,
      isError,
      readOnly: isReadOnly,
      onChange,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      'aria-label': (rest['aria-label'] = 'Input'),
      type: 'search',
    }

    useEffect(() => {
      if (!captionRef.current) {
        return
      }
      setCaptionWidth(captionRef.current.offsetWidth)
    }, [caption])

    return (
      <Container {...rest}>
        <Prefix isDisabled={isDisabled}>
          <Icon name="20-search" mr="xxxs" />
        </Prefix>

        <Input {...inputProps} />

        {caption && (
          <Caption ref={captionRef} fontSize="s" lineHeight="s">
            {caption}
          </Caption>
        )}
      </Container>
    )
  },
)

export const Container = styled(Box)`
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
`

export const Input = styled(Text).attrs({
  forwardedAs: 'input',
  fontSize: 'm',
  lineHeight: 'm',
  color: 'grey100',
})<IInput>`
  width: 100%;
  appearance: none;
  font-family: inherit;
  letter-spacing: 0.2px;
  ${setTransition(['border-color', 'box-shadow'], 'productive')};

  ${p => css`
    padding: 13px 15px 13px 43px;
    border: ${p.theme.components.textInputBorder};
    border-radius: ${p.theme.radii.rounded};

    ${!p.isDisabled &&
    css`
      &:hover {
        border-color: ${p.theme.components[
          p.isError
            ? 'textInputErrorHoverBorderColor'
            : 'textInputHoverBorderColor'
        ]};
      }

      &:focus {
        outline: none;
        box-shadow: ${p.theme.focuses[p.isError ? 'error' : 'normal']};
        border-color: transparent;
      }
    `}

    &::placeholder {
      color: ${p.theme.components[
        p.isDisabled
          ? 'textInputDisabledPlaceholderColor'
          : 'textInputPlaceholderColor'
      ]};
    }

    ${p.offsetRight &&
    css`
      padding-right: ${p.offsetRight + 15}px;
    `};

    ${p.isError &&
    !p.isDisabled &&
    css`
      border-color: ${p.theme.components.textInputErrorBorderColor};
    `};

    ${p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .textInputDisabledBackgroundBackground};
      color: ${p.theme.components.textInputDisabledColor};
      cursor: not-allowed;
    `};
  `};

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`

export const Prefix = styled(Flex)<IPrefix>`
  position: absolute;
  pointer-events: none;

  ${p =>
    css`
      left: ${p.theme.space.s - 1}px;
      color: ${p.theme.components[
        p.isDisabled ? 'textInputPrefixDisabledColor' : 'textInputPrefixColor'
      ]};
    `}
`

const Caption = styled(Text)`
  position: absolute;
  ${p =>
    css`
      right: ${p.theme.space.s - 1}px;
      color: ${p.theme.colors.grey50};
    `}
`
