import React, { FC } from 'react'
import { IBreadcrumbsItem } from './types'
import styled, { css } from 'styled-components'
import { Text } from '../Text'
import { theme } from '../../theme'

export const BreadcrumbsItem: FC<React.PropsWithChildren<IBreadcrumbsItem>> = ({
  children,
  as,
  isCurrentPage,
  ...rest
}) => {
  const sharedProps = {
    children,
    as,
    ...rest,
  }

  if (!children) {
    return null
  }

  return (
    <StyledBreadcrumbsItem mr="xxs">
      {isCurrentPage ? children : <StyledLink {...sharedProps} />}
    </StyledBreadcrumbsItem>
  )
}

const StyledBreadcrumbsItem = styled(Text)`
  white-space: nowrap;

  ${({ theme }) => css`
    color: ${theme.colors.grey100};
  `};
`

const StyledLink = styled.a`
  color: ${theme.colors.grey50};
`
