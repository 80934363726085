import React, { useState, useEffect, ChangeEvent } from 'react'
import { ChromePicker, ColorResult } from 'react-color'

import { ColorPickerProps } from './types'

const ColorPicker = ({
  color,
  onChange,
  onChangeComplete,
  disableAlpha = false,
}: ColorPickerProps) => {
  /*
    The ChromePicker from the react-color library does not natively support alpha channel handling.
    To ensure that the alpha channel is properly managed, we use the internal color.rgb state provided by the library.
    This allows us to handle and display the alpha channel correctly within the component.
   */
  const [colorState, setColorState] = useState(color)

  useEffect(() => {
    setColorState(color)
  }, [color])

  const handleColorChange = (
    colorResult: ColorResult,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setColorState(colorResult.rgb)
    if (onChange) {
      onChange(colorResult, event)
    }
  }

  return (
    <ChromePicker
      color={colorState}
      onChangeComplete={onChangeComplete}
      onChange={handleColorChange}
      disableAlpha={disableAlpha}
    />
  )
}
export { ColorPicker }
