import React, { FC } from 'react'
import styled from 'styled-components'
import { Icon, Tooltip, ITooltip } from '@ads/front-ds'
import zIndex from '@ads/front-core/constants/zIndex'

export interface IInfoIconTooltip {
  tooltipMessage: string
}

export const InfoIconTooltip: FC<IInfoIconTooltip & ITooltip> = ({
  tooltipMessage,
  ...restTooltip
}) => (
  <TooltipStyled text={tooltipMessage} zIndex={zIndex.TOOLTIP} {...restTooltip}>
    <Icon name="16-info-solid" color="greyDisabled" />
  </TooltipStyled>
)

const TooltipStyled = styled(Tooltip)`
  & > div {
    display: flex;
  }
`
