import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import {
  reloadComponents,
  reloadComponentsReducer,
} from './reloadComponentsReducer'

const reloadComponentsReducers = createReducer(
  preloadedRootState.reloadComponents,
  builder => {
    builder.addCase(reloadComponents.type, reloadComponentsReducer)
  },
)

export default reloadComponentsReducers
