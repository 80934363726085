import { createAction } from '@reduxjs/toolkit'
import { ResponseCampaignToArchive } from '@ads/front-core/api'
import { AppState } from '../../appState'

export const setCampaignsToArchive = createAction<ResponseCampaignToArchive[]>(
  'campaign/setCampaignsToArchive',
)

export const setCampaignsToArchiveReducer = (
  state: AppState['campaign'],
  action: ReturnType<typeof setCampaignsToArchive>,
): void => {
  state.campaignsToArchive = action.payload
}
