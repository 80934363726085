import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isAfter, parseISO, subDays } from 'date-fns'
import { getCurrentUser } from '@ads/front-core/store/selectors/getCurrentUser'

const SURVICATE_ID = 'survicate_wpads'
const useSurvicateScript = () => {
  const currentUser = useSelector(getCurrentUser)

  const isAccountExistsAtLeast30days = (): boolean => {
    if (currentUser) {
      const date30DayAgo = subDays(new Date(), 30)
      return isAfter(date30DayAgo, parseISO(currentUser.createDate))
    }
    return false
  }

  const injectSurvicateScript = () => {
    const script = document.createElement('script')
    script.src =
      'https://survey.survicate.com/workspaces/d6d29dc9cfb3f131c74ac169401b1e69/web_surveys.js'
    script.async = true
    script.id = SURVICATE_ID
    const e = document.getElementsByTagName('script')[0]
    e.parentNode.insertBefore(script, e)
  }

  useEffect(() => {
    if (
      isAccountExistsAtLeast30days() &&
      !document.getElementById(SURVICATE_ID) &&
      window.location.hostname === 'ads.wp.pl'
    ) {
      injectSurvicateScript()
    }
  }, [currentUser?.id])
}

export default useSurvicateScript
