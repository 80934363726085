import { createAction } from '@reduxjs/toolkit'
import { SegmentsStore } from 'components/Targeting/TargetSegments/interfaces'
import { AppState } from '../../appState'

export const setCampaignSegments = createAction<SegmentsStore>(
  'campaign/setCampaignSegments',
)

export const setCampaignSegmentsReducer = (
  state: AppState['campaign'],
  action: ReturnType<typeof setCampaignSegments>,
): void => {
  state.campaignSegments = action.payload
}
