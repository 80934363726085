import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { put, takeEvery } from 'redux-saga/effects'
import { banner } from './bannerSaga'

export type SuccessSaga = ReturnType<PayloadActionCreator<string, string>>

export function* sagaSuccess(): Generator {
  yield takeEvery(success.type, successSaga)
}

export const success = createAction<string>('saga/success')

export function* successSaga({ payload }: SuccessSaga): Generator {
  yield put(
    banner({
      description: payload,
      variant: 'positive',
      disableAutoHide: false,
    }),
  )
}
