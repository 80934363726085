import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setIsMobile = createAction<boolean>('setIsMobile')

export const setIsMobileReducer = (
  state: RootState['app'],
  action: ReturnType<typeof setIsMobile>,
): void => {
  state.isMobile = action.payload
}
