import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Avatar } from '../../Avatars'
import { Box } from '../../Box'
import { Text } from '../../Text'
import { Tick } from './MenuItem'
import { ILabel, IMenuAvatar, IMenuItemContainer, IOptions } from './types'

import { checkIntent } from '../../../utility'
import { MatchPhrase } from './MenuItem'
import { getImageResizerURL } from './getImageResizerURL'

export const MenuAvatar: FC<React.PropsWithChildren<IMenuAvatar>> = ({
  label,
  backgroundColor,
  src,
  isDisabled,
  intent,
  isSelected,
  phrase,
  caption,
  ...rest
}) => {
  const resizedImg = src
    ? getImageResizerURL({ height: 24, width: 24, url: src })
    : undefined
  return (
    <Container isDisabled={isDisabled} intent={intent} tabIndex={0} {...rest}>
      {checkIntent('selectable', intent) && (
        <Tick
          name="20-check"
          mr="xxs"
          isVisible={isSelected}
          isDisabled={isDisabled}
        />
      )}
      <StyledAvatar
        name={label}
        size="s"
        mr="xxs"
        isDisabled={isDisabled}
        backgroundColor={backgroundColor}
        src={resizedImg}
      />
      <Label fontSize="m" lineHeight="m" isDisabled={isDisabled}>
        {phrase ? <MatchPhrase str={label} phrase={phrase} /> : label}
      </Label>
      {caption && (
        <Options isSelectable={checkIntent('selectable', intent)}>
          <Caption fontSize="m" lineHeight="m" ml="xxs">
            {caption}
          </Caption>
        </Options>
      )}
    </Container>
  )
}

const Container = styled(Box)<IMenuItemContainer>`
  display: flex;
  cursor: pointer;
  align-items: center;

  &:focus {
    outline: none;
  }

  ${p => css`
    padding: ${p.theme.space.xxs}px;
    pointer-events: ${p.isDisabled && 'none'};
    padding-right: ${checkIntent('selectable', p.intent) &&
    p.theme.space.xl + p.theme.space.xxxs + 'px'};
    border-radius: ${p.theme.radii.rounded};
    &:hover {
      background-color: ${p.theme.components.menuChildHoverBackgroundColor};
    }

    &:focus {
      background-color: ${p.theme.components.menuChildFocusBackgroundColor};
    }

    ${checkIntent('children', p.intent) &&
    css`
      padding-left: ${checkIntent('selectable', p.intent)
        ? p.theme.space.l + p.theme.space.xs
        : p.theme.space.l}px;
    `}
  `};
`

const Label = styled(Text)<ILabel>`
  ${p => css`
    padding: ${p.theme.space.xxxs / 2}px 0;
    color: ${p.theme.components[
      p.isDisabled ? 'menuChildDisabledColor' : 'menuChildColor'
    ]};
  `};
`

const StyledAvatar = styled(Avatar)<ILabel>`
  ${p => css`
    opacity: ${p.isDisabled && 0.55};
    color: ${p.theme.components[
      p.isDisabled ? 'menuChildDisabledColor' : 'menuChildColor'
    ]};
  `};
`

const Options = styled(Box)<IOptions>`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${p =>
    css`
      padding-left: ${p.isSelectable
        ? p.theme.space.xl + p.theme.space.xxxs
        : p.theme.space.s}px;
    `};
`

const Caption = styled(Text)`
  color: ${p => p.theme.components.menuItemCaptionColor};
`
