import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { call, put, takeEvery } from 'redux-saga/effects'
import { ClientService, ResponseClient } from '@ads/front-core/api'
import { setCurrentCurrency } from '@ads/front-core/store/reducers/currency/setCurrentCurrencyReducer'
import { apiError } from '@ads/front-core/store/sagas/apiErrorSaga'
import { getCurrencyExchange } from '@ads/front-core/utils'
import { setClient } from '../reducers/client/setClientReducer'

interface FetchClientDataProps {
  clientId: number
}

type FetchClientDataSaga = ReturnType<
  PayloadActionCreator<FetchClientDataProps, string>
>

export function* sagaFetchClientData(): Generator {
  yield takeEvery(fetchClientData.type, fetchClientDataSaga)
}

export const fetchClientData = createAction<FetchClientDataProps>(
  'saga/fetchClientData',
)

export function* fetchClientDataSaga({
  payload,
}: FetchClientDataSaga): Generator {
  const { clientId } = payload
  try {
    if (!clientId) {
      return
    }

    const responseClient = yield call(ClientService.getClient, { clientId })
    const client = responseClient as ResponseClient

    yield put(setClient(client))
    yield put(
      setCurrentCurrency(getCurrencyExchange(client?.settings?.currency)),
    )
  } catch (error) {
    yield put(apiError({ error }))
  }
}
