import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { delay, put, select, takeEvery } from 'redux-saga/effects'
import { SidebarProps } from '@ads/front-core/components/Sidebar'
import transition from '@ads/front-core/constants/transition'
import { lockSidebar } from '../reducers/sidebar/lockSidebarReducer'
import { toggleSidebar as toggleSidebarReducer } from '../reducers/sidebar/toggleSidebarReducer'
import { RootState } from '../rootState'
import { getSidebar } from '../selectors/getSidebar'

export type SidebarSaga = ReturnType<PayloadActionCreator<SidebarProps, string>>

export function* sagaSidebar(): Generator {
  yield takeEvery(toggleSidebar.type, lockSidebarSaga)
  yield takeEvery(toggleSidebar.type, toggleSidebarSaga)
  yield takeEvery(openSidebar.type, openSidebarSaga)
  yield takeEvery(closeSidebar.type, closeSidebarSaga)
}

export const toggleSidebar = createAction('saga/sidebar')
export const openSidebar = createAction('saga/openSidebar')
export const closeSidebar = createAction('saga/closeSidebar')

export function* lockSidebarSaga(): Generator {
  yield put(lockSidebar({ isLock: true }))
  yield delay(transition.SIDEBAR)
  yield put(lockSidebar({ isLock: false }))
}

export function* openSidebarSaga(): Generator {
  yield put(toggleSidebarReducer({ isOpen: true }))
}

export function* closeSidebarSaga(): Generator {
  yield put(toggleSidebarReducer({ isOpen: false }))
}

export function* toggleSidebarSaga(): Generator {
  const { isOpen } = (yield select(getSidebar)) as RootState['sidebar']
  yield put(toggleSidebarReducer({ isOpen: !isOpen }))
}
