import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

type MarkFirstVisit = {
  clientId: number
}

export const markFirstVisit = createAction<MarkFirstVisit>(
  'user/markFirstVisit',
)

export const markFirstVisitReducer = (
  state: RootState['user'],
  action: ReturnType<typeof markFirstVisit>,
): void => {
  state.clients = state.clients?.map(c =>
    c.clientId === action.payload.clientId
      ? {
          ...c,
          flags: [...(c.flags || []), 'WELCOME_BOARD_SHOWED'],
        }
      : c,
  )
}
