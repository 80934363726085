import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { IBadge as IBadgeDS, IText, Text } from '@ads/front-ds'
import { InfoIconTooltip } from '@ads/front-core/atoms'
import { getColorByVariant } from './utils'

export interface IBadge {
  label: string
  tooltipText?: string
  variant?: IBadgeDS['variant']
}

const Badge: FC<IBadge & IText> = ({
  label,
  variant,
  tooltipText,
  ...restText
}) => (
  <BadgeStyled variant={variant} {...restText}>
    {label}
    {!!tooltipText && <InfoIconTooltip tooltipMessage={tooltipText} />}
  </BadgeStyled>
)

const BadgeStyled = styled(Text)<Pick<IBadge, 'variant'>>`
  ${p => css`
    display: inline-flex;
    align-items: center;
    gap: ${p.theme.space.xxs}px;
    background-color: ${getColorByVariant(p.variant)};
    border-radius: ${p.theme.radii.rounded};
    padding: ${p.theme.space.xxs - 2}px ${p.theme.space.xxs}px;
    line-height: 1em;
  `}
`

export default Badge
