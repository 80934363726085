import { createAction } from '@reduxjs/toolkit'
import { IForm } from 'components/views/Newsletter/Creator/interfaces'
import { AppState } from '../../appState'

export const setNewsletterDraft = createAction<Partial<IForm>>(
  'draft/setNewsletterDraft',
)

export const setNewsletterDraftReducer = (
  state: AppState['draft'],
  action: ReturnType<typeof setNewsletterDraft>,
): void => {
  state.newsletter = action.payload
}
