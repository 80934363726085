import { createAction } from '@reduxjs/toolkit'
import { ResponseShop } from '@ads/front-core/api'
import { AppState } from '../../appState'

export const setClientShops = createAction<Required<ResponseShop[]>>(
  'client/setClientShops',
)

export const setClientShopsReducer = (
  state: AppState['client'],
  action: ReturnType<typeof setClientShops>,
): void => {
  state.currentClientShops = action.payload
}
