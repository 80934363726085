import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import {
  setCurrencyData,
  setCurrencyDataReducer,
} from './setCurrencyDataReducer'
import {
  setCurrencyLoading,
  setCurrencyLoadingReducer,
} from './setCurrencyLoadingReducer'
import {
  setCurrentCurrency,
  setCurrentCurrencyReducer,
} from './setCurrentCurrencyReducer'

const currencyReducers = createReducer(preloadedRootState.currency, builder => {
  builder.addCase(setCurrencyLoading.type, setCurrencyLoadingReducer)
  builder.addCase(setCurrencyData.type, setCurrencyDataReducer)
  builder.addCase(setCurrentCurrency.type, setCurrentCurrencyReducer)
})

export default currencyReducers
