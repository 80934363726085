import React, { FC } from 'react'

import { icons } from './icons'
import { IIcon } from './types'

import { IconSVG } from './IconSVG'

export const convertLabel = (string: string) => {
  const label = string.substr(3).replace('-', ' ')
  return label.charAt(0).toUpperCase() + label.slice(1)
}

export const Icon: FC<React.PropsWithChildren<IIcon>> = React.memo(
  ({ name, ...rest }) => {
    const item = React.useMemo(() => icons[name], [name])

    if (!item) {
      throw new Error(`There's no icon named ${name}`)
    }

    return (
      <IconSVG
        size={item.size}
        color={
          Object.hasOwnProperty.call(item, 'fill') ? item.fill : 'currentColor'
        }
        path={item.path}
        paths={item.paths}
        aria-label={convertLabel(name as string)}
        {...rest}
      />
    )
  },
)

Icon.displayName = 'Icon'
