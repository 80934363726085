import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setIsOpenLeadModal = createAction<boolean>(
  'common/setIsOpenLeadModal',
)

export const setIsOpenLeadModalReducer = (
  state: AppState['common'],
  action: ReturnType<typeof setIsOpenLeadModal>,
): void => {
  state.isOpenLeadModal = action.payload
}
