import { createAction } from '@reduxjs/toolkit'
import { GetConfigResponse } from '@ads/front-core/interfaces'
import { RootState } from '../../rootState'

export const setReleaseVersion = createAction<GetConfigResponse['release']>(
  'app/setReleaseVersion',
)

export const setReleaseVersionReducer = (
  state: RootState['app'],
  action: ReturnType<typeof setReleaseVersion>,
): void => {
  state.releaseVersion = action.payload
}
