import { createAction } from '@reduxjs/toolkit'
import { Currency } from '@ads/front-core/interfaces'
import { RootState } from '../../rootState'

export const setCurrencyData = createAction<{
  date: string
  data: Record<Currency, number>
}>('currency/setCurrencyData')

export const setCurrencyDataReducer = (
  state: RootState['currency'],
  action: ReturnType<typeof setCurrencyData>,
): void => {
  state.data[action.payload.date] = action.payload.data
}
