import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../Text'
import { INewBadgeField, IStyledButton } from './types'
import { adjustPaddings, setTransition } from '../../utility'

export const NewBadgeField: FC<React.PropsWithChildren<INewBadgeField>> = ({
  children,
  as = 'button',
  isDisabled,
  ...rest
}) => {
  const [mouseDown, setMouseDown] = useState(false)

  const handleMouseDown = () => setMouseDown(true)
  const handleOnBlur = () => setMouseDown(true)

  return (
    <StyledButton
      fontSize="xs"
      lineHeight="xs"
      forwardedAs={as as keyof IStyledButton['forwardedAs']}
      isDisabled={isDisabled}
      fontWeight="bold"
      textTransform="uppercase"
      aria-label={children}
      isMouseDown={mouseDown}
      onMouseDown={handleMouseDown}
      onBlur={handleOnBlur}
      {...rest}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Text)<IStyledButton>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  appearance: none;
  font-family: inherit;
  letter-spacing: 0.2px;
  text-decoration: none;
  ${adjustPaddings(['xxxs', 'xxs'])};
  ${setTransition(['color', 'box-shadow'], 'productive')};

  ${({ theme, isDisabled, isMouseDown }) => css`
    background-color: ${theme.colors.inverted100};
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${isDisabled && 'none'};
    border: ${theme.components.newBadgeFieldBorder};
    border-radius: ${theme.radii.rounded};
    color: ${theme.components[
      isDisabled ? 'newBadgeFieldDisabledColor' : 'newBadgeFieldColor'
    ]};

    &:hover {
      color: ${theme.components.newBadgeFieldHoverColor};
    }

    &:active {
      opacity: ${theme.aliases.defaultActiveOpacity};
    }

    &:focus {
      outline: none;

      ${!isMouseDown &&
      css`
        box-shadow: ${theme.focuses.normal};
        border-color: transparent;
      `};
    }
  `};
`
