import React, { FC } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

import { IIllustrationSVG, IIllustrationSvgElements } from './types'
import { useNanoid } from '../../utility'

export const IllustrationSVG: FC<React.PropsWithChildren<IIllustrationSVG>> = ({
  width,
  height,
  svgElements,
  ...rest
}) => {
  const [svgElementsIds] = useNanoid(svgElements.length)

  const switchElement = (
    svgElement: IIllustrationSvgElements,
    index: number,
  ) => {
    switch (svgElement.type) {
      case 'path': {
        const {
          stroke,
          strokeWidth,
          strokeLinecap,
          strokeLinejoin,
          transform,
          path,
        } = svgElement

        const illustrationProps = {
          d: path,
          stroke,
          strokeWidth,
          strokeLinecap,
          transform,
          strokeLinejoin,
        }

        return (
          <IllustrationSvgPath
            fill={svgElement.fill ? svgElement.fill : 'currentColor'}
            opacity={svgElement.opacity}
            fillRule="evenodd"
            key={index + svgElementsIds[index]}
            {...illustrationProps}
          />
        )
      }
      case 'circle':
        return (
          <IllustrationSvgCircle
            fill={svgElement.fill ? svgElement.fill : 'currentColor'}
            opacity={svgElement.opacity}
            cx={svgElement.cx}
            cy={svgElement.cy}
            r={svgElement.r}
            transform={svgElement.transform ? svgElement.transform : ''}
            key={index + svgElementsIds[index]}
          />
        )
      case 'ellipse':
        return (
          <IllustrationSvgEllipse
            fill={svgElement.fill ? svgElement.fill : 'currentColor'}
            opacity={svgElement.opacity}
            cx={svgElement.cx}
            cy={svgElement.cy}
            rx={svgElement.rx}
            ry={svgElement.ry}
            transform={svgElement.transform ? svgElement.transform : ''}
            key={index + svgElementsIds[index]}
          />
        )
      case 'polygon':
        return (
          <IllustrationSvgPolygon
            fill={svgElement.fill ? svgElement.fill : 'currentColor'}
            opacity={svgElement.opacity}
            points={svgElement.points}
            transform={svgElement.transform ? svgElement.transform : ''}
            key={index + svgElementsIds[index]}
          />
        )
    }
  }

  return (
    <StyledIllustrationSvg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {Array.isArray(svgElements) &&
        svgElementsIds &&
        svgElements.map((p, index) => p && switchElement(p, index))}
    </StyledIllustrationSvg>
  )
}

const StyledIllustrationSvg = styled.svg`
  display: inline-flex;
  ${space};
  ${color};
  /* Fix rendering bugs in Chrome for hdpi */
  shape-rendering: 'inherit';
  transform: 'translate3d(0,0,0)';
`

const IllustrationSvgPath = styled.path``
const IllustrationSvgCircle = styled.circle``
const IllustrationSvgEllipse = styled.ellipse``
const IllustrationSvgPolygon = styled.polygon``
