import { createAction } from '@reduxjs/toolkit'
import { IInlineBanner } from '@ads/front-ds'
import { RootState } from '../../rootState'

export interface ShowBannerParams extends IInlineBanner {
  id: string
}

export const showBanner = createAction<ShowBannerParams>('banner/showBanner')

export const showBannerReducer = (
  state: RootState['banner'],
  action: ReturnType<typeof showBanner>,
): void => {
  const { id } = action.payload
  state[id] = { variant: 'neutral', ...action.payload }
}
