import React, { FC, useEffect, useState, ReactNode, ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { rgba } from 'polished'
import ReactFocusLock from 'react-focus-lock'

import { useModal, UseModalProps, UseModalReturn } from './useModal'
import { Box } from '../Box'

import { Portal } from '../Portal'
import { createContext } from '../../utility'
import { IModalWrapper, IOverlayWrapper, ModalContainerProps } from './types'

export interface ModalProps extends UseModalProps {
  /**  Use ModalContainer as children. */
  children: ReactNode
}

interface ModalContext extends UseModalReturn {}

const [ModalContextProvider, useModalContext] = createContext<ModalContext>({
  strict: true,
  name: 'ModalContext',
  errorMessage:
    'useModalContext: `context` is undefined. Seems you forgot to wrap modal components in `<Modal />`',
})

export { ModalContextProvider, useModalContext }

/**
 * Modal provides context to all other modal components.
 *
 */
export const Modal = (props: ModalProps) => {
  const { children, isOpen } = props

  const modal = useModal(props)

  const context = {
    ...modal,
  }

  const [showContainer, setShowContainer] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setShowContainer(true)
      setShowModal(true)
    } else {
      setShowModal(false)
      setTimeout(() => setShowContainer(false), 100)
    }
  }, [isOpen])

  return (
    <ModalContextProvider value={context}>
      {context.isOpen && (
        <TransitionGroup>
          <CSSTransition
            in={showContainer}
            timeout={350}
            classNames="fade"
            unmountOnExit
          >
            <Portal>
              <Overlay isOpen={showContainer}>
                <Container>
                  <CSSTransition
                    in={showModal}
                    timeout={350}
                    classNames="slide"
                    unmountOnExit
                  >
                    {children}
                  </CSSTransition>
                </Container>
              </Overlay>
            </Portal>
          </CSSTransition>
        </TransitionGroup>
      )}
    </ModalContextProvider>
  )
}

export const Overlay: FC<React.PropsWithChildren<IOverlayWrapper>> = props => {
  const { containerProps: rootProps, children, isOpen } = props
  const { getDialogContainerProps } = useModalContext()
  const containerProps = getDialogContainerProps(rootProps)

  return (
    <ModalFocusScope>
      <OverlayStyle
        isOpen={isOpen}
        p="xxl"
        ref={containerProps?.ref as React.RefObject<any>}
      >
        {children}
      </OverlayStyle>
    </ModalFocusScope>
  )
}

interface ModalFocusScopeProps {
  /**
   * @type React.ReactElement
   */
  children: ReactElement
}

export function ModalFocusScope(props: ModalFocusScopeProps) {
  return <ReactFocusLock>{props.children}</ReactFocusLock>
}

export const ModalContainer = (props: ModalContainerProps) => {
  const { size = 'm', children, ...rest } = props
  const { getDialogProps } = useModalContext()
  const dialogProps = getDialogProps(rest) as any

  return (
    <ModalContainerStyle
      p="m"
      borderRadius="rounded"
      size={size}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modalTitle"
      {...dialogProps}
    >
      {children}
    </ModalContainerStyle>
  )
}

const OverlayStyle = styled(Box)<IOverlayWrapper>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  ${p => css`
    z-index: ${p.theme.zIndex.modal};
    background-color: ${rgba(p.theme.components.modalOverlayBackground, 0.98)};
    visibility: ${p.isOpen ? 'visible' : 'hidden'};

    &.fade-enter {
      opacity: 0;
      visibility: hidden;
    }

    &.fade-enter-active {
      opacity: 1;
      visibility: visible;
      transition: visiblity ${p.theme.motion.expressive},
        opacity ${p.theme.motion.expressive};
    }

    &.fade-exit {
      opacity: 1;
      visibility: visible;
    }

    &.fade-exit-active {
      opacity: 0;
      visibility: hidden;
      transition: visiblity ${p.theme.motion.expressive},
        opacity ${p.theme.motion.expressive};
    }
  `};
`

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
`

const ModalContainerStyle = styled(Box)<IModalWrapper>`
  position: relative;

  ${p => css`
    width: ${p.size === 'l'
      ? p.theme.components.modalSizeLarge
      : p.theme.components.modalSizeMedium}px;
    background-color: ${p.theme.components.modalBackground};
    color: ${p.theme.components.modalColor};

    &.slide-enter {
      opacity: 0;
      transform: translate3d(0px, -40px, 0px);
    }

    &.slide-enter-active {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity ${p.theme.motion.expressive},
        transform ${p.theme.motion.expressive};
    }

    &.slide-exit {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &.slide-exit-active {
      opacity: 0;
      transform: translate3d(0px, -40px, 0px);
      transition: opacity ${p.theme.motion.expressive},
        transform ${p.theme.motion.expressive};
    }
  `};
`
