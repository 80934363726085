import React, { forwardRef, Ref, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { adjustPaddings, setTransition } from '../../utility'
import { Box } from '../Box'
import { IconButton } from '../Buttons'
import { Flex } from '../Flex'
import { Icon } from '../Icon'
import { defaultIcons } from '../Icon/icons'
import { Text } from '../Text'
import { IContainer, IInput, IPrefix, ITextInput } from './types'

export const getPrefix = (prefix: string) => {
  if (Object.keys(defaultIcons).includes(prefix as string)) {
    return <Icon name={prefix} mr="xxxs" />
  }

  return (
    <Text fontSize="m" lineHeight="m">
      {prefix}
    </Text>
  )
}

export const TextInput = React.memo(
  forwardRef((props: ITextInput, ref: Ref<HTMLInputElement>) => {
    const prefixRef = useRef<HTMLDivElement>(null)
    const [prefixWidth, setPrefixWidth] = useState<number | undefined>(
      undefined,
    )

    const {
      value,
      defaultValue,
      placeholder,
      type = 'text',
      id,
      isDisabled,
      isError,
      isReadOnly,
      prefix,
      arrow,
      onChange,
      onClear,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      imageSource,
      imageAlt,
      autoComplete,
      m,
      my,
      theme,
      styleCss,
      mt,
      mr,
      mb,
      ml,
      mx,
      'data-testid': dataTestId,
      className,
      ...rest
    } = props

    const containerStyle = {
      theme,
      styleCss,
      m,
      my,
      mb,
      mt,
      mx,
      mr,
      ml,
      'data-testid': dataTestId,
      className,
    }

    const inputProps = {
      ref,
      offsetLeft: prefixWidth,
      value,
      defaultValue,
      placeholder,
      type,
      id,
      disabled: isDisabled,
      imageSource,
      imageAlt,
      isError,
      readOnly: isReadOnly,
      clearable: (!!onClear && !!value) || !!arrow,
      onChange,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      autoComplete,
      ...rest,
    }

    useEffect(() => {
      if (prefixRef && prefixRef.current) {
        setPrefixWidth(prefixRef.current.offsetWidth)
      } else {
        setPrefixWidth(undefined)
      }
    }, [prefix])

    if (process.env.NODE_ENV !== 'production' && imageSource && prefix)
      console.warn("You shouldn't set prefix and imageSource at the same time.")

    return (
      <Container {...containerStyle} isVisible={!!prefixWidth || !prefix}>
        {prefix && (
          <Prefix ref={prefixRef} isDisabled={isDisabled}>
            {getPrefix(prefix)}
          </Prefix>
        )}
        {imageSource && <Image src={imageSource} alt={imageAlt} />}

        <Input {...inputProps} />

        {!arrow && onClear && !!value && (
          <Clear
            variant="bareDark"
            aria-label="Wyczyść"
            name="20-close"
            onClick={onClear}
          />
        )}

        {arrow && <Arrow name={`20-arrowhead-${arrow}`} />}
      </Container>
    )
  }),
)

TextInput.displayName = 'TextInput'

export const Container = styled(Box)<IContainer>`
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;

  ${p => css`
    opacity: ${p.isVisible ? 1 : 0};
  `};
`

export const Input = styled(Text).attrs({
  forwardedAs: 'input',
  fontSize: 'm',
  lineHeight: 'm',
  color: 'grey100',
})<IInput>`
  width: 100%;
  appearance: none;
  font-family: inherit;
  letter-spacing: 0.2px;
  ${setTransition(['border-color', 'box-shadow'], 'productive')};

  ${p => css`
    ${adjustPaddings(['xxs', p.isClearable ? 'xl' : 's', 'xxs', 's'])};
    border: ${p.theme.components.textInputBorder};
    border-radius: ${p.theme.radii.rounded};

    ${!p.disabled &&
    css`
      &:hover {
        border-color: ${p.theme.components[
          p.isError
            ? 'textInputErrorHoverBorderColor'
            : 'textInputHoverBorderColor'
        ]};
      }

      &:focus {
        outline: none;
        box-shadow: ${p.theme.focuses[p.isError ? 'error' : 'normal']};
        border-color: transparent;
      }
    `}

    &::placeholder {
      color: ${p.theme.components[
        p.disabled
          ? 'textInputDisabledPlaceholderColor'
          : 'textInputPlaceholderColor'
      ]};
    }

    ${p.offsetLeft &&
    css`
      padding-left: ${p.offsetLeft +
      p.theme.space.m -
      p.theme.space.xxxs -
      1}px;
    `};

    ${p.imageSource &&
    css`
      padding-left: 47px;
    `};

    ${p.isError &&
    !p.disabled &&
    css`
      border-color: ${p.theme.components.textInputErrorBorderColor};
    `};

    ${p.disabled &&
    css`
      background-color: ${p.theme.components
        .textInputDisabledBackgroundBackground};
      color: ${p.theme.components.textInputDisabledColor};
      cursor: not-allowed;
    `};
  `};

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`

export const Prefix = styled(Flex)<IPrefix>`
  position: absolute;
  pointer-events: none;

  ${p =>
    css`
      left: ${p.theme.space.s - 1}px;
      color: ${p.theme.components[
        p.isDisabled ? 'textInputPrefixDisabledColor' : 'textInputPrefixColor'
      ]};
    `}
`

const Arrow = styled(Icon)`
  position: absolute;
  pointer-events: none;

  ${p => css`
    color: ${p.theme.components.textInputArrowColor};
    right: ${p.theme.space.s - 2}px;
  `};
`

const Clear = styled(IconButton)`
  position: absolute;

  ${p => css`
    right: ${p.theme.space.xxs + 1}px;
  `};
`

const Image = styled.img`
  position: absolute;
  pointer-events: none;
  width: 24px;
  height: 24px;
  object-fit: contain;
  border-radius: 4px;

  ${p =>
    css`
      left: ${p.theme.space.s - 1}px;
    `}
`
