import React, { useState, FC } from 'react'
import styled, { css } from 'styled-components'

import { IText, Text } from '../Text'
import { IContentSwitcher, IStyledTabs } from './types'

import { setTransition } from '../../utility'

export const ContentSwitcher: FC<IContentSwitcher> = ({
  label,
  isDisabled,
  isSelected,
  ...rest
}) => {
  const [mouseDown, setMouseDown] = useState(false)

  return (
    <StyledContentSwitcher
      fontSize="m"
      fontWeight="medium"
      forwardedAs={'button' as IText['forwardedAs']}
      isDisabled={isDisabled}
      isSelected={isSelected}
      isMouseDown={mouseDown}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      {...rest}
    >
      {label}
    </StyledContentSwitcher>
  )
}

const StyledContentSwitcher = styled(Text)<IStyledTabs>`
  display: block;
  line-height: 20px;
  align-items: center;
  ${setTransition(['color', 'box-shadow'], 'productive')};
  font-family: inherit;
  background-color: transparent;
  position: relative;
  justify-content: center;
  flex-grow: 1;

  ${p => css`
    padding: ${p.theme.space.xxxs}px ${p.theme.space.xs}px;
    background-color: ${p.theme.colors.grey05};
    border: 4px solid ${p.theme.colors.grey05};
    color: ${p.theme.colors.grey50};

    cursor: ${p.onClick && 'pointer'};

    &:first-child {
      border-bottom-left-radius: ${p.theme.space.xxxs}px;
      border-top-left-radius: ${p.theme.space.xxxs}px;
    }

    &:last-child {
      border-bottom-right-radius: ${p.theme.space.xxxs}px;
      border-top-right-radius: ${p.theme.space.xxxs}px;
    }

    &:active {
      opacity: ${p.onClick && p.theme.aliases.defaultActiveOpacity};
    }

    &:focus {
      outline: none;

      ${p.isMouseDown &&
      css`
        z-index: 1;
        box-shadow: ${p.theme.focuses.normal};
      `};
    }

    ${p.isSelected &&
    css`
      color: ${p.theme.colors.grey100};
      background-color: ${p.theme.colors.inverted100};
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.aliases.disabledContent};
      pointer-events: none;
    `};
  `};
`
