import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface ShowToastParams {
  id: string
  message: string
}

export const showToast = createAction<ShowToastParams>('toast/showToast')

export const showToastReducer = (
  state: RootState['toast'],
  action: ReturnType<typeof showToast>,
): void => {
  const { id, ...rest } = action.payload
  state[id] = rest
}
