import React, { FC, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import { IIcon, Icon as IconDS, Text, Tooltip } from '@ads/front-ds'

export interface IMenuIcon {
  Icon: IIcon['name'] | React.ReactElement
  label: string
  tooltipText?: string
  isDisabled?: boolean
  isExpanded?: boolean
  onClick?: (e?: React.MouseEvent<Element, MouseEvent>) => void
  children?: ReactNode
}

interface IMenuIconItem extends Omit<IMenuIcon, 'tooltipText'> {
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  children?: ReactNode
}

const MenuIconItem: FC<IMenuIconItem> = ({
  Icon,
  label,
  isDisabled,
  children,
  isExpanded,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => (
  <Wrapper
    isDisabled={isDisabled}
    onClick={isDisabled ? undefined : onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <IconWrapper>
      {typeof Icon === 'string' ? <IconDS name={Icon} /> : Icon}
    </IconWrapper>
    <Text
      textAlign="left"
      fontSize="m"
      fontWeight="regular"
      color={isDisabled ? 'greyDisabled' : 'grey100'}
      styleCss={{ flex: 1 }}
    >
      {label}
    </Text>
    {!!children && (
      <IconDS
        color="grey100"
        name={isExpanded ? '20-arrowhead-down' : '20-arrowhead-right'}
      />
    )}
  </Wrapper>
)

const MenuIcon: FC<IMenuIcon> = ({
  Icon,
  label,
  isDisabled,
  tooltipText,
  children,
  onClick,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  return tooltipText ? (
    <Tooltip
      hide={!isTooltipVisible}
      animatable
      text={tooltipText}
      placement="right"
    >
      <MenuIconItem
        Icon={Icon}
        label={label}
        isDisabled={isDisabled}
        onClick={onClick}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
      >
        {children}
      </MenuIconItem>
    </Tooltip>
  ) : (
    <MenuIconItem
      Icon={Icon}
      label={label}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </MenuIconItem>
  )
}

const IconWrapper = styled.div`
  svg {
    min-width: 20px;
  }
`

const Wrapper = styled.button<{ isDisabled?: boolean }>`
  ${p => css`
    width: 100%;
    display: flex;
    gap: ${p.theme.space.xxs}px;
    border: 0;
    background: ${p.theme.colors.inverted100};
    margin: 0;
    padding: 6px 16px;

    ${p.isDisabled
      ? css`
          color: ${p.theme.colors.greyDisabled};
          cursor: not-allowed;
        `
      : css`
          color: ${p.theme.colors.grey100};
          cursor: pointer;

          &:hover {
            background-color: ${p.theme.colors.primary05};
          }

          &:focus {
            background-color: ${p.theme.colors.grey05};
          }
        `}
  `}
`

export default MenuIcon
