import { ResponseUser } from '@ads/front-core/api'
import { lng } from '@ads/front-core/locales'

export const ROLES: Record<
  ResponseUser['roleId'],
  { value: string; description: string }
> = {
  ACCOUNTANT: {
    value: lng('account:accountant'),
    description: lng('account:rolesAccountant'),
  },
  ANALYST: {
    value: lng('account:analyst'),
    description: lng('account:rolesAnalyst'),
  },
  MANAGER: {
    value: lng('account:representative'),
    description: lng('account:rolesManager'),
  },
  OWNER: {
    value: lng('account:owner'),
    description: lng('account:rolesOwner'),
  },
  SITE_KEEPER: {
    value: lng('account:websiteAdministrator'),
    description: lng('account:rolesSiteKeeper'),
  },
  SITE_ANALYST: {
    value: lng('account:siteAnalyst'),
    description: lng('account:siteAnalystDescription'),
  },
}
