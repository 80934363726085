import { createReducer } from '@reduxjs/toolkit'
import { preloadedState } from '../../appState'
import {
  setFormTemplates,
  setFormTemplatesReducer,
} from './setFormTemplatesReducer'
import {
  addTemplatesAfterSave,
  addTemplatesAfterSaveReducer,
} from './setAddTemplatesAfterSaveReducer'
import { setFormStatus, setFormStatusReducer } from './setFormStatusReducer'
import {
  setServiceWorkerStatus,
  setServiceWorkerStatusReducer,
} from './setServiceWorkerStatusReducer'

const formReducers = createReducer(preloadedState.form, builder => {
  builder.addCase(setFormTemplates.type, setFormTemplatesReducer)
  builder.addCase(addTemplatesAfterSave.type, addTemplatesAfterSaveReducer)
  builder.addCase(setFormStatus.type, setFormStatusReducer)
  builder.addCase(setServiceWorkerStatus.type, setServiceWorkerStatusReducer)
})

export default formReducers
