import React from 'react'
import styled from 'styled-components'

import { Button } from '../Buttons'
import { Grid } from '../Grid'
import { Illustration } from '../Illustration'
import { Text } from '../Text'
import { CorgiProps } from './types'

export const Corgi = ({
  title,
  description,
  illustration,
  action,
  secondaryAction,
  ...rest
}: CorgiProps) => {
  const primaryAction = action || secondaryAction

  return (
    <MessageContainer gap="s" {...rest}>
      <Text
        mb="s"
        fontSize="xl"
        lineHeight="l"
        fontWeight="medium"
        textAlign="center"
      >
        {title}
      </Text>
      <CorgiContent>{description}</CorgiContent>

      <Illustration name={illustration} mx="auto" my="l" />
      {primaryAction && (
        <Button {...primaryAction} variant="cta" intent="fitted">
          {primaryAction.label}
        </Button>
      )}
      {action && secondaryAction && (
        <Button {...secondaryAction}>{secondaryAction.label}</Button>
      )}
    </MessageContainer>
  )
}

export const MessageContainer = styled(Grid)`
  max-width: 377px;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const CorgiContent = styled(Grid)`
  ${p => `
    color: ${p.theme.colors.grey100};
    font-size: ${p.theme.fontSizes.m}px;
    line-height: ${p.theme.lineHeights.m};
    text-align: center;
  `}
`
