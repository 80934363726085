import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import {
  clearFetchCache,
  clearFetchCacheReducer,
} from './clearFetchCacheReducer'
import { fetchCache, fetchCacheReducer } from './fetchCacheReducer'

const fetchCacheReducers = createReducer(
  preloadedRootState.fetchCache,
  builder => {
    builder.addCase(fetchCache.type, fetchCacheReducer),
      builder.addCase(clearFetchCache.type, clearFetchCacheReducer)
  },
)

export default fetchCacheReducers
