import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface HideToastParams {
  id: string
}

export const hideToast = createAction<HideToastParams>('toast/hideToast')

export const hideToastReducer = (
  state: RootState['toast'],
  action: ReturnType<typeof hideToast>,
): void => {
  const { id } = action.payload
  delete state[id]
}
