import { differenceInMilliseconds } from 'date-fns'
import {
  GenericCampaignTargeting,
  GenericCampaignTargetingType,
  RequestGenericCampaignCalculator,
} from '@ads/front-core/api'
import { Duration } from '@ads/front-core/interfaces/duration'
import { lng } from '../locales'

export const TARGETING_KEY: Partial<
  Record<GenericCampaignTargetingType, keyof GenericCampaignTargeting>
> = {
  SEGMENT: 'segment',
  TOPIC: 'topic',
}

export const connectAdjacentAges = (
  selectedTargetingAge: string[],
): string[] => {
  if (!selectedTargetingAge?.length) {
    return []
  }

  const sortedAges =
    selectedTargetingAge?.sort(
      (a, b) => Number(a.slice(0, 2)) - Number(b.slice(0, 2)),
    ) || []

  return sortedAges.reduce((acc, cV) => {
    const prev = Number(acc.at(-1)?.slice(-2))
    const next = cV.includes('+')
      ? Number(cV.slice(-3, -1))
      : Number(cV.slice(0, 2))

    const isAdjacent = next - prev === 1

    if (isAdjacent) {
      const result = `${acc.at(-1).slice(0, 2)}-${
        cV.includes('+') ? cV.slice(-3) : cV.slice(-2)
      }`

      return [...acc.filter(item => !item.includes(prev)), result]
    }

    return [...acc, cV]
  }, [])
}

export const generateDuration = (startDate: Date, endDate: Date) => {
  const timeDifference = differenceInMilliseconds(
    new Date(endDate),
    new Date(startDate),
  )

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  )
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))

  return { days, hours, minutes }
}

export const durationToString = (duration: Duration) =>
  `${lng('calendar:days', { count: duration.days })} ${
    duration.hours ? `${duration.hours} h ` : ''
  }${duration.minutes ? `${duration.minutes} min` : ''}`

export const getCalculatorRequestBody = (
  calculator: RequestGenericCampaignCalculator,
): RequestGenericCampaignCalculator => {
  switch (calculator.type) {
    case 'NATIVE':
      return {
        type: calculator.type,
        budget: calculator.budget,
        targeting: calculator.targeting,
      }
    case 'VIDEO':
      return {
        type: calculator.type,
        budget: calculator.budget,
        duration: calculator.duration,
        budgetModel: calculator.budgetModel,
      }
    case 'MIX':
      return {
        type: calculator.type,
        budget: calculator.budget,
        creationTypes: calculator.creationTypes,
      }
    default:
      return {
        type: calculator.type,
        budget: calculator.budget,
      }
  }
}
