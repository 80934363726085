import { createReducer } from '@reduxjs/toolkit'
import { preloadedState } from '../../appState'
import {
  setFeedsLoading,
  setFeedsLoadingReducer,
} from './setFeedsLoadingReducer'
import { setFeeds, setFeedsReducer } from './setFeedsReducer'

const feedsReducers = createReducer(preloadedState.feeds, builder => {
  builder.addCase(setFeeds.type, setFeedsReducer)
  builder.addCase(setFeedsLoading.type, setFeedsLoadingReducer)
})

export default feedsReducers
