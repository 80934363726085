import React from 'react'
import styled, { css } from 'styled-components'

import { useNanoid } from '../../../utility'
import { IconButton, TextButton } from '../../Buttons'
import { Icon } from '../../Icon'
import { Text } from '../../Text'

import { Flex } from '../../Flex'
import { IBanner, IBannerIcon, IContainer } from './types'

const getIconProps = (
  variant: IBanner['variant'],
): {
  name: IBannerIcon['name']
  iconColor: IBannerIcon['iconColor']
} => {
  switch (variant) {
    case 'positive':
      return {
        name: '20-check-stroke',
        iconColor: 'bannerSuccessIconColor',
      }
    case 'informative':
      return {
        name: '20-info',
        iconColor: 'bannerInformativeIconColor',
      }
    case 'warning':
      return {
        name: '20-warning',
        iconColor: 'bannerWarningIconColor',
      }
    case 'error':
      return {
        name: '20-alert',
        iconColor: 'bannerErrorIconColor',
      }
    default:
      return {
        name: '20-info',
        iconColor: 'bannerInformativeIconColor',
      }
  }
}

const getBg = (variant: IBanner['variant']) => {
  switch (variant) {
    case 'positive':
      return 'bannerSuccessBackgroundColor'
    case 'informative':
      return 'bannerInformativeBackgroundColor'
    case 'warning':
      return 'bannerWarningBackgroundColor'
    case 'error':
      return 'bannerErrorBackgroundColor'
    default:
      return 'bannerInformativeBackgroundColor'
  }
}

export const Banner = ({
  label,
  variant = 'informative',
  action,
  role = 'banner',
  onCloseClick,
  isActionsBottom,
  ...rest
}: IBanner) => {
  const [ids] = useNanoid(Array.isArray(action) ? action.length : 1)

  return (
    <Container
      py="s"
      pl="m"
      pr={onCloseClick ? 'xxxl' : 'm'}
      variant={variant}
      isCloseClick={!!onCloseClick}
      role={role}
      {...rest}
    >
      <BannerIcon {...getIconProps(variant)} />

      <Flex
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection={isActionsBottom ? 'column' : 'row'}
        pl={'xxs'}
        flexWrap="wrap"
        styleCss={{
          gap: '8px 24px ',
        }}
      >
        <Text textAlign="left">{label}</Text>

        {action && (
          <Flex gap="s">
            {Array.isArray(action) ? (
              action.map((button, index) => (
                <Button
                  variant={index === 0 ? 'primary' : 'secondary'}
                  onClick={button.onClick}
                  key={ids[index]}
                >
                  {button.label}
                </Button>
              ))
            ) : (
              <Button
                onClick={action.onClick}
                aria-label={action['aria-label']}
              >
                {action.label}
              </Button>
            )}
          </Flex>
        )}
      </Flex>

      {onCloseClick && (
        <Close
          name="20-close"
          variant="bareDark"
          mr="m"
          onClick={onCloseClick}
          aria-label="Zamknij"
        />
      )}
    </Container>
  )
}

const Container = styled(Flex)<IContainer>`
  position: relative;
  ${p => css`
    background-color: ${p.theme.components[getBg(p.variant)]};
    color: ${p.theme.components.bannerColor};
  `};
`

const BannerIcon = styled(Icon)<IBannerIcon>`
  color: ${p => p.theme.components[p.iconColor]};
  flex-basis: 20px;
  flex-grow: 0;
  flex-shrink: 0;
`

const Close = styled(IconButton)`
  position: absolute;
  right: 0;
`

const Button = styled(TextButton)`
  white-space: nowrap;
`
