import React, { FC } from 'react'
import { Text, Flex } from '@ads/front-ds'
import { TTranslationsKeys, lng } from '@ads/front-core/locales'

export interface ICampaignSectionHeader {
  headerLngKey: TTranslationsKeys
  descriptionLngKey?: TTranslationsKeys
}

export const CampaignSectionHeader: FC<ICampaignSectionHeader> = ({
  headerLngKey,
  descriptionLngKey,
}) => (
  <Flex flexDirection="column" gap="xxs">
    <Text fontSize="l">{lng(headerLngKey)}</Text>
    {descriptionLngKey && (
      <Text
        fontSize="m"
        color="grey50"
        styleCss={{ whiteSpace: 'break-spaces' }}
      >
        {lng(descriptionLngKey)}
      </Text>
    )}
  </Flex>
)
