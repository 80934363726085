import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Flex } from '../Flex'

interface ModalFooterProps {
  /** Intended to contain the modal action. Use component Button here. */
  children?: ReactNode
}

export const ModalFooter = (props: ModalFooterProps) => {
  return <Footer mt="l" {...props} />
}

const Footer = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
`
