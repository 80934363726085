import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { AppState } from '../../appState'

export const setFormStatus = createAction<AppState['form']['status']>(
  'form/setFormStatus',
)

export const setFormStatusReducer = (
  state: AppState['form'],
  action: ReturnType<typeof setFormStatus>,
): void => {
  state.status = cloneDeep(action.payload)
}
