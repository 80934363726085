/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { PaymentType } from '../models/PaymentType'
import type { RequestAdminChangePaymentType } from '../models/RequestAdminChangePaymentType'
import type { RequestAdminCreateNewClient } from '../models/RequestAdminCreateNewClient'
import type { RequestAdminSetClientAccess } from '../models/RequestAdminSetClientAccess'
import type { RequestAdminUpdateNewClient } from '../models/RequestAdminUpdateNewClient'
import type { RequestTransferBankierDukat } from '../models/RequestTransferBankierDukat'
import type { ResponseAdminClient } from '../models/ResponseAdminClient'
import type { ResponseAdminClientWithBalanceAndCost } from '../models/ResponseAdminClientWithBalanceAndCost'
import type { ResponseAdminClientWithBonus } from '../models/ResponseAdminClientWithBonus'
import type { ResponseAdminStatusClient } from '../models/ResponseAdminStatusClient'
import type { ResponseUserClients } from '../models/ResponseUserClients'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class AdminClientService {
  /**
   * inicjuje transfer danych finansowych miedzy bankierem a dukatem
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static bankierDukatTransfer({
    requestBody,
  }: {
    /**
     * argumenty wyszukiwania
     */
    requestBody?: RequestTransferBankierDukat
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/bankier-dukat-transfer',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * blokuje/odblokowuje klienta
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static blockClient({
    clientId,
    requestBody,
  }: {
    clientId: number
    /**
     * Dane klienta do zablokowania/odblokowania
     */
    requestBody?: RequestAdminSetClientAccess
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/client/{clientId}/set-access',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zmiana typu platnosci klienta
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static changePaymentType({
    clientId,
    requestBody,
  }: {
    clientId: number
    /**
     * Typ płatności jaki ma być ustawiony
     */
    requestBody: RequestAdminChangePaymentType
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/client/{clientId}/payment-type',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca listę klientów przypisanych do admina
   *
   * @returns ResponseUserClients podstawowe dane klientów (id, name)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAdminClients({
    search,
    page,
    limit,
    status,
    sortBy = 'name',
    sortDir = 'desc',
  }: {
    /**
     * napis po którym będą fltrowane wyniki (nazwy klientów)
     */
    search: string
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * oczekiwany status klienta
     */
    status?: 'INACTIVE' | 'ACTIVE'
    /**
     * pole po którym będą sortowane wyniki zapytania
     */
    sortBy?: 'createDate' | 'name'
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
  }): CancelablePromise<ResponseUserClients | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/client',
      query: {
        search: search,
        page: page,
        limit: limit,
        status: status,
        sortBy: sortBy,
        sortDir: sortDir,
      },
    })
  }

  /**
   * Zwraca listę klientów wraz ze statusami przypisanych do admina
   *
   * @returns ResponseAdminStatusClient podstawowe dane klientów (id, name)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAdminStatusClients({
    search,
    page,
    limit,
    status,
    sortBy = 'name',
    sortDir = 'desc',
    advOrderOwnerId,
    onlyAgency,
    paymentType,
  }: {
    /**
     * napis po którym będą filtrowane wyniki (nazwy klientów)
     */
    search: string
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * oczekiwany status klienta
     */
    status?: 'INACTIVE' | 'ACTIVE'
    /**
     * pole po którym będą sortowane wyniki zapytania
     */
    sortBy?: 'createDate' | 'name'
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
    /**
     * właściciel zlecenia reklamowego
     */
    advOrderOwnerId?: number
    /**
     * type agencje reklamowe
     */
    onlyAgency?: boolean
    paymentType?: PaymentType
  }): CancelablePromise<ResponseAdminStatusClient | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/status-client',
      query: {
        search: search,
        page: page,
        limit: limit,
        status: status,
        sortBy: sortBy,
        sortDir: sortDir,
        advOrderOwnerId: advOrderOwnerId,
        onlyAgency: onlyAgency,
        paymentType: paymentType,
      },
    })
  }

  /**
   * Zwraca listę klientów wraz z właścicielem zlecenia reklamowego oraz saldem i kosztami z bankiera
   *
   * @returns ResponseAdminClientWithBalanceAndCost podstawowe dane klientów wraz z saldem i kosztem z bankiera
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static adminGetClientsForAdvOrderOwners({
    search,
    page,
    limit,
    costFromDate,
    costToDate,
    status,
    sortBy = 'name',
    sortDir = 'desc',
    advOrderOwnerId,
  }: {
    /**
     * napis po którym będą filtrowane wyniki (nazwy klientów)
     */
    search: string
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * data od której mają być sumowane koszty z bankiera
     */
    costFromDate: string
    /**
     * data do której mają być sumowane koszty z bankiera
     */
    costToDate: string
    /**
     * oczekiwany status klienta
     */
    status?: 'INACTIVE' | 'ACTIVE'
    /**
     * pole po którym będą sortowane wyniki zapytania
     */
    sortBy?: 'createDate' | 'name'
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
    /**
     * właściciel zlecenia reklamowego
     */
    advOrderOwnerId?: number
  }): CancelablePromise<ResponseAdminClientWithBalanceAndCost | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/client-for-advertising-order-owner',
      query: {
        search: search,
        page: page,
        limit: limit,
        status: status,
        sortBy: sortBy,
        sortDir: sortDir,
        advOrderOwnerId: advOrderOwnerId,
        costFromDate: costFromDate,
        costToDate: costToDate,
      },
    })
  }

  /**
   * Zwraca listę klientów z przyznanym bonusem 1000 za 1000
   *
   * @returns ResponseAdminClientWithBonus podstawowe dane klientów wraz z saldem bunusu i datą przyznania
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static adminGetClientsWithBonus({
    page,
    limit,
    search,
    sortDir,
    sortBy = 'name',
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * napis po którym będą filtrowane wyniki (nazwa, hbpId, id)
     */
    search?: string
    /**
     * kierunek sortowania
     */
    sortDir?: 'asc' | 'desc'
    /**
     * pole po którym będą sortowane wyniki zapytania
     */
    sortBy?: 'name' | 'bonusDate'
  }): CancelablePromise<ResponseAdminClientWithBonus | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/client-with-bonus',
      query: {
        search: search,
        page: page,
        limit: limit,
        sortDir: sortDir,
        sortBy: sortBy,
      },
    })
  }

  /**
   * Tworzy nowego klienta
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static createAdminNewClient({
    requestBody,
  }: {
    /**
     * Dane klienta do zmodyfikowania
     */
    requestBody: RequestAdminCreateNewClient
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/new-client',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Aktualizuje dane klienta
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static updateAdminNewClient({
    clientId,
    requestBody,
  }: {
    clientId: number
    /**
     * Dane klienta do zmodyfikowania
     */
    requestBody: RequestAdminUpdateNewClient
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/new-client/{clientId}',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Pobiera dane klienta do edycji po stronie admina
   * @returns ResponseAdminClient dane klienta
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAdminNewClient({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<ResponseAdminClient | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/new-client/{clientId}',
      path: {
        clientId: clientId,
      },
    })
  }
}
