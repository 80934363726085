import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Button, IButton, Icon } from '@ads/front-ds'

export const CloseButton: FC<IButton> = props => (
  <ButtonStyled variant="secondary" {...props}>
    <Icon name="20-close" />
  </ButtonStyled>
)

const ButtonStyled = styled(Button)`
  ${p => css`
    display: flex;
    color: ${p.theme.colors.grey100};
    padding: ${p.theme.space.xxs}px;
    border-radius: ${p.theme.radii.fullRounded};
    border: ${p.theme.borders.thinGrey20};
  `}
`
