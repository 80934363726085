import React, { forwardRef, useState, useRef, useEffect, Ref } from 'react'
import styled, { css } from 'styled-components'

import { Flex } from '../Flex'
import { getPrefix, Prefix, Container, Input } from '../TextInput/TextInput'
import { IconButton } from '../Buttons/IconButton'
import { IActionIconInput } from './types'

export const ActionIconInput = forwardRef(
  (props: IActionIconInput, ref: Ref<HTMLDivElement>) => {
    const prefixRef = useRef<HTMLDivElement>(null)
    const [prefixWidth, setPrefixWidth] = useState<number | null>(null)

    const {
      prefix,
      icon,
      value,
      defaultValue,
      placeholder,
      type,
      id,
      isDisabled,
      isError,
      isReadOnly,
      onChange,
      onActionClick,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      ...rest
    } = props

    const inputProps = {
      ref,
      value,
      defaultValue,
      placeholder,
      type,
      id,
      isDisabled,
      isError,
      offsetLeft: prefixWidth,
      isReadOnly,
      onChange,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      'aria-label': (rest['aria-label'] = 'Input'),
    }

    useEffect(() => {
      setPrefixWidth(prefixRef.current ? prefixRef.current.offsetWidth : null)
    }, [prefix])

    return (
      <Container {...rest} isVisible={!!prefixWidth || !prefix}>
        {prefix && (
          <Prefix ref={prefixRef} isDisabled={isDisabled}>
            {getPrefix(prefix)}
          </Prefix>
        )}
        <StyledInput {...inputProps} />
        <ButtonHolder alignItems="center">
          <IconButton
            aria-label="action button"
            variant="bareDark"
            name={icon}
            isDisabled={isDisabled}
            onClick={onActionClick}
          />
        </ButtonHolder>
      </Container>
    )
  },
)

const StyledInput = styled(p => <Input {...p} />)`
  ${p => css`
    padding-right: ${p.theme.space.xl - 1}px;

    ${p.offsetLeft &&
    css`
      padding-left: ${p.offsetLeft +
      p.theme.space.m -
      p.theme.space.xxxs -
      1}px;
    `};
  `};
`

const ButtonHolder = styled(Flex)`
  position: absolute;

  ${p => css`
    right: ${p.theme.space.s - 1}px;
  `};
`
