import React from 'react'
import styled from 'styled-components'

import { Box } from '../Box'
import { Text } from '../Text'
import { ITitle } from './types'

export interface ModalHeaderProps {
  /** Modal title in the ModalHeader. */
  title: string
}

export const ModalHeader = (props: ModalHeaderProps) => {
  const { title } = props

  return (
    <Header>
      <StyledTitle
        fontSize="xl"
        lineHeight="l"
        fontWeight="bold"
        pr="m"
        id="modalTitle"
      >
        {title}
      </StyledTitle>
    </Header>
  )
}

const Header = styled(Box)`
  display: flex;
  align-items: flex-start;
`

const StyledTitle = styled(Text)<ITitle>`
  word-break: break-all;
`
