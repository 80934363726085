import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setRunCampaignAfterSave = createAction<boolean>(
  'campaign/setRunCampaignAfterSave',
)

export const setRunCampaignAfterSaveReducer = (
  state: AppState['campaign'],
  action: ReturnType<typeof setRunCampaignAfterSave>,
): void => {
  state.runCampaignAfterSave = action.payload
}
