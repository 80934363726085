/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestLoggedUserSendContact } from '../models/RequestLoggedUserSendContact'
import type { RequestSendContact } from '../models/RequestSendContact'
import type { ResponseDictionaryByType } from '../models/ResponseDictionaryByType'
import type { ResponseGetCountries } from '../models/ResponseGetCountries'
import type { ResponseGetCurrentVisibleAdminUserMessagesItem } from '../models/ResponseGetCurrentVisibleAdminUserMessagesItem'
import type { ResponseGetIABCategories } from '../models/ResponseGetIABCategories'
import type { ResponseGetSegments } from '../models/ResponseGetSegments'
import type { ResponseSegment } from '../models/ResponseSegment'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class OtherService {
  /**
   * Zwraca dane słownikowe
   *
   * @returns ResponseDictionaryByType słowniki
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getDictionaryByType({
    type,
    filter,
  }: {
    type:
      | 'shop-category'
      | 'user-role'
      | 'state'
      | 'mc-region'
      | 'mc-country'
      | 'mc-city'
      | 'mc-sex'
      | 'mc-age'
      | 'mix-campaign-city'
      | 'article-campaign-website'
    filter?: string
  }): CancelablePromise<ResponseDictionaryByType | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dictionary/{type}',
      path: {
        type: type,
      },
      query: {
        filter: filter,
      },
    })
  }

  /**
   * Zwraca segmenty targetingowe AC
   *
   * @returns ResponseGetSegments segmenty
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getSegments({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponseGetSegments | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dictionary/ac-segments',
      query: {
        shopId: shopId,
      },
    })
  }

  /**
   * Zwraca stare segmenty targetingowe AC
   *
   * @returns ResponseGetSegments segmenty
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getOldSegments({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponseGetSegments | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dictionary/old-ac-segments',
      query: {
        shopId: shopId,
      },
    })
  }

  /**
   * Zwraca drzewo kategorii IAB
   *
   * @returns ResponseGetIABCategories drzewo katgorii
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getIabCategories({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponseGetIABCategories | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dictionary/iab-categories',
      query: {
        shopId: shopId,
      },
    })
  }

  /**
   * Zwraca segmenty targetingowe AC dla sklepu
   *
   * @returns ResponseGetSegments segmenty
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getRetargetingSegments({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<ResponseGetSegments | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dictionary/ac-retargeting-segments',
      query: {
        shopId: shopId,
      },
    })
  }

  /**
   * Zwraca słownik kraje
   *
   * @returns ResponseGetCountries kraje
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCountries(): CancelablePromise<
    ResponseGetCountries | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dictionary/country',
    })
  }

  /**
   * Zwraca listę wszystkich prywatnych segmentów AC
   *
   * @returns ResponseSegment segmenty
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getPrivateAcSegments(): CancelablePromise<
    Array<ResponseSegment> | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/dictionary/private-ac-segments',
    })
  }

  /**
   * Zwraca aktualne komunikaty admin-użytkownik, które użytkownik powinien widzieć
   * @returns ResponseGetCurrentVisibleAdminUserMessagesItem lista aktualnych komunikatów admin-użytkownik
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getCurrentVisibleAdminUserMessages(): CancelablePromise<
    Array<ResponseGetCurrentVisibleAdminUserMessagesItem> | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin-user-messages',
    })
  }

  /**
   * Sprawdza połączenie z bazą danych
   * @returns string status połączenia z bazą danych
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getHealthcheck(): CancelablePromise<string | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/healthcheck',
    })
  }

  /**
   * Wysyła maila z danymi z formularza kontaktowego
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static sendContact({
    requestBody,
  }: {
    /**
     * Dane formularza kontaktowego
     */
    requestBody: RequestSendContact
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/contact',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Wysyła maila z danymi z formularza kontaktowego zalogowanego użytkownika
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static loggedUserSendContact({
    requestBody,
  }: {
    /**
     * Dane formularza kontaktowego
     */
    requestBody: RequestLoggedUserSendContact
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/logged-user-contact',
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
