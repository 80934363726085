import { createReducer } from '@reduxjs/toolkit'
import { preloadedRootState } from '../../rootState'
import {
  removeFormDraft,
  removeFormDraftReducer,
} from './removeFormDraftReducer'
import {
  setDemoDialogVisibility,
  setDemoDialogVisibilityReducer,
} from './setDemoDialogVisibility'
import { setFormDraft, setFormDraftReducer } from './setFormDraftReducer'
import {
  setFormSubmittingState,
  setFormSubmittingStateReducer,
} from './setFormSubmittingStateReducer'
import {
  setHeaderBannerVisibility,
  setHeaderBannerVisibilityReducer,
} from './setHeaderBannerVisibility'
import { setIsMobile, setIsMobileReducer } from './setIsMobileReducer'
import { setMaintenance, setMaintenanceReducer } from './setMaintenanceReducer'
import {
  setReleaseVersion,
  setReleaseVersionReducer,
} from './setReleaseVersionReducer'

const setMaintenanceReducers = createReducer(
  preloadedRootState.app,
  builder => {
    builder.addCase(setMaintenance.type, setMaintenanceReducer)
    builder.addCase(setReleaseVersion.type, setReleaseVersionReducer)
    builder.addCase(setFormDraft.type, setFormDraftReducer)
    builder.addCase(removeFormDraft.type, removeFormDraftReducer)
    builder.addCase(setFormSubmittingState.type, setFormSubmittingStateReducer)
    builder.addCase(setIsMobile.type, setIsMobileReducer)
    builder.addCase(
      setHeaderBannerVisibility.type,
      setHeaderBannerVisibilityReducer,
    )
    builder.addCase(
      setDemoDialogVisibility.type,
      setDemoDialogVisibilityReducer,
    )
  },
)

export default setMaintenanceReducers
