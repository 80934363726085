import React, { forwardRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { IText, Text } from '../Text'
import { IStyledTabs, ITabs } from './types'

import { checkIntent, setTransition } from '../../utility'

export const Tabs = forwardRef(
  (props: ITabs, ref: React.Ref<HTMLDivElement>) => {
    const { label, intent, ...rest } = props

    const tabProps = {
      ref,
      ...rest,
    }

    const [mouseDown, setMouseDown] = useState(false)

    return (
      <StyledTab
        fontSize="m"
        lineHeight="m"
        fontWeight="medium"
        forwardedAs={'button' as IText['forwardedAs']}
        isSelected={checkIntent('selected', intent)}
        isMouseDown={mouseDown}
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => setMouseDown(false)}
        {...tabProps}
      >
        {label}
      </StyledTab>
    )
  },
)

const StyledTab = styled(Text)<IStyledTabs>`
  display: inline-flex;
  align-items: center;
  ${setTransition(['color', 'box-shadow'], 'productive')};
  font-family: inherit;
  border: 0;
  background-color: transparent;
  position: relative;

  ${p => css`
    padding: ${p.size === 'm' ? p.theme.space.m : p.theme.space.s}px 0;
    color: ${p.theme.aliases.defaultContentSecondary};
    cursor: ${p.onClick && 'pointer'};

    &:active {
      opacity: ${p.onClick && p.theme.aliases.defaultActiveOpacity};
    }

    &:focus {
      outline: none;

      ${p.isMouseDown &&
      css`
        box-shadow: ${p.theme.focuses.normal};
      `};
    }

    ${p.isSelected &&
    css`
      color: ${p.theme.aliases.ctaContent};

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: ${p.theme.aliases.ctaContent};
      }
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.aliases.disabledContent};
      pointer-events: none;

      &::after {
        display: none;
      }
    `};
  `};
`
