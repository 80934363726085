import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../../Text'
import { IMenuAddon } from './types'

export const MenuHeader: FC<React.PropsWithChildren<IMenuAddon>> = ({
  label,
  ...rest
}) => {
  console.warn('<MenuHeader /> is deprecated, use <MenuAddon /> component!')

  return (
    <StyledText fontSize="m" lineHeight="m" color="grey50" {...rest}>
      {label}
    </StyledText>
  )
}

const StyledText = styled(Text)`
  ${p => css`
    padding: ${p.theme.space.xs / 2}px ${p.theme.space.xxs}px;
  `};
`
