import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from '../../Icon'
import { CircleButtonProps } from './types'
import { Primary, Secondary } from './variants'

const setVariant = (variant: CircleButtonProps['variant']) => {
  switch (variant) {
    case 'primary':
      return Primary
    case 'secondary':
      return Secondary
    default:
      return Secondary
  }
}

export const CircleButton = ({
  size = 'large',
  variant = 'primary',
  isDisabled = false,
  isSelected,
  onClick,
  name = '20-plus',
  as = 'button',
  'aria-label': ariaLabel,
  ...rest
}: CircleButtonProps) => {
  const CircleButton = setVariant(variant)
  const [isMouseDown, setIsMouseDown] = useState(false)

  return (
    <CircleButton
      size={size}
      variant={variant}
      disabled={isDisabled}
      isDisabled={isDisabled}
      isSelected={isSelected}
      isMouseDown={isMouseDown}
      onMouseDown={() => setIsMouseDown(true)}
      onBlur={() => setIsMouseDown(false)}
      onClick={onClick}
      forwardedAs={as ? as : 'button'}
      aria-label={ariaLabel}
      {...rest}
    >
      {name && (
        <StyledIcon
          color={
            variant === 'primary'
              ? 'inverted100'
              : variant === 'secondary' && isSelected
              ? 'primary50'
              : 'grey100'
          }
          name={name}
          buttonSize={size}
          aria-hidden="true"
        />
      )}
    </CircleButton>
  )
}

interface StyledIconProps {
  buttonSize?: string
}

const StyledIcon = styled(Icon)<StyledIconProps>`
  flex-shrink: 0;

  ${p => css`
    ${p.buttonSize !== 'large' &&
    css`
      width: ${p.theme.space.s}px;
      height: ${p.theme.space.s}px;
    `};
  `}
`
