import { createAction } from '@reduxjs/toolkit'
import { ResponseGetIABCategories } from '@ads/front-core/api'
import { AppState } from '../../appState'

export const setCampaignIAB = createAction<ResponseGetIABCategories>(
  'campaign/setCampaignIAB',
)

export const setCampaignIABReducer = (
  state: AppState['campaign'],
  action: ReturnType<typeof setCampaignIAB>,
): void => {
  state.campaignIAB = action.payload
}
