import { createAction } from '@reduxjs/toolkit'
import { call, takeEvery } from 'redux-saga/effects'

export function* sagaLogout(): Generator {
  yield takeEvery(logout.type, logoutSaga)
}

export const logout = createAction('saga/logout')

export function* logoutSaga(): Generator {
  yield call(window.OneLoginWidget.logout)
}
