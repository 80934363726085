import React from 'react'
import styled, { css } from 'styled-components'

import { checkIntent } from '../../../utility'

import { Box, IBox } from '../../Box'
import { Flex } from '../../Flex'
import { Icon } from '../../Icon'
import { Text } from '../../Text'
import { Button, IButton } from '../Button'
import { IDivider, ISplitButton } from './types'

const switchBgColor = (
  variant: ISplitButton['variant'],
  disabled?: boolean,
) => {
  switch (variant) {
    case 'primary':
      return disabled
        ? 'splitButtonPrimaryDividerDisabled'
        : 'splitButtonPrimaryDivider'
    case 'secondary':
      return disabled
        ? 'splitButtonSecondaryDividerDisabled'
        : 'splitButtonSecondaryDivider'
    default:
      return disabled
        ? 'splitButtonCtaDividerDisabled'
        : 'splitButtonCtaDivider'
  }
}

export const SplitButton = ({
  children,
  as = 'button',
  variant = 'primary',
  isDisabled,
  isDisabledIcon,
  isDisabledButton,
  onClick,
  onIconClick,
  isLoading,
  intent,
  arrowDirection = 'down',
  shift,
  ...rest
}: ISplitButton) => (
  <Flex {...rest}>
    <ButtonContainer intent={intent}>
      <MainButton
        forwardedAs={as as IBox['as']}
        isDisabled={isDisabled || isDisabledButton}
        variant={variant}
        onClick={onClick}
        intent={intent}
        isLoading={isLoading}
      >
        <Text
          as="span"
          styleCss={{
            marginLeft: shift ? '36px' : '0',
          }}
        >
          {children}
        </Text>
      </MainButton>
    </ButtonContainer>
    <Divider variant={variant} isDisabled={isDisabled} />
    <ArrowButton
      isDisabled={isDisabled || isDisabledIcon}
      variant={variant}
      onClick={isLoading ? undefined : onIconClick}
      isLoading={isLoading}
      aria-label="Rozwiń"
    >
      <Icon name={`20-arrowhead-${arrowDirection}`} />
    </ArrowButton>
  </Flex>
)

const ButtonContainer = styled(Box)<Pick<IButton, 'intent'>>`
  &:focus-within {
    position: relative;
    z-index: 1;
  }
  ${p => css`
    ${checkIntent('fitted', p.intent) &&
    css`
      width: calc(100% - 37px);
    `};
  `}
`

const Divider = styled(Box)<IDivider>`
  flex: 0 0 1px;
  width: 1px;
  background-color: ${({ variant, isDisabled, theme }) =>
    theme.components[switchBgColor(variant, isDisabled)]};
`

const MainButton = styled(Button)`
  border-right: 0;

  ${p => css`
    border-radius: ${p.theme.radii.rounded} 0 0 ${p.theme.radii.rounded};
  `}
`

const ArrowButton = styled(Button)`
  border-left: 0;
  padding: 0;
  width: 36px;

  ${p => css`
    border-radius: 0 ${p.theme.radii.rounded} ${p.theme.radii.rounded} 0;

    ${p.isLoading && `cursor: not-allowed;`}
  `}
`
