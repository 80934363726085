import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface SetFormDraftParams {
  key: string
  val: string
}

export const setFormDraft = createAction<SetFormDraftParams>('setFormDraft')

export const setFormDraftReducer = (
  state: RootState['app'],
  action: ReturnType<typeof setFormDraft>,
): void => {
  const { key, val } = action.payload
  state.formDraft = { ...state.formDraft, [key]: val }
}
