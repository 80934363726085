import {
  addMonths,
  addYears,
  compareAsc,
  getHours,
  getMinutes,
  isSameMonth,
  setHours,
  setMinutes,
  subMonths,
  subYears,
} from 'date-fns'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../../Box'
import { TextButton } from '../../Buttons'
import { Flex } from '../../Flex'
import { FormGroup } from '../../FormGroup'
import { TimeInput } from '../TimeInput/TimeInput'
import { Calendar } from './Calendar'
import { IDatepicker } from './types'

const getCurrentDate = (
  date: Date | Date[] | undefined,
  display: IDatepicker['display'],
) => {
  if (!date) return new Date()

  if (Array.isArray(date)) {
    if (date.length === 0) return new Date()

    const [start, end] = date.sort(compareAsc)

    if (display === 'dual')
      return isSameMonth(end, addMonths(start, 1)) ? subMonths(end, 1) : start

    return start
  }

  return date
}
export const Datepicker = forwardRef<HTMLDivElement, IDatepicker>(
  (props, ref) => {
    const {
      date,
      disabledDates,
      disabledFrom,
      disabledTo,
      isHidePicked,
      shortcuts,
      display = 'days',
      onHeaderClick,
      onDateChange,
      action,
      dualStart,
      ...rest
    } = props

    const dualStartMonth = dualStart || 0

    const [currentDate, setCurrentDate] = useState(
      getCurrentDate(date, display),
    )
    const [pickedDate, setPickedDate] = useState<null | Date | Date[]>(null)
    const [hovered, setHovered] = useState<null | Date[]>(null)
    const [hasRange, setHasRange] = useState(false)
    const [preventUpdate, setPreventUpdate] = useState(false)

    const setRange = useCallback(() => {
      setCurrentDate(getCurrentDate(date, display))
    }, [date, display])

    useEffect(() => {
      setHasRange(Array.isArray(date))
      if (!Array.isArray(date) && date)
        setCurrentDate(getCurrentDate(date, display))
      if (!preventUpdate) setRange()
    }, [date, preventUpdate, setRange, display])

    const handleOnCellClick = (date: Date) => {
      setPreventUpdate(true)

      if (!hasRange) return onDateChange(date)

      if (hasRange && pickedDate) {
        hovered && setHovered(null)
        if (Array.isArray(pickedDate)) return
        onDateChange([pickedDate, date])
        setPickedDate(null)
      } else {
        setPickedDate(date)
      }
    }

    const handleOnHourClick = (date: Date) => {
      const hours = getHours(date)
      const minutes = getMinutes(date)

      setCurrentDate(setMinutes(setHours(currentDate, hours), minutes))
      onDateChange(date)
    }

    const handleOnCellHover = (date: Date) => {
      if (!pickedDate || Array.isArray(pickedDate)) return
      setHovered([pickedDate, date])
    }

    const addMonthToCurrent = () => {
      setCurrentDate(addMonths(currentDate, 1))
    }

    const subMonthToCurrent = () => {
      setCurrentDate(subMonths(currentDate, 1))
    }

    const addYearsToCurrent = (length: number = 1) => {
      setCurrentDate(addYears(currentDate, length))
    }

    const subYearsToCurrent = (length: number = 1) => {
      setCurrentDate(subYears(currentDate, length))
    }

    const timeProps = {
      currentDate,
      disabledFrom,
      disabledTo,
    }

    const switchCalendar = (view: IDatepicker['display']) => {
      const picked = isHidePicked ? undefined : pickedDate ? pickedDate : date
      const commonProps = {
        currentDate: currentDate,
        picked,
        hovered,
        disabledDates,
        disabledFrom,
        disabledTo,
        onHeaderClick,
        onCellClick: handleOnCellClick,
        onCellHover: handleOnCellHover,
        onMouseLeave: () => setHovered(null),
      }

      switch (view) {
        case 'time':
        case 'days':
          return (
            <Calendar
              {...commonProps}
              display="days"
              view={view}
              onPreviousClick={subMonthToCurrent}
              onNextClick={addMonthToCurrent}
            />
          )
        case 'months':
          return (
            <Calendar
              {...commonProps}
              display="months"
              onPreviousClick={() => subYearsToCurrent()}
              onNextClick={() => addYearsToCurrent()}
            />
          )
        case 'years':
          return (
            <Calendar
              {...commonProps}
              display="years"
              onPreviousClick={() => subYearsToCurrent(11)}
              onNextClick={() => addYearsToCurrent(11)}
            />
          )
        case 'dual':
          // todo protection against the absence of a variable
          if (typeof dualStart === 'undefined') {
            console.warn('You should set dualStart.')
          }

          return (
            <>
              <Calendar
                {...commonProps}
                display="days"
                intent="dual"
                currentDate={addMonths(currentDate, dualStartMonth)}
                onPreviousClick={subMonthToCurrent}
                onNextClick={undefined}
              />
              <Calendar
                {...commonProps}
                currentDate={addMonths(currentDate, dualStartMonth + 1)}
                display="days"
                intent="dual"
                onPreviousClick={undefined}
                onNextClick={addMonthToCurrent}
                ml="m"
              />
            </>
          )
        default:
          return (
            <Calendar
              {...commonProps}
              display="days"
              onPreviousClick={subMonthToCurrent}
              onNextClick={addMonthToCurrent}
            />
          )
      }
    }

    return (
      <Container
        borderRadius="rounded"
        backgroundColor="inverted100"
        boxShadow="l"
        ref={ref}
        {...rest}
      >
        {shortcuts && (
          <Shortcuts>
            <ScrollBox>
              {Array.isArray(shortcuts) ? (
                shortcuts.map(s => (
                  <Shortcut onClick={s.onClick} key={s.label}>
                    {s.label}
                  </Shortcut>
                ))
              ) : (
                <Shortcut onClick={shortcuts.onClick}>
                  {shortcuts.label}
                </Shortcut>
              )}
            </ScrollBox>
          </Shortcuts>
        )}
        <div>
          <Flex px="m" pt="m" pb={action ? 's' : 'm'} justifyContent="center">
            <Flex alignItems="flex-start">{switchCalendar(display)}</Flex>
          </Flex>

          {!hasRange && display === 'time' && (
            <Box mb="m" mx="m">
              <FormGroup label="Godzina">
                <TimeInput
                  onChange={handleOnHourClick}
                  {...timeProps}
                  date={currentDate}
                  mx="m"
                  mb="m"
                  intent="fitted"
                />
              </FormGroup>
            </Box>
          )}

          {action && (
            <Flex justifyContent="center" mb="m">
              <TextButton
                onClick={action.onClick}
                aria-label={action['aria-label']}
              >
                {action.label}
              </TextButton>
            </Flex>
          )}
        </div>
      </Container>
    )
  },
)

const Container = styled(Box)`
  display: inline-flex;
  overflow: hidden;
`

const Shortcuts = styled(Box)`
  position: relative;

  ${p => css`
    width: ${p.theme.components.datepickerShortcutsWidth}px;
    border-right: ${p.theme.components.datepickerDivider};
  `};
`

const Shortcut = styled(TextButton).attrs({ variant: 'secondary' })`
  ${p => css`
    text-align: left;

    & + & {
      margin-top: ${p.theme.space.s}px;
    }
  `};
`

const ScrollBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;

  ${p => css`
    height: calc(100% - ${p.theme.space.m * 2}px);
    margin: ${p.theme.space.m}px 0;
    padding: 0 ${p.theme.space.m}px;
  `};
`
