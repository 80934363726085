/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import Layout from './src/components/Layout'
import '@ads/front-core/locales/i18n'

export const wrapRootElement = ({ element }) => {
  return <Layout>{element}</Layout>
}

/* eslint-disable no-undef */
export const onRouteUpdate = ({ prevLocation }) => {
  if (typeof window !== 'undefined') {
    if (prevLocation) {
      if (typeof window.SavecartNS.stop === 'function') {
        window.SavecartNS.stop()
      }
    }
    if (typeof window.SavecartNS.start === 'function') {
      window.SavecartNS.start()
    }
  }
}
