import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface HideBannerParams {
  id: string
}

export const hideBanner = createAction<HideBannerParams>('banner/hideBanner')

export const hideBannerReducer = (
  state: RootState['banner'],
  action: ReturnType<typeof hideBanner>,
): void => {
  const { id } = action.payload
  delete state[id]
}
