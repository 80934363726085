import React, { Children, FC, ReactElement, cloneElement } from 'react'
import styled, { css } from 'styled-components'
import {
  Box,
  CheckboxControl,
  Counter,
  Flex,
  IBox,
  Icon,
  StatusDot,
  Text,
  Tooltip,
} from '@ads/front-ds'
import zIndex from '@ads/front-core/constants/zIndex'
import {
  ICaption,
  IMenuItem,
  IMenuItemContainer,
  IMenuText,
  IOptions,
  ITick,
  TIntent,
} from './interface'

const hasIntent = (
  intent: TIntent | TIntent[],
  keyExists: TIntent | TIntent[],
): boolean => {
  if (Array.isArray(intent)) {
    return intent.some(i =>
      Array.isArray(keyExists) ? keyExists.includes(i) : i === keyExists,
    )
  }
  return Array.isArray(keyExists)
    ? keyExists.includes(intent)
    : intent === keyExists
}

const MenuLabelSearch: FC<{ label: string; phrase: string }> = ({
  label,
  phrase,
}) => {
  if (!phrase) {
    return <>{label}</>
  }

  const searchRegex = phrase.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  const parts = label.split(new RegExp(searchRegex, 'ig'))
  const phrases = label.match(new RegExp(searchRegex, 'ig'))
  return (
    <>
      {parts.map((part, i) => (
        <React.Fragment key={`part-${i}`}>
          {part}
          {!!phrases?.[i] && <b>{phrases[i]}</b>}
        </React.Fragment>
      ))}
    </>
  )
}

export const MenuItem: FC<IMenuItem & IBox> = ({
  label,
  labelAfter,
  disabled,
  children,
  intent,
  selected,
  indeterminate,
  expanded,
  counter,
  caption,
  level,
  status,
  tabIndex = 0,
  backgroundColor,
  dontChangeSelectedStyle,
  ComponentAfterCaption,
  phrase,
  disableTooltips,
  onClick,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  onChangeCheckbox,
  ...boxProps
}) => (
  <StyledBox forwardedAs="div" isDisabled={disabled} {...boxProps}>
    <StyledText
      data-testid={`menu-${label}`}
      fontSize="m"
      lineHeight="m"
      disabled={disabled}
      intent={intent}
      level={level}
      tabIndex={tabIndex}
      backgroundColor={backgroundColor}
      moreSpaceOnRight={
        hasIntent(intent, ['selectable', 'checkbox']) &&
        !counter &&
        !caption &&
        !children
      }
      onClick={onClick}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
    >
      {hasIntent(intent, 'selectable') && (
        <Tick name="20-check" disabled={disabled} visible={selected} mr="xxs" />
      )}
      {hasIntent(intent, 'checkbox') && onChangeCheckbox && (
        <CheckboxControl
          isDisabled={disabled}
          mr="xxs"
          isChecked={selected}
          isIndeterminate={indeterminate}
          onChange={e => {
            e.stopPropagation()
            onChangeCheckbox(indeterminate || e?.currentTarget?.checked)
          }}
        />
      )}
      {status && (
        <StatusContainer mr="xxs" isDisabled={disabled}>
          <StatusDot {...status} />
        </StatusContainer>
      )}

      <ItemTextContainer>
        {!disableTooltips &&
        (label || '').trim().length + (caption || '').trim().length > 30 ? (
          <TooltipStyled placement="start" text={label} zIndex={zIndex.TOOLTIP}>
            <ItemText
              selected={
                !dontChangeSelectedStyle && !backgroundColor && selected
              }
            >
              <MenuLabelSearch label={label} phrase={phrase} />
            </ItemText>
          </TooltipStyled>
        ) : (
          <>
            <ItemText
              selected={
                !dontChangeSelectedStyle && !backgroundColor && selected
              }
            >
              <MenuLabelSearch label={label} phrase={phrase} />
            </ItemText>
            {labelAfter}
          </>
        )}
      </ItemTextContainer>

      {(caption || counter || children || ComponentAfterCaption) && (
        <Options selectable={hasIntent(intent, ['selectable', 'checkbox'])}>
          {caption && !counter && (
            <Caption disabled={disabled} fontSize="m" lineHeight="m" ml="xxs">
              {caption}
            </Caption>
          )}
          {counter && !expanded && (
            <Counter isPrefix={selected} label={+counter} ml="xxs" />
          )}
          {ComponentAfterCaption}
          {children && (
            <Arrow
              name={expanded ? '20-arrowhead-down' : '20-arrowhead-right'}
              ml="xxs"
            />
          )}
        </Options>
      )}
    </StyledText>

    {children && expanded && (
      <Box>
        {Children.map(children as ReactElement, (child: ReactElement) => {
          if (!child) {
            return null
          }

          const baseIntent = ['children']
          const childIntent = child?.props?.intent
          const childLevel = (level || 0) + 1

          if (childIntent) baseIntent.push(childIntent)

          return cloneElement(child as ReactElement, {
            intent: [].concat(...baseIntent),
            level: childLevel,
          })
        })}
      </Box>
    )}
  </StyledBox>
)

const StyledBox = styled(Box)<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: ${p => (p.isDisabled ? 'not-allowed' : 'pointer')};

  &:focus {
    outline: none;
  }

  ${p => css`
    transition: background-color ${p.theme.motion.productive};
  `};
`

const Arrow = styled(Icon)`
  flex: 0 0 20px;
  color: ${p => p.theme.components.menuItemArrowColor};
`

const StyledText = styled(Text)<IMenuItemContainer>`
  display: flex;
  align-items: center;

  ${p => css`
    padding: ${p.theme.space.xs / 2}px ${p.theme.space.s}px;
    pointer-events: ${p.disabled && 'none'};
    padding-right: ${p.moreSpaceOnRight &&
    p.theme.space.xl + p.theme.space.xxxs + 'px'};
    color: ${p.theme.components[
      p.disabled ? 'menuChildDisabledColor' : 'menuChildColor'
    ]};

    ${p.backgroundColor &&
    css`
      background-color: ${p.theme.colors[p.backgroundColor]};
    `}

    &:hover:not(:focus) {
      background-color: ${p.theme.colors[p.backgroundColor] ||
      p.theme.components.menuChildHoverBackgroundColor};

      ${Arrow} {
        color: ${p.theme.components.menuChildColor};
      }
    }

    &:focus {
      background-color: ${p.theme.colors[p.backgroundColor] ||
      p.theme.components.menuChildFocusBackgroundColor};
      outline: none;

      ${Arrow} {
        color: ${p.theme.components.menuChildColor};
      }
    }

    ${hasIntent(p.intent, 'children') &&
    css`
      padding-left: ${p.level * p.theme.space.l}px;
    `}
  `};
`

const tickColors = ['menuTickDisabledColor', 'menuTickColor']

export const Tick = styled(Icon)<ITick>`
  flex: 0 0 20px;

  ${p => css`
    visibility: ${p.visible ? 'visible' : 'hidden'};
    color: ${p.theme.components[p.disabled ? tickColors[0] : tickColors[1]]};
  `};
`

const StatusContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})<{ isDisabled?: boolean }>`
  width: 20px;
  height: 20px;
  opacity: ${p => (p.isDisabled ? 0.5 : 1)};
`

const Options = styled(Box)<IOptions>`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const Caption = styled(Text)<ICaption>`
  white-space: nowrap;

  ${p =>
    css`
      color: ${p.theme.components.menuItemCaptionColor};
      padding-left: ${p.theme.space.s}px;
    `};
`

const ItemTextContainer = styled(Box)`
  display: flex;
  min-width: 0;
  width: 100%;
`

const ItemText = styled(Text)<IMenuText>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${p =>
    css`
      font-weight: ${p.selected
        ? p.theme.fontWeights.medium
        : p.theme.fontWeights.regular};
    `};
`

const TooltipStyled = styled(Tooltip)`
  min-width: 0;
  & > div {
    min-width: 0;
    display: flex;
  }
`
