import { Borders } from './interfaces'
import { colors } from './colors'

export const borders: Borders = {
  // solid
  thinGrey20: `1px solid ${colors.grey20}`,
  thinPrimary20: `1px solid ${colors.primary20}`,
  thinWarning50: `1px solid ${colors.warning50}`,
  thinAlert20: `1px solid ${colors.alert20}`,
  thinAlert50: `1px solid ${colors.alert50}`,
  // dashed
  thinDashedGrey20: `1px dashed ${colors.grey20}`,
  thinDashedPrimary20: `1px dashed ${colors.primary20}`,
  thinDashedPrimary50: `1px dashed ${colors.primary50}`,
  thinDashedAlert50: `1px dashed ${colors.alert50}`,
}
