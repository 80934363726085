import React from 'react'
import styled, { css } from 'styled-components'
import { useModalContext } from './'
import { CloseButtonProps } from './types'
import { IconButton } from '../Buttons'
import { callAllHandlers } from '../../utils'

export const ModalCloseButton = (props: CloseButtonProps) => {
  const { onClick } = props
  const { onClose } = useModalContext()

  return (
    <Close
      aria-label="Close Button"
      my="xxxs"
      variant="bareDark"
      name="20-close"
      onClick={callAllHandlers(onClick, e => {
        e?.stopPropagation()
        onClose()
      })}
    />
  )
}

const Close = styled(IconButton)`
  position: absolute;
  ${p => css`
    right: ${p.theme.space.m}px;
    top: ${p.theme.space.m}px;
  `}
`
