import { Colors } from './interfaces'

export const colors: Colors = {
  grey100: '#262628',
  grey50: '#6d6d73',
  grey20: '#dfdfe1',
  grey10: '#e2e2e4',
  grey05: '#f2f2f4',
  grey0: '#f8f8f9',
  greyTransparent: 'rgba(38, 38, 40, 0.75)',
  greyDisabled: 'rgba(109, 109, 115, 0.55)',
  primary100: '#0050ab',
  primary50: '#026ae1',
  primary20: '#8fc4fe',
  primary10: '#c7e1ff',
  primary05: '#e9f3fe',
  success100: '#026600',
  success50: '#2eab2c',
  success20: '#9fdb9e',
  success10: '#c9e9c8',
  success05: '#e6f6e6',
  warning100: '#a1480c',
  warning50: '#ee701c',
  warning20: '#fab98e',
  warning10: '#fcdcc7',
  warning05: '#ffeadd',
  alert100: '#9c0003',
  alert50: '#e3161a',
  alert20: '#fa8e90',
  alert10: '#fcc7c7',
  alert05: '#fee2e9',
  weakSuggestion100: '#8e6d0b',
  weakSuggestion50: '#e2aa00',
  weakSuggestion20: '#f9d97b',
  weakSuggestion10: '#fbe7ad',
  weakSuggestion05: '#fff1c5',
  inverted100: '#ffffff',
  inverted50: 'rgba(255, 255, 255, 0.7)',
  inverted20: 'rgba(255, 255, 255, 0.25)',
  inverted05: 'rgba(255, 255, 255, 0.15)',
  invertedDisabled: 'rgba(255, 255, 255, 0.35)',
  cornflowerBlue: '#6181e9',
  monteCarlo: '#7ed2c9',
  amaranth: '#eb3a57',
  orchid: '#e165c7',
  porsche: '#e69a53',
  amethyst: '#8f56d2',
}
