import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setCurrentShopId = createAction<
  AppState['client']['currentShopId']
>('client/setCurrentShopId')

export const setCurrentShopIdReducer = (
  state: AppState['client'],
  action: ReturnType<typeof setCurrentShopId>,
): void => {
  state.currentShopId = action.payload
  window.localStorage.setItem('currentShopId', String(action.payload))
}
