import React, { FC } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Theme } from '../../../theme/interfaces'

import { ILoader } from './'

export const Loader: FC<React.PropsWithChildren<ILoader>> = ({ variant }) => (
  <Ring variant={variant}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </Ring>
)
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const setBorder = (variant: ILoader['variant'], colors: Theme['colors']) => {
  if (variant === 'destructive') {
    console.warn(
      'Variant destructive will be deprecated, please use variant destructiveCta',
    )
  }

  switch (variant) {
    case 'primary':
      return css`
        border: 2px solid ${colors.primary50};
        border-color: ${colors.primary50} transparent transparent transparent;
      `

    case 'secondary':
      return css`
        border: 2px solid ${colors.primary50};
        border-color: ${colors.primary50} transparent transparent transparent;
      `
    case 'cta':
      return css`
        border: 2px solid ${colors.inverted100};
        border-color: ${colors.inverted100} transparent transparent transparent;
      `
    case 'ghost':
      return css`
        border: 2px solid ${colors.primary50};
        border-color: ${colors.primary50} transparent transparent transparent;
      `
    case 'ghostInverted':
      return css`
        border: 2px solid ${colors.primary50};
        border-color: ${colors.primary50} transparent transparent transparent;
      `
    case 'destructive':
      return css`
        border: 2px solid ${colors.inverted100};
        border-color: ${colors.inverted100} transparent transparent transparent;
      `
    case 'destructiveCta':
      return css`
        border: 2px solid ${colors.inverted100};
        border-color: ${colors.inverted100} transparent transparent transparent;
      `
    case 'destructivePrimary':
      return css`
        border: 2px solid ${colors.primary50};
        border-color: ${colors.primary50} transparent transparent transparent;
      `
    default:
      return css`
        border: 2px solid ${colors.primary50};
        border-color: ${colors.primary50} transparent transparent transparent;
      `
  }
}

const Ring = styled.div<ILoader>`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 20px;
  height: 20px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    ${({ variant, theme }) => setBorder(variant, theme.colors)}

    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`
