import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Box, IBox } from '../Box'
import { ICascaderMenu } from './types'

export const CascaderMenu: FC<React.PropsWithChildren<ICascaderMenu>> = ({
  children,
  ...rest
}) => (
  <Container
    forwardedAs={'div' as IBox['forwardedAs']}
    borderRadius="rounded"
    boxShadow="l"
    {...rest}
  >
    {children}
  </Container>
)

const Container = styled(Box)<ICascaderMenu>`
  display: flex;
  max-height: 336px;
  &:focus {
    outline: none;
  }

  ${p => css`
    background-color: ${p.theme.components.menuBackgroundColor};
  `};
`
