import {
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns'
import { RelativeRange } from '@ads/front-core/interfaces'
import { lng } from '@ads/front-core/locales'

export const getRelativeRangeConfig = (
  key: RelativeRange,
): {
  label: string
  gqlRelativeRange: RelativeRange | undefined
  dateFrom: string
  dateTo: string
} => {
  const dateFormat = 'yyyy-MM-dd'
  const today = format(new Date(), dateFormat)
  switch (key) {
    case 'THIS_WEEK':
      return {
        label: lng('calendar:thisWeek'),
        gqlRelativeRange: 'THIS_WEEK',
        dateFrom: format(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          dateFormat,
        ),
        dateTo: format(endOfWeek(new Date(), { weekStartsOn: 1 }), dateFormat),
      }
    case 'LAST_30_DAYS':
      return {
        label: lng('calendar:lastDays', { days: 30 }),
        gqlRelativeRange: 'LAST_30_DAYS',
        dateFrom: format(subDays(new Date(), 30), dateFormat),
        dateTo: today,
      }
    case 'LAST_7_DAYS':
      return {
        label: lng('calendar:lastDays', { days: 7 }),
        gqlRelativeRange: 'LAST_7_DAYS',
        dateFrom: format(subDays(new Date(), 7), dateFormat),
        dateTo: today,
      }
    case 'THIS_MONTH':
      return {
        label: lng('calendar:thisMonth'),
        gqlRelativeRange: 'THIS_MONTH',
        dateFrom: format(startOfMonth(new Date()), dateFormat),
        dateTo: format(endOfMonth(new Date()), dateFormat),
      }
    case 'PREV_MONTH': {
      const prevMonth = subMonths(new Date(), 1)
      return {
        label: lng('calendar:previousMonth'),
        gqlRelativeRange: undefined,
        dateFrom: format(startOfMonth(prevMonth), dateFormat),
        dateTo: format(endOfMonth(prevMonth), dateFormat),
      }
    }
    case 'THIS_YEAR':
      return {
        label: lng('calendar:thisYear'),
        gqlRelativeRange: 'THIS_YEAR',
        dateFrom: format(startOfYear(new Date()), dateFormat),
        dateTo: format(endOfYear(new Date()), dateFormat),
      }
    case 'TODAY':
      return {
        label: lng('calendar:today'),
        gqlRelativeRange: 'TODAY',
        dateFrom: today,
        dateTo: today,
      }
    case 'YESTERDAY': {
      const yesterday = format(subDays(new Date(), 1), dateFormat)
      return {
        label: lng('calendar:yesterday'),
        gqlRelativeRange: 'YESTERDAY',
        dateFrom: yesterday,
        dateTo: yesterday,
      }
    }
    case 'CURRENT_NEWSLETTER':
      return {
        label: lng('calendar:currentNewsletter'),
        gqlRelativeRange: 'CURRENT_NEWSLETTER',
        dateFrom: format(subDays(new Date(), 30), dateFormat),
        dateTo: format(addYears(new Date(), 1), dateFormat),
      }
  }
}
