import React, { forwardRef, Ref } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { IFlex } from './types'

export const Flex = forwardRef((props: IFlex, ref: Ref<HTMLDivElement>) => {
  const { forwardedAs, as, ...rest } = props

  return <Container ref={ref} forwardedAs={forwardedAs || as} {...rest} />
})

const Container = styled(Box)<IFlex>`
  ${({
    isInline,
    flexDirection,
    flexWrap,
    justifyContent,
    alignItems,
    alignContent,
    gap,
    theme,
  }) => css`
    display: ${isInline ? 'inline-flex' : 'flex'};
    flex-direction: ${flexDirection};
    flex-wrap: ${flexWrap};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    align-content: ${alignContent};
    ${gap && `gap: ${theme.space[gap]}px`}};
  `};
`
