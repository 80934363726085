import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface FetchCacheParams {
  cacheKey: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

export const fetchCache = createAction<FetchCacheParams>('fetchCache')

export const fetchCacheReducer = (
  state: RootState['fetchCache'],
  action: ReturnType<typeof fetchCache>,
): void => {
  state.list = { ...state.list, [action.payload.cacheKey]: action.payload.data }
}
