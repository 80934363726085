import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { call, put, takeEvery } from 'redux-saga/effects'
import { ResponseShop, ClientService } from '@ads/front-core/api'
import { setClientShops } from '../reducers/client/setClientShopsReducer'

interface FetchClientShopsDataProps {
  clientId: number
}

type FetchClientShopsDataSaga = ReturnType<
  PayloadActionCreator<FetchClientShopsDataProps, string>
>

export const fetchClientShopsData = createAction<FetchClientShopsDataProps>(
  'saga/fetchClientShopsData',
)

export function* sagaFetchClientShopsData(): Generator {
  yield takeEvery(fetchClientShopsData.type, fetchClientShopsDataSaga)
}

export function* fetchClientShopsDataSaga({
  payload,
}: FetchClientShopsDataSaga): Generator {
  const { clientId } = payload
  try {
    if (!clientId) {
      return
    }

    const responseShops = yield call(ClientService.getClientShops, { clientId })
    const shops = responseShops as Required<ResponseShop[]>

    yield put(setClientShops(shops))
  } catch (error) {
    yield put(setClientShops([]))
  }
}
