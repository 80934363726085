import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../Text'
import { IStyledTagPath, ITagPath, ITagSlash } from './types'

import { checkIntent } from '../../utility'

import { useTagsPathSettings } from './tagsPathContext'

export const TagPath: FC<React.PropsWithChildren<ITagPath>> = ({
  children,
  type,
  index,
  ...rest
}) => {
  const {
    currentHoveredTag,
    setCurrentHoveredTag,
    setHoveredLastTag,
    tagsLength,
  } = useTagsPathSettings()

  const isSelected = checkIntent('isSelected', type)
  const isSuggested = checkIntent('isSuggested', type)

  const onMouseEnterHandler = () => {
    setCurrentHoveredTag(index)
    setHoveredLastTag(index + 1 === tagsLength)
  }

  const onMouseLeaveHandler = () => {
    setCurrentHoveredTag(-1)
  }

  return (
    <>
      <StyledTag
        fontSize="m"
        lineHeight="m"
        isSelected={isSelected}
        isSuggested={isSuggested}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        isHovered={index <= currentHoveredTag}
        {...rest}
      >
        {children}
      </StyledTag>
      {index + 1 < tagsLength && (
        <StyledSlash
          fontSize="m"
          lineHeight="m"
          color="grey50"
          px="xxxs"
          isSelected={isSelected}
          isSuggested={isSuggested}
          isHovered={index + 1 <= currentHoveredTag}
        >
          /
        </StyledSlash>
      )}
    </>
  )
}

const StyledTag = styled(Text)<IStyledTagPath>`
  cursor: pointer;
  display: inline-block;

  ${p => css`
    ${p.isHovered &&
    (p.isSelected
      ? `background-color: ${p.theme.colors.primary10}`
      : `background-color: ${p.theme.colors.grey10}`)}
  `}
`

const StyledSlash = styled(Text)<ITagSlash>`
  display: inline-block;
  ${p => css`
    ${p.isHovered &&
    (p.isSelected
      ? `background-color: ${p.theme.colors.primary10}`
      : `background-color: ${p.theme.colors.grey10}`)}
  `}
`
