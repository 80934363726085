import { createAction } from '@reduxjs/toolkit'
import { SidebarProps } from '@ads/front-core/components/SidebarFixed'
import { AppState } from '../../appState'

export const setForceSelectedTab = createAction<SidebarProps['selectedTab']>(
  'common/setForceSelectedTab',
)

export const setForceSelectedTabReducer = (
  state: AppState['common'],
  action: ReturnType<typeof setForceSelectedTab>,
): void => {
  state.forceSelectTab = action.payload
}
