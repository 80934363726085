import { aliases } from './aliases'
import { borders } from './borders'
import { colors } from './colors'
import { Components } from './interfaces'

export const components: Components = {
  /**
   * Announcments/Banner
   */
  bannerColor: aliases.defaultContent,
  bannerSuccessBackgroundColor: aliases.successBackgroundColorSubtle,
  bannerSuccessIconColor: aliases.successContent,
  bannerInformativeBackgroundColor: aliases.ctaBackgroundColorSubtle,
  bannerInformativeIconColor: aliases.ctaContent,
  bannerWarningBackgroundColor: aliases.warningBackgroundColorSubtle,
  bannerWarningIconColor: aliases.warningContent,
  bannerErrorBackgroundColor: aliases.errorBackgroundColorSubtle,
  bannerErrorIconColor: aliases.errorContent,
  /**
   * Announcments/Dialog
   * DIALOG WILL BE DEPRACTICATED
   */
  dialogSizeMedium: 480,
  dialogSizeLarge: 648,
  dialogOverlayBackground: aliases.defaultBackgroundColorInverted,
  dialogModalBackground: aliases.defaultBackgroundColor,
  dialogModalColor: aliases.defaultContent,
  /**
   * Modal
   */
  modalSizeMedium: 480,
  modalSizeLarge: 648,
  modalOverlayBackground: aliases.defaultBackgroundColorInverted,
  modalBackground: aliases.defaultBackgroundColor,
  modalColor: aliases.defaultContent,
  /**
   * Announcments/InlineBanner
   */
  inlineBannerColor: aliases.defaultContent,
  inlineBannerInformativeBackgroundColor: aliases.ctaBackgroundColorSubtle,
  inlineBannerInformativeIconColor: aliases.ctaContent,
  inlineBannerPositiveBackgroundColor: aliases.successBackgroundColorSubtle,
  inlineBannerPositiveIconColor: aliases.successContent,
  inlineBannerWarningBackgroundColor: aliases.warningBackgroundColorSubtle,
  inlineBannerWarningIconColor: aliases.warningContent,
  inlineBannerErrorBackgroundColor: aliases.errorBackgroundColorSubtle,
  inlineBannerErrorIconColor: aliases.errorContent,
  inlineBannerSuggestionBackgroundColor:
    aliases.weakSuggestionBackgroundColorSubtle,
  inlineBannerSuggestionIconColor: aliases.weakSuggestionContent,
  inlineBannerNeutralBackgroundColor:
    aliases.defaultBackgroundColorInvertedSubtle,
  inlineBannerNeutralIconColor: aliases.defaultContentSecondary,
  /**
   * Announcments/Toast
   */
  toastBackgroundColor: aliases.defaultBackgroundColorInverted,
  toastColor: aliases.defaultContentSecondaryInverted,
  toastErrorIconColor: aliases.errorContent,
  /**
   * Avatars/Avatar
   */
  avatarColor: aliases.defaultContentInverted,
  avatarSizeExtraSmall: 20,
  avatarSizeSmall: 24,
  avatarSizeMedium: 36,
  avatarSizeLarge: 48,
  avatarSizeExtraLarge: 62,
  /**
   * Avatars/AvatarsGroup
   */
  avatarsGroupCounterBackgroundColor:
    aliases.defaultBackgroundColorInvertedSubtle,
  avatarsGroupCounterColor: aliases.defaultContent,
  /**
   * Badge
   */
  badgeDefaultLowContrastColor: colors.grey10,
  badgeNeutralLowContrastColor: colors.primary10,
  badgeSuccessLowContrastColor: colors.success10,
  badgeWarningLowContrastColor: colors.warning10,
  badgeTransparentLowContrastColor:
    aliases.defaultBackgroundColorInvertedSubtle,
  badgeAlertLowContrastColor: colors.alert10,
  badgeDefaultStrongContrastColor: aliases.defaultContentSecondary,
  badgeNeutralStrongContrastColor: aliases.ctaContent,
  badgeSuccessStrongContrastColor: aliases.successContent,
  badgeWarningStrongContrastColor: aliases.warningContent,
  badgeTransparentStrongContrastColor:
    aliases.defaultBackgroundColorTransparent,
  badgeAlertStrongContrastColor: aliases.errorContent,
  badgeHoverDefaultStrongContrastColor: colors.grey100,
  badgeHoverNeutralStrongContrastColor: colors.primary100,
  badgeHoverSuccessStrongContrastColor: colors.success100,
  badgeHoverWarningStrongContrastColor: colors.warning100,
  badgeHoverTransparentStrongContrastColor: colors.grey100,
  badgeHoverAlertStrongContrastColor: colors.alert100,
  badgeHoverDefaultSubtleContrastColor: colors.grey20,
  badgeHoverNeutralSubtleContrastColor: colors.primary20,
  badgeHoverSuccessSubtleContrastColor: colors.success20,
  badgeHoverWarningSubtleContrastColor: colors.warning20,
  badgeHoverTransparentSubtleContrastColor: colors.grey100,
  badgeHoverAlertSubtleContrastColor: colors.alert20,
  badgeInvertedColor: aliases.defaultContentInverted,
  badgeInvertedHoverColor: colors.grey100,
  badgeSubtleColor: aliases.defaultContent,
  /**
   * Buttons/Button
   */
  buttonPrimaryBackgroundColor: aliases.ctaBackgroundColorInverted,
  buttonPrimaryColor: aliases.ctaContent,
  buttonPrimaryBorder: aliases.ctaBorder,
  buttonPrimaryHoverColor: aliases.ctaContentHover,
  buttonPrimaryDisabledBorderColor: aliases.disabledBorderColor,
  buttonPrimaryDisabledColor: aliases.disabledContent,
  buttonSecondaryBackgroundColor: aliases.secondaryBackgroundColor,
  buttonSecondaryColor: aliases.secondaryContent,
  buttonSecondaryBorder: aliases.secondaryBorder,
  buttonSecondaryHoverColor: aliases.secondaryContentHover,
  buttonSecondaryDisabledColor: aliases.disabledContent,
  buttonCtaBackgroundColor: aliases.ctaBackgroundColor,
  buttonCtaColor: aliases.ctaContentInverted,
  buttonCtaHoverBackgroundColor: aliases.ctaBackgroundColorHover,
  buttonCtaDisabledColor: aliases.disabledContentStrong,
  buttonCtaDisabledBackgroundColor: aliases.disabledBackgroundColorStrong,
  buttonGhostColor: aliases.ghostContent,
  buttonGhostHoverBackgroundColor: aliases.ghostBackgroundColorHover,
  buttonGhostHoverColor: aliases.ghostContentHover,
  buttonGhostSelectedBackgroundColor: aliases.ghostBackgroundColorSelected,
  buttonGhostSelectedColor: aliases.ghostContentSelected,
  buttonGhostDisabledColor: aliases.disabledContent,
  buttonGhostInvertedColor: aliases.ghostInvertedContent,
  buttonGhostInvertedHoverBackgroundColor: aliases.defaultBackgroundColorSublte,
  buttonGhostInvertedHoverColor: aliases.ghostInvertedBackgroundColorHover,
  buttonGhostInvertedSelectedBackgroundColor:
    aliases.ghostInvertedBackgroundColorSelected,
  buttonGhostInvertedDisabledColor: aliases.disabledContentInverted,
  buttonDestructiveBackgroundColor: aliases.errorColor,
  buttonDestructiveColor: aliases.destructiveContentInverted,
  buttonDestructiveHoverBackgroundColor:
    aliases.destructiveBackgroundColorHover,
  buttonDestructiveDisabledBackgroundColor:
    aliases.disabledBackgroundColorStrong,
  buttonDestructiveDisableColor: aliases.disabledContentStrong,
  buttonDestructivePrimaryColor: aliases.destructiveContent,
  buttonDestructivePrimaryBorder: aliases.destructivePrimaryBorder,
  buttonDestructivePrimaryDisabledBorder: aliases.destructivePrimaryBorder,
  buttonDestructivePrimaryHoverColor: aliases.destructiveContentHover,
  buttonDestructivePrimaryActiveColor: aliases.destructiveContentActive,
  buttonDestructivePrimaryDisableColor: aliases.disabledContent,
  /**
   * Buttons/IconButton
   */
  iconButtonDarkColor: aliases.ghostContent,
  iconButtonDarkHoverBackgroundColor: aliases.ghostBackgroundColorHover,
  iconButtonDarkHoverColor: aliases.ghostContentHover,
  iconButtonDarkDisabledColor: aliases.disabledContent,
  iconButtonDarkSelectedBackgroundColor: aliases.ctaBackgroundColorSubtle,
  iconButtonDarkSelecteColor: aliases.ctaContent,
  iconButtonDarkSelecteDisabledBackgroundColor: aliases.disabledBackgroundColor,
  iconButtonInvertedColor: aliases.ghostInvertedContent,
  iconButtonInvertedHoverBackgroundColor:
    aliases.ghostInvertedBackgroundColorHover,
  iconButtonInvertedHoverColor: aliases.ghostInvertedContentHover,
  iconButtonInvertedDisabledColor: aliases.disabledContentInverted,
  iconButtonInvertedSelectedBackgroundColor:
    aliases.ghostInvertedBackgroundColorSelected,
  iconButtonInvertedSelectedDisabledBackgroundColor:
    aliases.disabledBackgroundColorInverted,
  iconButtonDestructiveColor: aliases.destructiveContent,
  iconButtonDestructiveHoverBackgroundColor:
    aliases.destructiveBackgroundColorHover,
  iconButtonDestructiveHoverColor: colors.inverted50,
  iconButtonDestructiveDisabledColor: aliases.disabledContent,
  iconButtonBareDarkColor: aliases.secondarySubtleContent,
  iconButtonBareDarkHoverColor: aliases.secondarySubtleContentHover,
  iconButtonBareDarkDisabledColor: aliases.disabledContent,
  iconButtonBareDarkSelectedColor: aliases.secondarySubtleContentSelected,
  iconButtonBareInvertedColor: aliases.secondaryInvertedContent,
  iconButtonBareInvertedHoverColor: aliases.secondaryInvertedContentHover,
  iconButtonBareInvertedDisabledColor: aliases.disabledContentInverted,
  iconButtonBareInvertedSelectedColor: aliases.ghostBackgroundColorSelected,
  iconButtonBareDestructiveColor: aliases.destructiveContent,
  iconButtonBareDestructiveHoverColor: aliases.destructiveContentHover,
  iconButtonBareDestructiveDisabledColor: aliases.disabledContent,
  /**
   * Buttons/SplitButton
   */
  splitButtonPrimaryDivider: colors.primary20,
  splitButtonPrimaryDividerDisabled: colors.grey20,
  splitButtonSecondaryDivider: colors.grey20,
  splitButtonSecondaryDividerDisabled: colors.grey20,
  splitButtonCtaDivider: colors.inverted100,
  splitButtonCtaDividerDisabled: colors.inverted100,
  /**
   * Buttons/CircleButton
   */
  CircleButtonPrimaryColor: colors.primary50,
  CircleButtonPrimaryHoverColor: colors.primary100,
  CircleButtonPrimarySelectedColor: colors.primary100,
  CircleButtonSecondaryColor: colors.inverted100,
  CircleButtonSecondarySelectedColor: colors.primary05,
  CircleButtonSecondaryBorder: borders.thinGrey20,
  CircleButtonSecondarySelectedBorder: borders.thinPrimary20,
  /**
   * Buttons/TextButton
   */
  textButtonPrimaryColor: colors.primary50,
  textButtonPrimaryHoverColor: colors.primary100,
  textButtonSecondaryColor: colors.grey50,
  textButtonSecondaryHoverColor: colors.grey100,
  textButtonDestructiveColor: colors.alert50,
  textButtonDestructiveHoverColor: colors.alert100,
  /**
   * Checkboxes/Checkbox
   */
  checkboxColor: aliases.defaultContent,
  checkboxDisabledColor: aliases.disabledContent,
  /**
   * Checkboxes/CheckboxControl
   */
  checkboxControlSquareBorder: aliases.formBorder,
  checkboxControlSquareHoverBorderColor: aliases.formBorderColorHover,
  checkboxControlSquareHoverErrorBorderColor: aliases.errorBorderColorHover,
  checkboxControlSquareCheckedBackgroundColor: aliases.ctaBackgroundColor,
  checkboxControlSquareCheckedBorderColor: aliases.ctaBackgroundColor,
  checkboxControlSquareCheckedHoverBackgroundColor:
    aliases.ctaBackgroundColorHover,
  checkboxControlSquareCheckedHoverBorderColor: aliases.ctaBackgroundColorHover,
  checkboxControlSquareErrorBorderColor: aliases.errorBorderColor,
  checkboxControlSquareDisabledBackgroundColor: aliases.disabledBackgroundColor,
  checkboxControlSquareDisabledBorderColor: aliases.disabledContent,
  checkboxControlSquareActiveBackgroundColor: aliases.ctaBackgroundColorSubtle,
  /**
   * Counter
   */
  counterDefaultLowContrastColor: aliases.defaultBackgroundColorInvertedSubtle,
  counterNeutralLowContrastColor: aliases.ctaBackgroundColorSubtle,
  counterIncreaseLowContrastColor: aliases.successBackgroundColorSubtle,
  counterDecreaseLowContrastColor: aliases.errorBackgroundColorSubtle,
  counterDefaultStrongContrastColor: aliases.defaultContent,
  counterDefaultStrongBackgroundColor: aliases.defaultContentSecondary,
  counterNeutralStrongContrastColor: aliases.ctaContent,
  counterIncreaseStrongContrastColor: aliases.successContent,
  counterDecreaseStrongContrastColor: aliases.errorContent,
  counterInvertedColor: aliases.defaultContentInverted,
  /**
   * DateInput
   */
  dateInputHeight: 36,
  dateInputDayWidth: 21,
  dateInputYearWidth: 34,
  /**
   * Datepicker
   */
  datepickerCalendarContainerWidth: 252,
  datepickerDayNameWidth: 36,
  datepickerDayNameHeight: 36,
  datepickerCellHeight: 36,
  datepickerCellBorderRadius: 36,
  datepickerCellCircleColor: aliases.defaultContent,
  datepickerCellCircleHoverBackgroundColor: aliases.ctaBackgroundColorSubtle,
  datepickerCellCircleGreyColor: aliases.defaultContentSecondary,
  datepickerCellCircleyDisabledColor: aliases.disabledContent,
  datepickerCellCircleTodayBorder: aliases.ctaBorder,
  datepickerCellCircleTodaySelectedDisabledBackgroundColor:
    aliases.defaultBackgroundColor,
  datepickerCellCircleTodaySelectedDisabledBorder: aliases.disabledBorder,
  datepickerCellCircleTodaySelectedBackgroundColor: aliases.ctaBackgroundColor,
  datepickerCellCircleTodaySelectedColor: aliases.ctaContentInverted,
  datepickerDivider: aliases.defaultBorder,
  datepickerShortcutsWidth: 140,
  datepickerTimeHourWidth: 68,
  datepickerTimeHourBorderRadius: 100,
  datepickerTimeHourBackgroundColor: aliases.ctaBackgroundColorSubtle,
  datepickerTimeHourSelectedBackgroundColor: aliases.ctaBackgroundColor,
  datepickerTimeHourSelectedColor: aliases.ctaContentInverted,
  datepickerZIndex: 53,
  /**
   * Loaders/Spinner
   */
  spinnerSize: 18,
  spinnerColor: aliases.ctaContent,
  spinnerInvertedColor: aliases.defaultContentInverted,
  /**
   * Menu
   */
  menuBackgroundColor: aliases.defaultBackgroundColor,
  menuDividerColor: aliases.defaultBorderColor,
  menuChildColor: aliases.defaultContent,
  menuChildHoverBackgroundColor: aliases.ctaBackgroundColorSubtle,
  menuChildFocusBackgroundColor: colors.grey0,
  menuChildDisabledColor: aliases.disabledContent,
  menuActionColor: aliases.ctaContent,
  menuActionHoverColor: aliases.ctaContentHover,
  menuAddonColor: aliases.defaultContentSecondary,
  menuAddonErrorColor: aliases.errorContent,
  menuTickColor: aliases.ctaContent,
  menuTickDisabledColor: aliases.disabledContent,
  menuIconGlyphColor: aliases.defaultContentSecondary,
  menuItemArrowColor: aliases.defaultContentSecondary,
  menuItemCaptionColor: aliases.defaultContentSecondary,
  menuItemCaptionDisabledColor: aliases.disabledContent,
  /**
   * NewBadgeField
   */
  newBadgeFieldBorder: borders.thinDashedGrey20,
  newBadgeFieldColor: aliases.ctaContent,
  newBadgeFieldDisabledColor: aliases.disabledContent,
  newBadgeFieldHoverColor: aliases.ctaContentHover,
  /**
   * NewItemField
   */
  newItemFieldBackGroundColor: aliases.defaultBackgroundColor,
  newItemFieldColor: aliases.ctaContent,
  newItemFieldLoadingColor: aliases.secondaryContent,
  newItemFieldHoverBorderColor: aliases.ctaBorderColorHover,
  newItemFieldHoverColor: aliases.ctaContentHover,
  newItemFieldErrorColor: aliases.errorColor,
  newItemFieldDisabledBorderColor: aliases.disabledBorderColor,
  newItemFieldDisabledColor: aliases.disabledContent,
  newItemFieldBarBackgroundColor: aliases.ctaBackgroundColor,
  /**
   * Pill
   */
  pillBackgroundColor: aliases.defaultBackgroundColorInvertedSubtle,
  pillBorderRadius: 100,
  pillColor: aliases.defaultContent,
  pillSelectedBackgroundColor: aliases.ctaBackgroundColorSubtle,
  pillSelecteDisabledBackgroundColor: aliases.disabledBackgroundColor,
  pillDisabledColor: aliases.disabledContent,
  pillPrefixColor: aliases.defaultContentSecondary,
  /**
   * Radios/Radio
   */
  radioColor: aliases.defaultContent,
  radioDisabledColor: aliases.disabledContent,
  /**
   * Radios/RadioBox
   */
  radioBoxItemBorder: aliases.formBorder,
  radioBoxItemHoverBorderColor: aliases.formBorderColorHover,
  radioBoxItemErrorBorderColor: aliases.errorBorderColor,
  radioBoxItemErrorHoverBorderColor: aliases.errorBorderColorHover,
  radioBoxItemDisabledBorderColor: aliases.disabledBorderColor,
  radioBoxItemDisabledrColor: aliases.disabledContent,
  radioBoxItemCaptionColor: aliases.defaultContentSecondary,
  radioBoxItemCaptionDisabledColor: aliases.disabledContent,
  /**
   * SegmentedControls
   */
  segmentedControlBackgroundColor: aliases.defaultBackgroundColor,
  segmentedControlBorder: aliases.formBorder,
  segmentedControlHoverBackgroundColor: aliases.ctaBackgroundColorSubtle,
  segmentedControlHoverBorderColor: aliases.ctaBorderColorHover,
  segmentedControlHoverColor: aliases.secondaryContent,
  segmentedControlErrorBorderColor: aliases.errorBorderColor,
  segmentedControlErrorHoverBorderColor: aliases.errorBorderColorHover,
  segmentedControlActiveBackgroundColor: aliases.ctaBackgroundColorSubtle,
  segmentedControlActiveBorderColor: aliases.ctaBorderColorHover,
  segmentedControlActiveColor: aliases.ctaContent,
  segmentedControlActiveHoverBorderColor: aliases.ctaBorderColorHover,
  segmentedControlActiveHoverColor: aliases.ctaContentHover,
  segmentedControlDisabledBackgroundColor: aliases.disabledBackgroundColor,
  segmentedControlDisabledBorderColor: aliases.disabledBorderColor,
  segmentedControlDisabledColor: aliases.disabledContent,
  /**
   * StatusDot
   */
  statusDotSizeSmall: 8,
  statusDotSizeLarge: 12,
  statusDotYellowColor: colors.weakSuggestion50,
  statusDotRedColor: colors.alert50,
  statusDotOrangeColor: colors.warning50,
  statusDotGreyColor: colors.grey50,
  statusDotBlueColor: colors.primary50,
  statusDotGreenColor: colors.success50,
  statusDotBlackColor: colors.grey100,
  statusDotPurpleColor: colors.amethyst,
  /**
   * Tag
   */
  tagBackgroundColor: aliases.defaultBackgroundColorInvertedSubtle,
  tagDisabledColor: aliases.disabledContent,
  tagSelectedBackgroundColor: aliases.ctaBackgroundColorSubtle,
  tagSuggestedBackgroundColor: aliases.weakSuggestionBackgroundColorSubtle,
  tagSelectedDisabledBackgroundColor: aliases.ctaBackgroundColorInverted,
  tagSelectedDisabledBorder: aliases.defaultBorder,
  tagIconColor: aliases.defaultContentSecondary,
  /**
   * TagInputs
   */
  tagInputBorder: aliases.secondaryBorder,
  tagInputHoverBorderColor: aliases.ctaBorderColorHover,
  tagInputErrorBorderColor: aliases.errorBorderColor,
  tagInputErrorHoverBorderColor: aliases.errorBorderColorHover,
  tagInputDisabledBackgroundColor: aliases.disabledBackgroundColor,
  tagInputTextInputColor: aliases.defaultContent,
  tagInputTextInputDisabledColor: aliases.disabledContent,
  tagInputTextInputPlaceholderColor: aliases.defaultContentSecondary,
  /**
   * TextInput
   */
  textInputBorder: aliases.formBorder,
  textInputHoverBorderColor: aliases.ctaBorderColor,
  textInputErrorBorderColor: aliases.errorBorderColor,
  textInputErrorHoverBorderColor: aliases.errorBorderColorHover,
  textInputPlaceholderColor: aliases.defaultContentSecondary,
  textInputDisabledPlaceholderColor: aliases.disabledContent,
  textInputDisabledBackgroundBackground: aliases.disabledBackgroundColor,
  textInputDisabledColor: aliases.disabledContent,
  textInputPrefixColor: aliases.defaultContentSecondary,
  textInputPrefixDisabledColor: aliases.disabledContent,
  textInputArrowColor: aliases.defaultContent,
  /**
   * Textarea
   */
  textareaBorder: aliases.formBorder,
  textareaColor: aliases.defaultContent,
  textareaHoverBorderColor: aliases.ctaBorderColor,
  textareaErrorBorderColor: aliases.errorBorderColor,
  textareaErrorHoverBorderColor: aliases.errorBorderColorHover,
  textareaPlaceholderColor: aliases.defaultContentSecondary,
  textareaDisabledPlaceholderColor: aliases.disabledContent,
  textareaDisabledBackgroundColor: aliases.disabledBackgroundColor,
  textareaDisabledColor: aliases.disabledContent,
  /**
   * Toggles/Toggle
   */
  toggleColor: aliases.defaultContent,
  toggleDisabledColor: aliases.disabledContent,
  /**
   * Toggles/ToggleBox
   */
  toggleBoxBorder: aliases.secondaryBorder,
  toggleBoxHoverBorderColor: aliases.ctaBorderColorHover,
  toggleBoxErrorBorderColor: aliases.errorBorderColor,
  toggleBoxErrorColor: aliases.errorContent,
  toggleBoxHoverErrorBorderColor: aliases.errorBorderColorHover,
  toggleBoxWarningBorderColor: aliases.warningBorderColor,
  toggleBoxWarningColor: aliases.warningContent,
  toggleBoxHoverWarningBorderColor: aliases.warningBorderColorHover,
  toggleBoxCaptionColor: aliases.defaultContentSecondary,
  toggleBoxDisabledCaptionColor: aliases.disabledContent,
  /**
   * Toggles/ToggleControl
   */
  toggleControlDotBackgroundColor: aliases.defaultBackgroundColor,
  toggleControlOvalSizeSmallWidth: 20,
  toggleControlOvalSizeSmallHeight: 12,
  toggleControlOvalSizeMediumWidth: 28,
  toggleControlOvalSizeMediumHeight: 16,
  toggleControlOvalBackgroundColor: colors.grey50,
  toggleControlOvalBorderRadius: 100,
  toggleControlOvalCheckedBackgroundColor: aliases.ctaBackgroundColor,
  toggleControlOvalCheckedHoverBackgroundColor: aliases.ctaBackgroundColorHover,
  toggleControlOvalDisabledBackgroundColor: colors.grey20,
  toggleControlOvalDisabledCheckedBackgroundColor: aliases.ctaBorderColorHover,
  /**
   * Tooltip
   */
  tooltipBackgroundColor: aliases.defaultBackgroundColorInverted,
  tooltipLightBackgroundColor: aliases.defaultBackgroundColorLight,
  cardTooltipBackgroundColor: aliases.secondaryBackgroundColor,
  tooltipColor: aliases.defaultContentInverted,
  tooltipLightColor: aliases.defaultContent,
  tooltipMaxWidth: 264,
  tooltipCaptionColor: aliases.defaultContentSecondaryInverted,
  tooltipLightCaptionColor: aliases.defaultContentSecondary,
  /**
   * FormGroup
   */
  formGroupLabelColor: aliases.defaultContent,
  formGroupDisabledLabelColor: aliases.disabledContent,
  formGroupCaptionColor: aliases.defaultContentSecondary,
  formGroupDisabledCaptionColor: aliases.disabledContent,
  formGroupErrorCaptionColor: aliases.errorContent,
  formGroupWarningCaptionColor: aliases.warningContent,
  /**
   * FormGroup
   */
  cascaderItemLabel: colors.grey100,
  cascaderItemLabelActive: colors.inverted100,
  cascaderItemBackground: colors.inverted100,
  cascaderItemBackgroundActive: colors.primary50,
  cascaderMainItemLabel: colors.primary50,
  cascaderMainItemLabelActive: colors.primary100,
  cascaderMainItemBackground: colors.inverted100,
  cascaderMainItemBackgroundActive: colors.primary05,
  cascaderColumnBorder: colors.grey05,

  /**
   * Icon
   */
  icon20Size: 20,
  icon16Size: 16,
}
