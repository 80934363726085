import { PayloadActionCreator, createAction } from '@reduxjs/toolkit'
import { call, put, takeEvery } from 'redux-saga/effects'
import { ResponseShop, ShopService } from '@ads/front-core/api'
import { apiError } from '@ads/front-core/store/sagas/apiErrorSaga'
import { setShop } from '../reducers/shop/setShopReducer'

interface FetchShopDataProps {
  shopId: number
}

type FetchShopDataSaga = ReturnType<
  PayloadActionCreator<FetchShopDataProps, string>
>

export const fetchShopData = createAction<FetchShopDataProps>(
  'saga/fetchShopData',
)

export function* sagaFetchShopData(): Generator {
  yield takeEvery(fetchShopData.type, fetchShopDataSaga)
}

export function* fetchShopDataSaga({ payload }: FetchShopDataSaga): Generator {
  const { shopId } = payload
  try {
    if (!shopId) {
      return
    }

    const responseShop = yield call(ShopService.getShopById, { shopId })
    const shop = responseShop as Required<ResponseShop>

    yield put(setShop(shop))
  } catch (error) {
    yield put(setShop(undefined))
    window.localStorage.removeItem('currentClientId')
    window.localStorage.removeItem('currentShopId')
    yield put(apiError({ error }))
  }
}
