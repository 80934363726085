import { Budget, TPBudget } from '@ads/front-core/api'

const formatBudgetDevice = (budgetDevice: TPBudget): TPBudget => ({
  dailyLimit: budgetDevice?.dailyLimit || null,
  globalLimit: budgetDevice?.globalLimit,
  rate: budgetDevice?.rate,
})

export const formatBudget = (budget: Budget): Budget => ({
  ...(budget?.DESKTOP
    ? {
        DESKTOP: formatBudgetDevice(budget?.DESKTOP),
      }
    : {}),
  ...(budget?.MOBILE
    ? {
        MOBILE: formatBudgetDevice(budget?.MOBILE),
      }
    : {}),
  ...(budget?.ANY
    ? {
        ANY: formatBudgetDevice(budget?.ANY),
      }
    : {}),
})
