import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export const setFormSubmittingState = createAction<{
  formKey: string
  isSubmitting: boolean
  withOverlay?: boolean
}>('app/setFormSubmittingState')

export const setFormSubmittingStateReducer = (
  state: RootState['app'],
  action: ReturnType<typeof setFormSubmittingState>,
): void => {
  const { formKey, isSubmitting, withOverlay } = action.payload
  state.formSubmittingState[formKey] = { isSubmitting, withOverlay }
}
