import { RefObject, useLayoutEffect, useState } from 'react'
import { IDropdown } from '../components/Dropdown'

interface UseDropdownEdgeCaseProps {
  isOpen?: boolean
  node: RefObject<HTMLDivElement>
  minHeight?: number
  maxHeight?: number
}

const defaultDropdownMinHeight = 180
const defaultDropdownMaxHeight = 312
const defaultWindowMargin = 24

export const useDropdownEdgeCase = ({
  isOpen,
  node,
  minHeight = defaultDropdownMinHeight,
  maxHeight = defaultDropdownMaxHeight,
}: UseDropdownEdgeCaseProps) => {
  const [dropdownDirection, setDropdownDirection] = useState<
    IDropdown['direction']
  >('down')

  const [maxDropdownHeight, setMaxDropdownHeight] = useState(maxHeight)
  useLayoutEffect(() => {
    if (!isOpen) return
    const bounding = node?.current?.getBoundingClientRect()
    const windowHeight = window.innerHeight

    if (!bounding) return

    const freeSpace =
      windowHeight - (bounding?.top + bounding?.height + defaultWindowMargin)

    if (freeSpace > minHeight) {
      setDropdownDirection('down')
      setMaxDropdownHeight(Math.min(freeSpace, maxHeight))
    } else {
      setDropdownDirection('up')
      setMaxDropdownHeight(maxHeight)
    }
  }, [isOpen, minHeight, node, maxHeight])

  return { dropdownDirection, maxDropdownHeight }
}
