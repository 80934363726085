import { createAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { ResponseServiceWorkerValidation } from '@ads/front-core/api'
import { AppState } from '../../appState'

export const setServiceWorkerStatus = createAction<ResponseServiceWorkerValidation>(
  'form/setServiceWorkerStatus',
)

export const setServiceWorkerStatusReducer = (
  state: AppState['form'],
  action: ReturnType<typeof setServiceWorkerStatus>,
): void => {
  state.serviceWorkerValidation = cloneDeep(action.payload)
}
