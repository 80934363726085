import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { checkIntent } from '../../../utility'
import { Flex } from '../../Flex'
import { Icon } from '../../Icon'
import { Spinner } from '../../Loaders/Spinner'
import { Text } from '../../Text'
import { IMenuAddon, IMenuItemContainer } from './types'

export const MenuAddon: FC<React.PropsWithChildren<IMenuAddon>> = ({
  label,
  children,
  isError,
  intent,
  ...rest
}) => {
  if (process.env.NODE_ENV !== 'production' && label && children)
    console.warn("You shouldn't set label and children at the same time.")

  return (
    <Container alignItems="center" isError={isError} {...rest}>
      {!isError && checkIntent('loading', intent) && <Spinner mr="xxs" />}
      {isError && <Icon name="20-alert-solid" mr="xxs" />}
      {label && (
        <Text fontSize="m" lineHeight="m">
          {label}
        </Text>
      )}
      {children}
    </Container>
  )
}

const Container = styled(Flex)<IMenuItemContainer>`
  ${p => css`
    padding: ${p.theme.space.xs / 2}px ${p.theme.space.xxs}px;
    color: ${p.theme.components[
      p.isError ? 'menuAddonErrorColor' : 'menuAddonColor'
    ]};
    font-size: ${p.theme.fontSizes.m}px;
    line-height: ${p.theme.lineHeights.m};
  `};
`
