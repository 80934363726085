import React, { forwardRef, Ref } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { IGrid } from './types'
import { Space } from '../../theme/interfaces/Space'

export const Grid = forwardRef((props: IGrid, ref: Ref<HTMLElement>) => {
  const { gap = 'none', columns = '1', ...rest } = props
  return <GridContainer ref={ref} gap={gap} columns={columns} {...rest} />
})

const getGap = (gap: IGrid['gap'], space: Space) =>
  Array.isArray(gap)
    ? css`
        grid-gap: ${space[gap[1]]}px ${space[gap[0]]}px;
      `
    : css`
        grid-gap: ${gap ? space[gap] : ''}px;
      `

const GridContainer = styled(Box)<IGrid>`
  display: grid;

  ${p =>
    css`
      grid-template-columns: repeat(${p.columns}, 1fr);
      ${getGap(p.gap, p.theme.space)};
    `};
`
