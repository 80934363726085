import { IBadge as IBadgeDS, colors } from '@ads/front-ds'

export const getColorByVariant = (variant?: IBadgeDS['variant']) => {
  switch (variant) {
    default:
    case 'default':
      return colors.primary05
    case 'alert':
      return colors.alert05
    case 'success':
      return colors.success05
    case 'warning':
      return colors.warning05
    case 'neutral':
      return colors.grey05
    case 'transparent':
      return 'transparent'
  }
}
