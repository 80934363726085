import { sagaApiError } from './apiErrorSaga'
import { sagaBanner } from './bannerSaga'
import { sagaConfig } from './fetchConfigSaga'
import { sagaSidebarItem } from './sidebarItemSaga'
import { sagaSidebar } from './sidebarSaga'
import { sagaSuccess } from './successSaga'
import { sagaToast } from './toastSaga'

const rootSaga = [
  sagaApiError,
  sagaBanner,
  sagaToast,
  sagaSidebar,
  sagaSidebarItem,
  sagaConfig,
  sagaSuccess,
]

export default rootSaga
