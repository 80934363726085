import React from 'react'
import styled, { css } from 'styled-components'

import { Flex } from '../Flex'
import { Text } from '../Text'
import { ICounter, IContainer } from './types'
import { Components } from '../../theme/interfaces'

const getVariantColors = (
  variant: ICounter['variant'],
  appearance: ICounter['appearance'],
) => {
  // release protection after change counter structure
  // @ts-ignore
  if (variant === 'inverted') {
    console.warn('Change variant type')
    variant = 'default'
  }

  const variantKey = variant
    ? variant.charAt(0).toUpperCase() + variant.slice(1)
    : ''
  const strongContrastColor = `counter${variantKey}StrongContrastColor` as keyof Components
  const lowContrast = `counter${variantKey}LowContrastColor` as keyof Components

  switch (appearance) {
    case 'strong':
      return css`
        ${({ theme }) => css`
          background-color: ${variant === 'default'
            ? theme.components.counterDefaultStrongBackgroundColor
            : theme.components[strongContrastColor]};
          color: ${theme.components.counterInvertedColor};
        `}
      `
    case 'inverted':
      return css`
        ${({ theme }) => css`
          background-color: ${theme.components.counterInvertedColor};
          color: ${theme.components[strongContrastColor]};
        `}
      `
    default:
      return css`
        ${({ theme }) => css`
          background-color: ${theme.components[lowContrast]};
          color: ${theme.components[strongContrastColor]};
        `}
      `
  }
}

export const Counter = ({
  label,
  isPrefix,
  isSuffix,
  variant = 'default',
  ...rest
}: ICounter) => {
  if (process.env.NODE_ENV !== 'production' && isPrefix && isSuffix)
    console.warn("You shouldn't set isPrefix and isSuffix at the same time.")

  return (
    <Container
      alignItems="center"
      justifyContent="center"
      p="xxxs"
      variant={variant}
      {...rest}
    >
      <Text fontSize="xs" lineHeight="xs" fontWeight="bold" textAlign="center">
        {isPrefix && !isSuffix && '+'}
        {label}
        {isSuffix && '+'}
      </Text>
    </Container>
  )
}

const Container = styled(Flex)<IContainer>`
  display: inline-flex;
  min-width: 20px;
  border-radius: 100px;
  cursor: default;

  ${({ variant, appearance }) => getVariantColors(variant, appearance)};
`
