import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (...args: any[]) => {}

export interface TagsPathContextValue {
  currentHoveredTag: number
  hoveredLastTag: boolean
  tagsLength: number
  setCurrentHoveredTag: Dispatch<SetStateAction<number>>
  setHoveredLastTag: Dispatch<SetStateAction<boolean>>
}

export interface TagsPathContextProviderProps {
  children: ReactNode
  tagsLength: number
}

export const defaultTagsPathtmContext: TagsPathContextValue = {
  currentHoveredTag: 1,
  hoveredLastTag: false,
  tagsLength: 0,
  setCurrentHoveredTag: noop,
  setHoveredLastTag: noop,
}

const TagsPathContext = createContext<TagsPathContextValue>(
  defaultTagsPathtmContext,
)

TagsPathContext.displayName = 'TagsPathtmContext_StrictMode'

export const TagsPathtProvider = ({
  children,
  tagsLength,
}: TagsPathContextProviderProps) => {
  const [currentHoveredTag, setCurrentHoveredTag] = useState<number>(-1)
  const [hoveredLastTag, setHoveredLastTag] = useState(false)

  return (
    <TagsPathContext.Provider
      value={{
        currentHoveredTag,
        setCurrentHoveredTag,
        hoveredLastTag,
        setHoveredLastTag,
        tagsLength,
      }}
    >
      {children}
    </TagsPathContext.Provider>
  )
}
export const useTagsPathSettings = (): TagsPathContextValue =>
  useContext(TagsPathContext)
