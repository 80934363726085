import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { IText, Text } from '../../Text'
import { IMenuAction, IMenuItemContainer } from './types'

import { checkIntent } from '../../../utility'

export const MenuAction: FC<React.PropsWithChildren<IMenuAction>> = ({
  label,
  isDisabled,
  ...rest
}) => (
  <Label
    fontSize="m"
    lineHeight="m"
    forwardedAs={'div' as IText['forwardedAs']}
    isDisabled={isDisabled}
    {...rest}
  >
    {label}
  </Label>
)

const Label = styled(Text)<IMenuItemContainer>`
  ${p => css`
    color: ${p.theme.components[
      p.isDisabled ? 'menuChildDisabledColor' : 'menuActionColor'
    ]};
    padding: ${p.theme.space.xs / 2}px ${p.theme.space.xxs}px;
    pointer-events: ${p.isDisabled && 'none'};
    border-radius: ${p.theme.radii.rounded};
    cursor: ${p.isDisabled ? 'default' : 'pointer'};

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: ${p.theme.components.menuChildHoverBackgroundColor};
      color: ${p.theme.components.menuActionHoverColor};
    }

    &:focus {
      background-color: ${p.theme.components.menuChildFocusBackgroundColor};
    }

    ${checkIntent('children', p.intent) &&
    css`
      ${p.level &&
      css`
        padding-left: ${checkIntent('selectable', p.intent)
          ? p.level * p.theme.space.m + p.theme.space.xl
          : p.level * p.theme.space.m}px;
      `}
    `}
  `};
`
