/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestConfirmShopDraft } from '../models/RequestConfirmShopDraft'
import type { RequestCreateShopOrDraft } from '../models/RequestCreateShopOrDraft'
import type { ResponseCreateShopOrDraft } from '../models/ResponseCreateShopOrDraft'
import type { ResponseShop } from '../models/ResponseShop'
import type { ResponseShopDraft } from '../models/ResponseShopDraft'
import type { ResponseShopDraftList } from '../models/ResponseShopDraftList'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ShopDraftService {
  /**
   * Tworzy nowy sklep albo draft w przypadku braku uprawnień
   * @returns ResponseCreateShopOrDraft dane zapisane
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static addShopOrDraft({
    requestBody,
  }: {
    /**
     * Dane sklepu
     */
    requestBody: RequestCreateShopOrDraft
  }): CancelablePromise<ResponseCreateShopOrDraft | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/shop-or-draft',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * zwraca listę nowych draftów sklepów
   * @returns ResponseShopDraftList lista draftów sklepów
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAdminShopDraftList({
    page,
    limit,
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
  }): CancelablePromise<ResponseShopDraftList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/shop-draft',
      query: {
        page: page,
        limit: limit,
      },
    })
  }

  /**
   * zwraca listę draftów sklepów należących do klienta
   * @returns ResponseShopDraft lista draftów sklepów
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getShopDraftList({
    clientId,
    page,
    limit,
  }: {
    clientId: number
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
  }): CancelablePromise<Array<ResponseShopDraft> | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client/{clientId}/shop-draft',
      path: {
        clientId: clientId,
      },
      query: {
        page: page,
        limit: limit,
      },
    })
  }

  /**
   * zwraca draft sklepu
   * @returns ResponseShopDraft dane draftu sklepu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getShopDraft({
    draftId,
  }: {
    /**
     * identyfikator draftu sklepu z tabeli shop_draft
     */
    draftId: number
  }): CancelablePromise<ResponseShopDraft | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/shop-draft/{draftId}',
      path: {
        draftId: draftId,
      },
    })
  }

  /**
   * Tworzy sklep na podstawie draftu
   * @returns ResponseShop dane zapisane
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static confirmShopDraft({
    draftId,
    requestBody,
  }: {
    /**
     * identyfikator draftu sklepu z tabeli shop_draft
     */
    draftId: number
    /**
     * Dane sklepu
     */
    requestBody: RequestConfirmShopDraft
  }): CancelablePromise<ResponseShop | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admin/shop-draft/{draftId}/confirm',
      path: {
        draftId: draftId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Odrzuca draft sklepu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static rejestShopDraft({
    draftId,
    requestBody,
  }: {
    /**
     * identyfikator draftu sklepu z tabeli shop_draft
     */
    draftId: number
    /**
     * Dane sklepu
     */
    requestBody?: {
      rejectionReason?: string
    }
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/shop-draft/{draftId}/reject',
      path: {
        draftId: draftId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
