import React, {
  FocusEvent,
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import { checkIntent, setTransition } from '../../utility'
import { Box } from '../Box'
import { Icon } from '../Icon'
import { ITag, Tag } from '../Tag'
import { Text } from '../Text'
import {
  ITagInput,
  ITagInputItem,
  TagInputContainerProps,
  TagInputElementProps,
} from './types'

export const TagInput = React.memo(
  forwardRef((props: ITagInput, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      value,
      defaultValue,
      placeholder,
      tags,
      id,
      selected,
      isError,
      isReadOnly,
      isDisabled,
      intent,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      onTagRemove,
      onTagClick,
      ...rest
    } = props

    const [isFocused, setFocus] = useState(false)
    const [isFitted, setFitted] = useState<boolean | null>(false)

    const hasArrow = checkIntent('arrow', intent)

    const container = useRef<HTMLDivElement>(null)

    const inputRef = useRef<HTMLInputElement>(null)
    const input = ref && typeof ref !== 'function' ? ref : inputRef

    const handleOnFocus = React.useCallback(
      (e?: FocusEvent<HTMLInputElement>) => {
        if (!e) return
        setFocus(true)
        if (onFocus) onFocus(e)
      },
      [onFocus],
    )

    const handleOnBlur = React.useCallback(
      (e?: FocusEvent<HTMLInputElement>) => {
        if (!e) return
        setFocus(false)
        if (onBlur) onBlur(e)
      },
      [onBlur],
    )

    const handleOnTagClick = React.useCallback(
      (tag: ITagInputItem, e?: MouseEvent) => {
        if (!e) return
        e.stopPropagation()
        if (onTagClick) onTagClick(tag)
      },
      [onTagClick],
    )

    const handleOnTagRemove = React.useCallback(
      (tag: ITagInputItem, e?: MouseEvent) => {
        if (!e) return
        e.stopPropagation()
        if (onTagRemove) onTagRemove(tag)
      },
      [onTagRemove],
    )

    useEffect(() => setFocus(checkIntent('focused', intent)), [intent])

    useLayoutEffect(() => {
      const paddingX = 8
      const arrowWidth = hasArrow ? 40 : 0

      setFitted(
        input.current &&
          !!container.current &&
          input.current.offsetWidth + arrowWidth + paddingX ===
            container.current.offsetWidth &&
          (!tags || tags?.length === 0),
      )
    }, [input, value, tags, hasArrow])

    return (
      <TagInputContainer
        ref={container}
        p="xxxs"
        isFocus={isFocused}
        isError={!!isError}
        isDisabled={!!isDisabled}
        intent={intent}
        onClick={() => input.current?.focus()}
        {...rest}
      >
        {tags &&
          tags.length > 0 &&
          tags.map(tag => (
            <TagInputTagElement
              tag={tag}
              key={tag.id}
              handleOnTagClick={handleOnTagClick}
              handleOnTagRemove={handleOnTagRemove}
              isDisabled={isDisabled}
              selected={selected}
            />
          ))}
        <TagInputElement
          ref={input}
          id={id}
          placeholder={tags && tags.length > 0 ? '' : placeholder}
          lineHeight="l"
          isPaddings={!!isFitted}
          value={value}
          defaultValue={defaultValue}
          isReadOnly={isReadOnly}
          onChange={onChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          width={
            tags && tags.length > 0 && !isDisabled && value
              ? value.length
              : undefined
          }
          isDisabled={isDisabled}
          aria-label={rest['aria-label']}
        />
        {hasArrow && <StyledIcon name="20-arrowhead-down" />}
      </TagInputContainer>
    )
  }),
)

const TagInputTagElement = React.memo(
  ({
    tag,
    isDisabled,
    handleOnTagRemove,
    handleOnTagClick,
    selected,
  }: {
    tag: ITagInputItem
    isDisabled?: boolean
    handleOnTagRemove: (tag: ITagInputItem, e?: MouseEvent) => void
    handleOnTagClick: (tag: ITagInputItem, e?: MouseEvent) => void
    selected: string | undefined
  }) => {
    const iconAfter = React.useMemo((): ITag['iconAfter'] => {
      return {
        name: '16-close',
        onClick: e => handleOnTagRemove(tag, e),
      }
    }, [handleOnTagRemove, tag])
    const iconBefore = React.useMemo<ITag['iconBefore']>(() => {
      return tag.id === selected
        ? {
            name: '16-star',
            color: 'primary50',
          }
        : undefined
    }, [selected, tag.id])
    const onClick = React.useCallback<NonNullable<ITag['onClick']>>(
      e => handleOnTagClick(tag, e),
      [handleOnTagClick, tag],
    )

    return (
      <StyledTag
        label={tag.label}
        type={tag.type}
        iconBefore={iconBefore}
        iconAfter={iconAfter}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    )
  },
)

TagInputTagElement.displayName = 'TagInputTagElement'

TagInput.displayName = 'TagInput'

const TagInputContainer = styled(Box)<TagInputContainerProps>`
  display: flex;
  flex-wrap: wrap;
  cursor: text;
  ${setTransition(['border-color', 'box-shadow'], 'productive')};

  ${p => css`
    border-radius: ${p.theme.radii.rounded};
    padding: 1px;
    border: ${p.theme.components.tagInputBorder};
    background-color: ${p.theme.colors.inverted100};

    ${!p.isFocus &&
    css`
      &:hover {
        border-color: ${p.theme.components[
          p.isError
            ? 'tagInputErrorHoverBorderColor'
            : 'tagInputHoverBorderColor'
        ]};
      }
    `};

    ${p.isFocus &&
    css`
      box-shadow: ${p.theme.focuses[p.isError ? 'error' : 'normal']};
      border-color: transparent;
    `};

    ${p.isError &&
    !p.isFocus &&
    !p.isDisabled &&
    css`
      border-color: ${p.theme.components.tagInputErrorBorderColor};
    `};

    ${p.isDisabled &&
    css`
      background-color: ${p.theme.components.tagInputDisabledBackgroundColor};
      pointer-events: none;
    `};

    ${checkIntent('arrow', p.intent) &&
    css`
      position: relative;
      padding-right: ${p.theme.space.xl + 1}px;
    `};
  `};
`

const TagInputElement = styled(Text).attrs({
  forwardedAs: 'input',
})<TagInputElementProps>`
  border: 0;
  appearance: none;
  font-family: inherit;
  letter-spacing: 0.2px;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  ${p => css`
    margin: ${p.theme.space.xxxs / 2}px;
    padding: 0 ${p.isPaddings ? p.theme.space.s - 2 : 0}px;
    flex: ${!p.width ? `1 1 auto` : `0 0 ${p.width}ch`};
    width: ${!p.width && !p.value && '0px'};
    min-width: 24px;
    max-width: calc(100% - ${p.theme.space.xxxs}px);
    color: ${p.theme.components[
      p.isDisabled ? 'tagInputTextInputDisabledColor' : 'tagInputTextInputColor'
    ]};

    &::placeholder {
      color: ${p.theme.components[
        p.isDisabled
          ? 'tagInputTextInputDisabledColor'
          : 'tagInputTextInputPlaceholderColor'
      ]};
    }
  `};
`

const StyledTag = styled(Tag).attrs({ forwardedAs: 'button' })`
  ${p => css`
    margin: ${p.theme.space.xxxs / 2}px;
    word-break: break-word;
  `};
`

const StyledIcon = styled(Icon)`
  position: absolute;

  ${p => css`
    right: ${p.theme.space.s - 1}px;
    top: ${p.theme.space.xxs - 1}px;
  `};
`
