import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { setTransition } from '../../../utility'
import { Box, IBox } from '../../Box'
import { Icon } from '../../Icon'
import { ICheckboxControl, IInput, ISquare } from './types'

export const CheckboxControl = (props: ICheckboxControl) => {
  const {
    isChecked = false,
    isIndeterminate,
    value,
    name,
    isError,
    isDisabled,
    isHover,
    isRequired,
    forwardedAs,
    onChange,
    isReadOnly,
    ...rest
  } = props

  const [isFocused, setIsFocus] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)

  const attributes = {
    value,
    isChecked,
    isIndeterminate,
    isDisabled,
    name,
    isRequired,
  }

  const handleOnClick = () => setIsMouseDown(true)

  const handleOnFocus = () => setIsFocus(true)

  const handleOnBlur = () => {
    setIsFocus(false)
    setIsMouseDown(false)
  }

  return (
    <StyledControl
      forwardedAs={forwardedAs || 'label'}
      isDisabled={isDisabled}
      {...rest}
    >
      <Input
        type="checkbox"
        {...attributes}
        checked={isChecked}
        disabled={isDisabled}
        isError={!!isError}
        isMouseDown={isMouseDown}
        onClick={handleOnClick}
        onChange={onChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        readOnly={isReadOnly}
      />
      <Square
        borderRadius="rounded"
        forwardedAs={'span' as IBox['forwardedAs']}
        isChecked={isChecked}
        isFocus={isFocused}
        isHover={isHover}
        isError={isError}
        isDisabled={isDisabled}
      >
        {isChecked && (
          <Check
            name={isIndeterminate ? '16-minus' : '16-check'}
            color={isDisabled ? 'greyDisabled' : 'inverted100'}
          />
        )}
      </Square>
    </StyledControl>
  )
}

const StyledControl = styled(Box)<ICheckboxControl>`
  position: relative;
  display: inline-flex;
  align-items: center;

  ${p => css`
    ${p.isDisabled &&
    css`
      pointer-events: none;
    `};
  `};
`

const Square = styled(Box)<ISquare>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${setTransition(
    ['border-color', 'background-color', 'opacity', 'box-shadow'],
    'productive',
  )};

  ${({ theme, isChecked, isError, isHover, isDisabled }) => css`
    width: ${theme.space.s}px;
    height: ${theme.space.s}px;
    border: ${theme.components.checkboxControlSquareBorder};

    &:hover {
      border-color: ${!isChecked &&
      theme.components[
        isError
          ? 'checkboxControlSquareHoverErrorBorderColor'
          : 'checkboxControlSquareHoverBorderColor'
      ]};
    }

    ${isHover &&
    !isChecked &&
    `border-color: ${
      theme.components[
        isError
          ? 'checkboxControlSquareHoverErrorBorderColor'
          : 'checkboxControlSquareHoverBorderColor'
      ]
    }`};

    ${isChecked &&
    css`
      background-color: ${theme.components
        .checkboxControlSquareCheckedBackgroundColor};
      border-color: ${theme.components.checkboxControlSquareCheckedBorderColor};

      &:hover {
        background-color: ${theme.components
          .checkboxControlSquareCheckedHoverBackgroundColor};
        border-color: ${theme.components
          .checkboxControlSquareCheckedHoverBorderColor};
      }
    `};

    ${isError &&
    !isHover &&
    !isChecked &&
    css`
      border-color: ${theme.components.checkboxControlSquareErrorBorderColor};
    `};

    ${isDisabled &&
    css`
      background-color: ${theme.components
        .checkboxControlSquareDisabledBackgroundColor};
      border-color: ${theme.components
        .checkboxControlSquareDisabledBorderColor};
      pointer-events: none;
    `};
  `};
`

const Check = styled(Icon)`
  position: absolute;
  top: -1px;
  left: -1px;
`

const Input = styled.input<IInput>`
  position: absolute;
  opacity: 0;
  z-index: -1;

  ${({ theme, isMouseDown, isError }) => css`
    &:focus + ${Square} {
      box-shadow: ${!isMouseDown &&
      theme.focuses[isError ? 'error' : 'normal']};
      border-color: ${!isMouseDown && 'transparent'};
    }

    &:active:not(:checked) + ${Square} {
      background-color: ${theme.components
        .checkboxControlSquareActiveBackgroundColor};
      border-color: ${theme.components.checkboxControlSquareHoverBorderColor};
    }

    &:active:checked + ${Square} {
      opacity: ${theme.aliases.defaultActiveOpacity};
    }
  `}
`
