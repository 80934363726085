import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  isWithinInterval,
  startOfDay,
} from 'date-fns'
import React, { forwardRef, useEffect, useRef, useState } from 'react'

import { handleChangeProps } from '../DateInput/types'
import { TimeInputProps } from '../Datepicker/types'
import { Hour, Minute, MultiInput } from '../MultiInput'
import { dateUpdater } from '../utils'

import { usePrevious } from '../../../hooks'
import { DateInputIconButton } from '../DateInput/DateInputIconButton'
import { Prefix } from '../DateInput/Prefix'
import {
  checkIsAvailableDate,
  defaultDate,
  getCurrentInputParams,
} from '../utils'

export const isDisabledDates = (date: Date, disabled: Date | Date[]) => {
  if (Array.isArray(disabled)) {
    return !!disabled.find(d => isSameDay(date, d))
  }

  return isSameDay(date, disabled)
}

export const TimeInput = forwardRef<HTMLDivElement, TimeInputProps>(
  (
    {
      prefix,
      iconButton,
      onChange,
      date,
      disabledFrom,
      disabledTo,
      disabledDates,
      onFocus,
      onBlur,
      isDisabled,
      intent,
    },
    ref,
  ) => {
    const previousTime = usePrevious(date)

    const timeoutRef = useRef<number | null>(null)
    const [currentDate, setCurrentDate] = useState<Date | undefined>(date)
    const [isFocused, setIsFocused] = useState<boolean>(false)

    const hourRef = useRef<HTMLInputElement>(null)
    const minuteRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (date !== previousTime && date !== currentDate) {
        setCurrentDate(date)
      }
    }, [date, previousTime, currentDate])

    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
      if (date) {
        setCurrentDate(date)

        const isAvailable = checkIsAvailableDate({
          disabledDates,
          disabledFrom,
          disabledTo,
          currentDate: date,
        })

        if (disabledFrom && disabledTo) {
          const blockedStartDate = endOfDay(disabledFrom)
          const blockedEndDate = startOfDay(disabledTo)
          const isDateBlocked = isWithinInterval(date, {
            start: blockedStartDate,
            end: blockedEndDate,
          })
          setIsFormDisabled(isDateBlocked)
        } else if (disabledFrom) {
          const blockedStartDate = endOfDay(disabledFrom)
          const isDateBlocked = isAfter(date, blockedStartDate)
          setIsFormDisabled(isDateBlocked)
        } else if (disabledTo) {
          const blockedEndDate = startOfDay(disabledTo)
          const isDateBlocked = isBefore(date, blockedEndDate)
          setIsFormDisabled(isDateBlocked)
        }

        if (disabledDates) {
          const isDateBlocked = isDisabledDates(date, disabledDates)
          setIsFormDisabled(isDateBlocked)
        }

        setIsError(!isAvailable)
      }
    }, [date, disabledDates, disabledFrom, disabledTo])

    const [isFirstDigitZero, setIsFirstDigitZero] = useState<boolean>(false)

    const handleChange = ({ event, nextField, type }: handleChangeProps) => {
      const inputValue = event.target.value
      const { maxLength, maxFirstDigit } = getCurrentInputParams(type)
      let value = inputValue

      // Allow 0 as the first digit, but keep the date valid.
      if (inputValue === '0') {
        value = '1'
        setIsFirstDigitZero(true)
      }

      // Reset input when previous value was 0, and we replaced with 1. DateUpdater added a 0 at the beginning, so now is '01';
      if (isFirstDigitZero) {
        value = inputValue.substring(2, inputValue.length)
        setIsFirstDigitZero(false)
      }

      const valueLength = value.replace(/^0+/, '').length

      // More than 2 digits for hour or minute
      const isMaxLengthReached = valueLength >= maxLength

      // ex. if you type 2 in hour field, you can type next number, but if you type 3, you should jump to the next field
      const isFirstDigitMoreThanMax =
        maxFirstDigit && valueLength === 1 && parseInt(value) > maxFirstDigit

      if (
        nextField &&
        (isMaxLengthReached || isFirstDigitMoreThanMax || isFirstDigitZero)
      ) {
        nextField.focus()
        nextField.select()
      }

      const newDate = dateUpdater({
        value,
        type,
        oldDate: currentDate || defaultDate,
      })

      const isValidDate = isValid(newDate)
      const isAvailable = checkIsAvailableDate({
        disabledDates,
        disabledFrom,
        disabledTo,
        currentDate: newDate,
      })

      if (newDate && isValidDate && isAvailable) {
        setCurrentDate(newDate)
        onChange?.(newDate)
      }
    }

    const handleOnFocus = (e: { target: { select: () => void } }) => {
      e.target.select()
      setIsFirstDigitZero(false)

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      if (!isFocused) {
        setIsFocused(true)
      }
    }

    const handleOnBlur = () => {
      setIsFirstDigitZero(false)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = window.setTimeout(() => {
        setIsFocused(false)
      }, 100)
    }

    const focusFirstInput = () => {
      const isFocusedAlready =
        document.activeElement === hourRef.current ||
        document.activeElement === minuteRef.current

      if (!isFocusedAlready) {
        hourRef.current?.focus()
      }
    }

    return (
      <MultiInput
        ref={ref}
        isError={isError}
        isFocused={isFocused}
        intent={intent}
        isDisabled={isFormDisabled || isDisabled}
        onClick={focusFirstInput}
        onFocus={onFocus}
        onBlur={onBlur}
        pl={prefix ? 'xs' : 0}
        pr={iconButton ? 0 : 'xs'}
      >
        {prefix && <Prefix isDisabled={isDisabled} prefix={prefix} />}
        <Hour
          value={currentDate ? format(currentDate, 'HH') : ''}
          placeholder="HH"
          onChange={event =>
            handleChange({
              event,
              nextField: minuteRef.current,
              type: 'hour',
            })
          }
          onFocus={handleOnFocus}
          ref={hourRef}
          onBlur={handleOnBlur}
          disabled={isFormDisabled}
        />
        :
        <Minute
          value={currentDate ? format(currentDate, 'mm') : ''}
          placeholder="MM"
          onChange={event =>
            handleChange({
              event,
              nextField: hourRef.current,
              type: 'minute',
            })
          }
          onFocus={handleOnFocus}
          ref={minuteRef}
          onBlur={handleOnBlur}
          disabled={isFormDisabled}
        />
        {iconButton && <DateInputIconButton {...iconButton} />}
      </MultiInput>
    )
  },
)
