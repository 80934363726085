import React, { FC, RefObject, createRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Box, IBox, Toast, space } from '@ads/front-ds'
import zIndex from '@ads/front-core/constants/zIndex'
import { getToasts } from '@ads/front-core/store/selectors/getToasts'

interface WrapperProps extends IBox {
  containerHeight: number
}

const Toasts: FC = () => {
  const [containerHeight, setContainerHeight] = useState(0)
  const toasts = useSelector(getToasts)
  const toastsIds = Object.keys(toasts)

  const toastsRef = toastsIds.reduce(
    (acc, id) => ({
      ...acc,
      [id]: createRef<HTMLDivElement>(),
    }),
    {} as { [id in string]: RefObject<HTMLDivElement> },
  )

  useEffect(() => {
    const refs = Object.values(toastsRef)
    const itemsHeight = refs.reduce((acc, ref) => {
      if (ref.current) {
        return acc + ref.current.clientHeight
      }
      return acc
    }, 0)
    const spaceSum = (refs.length - 1) * space.xs
    setContainerHeight(Math.max(0, itemsHeight + spaceSum))
  }, [toastsRef])

  return (
    <Wrapper containerHeight={containerHeight}>
      {toastsIds.map((id: string) => (
        <Box key={`toast-${id}`} ref={toastsRef[id]}>
          <Toast label={toasts[id].message} />
        </Box>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled(Box)<WrapperProps>`
  ${p => css`
    display: grid;
    gap: ${p.theme.space.xs}px;
    position: fixed;
    top: calc(100vh - ${p.containerHeight + p.theme.space.xs}px);
    left: ${p.theme.space.l}px;
    z-index: ${zIndex.TOAST};
    transition: top 0.5s;
  `}
`

export default Toasts
