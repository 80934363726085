import { createAction } from '@reduxjs/toolkit'
import { ResponseAvailableCampaignTypes } from '@ads/front-core/api'
import { AppState } from '../../appState'

type SetAvailableCampaignTypes = {
  shopId: number
  types: ResponseAvailableCampaignTypes
}

export const setAvailableCampaignTypes = createAction<SetAvailableCampaignTypes>(
  'campaign/setAvailableCampaignTypes',
)

export const setAvailableCampaignTypesReducer = (
  state: AppState['campaign'],
  action: ReturnType<typeof setAvailableCampaignTypes>,
): void => {
  state.availableCampaignTypes[action.payload.shopId] = action.payload.types
}
