import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAvailableCampaignTypesLoading = createAction<boolean>(
  'campaign/setAvailableCampaignTypesLoading',
)

export const setAvailableCampaignTypesLoadingReducer = (
  state: AppState['campaign'],
  action: ReturnType<typeof setAvailableCampaignTypesLoading>,
): void => {
  state.availableCampaignTypesLoading = action.payload
}
