import { createAction } from '@reduxjs/toolkit'
import { call, put, takeEvery } from 'redux-saga/effects'
import { OtherService, ResponseDictionaryByType } from '@ads/front-core/api'
import { Category } from '@ads/front-core/hooks/useDictionary'
import { TDictionary } from '@ads/front-core/interfaces'
import { setDictionary } from '@ads/front-core/store/reducers/dictionary/setDictionaryReducer'

export function* sagaCacheDictionaries(): Generator {
  yield takeEvery(cacheDictionaries.type, cacheDictionariesSaga)
}

export const cacheDictionaries = createAction('saga/cacheDictionaries')

export function* cacheDictionariesSaga(): Generator {
  const dictionariesToCache: Category[] = [
    'mc-country',
    'mc-region',
    'mc-city',
    'mc-sex',
    'mc-age',
  ]

  for (const category of dictionariesToCache) {
    try {
      const res = yield call(OtherService.getDictionaryByType, {
        type: category,
      })
      const dictionary: TDictionary[] = (res as ResponseDictionaryByType).map(
        d => ({
          id: d.id,
          value: d.value,
        }),
      )
      yield put(
        setDictionary({
          dictionary,
          category,
        }),
      )
    } catch (e) {
      console.error('cacheDictionariesSaga', e)
    }
  }
}
