import { createAction } from '@reduxjs/toolkit'
import { UserDataResponse } from '@ads/front-core/interfaces'
import { RootState } from '../../rootState'

export const setAdmin = createAction<UserDataResponse['admin']>('user/setAdmin')

export const setAdminReducer = (
  state: RootState['admin'],
  action: ReturnType<typeof setAdmin>,
): void => {
  const { role, clients } = action.payload || {}
  state.role = role
  state.clients = clients
}
