import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setIsOpenPromotion1000About = createAction<boolean>(
  'common/isOpenPromotion1000About',
)

export const setIsOpenPromotion1000AboutReducer = (
  state: AppState['common'],
  action: ReturnType<typeof setIsOpenPromotion1000About>,
): void => {
  state.isOpenPromotion1000About = action.payload
}
