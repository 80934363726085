import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../../Text'
import { Box } from '../../Box'
import { Tick } from './MenuItem'
import { IMenuImage, IMenuItemContainer, ILabel } from './types'

import { MatchPhrase } from './MenuItem'
import { checkIntent } from '../../../utility'
import { Flex } from '../../Flex'

export const MenuImage: FC<React.PropsWithChildren<IMenuImage>> = ({
  label,
  src,
  isDisabled,
  intent,
  isSelected,
  phrase,
  ...rest
}) => (
  <Container isDisabled={isDisabled} intent={intent} tabIndex={0} {...rest}>
    {checkIntent('selectable', intent) && (
      <Tick
        name="20-check"
        mr="xxs"
        isVisible={isSelected}
        isDisabled={isDisabled}
      />
    )}
    {src && (
      <StyledImage mr="xxs">
        <Image src={src} alt={label} />
      </StyledImage>
    )}
    <Label fontSize="m" lineHeight="m" isDisabled={isDisabled}>
      {phrase ? <MatchPhrase str={label} phrase={phrase} /> : label}
    </Label>
  </Container>
)

const Container = styled(Box)<IMenuItemContainer>`
  display: flex;
  cursor: pointer;
  align-items: center;

  &:focus {
    outline: none;
  }

  ${p => css`
    padding: ${p.theme.space.xxs}px ${p.theme.space.s}px;
    pointer-events: ${p.isDisabled && 'none'};
    padding-right: ${checkIntent('selectable', p.intent) &&
    p.theme.space.xl + p.theme.space.xxxs + 'px'};

    &:hover {
      background-color: ${p.theme.components.menuChildHoverBackgroundColor};
    }

    &:focus {
      background-color: ${p.theme.components.menuChildFocusBackgroundColor};
    }

    ${checkIntent('children', p.intent) &&
    css`
      padding-left: ${checkIntent('selectable', p.intent)
        ? p.theme.space.l + p.theme.space.xs
        : p.theme.space.l}px;
    `}
  `};
`

const Label = styled(Text)<ILabel>`
  ${p => css`
    padding: ${p.theme.space.xxxs / 2}px 0;
    color: ${p.theme.components[
      p.isDisabled ? 'menuChildDisabledColor' : 'menuChildColor'
    ]};
  `};
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledImage = styled(Flex)`
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
`
