import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface ReloadComponentsParams {
  name: string
}

export const reloadComponents = createAction<ReloadComponentsParams>(
  'reloadComponents',
)

export const reloadComponentsReducer = (
  state: RootState['reloadComponents'],
  action: ReturnType<typeof reloadComponents>,
): void => {
  const name = action.payload.name
  state[name] = `${name}-${Math.random()}`
}
