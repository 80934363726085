import { createReducer } from '@reduxjs/toolkit'
import { preloadedState } from '../../appState'
import {
  setClientBalanceLoading,
  setClientBalanceLoadingReducer,
} from './setClientBalanceLoadingReducer'
import {
  setClientBalance,
  setClientBalanceReducer,
} from './setClientBalanceReducer'
import { setClient, setClientReducer } from './setClientReducer'
import { setClients, setClientsReducer } from './setClientsReducer'
import {
  setCurrentClientId,
  setCurrentClientIdReducer,
} from './setCurrentClientIdReducer'
import {
  setCurrentShopId,
  setCurrentShopIdReducer,
} from './setCurrentShopIdReducer'
import { setClientShops, setClientShopsReducer } from './setClientShopsReducer'

const clientReducers = createReducer(preloadedState.client, builder => {
  builder.addCase(setClients.type, setClientsReducer)
  builder.addCase(setClient.type, setClientReducer)
  builder.addCase(setCurrentClientId.type, setCurrentClientIdReducer)
  builder.addCase(setCurrentShopId.type, setCurrentShopIdReducer)
  builder.addCase(setClientBalance.type, setClientBalanceReducer)
  builder.addCase(setClientBalanceLoading.type, setClientBalanceLoadingReducer)
  builder.addCase(setClientShops.type, setClientShopsReducer)
})

export default clientReducers
