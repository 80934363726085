import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setClientBalanceLoading = createAction<boolean>(
  'user/setClientBalanceLoading',
)

export const setClientBalanceLoadingReducer = (
  state: AppState['client'],
  action: ReturnType<typeof setClientBalanceLoading>,
): void => {
  state.balance = {
    ...state.balance,
    isLoading: action.payload,
  }
}
