import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Icon, Text, Flex } from '@ads/front-ds'

interface IWarning {
  message: string
}

export const Warning: FC<IWarning> = ({ message }) => (
  <Flex gap="xxs" flexDirection="row" alignItems="flex-start">
    <IconWarning name="20-warning" color="warning50" />
    <Text color="grey50">{message}</Text>
  </Flex>
)

const IconWarning = styled(Icon)`
  ${p => css`
    width: ${p.theme.space.s}px;
    height: ${p.theme.space.s}px;
  `}
`
