import { createAction } from '@reduxjs/toolkit'
import { put, takeEvery } from 'redux-saga/effects'
import { GetConfigResponse } from '@ads/front-core/interfaces'
import { setReleaseVersion } from '@ads/front-core/store/reducers/app/setReleaseVersionReducer'
import { apiError } from '@ads/front-core/store/sagas/apiErrorSaga'
import getEnv from '@ads/front-core/utils/getEnv'

export const fetchConfig = createAction('saga/fetchConfig')

export function* sagaConfig(): Generator {
  yield takeEvery(fetchConfig.type, fetchConfigSaga)
}

export function* fetchConfigSaga(): Generator {
  try {
    const res = yield fetch(`${getEnv('REACT_APP_SSO', '')}/config`)
    const response = res as Response
    if (response.status === 200) {
      const jsonResponse = (yield response.json()) as GetConfigResponse
      yield put(setReleaseVersion(jsonResponse.release))
    }
  } catch (error) {
    yield put(apiError({ error }))
  }
}
