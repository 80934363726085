import { createAction } from '@reduxjs/toolkit'
import { Campaign } from '@ads/front-core/interfaces'
import { AppState } from '../../appState'

export const setCampaign = createAction<Campaign>('campaign/setCampaign')

export const setCampaignReducer = (
  state: AppState['campaign'],
  action: ReturnType<typeof setCampaign>,
): void => {
  state.campaign = action.payload
}
