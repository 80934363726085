import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface RemoveFormDraftParams {
  key: string
}

export const removeFormDraft = createAction<RemoveFormDraftParams>(
  'removeFormDraft',
)

export const removeFormDraftReducer = (
  state: RootState['app'],
  action: ReturnType<typeof removeFormDraft>,
): void => {
  const { key } = action.payload
  state.formDraft = Object.keys(state.formDraft).reduce<Record<string, string>>(
    (acc, k) => (k === key ? acc : { ...acc, [k]: state.formDraft[k] }),
    {},
  )
}
