/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { PaymentMethod } from '../models/PaymentMethod'
import type { RequestAddBonus } from '../models/RequestAddBonus'
import type { RequestAddPaymentCard } from '../models/RequestAddPaymentCard'
import type { RequestCreateNewPayment } from '../models/RequestCreateNewPayment'
import type { ResponseAccountHistory } from '../models/ResponseAccountHistory'
import type { ResponseAddPaymentCard } from '../models/ResponseAddPaymentCard'
import type { ResponseCreateNewPayment } from '../models/ResponseCreateNewPayment'
import type { ResponseGetGrossPayment } from '../models/ResponseGetGrossPayment'
import type { ResponseGetPaymentCard } from '../models/ResponseGetPaymentCard'
import type { ResponsePayment } from '../models/ResponsePayment'
import type { ResponsePaymentsHistory } from '../models/ResponsePaymentsHistory'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PaymentService {
  /**
   * Tworzy nową płatność
   *
   * @returns ResponseCreateNewPayment Nowa płatność utworzona
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static createNewPayment({
    requestBody,
  }: {
    /**
     * Dane płatności
     */
    requestBody: RequestCreateNewPayment
  }): CancelablePromise<ResponseCreateNewPayment | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Tworzy nową płatność za kampanie generyczną
   *
   * @returns ResponseCreateNewPayment Nowa płatność utworzona
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static createNewPaymentForCampaign({
    campaignId,
    paymentMethod,
  }: {
    campaignId: number
    /**
     * typ płatności
     */
    paymentMethod: PaymentMethod
  }): CancelablePromise<ResponseCreateNewPayment | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/campaign/{campaignId}',
      path: {
        campaignId: campaignId,
      },
      query: {
        paymentMethod: paymentMethod,
      },
    })
  }

  /**
   * Tworzy nową płatność za kampanie generyczną za pomocą salda
   *
   * @returns any Kampania opłacona
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static createNewBalancePaymentForCampaign({
    campaignId,
  }: {
    campaignId: number
  }): CancelablePromise<any | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/campaign/{campaignId}/balance',
      path: {
        campaignId: campaignId,
      },
    })
  }

  /**
   * Pobiera informacje o pojedyńczej płatości
   *
   * @returns ResponsePayment dane sklepu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getPayment({
    paymentId,
  }: {
    paymentId: number
  }): CancelablePromise<ResponsePayment | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/{paymentId}',
      path: {
        paymentId: paymentId,
      },
    })
  }

  /**
   * Usuwa płatność
   * @returns ResponsePayment Płatność zaktualizowana
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static deletePayment({
    paymentId,
  }: {
    paymentId: number
  }): CancelablePromise<ResponsePayment | Error> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/payment/{paymentId}',
      path: {
        paymentId: paymentId,
      },
    })
  }

  /**
   * Pobiera potwierdzenie płatności
   *
   * @returns binary Potwiedzenie Płatności
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getPaymentConfirmation({
    paymentId,
  }: {
    paymentId: number
  }): CancelablePromise<Blob | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/{paymentId}/confirmation',
      path: {
        paymentId: paymentId,
      },
    })
  }

  /**
   * Przelicza kwotę brutto na netto
   *
   * @returns ResponseGetGrossPayment dane sklepu
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getGrossPayment({
    amount,
    clientId,
  }: {
    /**
     * Kwota doładowania netto
     */
    amount: number
    clientId: number
  }): CancelablePromise<ResponseGetGrossPayment | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/gross-calculator',
      query: {
        amount: amount,
        clientId: clientId,
      },
    })
  }

  /**
   * Zwraca listę dokonanych płatności
   * @returns ResponsePaymentsHistory lista płatności
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getPaymentsHistory({
    clientId,
    page,
    limit,
  }: {
    clientId: number
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
  }): CancelablePromise<ResponsePaymentsHistory | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/history',
      query: {
        clientId: clientId,
        page: page,
        limit: limit,
      },
    })
  }

  /**
   * Zwraca listę dokonanych zwrotów, płatności oraz otrzymane bonusy
   * @returns ResponseAccountHistory lista płatności
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getAccountHistory({
    clientId,
    page,
    limit,
  }: {
    clientId: number
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
  }): CancelablePromise<ResponseAccountHistory | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/account-history',
      query: {
        clientId: clientId,
        page: page,
        limit: limit,
      },
    })
  }

  /**
   * Dodaje zasilenie bonus do salda klienta
   *
   * @returns Error unexpected error
   * @returns any Dodano zasilenie bonusowe
   * @throws ApiError
   */
  public static addBonus({
    requestBody,
  }: {
    /**
     * Dane bonusu
     */
    requestBody: RequestAddBonus
  }): CancelablePromise<Error | any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/bonus',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Przypisuje kartę do konta
   *
   * @returns Error unexpected error
   * @returns ResponseAddPaymentCard Przypisano kartę do konta
   * @throws ApiError
   */
  public static addPaymentCard({
    requestBody,
  }: {
    /**
     * dane karty
     */
    requestBody: RequestAddPaymentCard
  }): CancelablePromise<Error | ResponseAddPaymentCard> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payment/add-payment-card',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca kartę płatniczą
   * @returns ResponseGetPaymentCard dane karty
   * @throws ApiError
   */
  public static getPaymentCard({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<ResponseGetPaymentCard> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/{clientId}/card',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Zwraca kartę płatniczą
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static deletePaymentCard({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/payment/{clientId}/card',
      path: {
        clientId: clientId,
      },
    })
  }
}
