import React from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../Box'
import { IStatusDot } from './types'

const getColor = (variant: IStatusDot['variant']) => {
  switch (variant) {
    case 'yellow':
      return 'statusDotYellowColor'
    case 'red':
      return 'statusDotRedColor'
    case 'orange':
      return 'statusDotOrangeColor'
    case 'grey':
      return 'statusDotGreyColor'
    case 'blue':
      return 'statusDotBlueColor'
    case 'green':
      return 'statusDotGreenColor'
    case 'black':
      return 'statusDotBlackColor'
    case 'purple':
      return 'statusDotPurpleColor'
    default:
      return 'statusDotBlueColor'
  }
}

export const StatusDot = ({
  variant = 'blue',
  appearance = 'solid',
  size = 'small',
  $stroke = false,
  ...props
}: IStatusDot) => (
  <Circle
    borderRadius="fullRounded"
    role="presentation"
    variant={variant}
    appearance={appearance}
    size={size}
    $stroke={$stroke}
    {...props}
  />
)

const Circle = styled(Box)<IStatusDot>`
  display: inline-flex;

  ${p => css`
    width: ${p.size === 'small'
      ? `${p.theme.components.statusDotSizeSmall}px`
      : `${p.theme.components.statusDotSizeLarge}px`};

    height: ${p.size === 'small'
      ? `${p.theme.components.statusDotSizeSmall}px`
      : `${p.theme.components.statusDotSizeLarge}px`};

    background-color: ${p.theme.components[getColor(p.variant)]};

    ${p.appearance === 'subtle' &&
    !p.$stroke &&
    css`
      border: 2px solid ${p.theme.components[getColor(p.variant)]};
      background-color: transparent;
    `}

    ${p.$stroke &&
    css`
      box-shadow: 0px 0px 0px 2px ${p.theme.colors.inverted100};
    `}
  `};
`
