import React, { FC } from 'react'

export const FingerPrint: FC = () => (
  <svg
    width="385"
    height="87"
    viewBox="0 0 385 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112.266 32.4555C101.691 33.0322 91.0042 33.0321 80.429 34.4737C64.3992 36.636 48.3693 39.5191 32.4509 42.5464C24.102 44.1321 15.8645 46.871 7.62691 49.0333C4.51001 49.8983 2.84021 52.3489 2.84021 55.8086C2.84021 60.854 5.84581 63.593 10.0759 62.4397C23.2114 59.2683 36.2357 55.6644 49.3712 52.9255C70.1877 48.4567 91.3382 46.4386 112.489 46.5827C122.062 46.5827 131.635 47.4476 141.209 47.736C144.548 47.8801 146.663 45.2853 146.775 41.3931C146.886 37.3568 144.771 34.4737 141.543 34.1854C131.747 33.6088 122.062 33.0322 112.266 32.4555Z"
      fill="#DFDFE1"
    />
    <path
      d="M227.703 113.47C227.703 117.507 230.152 120.39 233.269 120.101C245.403 118.948 257.425 116.498 269.114 112.317C294.828 103.235 318.539 88.3874 339.355 66.62C343.697 62.1512 347.816 57.2499 351.934 52.3487C353.938 49.8981 354.161 46.2942 352.602 43.5552C351.155 40.9604 348.483 39.9514 346.146 41.3929C345.255 41.9695 344.476 42.9786 343.585 43.8435C335.905 51.7721 328.669 60.4213 320.543 67.4849C298.836 86.5134 274.791 98.4782 248.854 103.956C243.622 105.109 238.39 105.686 233.158 106.407C229.707 106.983 227.703 109.434 227.703 113.47Z"
      fill="#DFDFE1"
    />
    <path
      d="M211.339 56.8175C222.471 56.8175 233.603 55.6642 244.623 53.0695C258.984 49.7539 272.787 43.8435 285.923 35.3384C289.151 33.3202 290.153 29.2839 288.705 25.5359C287.37 22.2203 284.253 21.2113 281.247 23.2294C279.021 24.5268 276.906 25.9683 274.679 27.2657C248.854 41.5371 221.803 45.4293 194.085 41.9695C186.961 41.1046 179.948 39.2306 172.823 37.789C169.818 37.2124 167.146 39.3747 166.478 42.9786C165.81 46.5825 167.591 50.0422 170.597 51.0513C171.265 51.3396 171.933 51.4837 172.601 51.6279C185.291 54.9435 198.315 56.9616 211.339 56.8175Z"
      fill="#DFDFE1"
    />
    <path
      d="M422.176 21.7878C419.616 21.6437 418.28 24.5268 417.055 27.2657C407.927 46.8707 397.352 64.8901 384.996 81.3237C375.422 94.0093 365.07 105.542 354.049 115.921C353.047 116.93 352.046 118.083 351.489 119.525C350.376 122.264 351.044 125.579 352.825 127.453C354.94 129.76 357.277 129.616 360.06 126.877C384.773 103.956 405.256 75.8459 421.842 42.9786C423.734 39.2306 425.516 35.6267 427.185 31.7345C429.078 26.9774 426.629 21.6437 422.176 21.7878Z"
      fill="#DFDFE1"
    />
    <path
      d="M129.186 65.1786C127.183 65.1786 125.068 65.1786 123.064 65.1786C119.724 65.1786 116.274 64.8903 112.934 65.0344C109.594 65.1786 107.479 68.0616 107.591 72.098C107.702 75.9902 109.817 78.7291 113.045 78.8733C114.27 79.0174 115.494 78.8733 116.719 78.8733C139.094 78.1525 161.246 81.468 183.287 85.7927C188.742 86.8018 194.196 87.3784 199.651 88.0992C202.879 88.5316 205.328 85.6485 205.551 81.7564C205.773 78.1525 203.77 75.1252 200.764 74.5486C199.985 74.4045 199.317 74.2603 198.538 74.2603C192.304 73.3954 186.07 72.5305 179.947 71.3772C163.027 68.0617 146.218 65.1786 129.186 65.1786Z"
      fill="#DFDFE1"
    />
    <path
      d="M122.619 1.31766C122.619 1.75012 122.619 2.18258 122.619 2.61504C115.94 2.61504 109.372 2.61504 102.693 2.61504C99.2422 2.61504 97.1271 5.49816 97.2384 9.5345C97.2384 13.4267 99.5761 16.3098 102.916 16.3098C105.587 16.3098 108.148 16.3098 110.819 16.1656C130.189 15.7331 149.558 16.5981 168.816 19.0487C173.38 19.6253 177.944 20.3461 182.508 20.7786C185.736 21.0669 188.074 18.6162 188.297 14.7241C188.519 10.5436 186.738 7.66049 183.399 7.22802C180.616 6.79556 177.833 6.36311 175.05 6.0748C157.684 4.34494 140.207 2.75921 122.619 1.31766Z"
      fill="#DFDFE1"
    />
    <path
      d="M278.13 70.3681C278.13 65.6109 274.902 62.2954 271.34 63.8811C258.316 69.6473 244.957 73.2511 231.154 74.6927C227.926 74.981 225.922 78.1524 226.033 81.9004C226.256 85.7926 228.482 88.5315 231.933 88.2432C236.163 87.8108 240.505 87.09 244.735 86.3692C254.865 84.4952 264.661 81.1796 274.345 76.9992C276.794 75.8459 278.13 73.5395 278.13 70.3681Z"
      fill="#DFDFE1"
    />
    <path
      d="M70.5219 4.92168C69.2974 5.06584 67.739 5.35413 66.1805 5.49829C56.9411 6.93984 47.813 8.23724 38.5736 9.67879C37.2377 9.82294 35.9019 10.5437 34.7888 11.4086C32.785 13.1385 32.2284 16.5982 33.119 19.4813C34.0095 22.3644 36.4585 23.9501 39.3528 23.5177C45.6979 22.5086 52.0431 21.3553 58.3882 20.3463C62.841 19.6255 67.405 19.193 71.8577 18.4722C75.3086 17.8956 76.867 15.1567 76.5331 10.832C76.3104 7.22816 73.9728 4.77752 70.5219 4.92168Z"
      fill="#DFDFE1"
    />
    <path
      d="M112.266 32.4552C122.062 33.0318 131.747 33.6085 141.432 34.1851C144.771 34.3292 146.775 37.3565 146.664 41.3928C146.552 45.285 144.437 47.8798 141.098 47.7356C131.524 47.3032 121.951 46.5824 112.378 46.5824C91.2271 46.4382 70.188 48.4564 49.2602 52.9252C36.1246 55.8083 23.1004 59.268 9.96487 62.4394C5.73479 63.4485 2.8405 60.7095 2.72919 55.8083C2.72919 52.4927 4.39896 49.8979 7.51587 49.033C15.7534 46.7265 23.9909 44.1317 32.3398 42.546C48.2583 39.5188 64.2881 36.6357 80.3179 34.4734C91.0045 33.0318 101.691 33.176 112.266 32.4552Z"
      fill="#DFDFE1"
    />
    <path
      d="M227.703 113.47C227.703 109.434 229.707 106.983 233.381 106.407C238.613 105.686 243.844 105.109 249.076 103.956C275.014 98.4783 298.947 86.5134 320.765 67.485C328.892 60.4214 336.127 51.7721 343.808 43.8436C344.587 42.9787 345.367 41.9696 346.369 41.393C348.595 39.9514 351.378 40.9605 352.825 43.5553C354.272 46.2942 354.161 49.8981 352.157 52.3487C348.038 57.25 343.92 62.1513 339.578 66.6201C318.65 88.3874 295.051 103.091 269.336 112.317C257.648 116.498 245.626 118.948 233.492 120.101C230.152 120.534 227.703 117.507 227.703 113.47Z"
      fill="#DFDFE1"
    />
    <path
      d="M211.339 56.8175C198.204 56.9616 185.291 54.9434 172.489 51.772C171.821 51.6279 171.153 51.4837 170.486 51.1954C167.48 50.1863 165.699 46.7266 166.367 43.1227C167.035 39.5189 169.818 37.2124 172.712 37.9332C179.725 39.3747 186.849 41.2487 193.974 42.1137C221.692 45.5734 248.742 41.6812 274.568 27.4099C276.794 26.1125 278.909 24.8151 281.136 23.3736C284.253 21.4995 287.258 22.5086 288.594 25.68C290.041 29.2839 289.039 33.3203 285.811 35.4826C272.676 43.9877 258.983 49.7539 244.512 53.2136C233.603 55.6642 222.582 56.8175 211.339 56.8175Z"
      fill="#DFDFE1"
    />
    <path
      d="M422.176 21.7879C426.629 21.7879 429.078 27.1216 427.074 31.7346C425.404 35.6267 423.623 39.2306 421.731 42.9786C405.256 75.9901 384.662 103.956 359.949 126.877C357.166 129.471 354.829 129.616 352.713 127.453C350.932 125.579 350.264 122.264 351.378 119.525C351.934 118.083 352.936 116.93 353.938 115.921C364.958 105.542 375.422 94.0094 384.884 81.3238C397.129 64.8901 407.816 46.8708 416.944 27.2658C418.28 24.3827 419.616 21.6437 422.176 21.7879Z"
      fill="#DFDFE1"
    />
    <path
      d="M129.187 65.1786C146.218 65.3227 163.027 68.2058 179.836 71.3772C186.07 72.5305 192.304 73.2512 198.427 74.2603C199.206 74.4045 199.874 74.4045 200.653 74.5486C203.658 75.1252 205.662 78.1525 205.44 81.7564C205.217 85.6485 202.768 88.5316 199.54 88.0992C194.085 87.3784 188.519 86.8018 183.176 85.7927C161.135 81.468 138.983 78.1525 116.608 78.8733C115.383 78.8733 114.159 78.8733 112.934 78.8733C109.706 78.7291 107.591 75.9902 107.48 72.098C107.368 68.0616 109.483 65.1786 112.823 65.0344C116.162 64.8903 119.613 65.1786 122.953 65.1786C125.179 65.1786 127.183 65.1786 129.187 65.1786Z"
      fill="#DFDFE1"
    />
    <path
      d="M122.619 1.31773C140.096 2.90343 157.573 4.48916 175.161 6.07487C177.944 6.36318 180.727 6.79563 183.51 7.22809C186.849 7.66055 188.742 10.5436 188.408 14.7241C188.185 18.4722 185.847 21.0669 182.619 20.7786C178.055 20.3462 173.491 19.6254 168.927 19.0488C149.669 16.5981 130.3 15.7332 110.93 16.1657C108.259 16.1657 105.698 16.3098 103.027 16.3098C99.6872 16.3098 97.4609 13.5709 97.3495 9.53457C97.3495 5.49823 99.3533 2.75927 102.804 2.61511C109.483 2.47096 116.051 2.61511 122.73 2.61511C122.619 2.03849 122.619 1.60604 122.619 1.31773Z"
      fill="#DFDFE1"
    />
    <path
      d="M278.13 70.3679C278.13 73.5393 276.906 75.7017 274.345 76.8549C264.661 81.1795 254.865 84.351 244.735 86.225C240.505 87.0899 236.163 87.6665 231.933 88.099C228.594 88.3873 226.256 85.7925 226.033 81.7562C225.811 78.0081 227.926 74.9809 231.154 74.5484C244.957 73.1069 258.316 69.3588 271.34 63.7368C274.902 62.4394 278.13 65.6108 278.13 70.3679Z"
      fill="#DFDFE1"
    />
    <path
      d="M70.522 4.92168C74.0842 4.77752 76.3106 7.22816 76.6445 10.832C76.9785 15.1567 75.42 17.8956 71.9691 18.4722C67.5164 19.193 62.9524 19.6255 58.4996 20.3463C52.1545 21.3553 45.8094 22.5086 39.4643 23.5177C36.6813 23.9501 34.2323 22.3644 33.2304 19.4813C32.3399 16.5982 32.8965 13.1385 34.9002 11.4086C36.0134 10.3996 37.3492 9.82294 38.685 9.67879C47.9244 8.23724 57.0525 6.79568 66.2919 5.49829C67.739 5.20998 69.2975 5.06584 70.522 4.92168Z"
      fill="#DFDFE1"
    />
  </svg>
)
