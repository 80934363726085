import React from 'react'
import styled, { css } from 'styled-components'

import { Flex } from '../../Flex'
import { getPrefix } from '../../TextInput/TextInput'
import { IPrefix } from '../../TextInput/types'

interface PrefixProps {
  prefix: string
  isDisabled?: boolean
}

export const Prefix = ({ prefix, isDisabled }: PrefixProps) => {
  return (
    <StyledPrefix isDisabled={isDisabled} mr="xxxs">
      {getPrefix(prefix)}
    </StyledPrefix>
  )
}

export const StyledPrefix = styled(Flex)<IPrefix>`
  ${p =>
    css`
      white-space: nowrap;
      color: ${p.theme.components[
        p.isDisabled ? 'textInputPrefixDisabledColor' : 'textInputPrefixColor'
      ]};
    `}
`
