/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { PaymentType } from '../models/PaymentType'
import type { RequestSetClientFlag } from '../models/RequestSetClientFlag'
import type { RequestSetClientPaymentSettings } from '../models/RequestSetClientPaymentSettings'
import type { RequestSetClientSettings } from '../models/RequestSetClientSettings'
import type { ResponseBalance } from '../models/ResponseBalance'
import type { ResponseClient } from '../models/ResponseClient'
import type { ResponseClientApiToken } from '../models/ResponseClientApiToken'
import type { ResponseDefaultProductTypes } from '../models/ResponseDefaultProductTypes'
import type { ResponsePaymentTypeChangeValidator } from '../models/ResponsePaymentTypeChangeValidator'
import type { ResponseShop } from '../models/ResponseShop'
import type { ResponseUserClients } from '../models/ResponseUserClients'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ClientService {
  /**
   * Zwraca listę klientów przypisanych do użytkownika na podstawie jego identyfikacji z authTokena
   *
   * @returns ResponseUserClients podstawowe dane klientów (id, name)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getUserClients(): CancelablePromise<
    ResponseUserClients | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client',
    })
  }

  /**
   * Zwraca dane potrzebne do wyświetlenia formularza ustawień klienta. Powinien łączyć wewnętrzne dane ADSa z danymi pobranymi z HBP
   *
   * @returns ResponseClient dane klienta
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getClient({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<ResponseClient | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client/{clientId}',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Zwraca api token klienta
   *
   * @returns ResponseClientApiToken api token klienta
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getClientApiToken({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<ResponseClientApiToken | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client/{clientId}/api-token',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Generuje nowy token uwierzytelniający
   *
   * @returns Error unexpected error
   * @returns ResponseClientApiToken token wygenerowany
   * @throws ApiError
   */
  public static generateClientApiToken({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<Error | ResponseClientApiToken> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/client/{clientId}/api-token-generate',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Ustawia lub usuwa flagę
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static setClientFlag({
    clientId,
    requestBody,
  }: {
    clientId: number
    /**
     * Dane flagi do ustawienia/usunięcia
     */
    requestBody?: RequestSetClientFlag
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/client/{clientId}/set-flag',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zmienia ustawienia płatności klienta
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static setClientPaymentSettings({
    clientId,
    requestBody,
  }: {
    clientId: number
    /**
     * Ustawienia płatności
     */
    requestBody?: RequestSetClientPaymentSettings
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/client/{clientId}/payment-settings',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zmienia ustawienia klienta
   *
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static setClientSettings({
    clientId,
    requestBody,
  }: {
    clientId: number
    /**
     * Ustawienia klienta
     */
    requestBody?: RequestSetClientSettings
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/client/{clientId}/settings',
      path: {
        clientId: clientId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * sprawdza mozliwosc zmiany typu platnosci dla klienta
   *
   * @returns ResponsePaymentTypeChangeValidator
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static paymentTypeChangeValidator({
    clientId,
    paymentType,
  }: {
    clientId: number
    paymentType?: PaymentType
  }): CancelablePromise<ResponsePaymentTypeChangeValidator | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/{clientId}/payment-type-change',
      path: {
        clientId: clientId,
      },
      query: {
        paymentType: paymentType,
      },
    })
  }

  /**
   * Zwraca listę sklepów przypisanych do klienta
   *
   * @returns ResponseShop podstawowe dane sklepów (id, name)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getClientShops({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<Array<ResponseShop> | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/client/{clientId}/shop/',
      path: {
        clientId: clientId,
      },
    })
  }

  /**
   * Zwraca saldo klienta (pobrane z bankiera)
   *
   * @returns ResponseBalance wartość salda
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getClientBalance({
    clientId,
  }: {
    clientId: number
  }): CancelablePromise<ResponseBalance | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/balance',
      query: {
        clientId: clientId,
      },
    })
  }

  /**
   * Zwraca listę wszystkich produktów i kampanii generycznych oraz czy domyślnie zaznaczyć klientowi
   *
   * @returns ResponseDefaultProductTypes
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getDefaultProductTypes(): CancelablePromise<
    ResponseDefaultProductTypes | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/default-product-types',
    })
  }
}
