import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../../Text'
import { Icon } from '../../Icon'
import { ITextButton, IVariant } from './types'
import { setTransition } from '../../../utility'

import { checkIntent } from '../../../utility'
import { Loader } from '../Loader'

export const TextButton = ({
  iconBefore,
  iconAfter,
  children,
  as = 'button',
  variant = 'primary',
  intent,
  isDisabled,
  isLoading,
  onClick,
  ...rest
}: ITextButton) => {
  const [isMouseDown, setIsMouseDown] = useState(false)

  return (
    <StyledTextButton
      fontSize="m"
      lineHeight="m"
      fontWeight={variant === 'secondary' ? 'regular' : 'medium'}
      p="none"
      forwardedAs={as ? as : 'button'}
      variant={variant}
      isMouseDown={isMouseDown}
      intent={intent as IVariant['intent']}
      onMouseDown={() => setIsMouseDown(true)}
      onBlur={() => setIsMouseDown(false)}
      aria-label={typeof children === 'string' ? children : undefined}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={isLoading || isDisabled ? undefined : onClick}
      {...rest}
    >
      {iconBefore && variant !== 'secondary' && (
        <Icon name={iconBefore} mr="xxs" />
      )}
      {children}
      {iconAfter && variant !== 'secondary' && (
        <Icon name={iconAfter} ml="xxs" />
      )}
      {isLoading && (
        <Loader variant={variant === 'destructive' ? 'primary' : variant} />
      )}
    </StyledTextButton>
  )
}

const variants = {
  primary: (p: ITextButton) => css`
    color: ${({ theme }) => theme.components.textButtonPrimaryColor};

    ${!p.isDisabled &&
    css`
      &:hover {
        color: ${({ theme }) => theme.components.textButtonPrimaryHoverColor};
      }
    `};
  `,
  secondary: (p: ITextButton) => css`
    color: ${({ theme }) => theme.components.textButtonSecondaryColor};

    ${!p.isDisabled &&
    css`
      &:hover {
        color: ${({ theme }) => theme.components.textButtonSecondaryHoverColor};
      }
    `};
  `,
  destructive: (p: ITextButton) => css`
    color: ${({ theme }) => theme.components.textButtonDestructiveColor};

    ${!p.isDisabled &&
    css`
      &:hover {
        color: ${({ theme }) =>
          theme.components.textButtonDestructiveHoverColor};
      }
    `};

    &:focus {
      box-shadow: ${({ theme }) => theme.focuses.error};
    }
  `,
}

const StyledTextButton = styled(Text)<IVariant>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  appearance: none;
  background: transparent;
  font-family: inherit;
  letter-spacing: 0.2px;
  text-decoration: none;
  ${setTransition(['color', 'box-shadow'], 'productive')};

  ${p => css`
    border-radius: ${p.theme.radii.rounded};

    &:focus {
      outline: none;
      box-shadow: ${!p.isMouseDown && p.theme.focuses.normal};
    }

    ${!p.isDisabled &&
    css`
      &:active {
        opacity: ${p.theme.aliases.defaultActiveOpacity};
      }
    `};
    ${p.isLoading && `cursor: not-allowed;`}

    ${p.variant && !p.isLoading
      ? variants[p.variant](p)
      : css`
          cursor: not-allowed;
          color: transparent;
        `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.colors.greyDisabled};
      cursor: not-allowed;
    `};

    ${checkIntent('fitted', p.intent) &&
    css`
      width: calc(100% - 37px);
    `};
  `};
`
