import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setNewsletterListUrl = createAction<string>(
  'common/setNewsletterListUrl',
)

export const setNewsletterListUrlReducer = (
  state: AppState['common'],
  action: ReturnType<typeof setNewsletterListUrl>,
): void => {
  state.newsletterListUrl = action.payload
}
