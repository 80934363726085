import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

import { useNanoid } from '../../utility'
import { IIconSVG } from './types'

// Since React.memo does not pass generic information, define the component separately
const IconSVGComponent: FC<PropsWithChildren<IIconSVG>> = ({
  size,
  path,
  paths,
  ...rest
}) => {
  const [ids] = useNanoid(Array.isArray(paths) ? paths.length : 1)

  return (
    <StyledIconSvg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {Array.isArray(paths) ? (
        paths.map((p, index) => (
          <IconSvgPath
            fill={p.fill ? p.fill : 'currentColor'}
            fillRule="evenodd"
            d={p.path}
            key={index + ids[index]}
          />
        ))
      ) : (
        <IconSvgPath fill="currentColor" fillRule="evenodd" d={path} />
      )}
    </StyledIconSvg>
  )
}

export const IconSVG = React.memo(IconSVGComponent)
IconSVG.displayName = 'IconSVG'

const StyledIconSvg = styled.svg`
  display: inline-flex;
  ${space};
  ${color};
  /* Fix rendering bugs in Chrome for hdpi */
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
  flex-shrink: 0;
`

const IconSvgPath = styled.path``
