import styled, { css } from 'styled-components'

import { Flex } from '../Flex'
import { Text } from '../Text'
import { IInput } from '../TextInput/types'
import { MultiInputProps } from './types'

export const Input = styled(Text).attrs<IInput>({
  forwardedAs: 'input',
  fontSize: 'm',
  lineHeight: 'm',
  color: 'grey100',
  type: 'text',
})<IInput>`
  ${p => css`
    border: 0;
    appearance: none;
    font-family: inherit;
    letter-spacing: 0.2px;
    background-color: transparent;
    padding: 0;
    margin: 0;
    text-align: center;
    flex: auto 0 0;
    color: ${p.theme.colors[p.isDisabled ? 'grey20' : 'grey50']};

    &:disabled {
      pointer-events: none;
    }

    &:focus {
      outline: none;
      background-color: ${p.theme.colors.primary10};
    }

    &::placeholder {
      font-size: ${p.theme.fontSizes.s}px;
      color: ${p.theme.components[
        p.isDisabled
          ? 'tagInputTextInputDisabledColor'
          : 'tagInputTextInputPlaceholderColor'
      ]};
    }

    &::selection {
      background-color: ${p.theme.colors.primary10};
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  `};
`

export const Day = styled(Input)`
  ${p => css`
    width: ${p.theme.components.dateInputDayWidth}px;
  `};
`
export const Month = styled(Input)`
  ${p => css`
    width: ${p.theme.components.dateInputDayWidth}px;
  `};
`
export const Year = styled(Input)`
  ${p => css`
    width: ${p.theme.components.dateInputYearWidth}px;
  `};
`
export const Hour = styled(Input)`
  ${p => css`
    width: ${p.theme.components.dateInputDayWidth}px;
    margin-left: ${p.theme.space.xxs}px;
  `};
`
export const Minute = styled(Input)`
  ${p => css`
    width: ${p.theme.components.dateInputDayWidth}px;
  `};
`

export const VisuallyHidden = styled('span')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const MultiInput = styled(Flex)<MultiInputProps>`
  ${p => css`
    border: ${p.theme.components.textInputBorder};
    border-radius: ${p.theme.radii.rounded};
    align-items: center;
    position: relative;
    width: ${p.intent === 'fitted' ? ' 100%' : 'max-content'};
    height: ${p.theme.components.dateInputHeight}px;

    ${!p.isDisabled &&
    css`
      &:hover {
        border-color: ${p.theme.components[
          p.isError
            ? 'textInputErrorHoverBorderColor'
            : 'textInputHoverBorderColor'
        ]};
      }

      ${p.isFocused &&
      css`
        outline: none;
        box-shadow: ${p.theme.focuses[p.isError ? 'error' : 'normal']};
        border-color: transparent;
      `}
    `}

    ${p.isError &&
    !p.isDisabled &&
    css`
      border-color: ${p.theme.components.textInputErrorBorderColor};
    `};

    ${p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .textInputDisabledBackgroundBackground};
      color: ${p.theme.components.textInputDisabledColor};
      cursor: not-allowed;
    `};
  `};
`

export const DateWrapper = styled(Flex)<{ onClick?: () => void }>`
  cursor: default;
  width: 100%;
`
