import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface OverBannerParams {
  id: string
}

export const overBanner = createAction<OverBannerParams>('banner/overBanner')

export const overBannerReducer = (
  state: RootState['banner'],
  action: ReturnType<typeof overBanner>,
): void => {
  const { id } = action.payload
  state[id].overBanner = true
  state[id].disableAutoHide = true
}
