import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setCurrentClientId = createAction<
  AppState['client']['currentClientId']
>('client/setCurrentClientId')

export const setCurrentClientIdReducer = (
  state: AppState['client'],
  action: ReturnType<typeof setCurrentClientId>,
): void => {
  state.currentClientId = action.payload
  window.localStorage.setItem('currentClientId', String(action.payload))
}
