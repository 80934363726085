import React, { FC } from 'react'
import { Flex, IFlex, Spinner, Text } from '@ads/front-ds'
import { lng } from '@ads/front-core/locales'

export interface ILoading {
  withLabel?: boolean
}

const Loading: FC<ILoading & IFlex> = ({ withLabel, ...restFlex }) => (
  <Flex justifyContent="center" alignItems="center" {...restFlex}>
    <Spinner />
    {withLabel && (
      <Text fontSize="m" ml="s">
        {lng('common:loading')}
      </Text>
    )}
  </Flex>
)

export default Loading
