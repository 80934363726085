/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestCreatePrivateTopic } from '../models/RequestCreatePrivateTopic'
import type { RequestCreateTopic } from '../models/RequestCreateTopic'
import type { RequestUpdatePrivateTopic } from '../models/RequestUpdatePrivateTopic'
import type { RequestUpdateTopic } from '../models/RequestUpdateTopic'
import type { ResponseGetTopic } from '../models/ResponseGetTopic'
import type { ResponsePrivateTopicsList } from '../models/ResponsePrivateTopicsList'
import type { ResponseTopicsList } from '../models/ResponseTopicsList'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class TopicsService {
  /**
   * Zwraca listę tematyk
   * @returns ResponseTopicsList lista tematyk
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getTopicsList(): CancelablePromise<
    Array<ResponseTopicsList> | Error
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/topic',
    })
  }

  /**
   * Tworzy tematykę
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static createNewTopic({
    requestBody,
  }: {
    /**
     * dane nowego tematu
     */
    requestBody?: RequestCreateTopic
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/topic',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zwraca listę tematyk prywatnymch
   * @returns ResponsePrivateTopicsList lista tematyk
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getPrivateTopicsList({
    shopId,
  }: {
    shopId: number
  }): CancelablePromise<Array<ResponsePrivateTopicsList> | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/private-topic/{shopId}',
      path: {
        shopId: shopId,
      },
    })
  }

  /**
   * Tworzy tematykę prywatną
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static createNewPrivateTopic({
    shopId,
    requestBody,
  }: {
    shopId: number
    /**
     * dane nowego tematu
     */
    requestBody?: RequestCreatePrivateTopic
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/private-topic/{shopId}',
      path: {
        shopId: shopId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Edytuje tematykę prywatną
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static editPrivateTopic({
    privateTopicId,
    requestBody,
  }: {
    privateTopicId: string
    /**
     * dane tematu
     */
    requestBody?: RequestUpdatePrivateTopic
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/private-topic/{privateTopicId}',
      path: {
        privateTopicId: privateTopicId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Usuwa tematykę prywatną
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static deletePrivateTopic({
    privateTopicId,
  }: {
    privateTopicId: string
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/private-topic/{privateTopicId}',
      path: {
        privateTopicId: privateTopicId,
      },
    })
  }

  /**
   * Zwraca tematykę
   * @returns ResponseGetTopic tematyka
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getTopicById({
    topicId,
  }: {
    topicId: string
  }): CancelablePromise<ResponseGetTopic | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/topic/{topicId}',
      path: {
        topicId: topicId,
      },
    })
  }

  /**
   * Edytuje tematykę
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static editTopic({
    topicId,
    requestBody,
  }: {
    topicId: string
    /**
     * dane tematu
     */
    requestBody?: RequestUpdateTopic
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/topic/{topicId}',
      path: {
        topicId: topicId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Usuwa tematykę
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static deleteTopic({
    topicId,
  }: {
    topicId: string
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/topic/{topicId}',
      path: {
        topicId: topicId,
      },
    })
  }
}
