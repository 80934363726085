import React, { Children, cloneElement, FC, ReactElement } from 'react'
import styled, { css } from 'styled-components'

import { Box } from '../../Box'
import { Counter } from '../../Counter'
import { Flex } from '../../Flex'
import { Icon } from '../../Icon'
import { StatusDot } from '../../StatusDot'
import { Text } from '../../Text'
import {
  ICaption,
  IMenuItem,
  IMenuItemContainer,
  IMenuText,
  IOptions,
  ITick,
} from './types'

import { Components } from '../../../theme/interfaces'
import { checkIntent, useNanoid } from '../../../utility'

export const MatchPhrase = ({
  str,
  phrase,
}: {
  str: string
  phrase: string
}) => {
  const rgx = new RegExp(
    `(${phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`,
    'i',
  )
  const parts = str.split(rgx)

  const [ids] = useNanoid(parts.length)

  return (
    <>
      {parts.map((part, index) =>
        part.match(rgx) ? (
          <Text key={ids[index]} as="span" fontWeight="medium">
            {part}
          </Text>
        ) : (
          part
        ),
      )}
    </>
  )
}

export const MenuItem: FC<React.PropsWithChildren<IMenuItem>> = React.memo(
  ({
    label,
    labelAfter,
    isDisabled,
    children,
    intent,
    isSelected,
    isExpanded,
    counter,
    caption,
    phrase,
    level,
    status,
    tabIndex = 0,
    onClick,
    onKeyDown,
    onKeyUp,
    onKeyPress,
    dataTestId,
    ...rest
  }) => (
    <StyledBox forwardedAs="div" isDisabled={isDisabled} {...rest}>
      <StyledText
        fontSize="m"
        lineHeight="m"
        isDisabled={isDisabled}
        intent={intent}
        level={level}
        tabIndex={tabIndex}
        isMoreSpaceOnRight={
          checkIntent('selectable', intent) && !counter && !caption && !children
        }
        onClick={onClick}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        data-testid={dataTestId}
      >
        {checkIntent('selectable', intent) && (
          <Tick
            name="20-check"
            isDisabled={isDisabled}
            isVisible={isSelected}
            mr="xxs"
          />
        )}
        {status && (
          <StatusContainer mr="xxs">
            <StatusDot {...status} />
          </StatusContainer>
        )}

        <ItemTextContainer>
          <ItemText isSelected={isSelected}>
            {phrase ? <MatchPhrase str={label} phrase={phrase} /> : label}
          </ItemText>
          {labelAfter}
        </ItemTextContainer>

        {(caption || counter || children) && (
          <Options isSelectable={checkIntent('selectable', intent)}>
            {caption && !counter && (
              <Caption
                isDisabled={isDisabled}
                fontSize="m"
                lineHeight="m"
                ml="xxs"
              >
                {caption}
              </Caption>
            )}
            {counter && !isExpanded && (
              <Counter isPrefix={isSelected} label={+counter} ml="xxs" />
            )}
            {children && (
              <Arrow
                name={isExpanded ? '20-arrowhead-down' : '20-arrowhead-right'}
                ml="xxs"
              />
            )}
          </Options>
        )}
      </StyledText>

      {children && isExpanded && (
        <Box>
          {Children.map(children as ReactElement, (child: ReactElement) => {
            const baseIntent = ['children']
            const childIntent = child.props.intent
            const childLevel = (level || 0) + 1

            if (childIntent) baseIntent.push(childIntent)

            return cloneElement(child as ReactElement, {
              intent: [...baseIntent],
              level: childLevel,
            })
          })}
        </Box>
      )}
    </StyledBox>
  ),
)

MenuItem.displayName = 'MenuItem'

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${p => css`
    cursor: ${p.isDisabled && 'not-allowed'};
    transition: background-color ${p.theme.motion.productive};
  `};
`

const Arrow = styled(Icon)`
  flex: 0 0 20px;
  color: ${p => p.theme.components.menuItemArrowColor};
`

const StyledText = styled(Text)<IMenuItemContainer>`
  display: flex;

  ${p => css`
    padding: ${p.theme.space.xs / 2}px ${p.theme.space.xxs}px;
    pointer-events: ${p.isDisabled && 'none'};
    padding-right: ${p.isMoreSpaceOnRight &&
    p.theme.space.xl + p.theme.space.xxxs + 'px'};
    color: ${p.theme.components[
      p.isDisabled ? 'menuChildDisabledColor' : 'menuChildColor'
    ]};
    border-radius: ${p.theme.radii.rounded};

    &:hover:not(:focus) {
      background-color: ${p.theme.components.menuChildHoverBackgroundColor};

      ${Arrow} {
        color: ${p.theme.components.menuChildColor};
      }
    }

    &:focus {
      background-color: ${p.theme.components.menuChildFocusBackgroundColor};
      outline: none;

      ${Arrow} {
        color: ${p.theme.components.menuChildColor};
      }
    }

    ${checkIntent('children', p.intent) &&
    css`
      padding-left: ${checkIntent('selectable', p.intent)
        ? (p.level as number) * p.theme.space.m + p.theme.space.xs
        : (p.level as number) * p.theme.space.m}px;
    `}
  `};
`

const tickColors: Array<keyof Components> = [
  'menuTickDisabledColor',
  'menuTickColor',
]

export const Tick = styled(Icon)<ITick>`
  flex: 0 0 20px;

  ${p => css`
    visibility: ${p.isVisible ? 'visible' : 'hidden'};
    color: ${p.theme.components[p.isDisabled ? tickColors[0] : tickColors[1]]};
  `};
`

const StatusContainer = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})`
  width: 20px;
  height: 20px;
`

const Options = styled(Box)<IOptions>`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const Caption = styled(Text)<ICaption>`
  white-space: nowrap;

  ${p =>
    css`
      color: ${p.theme.components.menuItemCaptionColor};
      padding-left: ${p.theme.space.s}px;
    `};
`

const ItemTextContainer = styled(Box)`
  display: flex;
  min-width: 0;
`

const ItemText = styled(Text)<IMenuText>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${p =>
    css`
      font-weight: ${p.isSelected
        ? p.theme.fontWeights.medium
        : p.theme.fontWeights.regular};
    `};
`
