import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import {
  Counter,
  Flex,
  ITextButton,
  IconButton,
  Text,
  TextButton,
  Tooltip,
} from '@ads/front-ds'
import zIndex from '@ads/front-core/constants/zIndex'

export interface IPillCombo {
  label: string
  prefix?: string
  count?: number
  iconBefore?: ITextButton['iconBefore']
  isOpen?: boolean
  maxWidth?: { chars: number; width: number }
  disabled?: boolean
  onClick?: () => void
  onClear?: () => void
}

export const PillCombo: FC<IPillCombo> = ({
  label,
  prefix,
  count,
  iconBefore,
  isOpen,
  maxWidth,
  disabled,
  onClear,
  onClick,
}) => {
  return (
    <Wrapper withIconBefore={!!iconBefore}>
      <TextButton
        iconAfter={isOpen ? '20-arrowhead-up' : '20-arrowhead-down'}
        iconBefore={iconBefore}
        onClick={disabled ? null : onClick}
      >
        {prefix && (
          <Text color="grey50" fontSize="m" mr="xxxs">
            {prefix}
          </Text>
        )}
        {!maxWidth || maxWidth?.chars > label.length ? (
          <Text color={disabled ? 'greyDisabled' : 'grey100'} fontSize="m">
            {label}
          </Text>
        ) : (
          <TooltipStyled
            text={label}
            zIndex={zIndex.TOOLTIP}
            placement="top-start"
          >
            <TextWithTooltip
              color={disabled ? 'greyDisabled' : 'grey100'}
              fontSize="m"
              maxWidth={maxWidth?.width}
            >
              {label}
            </TextWithTooltip>
          </TooltipStyled>
        )}
        {typeof count !== 'undefined' && (
          <Counter
            label={count}
            variant="neutral"
            appearance="strong"
            ml="xxxs"
          />
        )}
      </TextButton>
      {onClear ? (
        <>
          <Separator />
          <IconButton
            ml="xxs"
            variant="bareDark"
            name="16-close"
            onClick={disabled ? null : onClear}
          />
        </>
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled(Flex)<{ withIconBefore: boolean }>`
  ${p => css`
    border-radius: 100px;
    background: ${p.theme.colors.primary05};
    display: flex;
    padding: 0 ${p.theme.space.xs}px;
    align-items: center;
    min-height: 28px;

    button {
      padding: 0;
    }

    svg {
      color: ${p.theme.colors.grey50};
    }

    ${p.withIconBefore &&
    css`
      svg:first-child {
        color: ${p.theme.colors.primary50};
      }
    `}
  `}
`

const Separator = styled.div`
  ${p => css`
    width: 1px;
    height: 28px;
    background: #fff;
    margin-left: ${p.theme.space.xxs}px;
  `}
`

const TextWithTooltip = styled(Text)<{ maxWidth: number }>`
  ${p => css`
    ${p.maxWidth > 0 &&
    css`
      display: block;
      max-width: ${p.maxWidth}px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
  `}
`

const TooltipStyled = styled(Tooltip)`
  display: block;
`
