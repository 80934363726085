/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { ResponseHBPClient } from '../models/ResponseHBPClient'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class HbpService {
  /**
   * Zwraca klienta z HBP na podstawie podanego numeru NIP, HBP ID lub clientId
   *
   * @returns ResponseHBPClient dane klienta HBP
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getHbpClient({
    nip,
    hbpId,
    clientId,
  }: {
    /**
     * NIP dla którego zostanie zwrócony klient z HBP
     */
    nip?: string
    /**
     * ID HBP
     */
    hbpId?: string
    /**
     * Client ID
     */
    clientId?: string
  }): CancelablePromise<ResponseHBPClient | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/hbp',
      query: {
        nip: nip,
        hbpId: hbpId,
        clientId: clientId,
      },
    })
  }
}
