import { createAction } from '@reduxjs/toolkit'
import { ResponseShopFeeds } from '@ads/front-core/api'
import { AppState } from '../../appState'

export const setFeeds = createAction<Required<ResponseShopFeeds>>(
  'feeds/setFeeds',
)

export const setFeedsReducer = (
  state: AppState['feeds'],
  action: ReturnType<typeof setFeeds>,
): void => {
  state.list = action.payload || []
}
