import { createAction } from '@reduxjs/toolkit'
import { RootState } from '../../rootState'

export interface ToggleLabelsParams {
  showLabels: boolean
}

export const toggleLabels = createAction<ToggleLabelsParams>(
  'sidebar/toggleLabels',
)

export const toggleLabelsReducer = (
  state: RootState['sidebarItem'],
  action: ReturnType<typeof toggleLabels>,
): void => {
  const { showLabels } = action.payload
  state['showLabels'] = showLabels
}
