import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { PortalProps } from '.'

export const Portal = ({
  children,
  isOpen,
  isBlockOnOpen,
  container = document.querySelector('body'),
  setOverflow = true,
}: React.PropsWithChildren<PortalProps>) => {
  useEffect(() => {
    if (!setOverflow) {
      return
    }

    if (container) {
      if (isOpen && isBlockOnOpen) {
        container.style.overflowY = 'hidden'
      } else if (isOpen !== undefined) {
        container.style.overflowY = 'auto'
      }
    }
    return () => {
      if (!setOverflow) {
        return
      }

      if (!container) {
        const body = document.querySelector('body')
        if (body) {
          body.style.overflowY = 'auto'
        }
      } else {
        container.style.overflowY = 'auto'
      }
    }
  }, [container, isBlockOnOpen, isOpen, setOverflow])

  if (!container) {
    return null
  }

  return <>{createPortal(children, container)}</>
}

const useSafeLayoutEffect = globalThis?.document ? useLayoutEffect : useEffect

/**
 * Portal that uses `document.body` as container, safe for SSR
 */
export const DefaultPortal = (props: { children: React.ReactNode }) => {
  const { children } = props

  const [tempNode, setTempNode] = useState<HTMLElement | null>(null)
  const portal = useRef<HTMLDivElement | null>(null)

  const [, forceUpdate] = useState({})
  useEffect(() => forceUpdate({}), [])

  useSafeLayoutEffect(() => {
    if (!tempNode) return

    const doc = tempNode.ownerDocument
    const host = doc.body

    if (!host) return

    portal.current = doc.createElement('div')

    host.appendChild(portal.current)
    forceUpdate({})

    const portalNode = portal.current
    return () => {
      if (host.contains(portalNode)) {
        host.removeChild(portalNode)
      }
    }
  }, [tempNode])

  const _children = children

  return portal.current ? (
    <>{createPortal(_children, portal.current)}</>
  ) : (
    <span
      ref={el => {
        if (el) setTempNode(el)
      }}
    />
  )
}
