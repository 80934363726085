import React, { MouseEvent } from 'react'

import { IconButton } from '../../Buttons'
import { Tooltip } from '../../Tooltip'
import { DateInputButtonProps } from './types'

export const DateInputIconButton = ({
  name,
  onClick,
  tooltipText,
  isDisabled,
  ariaLabel,
  currentDate,
  ...rest
}: DateInputButtonProps) => {
  const handleOnClick = (
    e?: MouseEvent<Element, globalThis.MouseEvent> | undefined,
  ) => {
    e?.stopPropagation()
    if (isDisabled) return
    onClick?.(currentDate, e)
  }

  return (
    <Tooltip
      text={tooltipText || ''}
      hide={!tooltipText || isDisabled}
      placement="bottom"
      ml="xxs"
      {...rest}
    >
      <IconButton
        name={name}
        onClick={handleOnClick}
        variant="bareDark"
        isDisabled={isDisabled}
        aria-label={ariaLabel || tooltipText || 'Date input button'}
        my="s"
        mx="xxs"
      />
    </Tooltip>
  )
}
