import { createAction } from '@reduxjs/toolkit'
import { Currency } from '@ads/front-core/interfaces'
import { RootState } from '../../rootState'

export const setCurrentCurrency = createAction<Currency>(
  'currency/setCurrentCurrency',
)

export const setCurrentCurrencyReducer = (
  state: RootState['currency'],
  action: ReturnType<typeof setCurrentCurrency>,
): void => {
  state.current = action.payload
}
