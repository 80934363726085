import { ResponseClient } from '@ads/front-core/api'
import { Currency, CurrencyDetails } from '@ads/front-core/interfaces'
import { lng } from '@ads/front-core/locales'

export const CURRENCY_MAP: Record<
  Currency,
  ResponseClient['settings']['currency']
> = {
  pln: null,
  ron: 'RON',
  bgn: 'BGN',
  usd: 'USD',
  gbp: 'GBP',
  eur: 'EUR',
  chf: 'CHF',
  czk: 'CZK',
}

export const CURRENCIES: CurrencyDetails[] = [
  {
    key: 'pln',
    symbol: 'PLN',
    label: lng('currency:pln'),
  },
  {
    key: 'eur',
    symbol: 'EUR',
    label: lng('currency:eur'),
  },
  {
    key: 'usd',
    symbol: 'USD',
    label: lng('currency:usd'),
  },
  {
    key: 'gbp',
    symbol: 'GBP',
    label: lng('currency:gbp'),
  },
  {
    key: 'chf',
    symbol: 'CHF',
    label: lng('currency:chf'),
  },
  {
    key: 'czk',
    symbol: 'CZK',
    label: lng('currency:czk'),
  },
  {
    key: 'bgn',
    symbol: 'BGN',
    label: lng('currency:bgn'),
  },
  {
    key: 'ron',
    symbol: 'RON',
    label: lng('currency:ron'),
  },
]

export const getCurrencyAds = (
  currency: Currency,
): ResponseClient['settings']['currency'] => CURRENCY_MAP[currency]

export const getCurrencyExchange = (
  currency: ResponseClient['settings']['currency'],
): Currency =>
  Object.keys(CURRENCY_MAP).find(
    (key: Currency) => CURRENCY_MAP[key] === currency,
  ) as Currency
