import styled, { css } from 'styled-components'

import { adjustPaddings, checkIntent, setTransition } from '../../../utility'
import { Text } from '../../Text'
import { IVariant } from './types'

const Button = styled(Text)<IVariant>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  appearance: none;
  font-family: inherit;
  letter-spacing: 0.2px;
  text-decoration: none;
  ${setTransition(
    ['border-color', 'background-color', 'color', 'box-shadow'],
    'productive',
  )};

  ${p => css`
    border-radius: ${p.theme.radii.rounded};

    &:focus {
      outline: none;

      ${!p.isMouseDown &&
      css`
        box-shadow: ${p.theme.focuses.normal};
        border-color: transparent;
      `};
    }

    ${!p.isDisabled &&
    css`
      &:active {
        opacity: ${p.theme.aliases.defaultActiveOpacity};
      }
    `};

    ${checkIntent('fitted', p.intent) &&
    css`
      width: 100%;
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.colors[
        p.variant === 'ghostInverted' ? 'invertedDisabled' : 'greyDisabled'
      ]};
      cursor: not-allowed;
    `};

    ${p.iconBefore &&
    !checkIntent(['ghost', 'ghostInverted'], p.variant) &&
    css`
      padding-left: ${p.size === 'l'
        ? p.theme.space.xs - 1
        : p.theme.space.xxs - 1}px;
    `};

    ${p.iconAfter &&
    !checkIntent(['ghost', 'ghostInverted'], p.variant) &&
    css`
      padding-right: ${p.size === 'l'
        ? p.theme.space.xs - 1
        : p.theme.space.xxs - 1}px;
    `};

    ${p.isLoading && `cursor: not-allowed;`}
  `};
`

export const Primary = styled(Button)`
  ${p => css`
    background-color: ${p.theme.components.buttonPrimaryBackgroundColor};
    color: ${p.theme.components.buttonPrimaryColor};

    ${p.size === 'l'
      ? adjustPaddings(['xxs', 's'])
      : adjustPaddings(['xxxs', 'xs'])}

    border: ${p.theme.components.buttonPrimaryBorder};

    ${!p.isDisabled &&
    !p.isLoading &&
    css`
      &:hover {
        color: ${p.theme.components.buttonPrimaryHoverColor};
      }
    `};

    ${p.isDisabled &&
    css`
      border-color: ${p.theme.components.buttonPrimaryDisabledBorderColor};
      color: ${p.theme.components.buttonPrimaryDisabledColor};
    `};
    ${p.isLoading &&
    css`
      color: transparent;
    `};
  `};
`

export const Secondary = styled(Button)`
  ${p => css`
    background-color: ${p.theme.components.buttonSecondaryBackgroundColor};
    color: ${p.theme.components.buttonSecondaryColor};
    ${p.size === 'l'
      ? adjustPaddings(['xxs', 's'])
      : adjustPaddings(['xxxs', 'xs'])}
    border: ${p.theme.components.buttonSecondaryBorder};

    ${!p.isDisabled &&
    !p.isLoading &&
    css`
      &:hover {
        color: ${p.theme.components.buttonSecondaryHoverColor};
      }
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.buttonSecondaryDisabledColor};
    `};
    ${p.isLoading &&
    css`
      color: transparent;
    `};
  `};
`

export const Cta = styled(Button)`
  ${p => css`
    background-color: ${p.theme.components.buttonCtaBackgroundColor};
    color: ${p.theme.components.buttonCtaColor};

    ${!p.isDisabled &&
    css`
      &:hover {
        background-color: ${p.theme.components.buttonCtaHoverBackgroundColor};
      }
    `};

    ${p.isDisabled &&
    css`
      opacity: ${p.theme.aliases.disabledOpacity};
    `};
    ${p.isLoading &&
    css`
      color: transparent;
    `};
  `};
`

export const Ghost = styled(Button)`
  background-color: transparent;

  ${p => css`
    padding: ${p.size === 'l'
      ? `${p.theme.space.xxs}px`
      : `${p.theme.space.xxxs}px`};
    color: ${p.theme.components.buttonGhostColor};

    ${!p.isDisabled &&
    !p.isLoading &&
    css`
      &:hover {
        background-color: ${p.theme.components.buttonGhostHoverBackgroundColor};
        color: ${p.theme.components.buttonGhostHoverColor};
      }
    `};

    ${checkIntent('selected', p.intent) &&
    !p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .buttonGhostSelectedBackgroundColor};
      color: ${p.theme.components.buttonGhostSelectedColor};
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.buttonGhostDisabledColor};
    `};

    ${p.isLoading &&
    css`
      color: transparent;
    `};
  `};
`

export const GhostInverted = styled(Button)`
  background-color: transparent;

  ${p => css`
    padding: ${p.theme.space.xxs}px;
    color: ${p.theme.components.buttonGhostInvertedColor};

    ${!p.isDisabled &&
    css`
      &:hover {
        background-color: ${p.theme.components
          .buttonGhostInvertedHoverBackgroundColor};
        color: ${p.theme.components.buttonGhostInvertedHoverColor};
      }

      &:focus {
        box-shadow: ${p.theme.focuses.inverted};
      }
    `};

    ${checkIntent('selected', p.intent) &&
    !p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .buttonGhostInvertedSelectedBackgroundColor};
    `};

    ${p.isDisabled &&
    css`
      color: ${p.theme.components.buttonGhostInvertedDisabledColor};
    `};

    ${p.isLoading &&
    css`
      color: transparent;
    `};
  `};
`

export const DestructiveCta = styled(Button)`
  ${p => css`
    background-color: ${p.theme.components.buttonDestructiveBackgroundColor};
    color: ${p.theme.components.buttonDestructiveColor};

    ${!p.isDisabled &&
    css`
      &:hover {
        background-color: ${p.theme.components
          .buttonDestructiveHoverBackgroundColor};
      }

      &:focus {
        box-shadow: ${p.theme.focuses.error};
      }
    `};

    ${p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .buttonDestructiveDisabledBackgroundColor};
      color: ${p.theme.components.buttonDestructiveDisableColor};
    `};

    ${p.isLoading &&
    css`
      color: transparent;
    `};
  `};
`

export const DestructivePrimary = styled(Button)`
  ${p => css`
    background-color: transparent;
    color: ${p.theme.components.buttonDestructivePrimaryColor};
    ${p.size === 'l'
      ? adjustPaddings(['xxs', 's'])
      : adjustPaddings(['xxxs', 'xs'])}
    border: ${p.theme.components.buttonDestructivePrimaryBorder};

    ${!p.isDisabled &&
    !p.isLoading &&
    css`
      &:hover {
        color: ${p.theme.components.buttonDestructivePrimaryHoverColor};
      }

      &:focus {
        box-shadow: ${p.theme.focuses.error};
      }
    `};

    ${p.isDisabled &&
    css`
      border: ${p.theme.components.buttonDestructivePrimaryDisabledBorder};
      color: ${p.theme.components.buttonDestructivePrimaryDisableColor};
    `};

    ${p.isLoading &&
    css`
      color: transparent;
    `};
  `};
`
