/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { NotificationStatus } from '../models/NotificationStatus'
import type { ResponseNotificationList } from '../models/ResponseNotificationList'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class NotificationService {
  /**
   * Zwraca listę powiadomień dotyczących sklepów przypisanych do klienta podanego w parametrze filtra
   * @returns ResponseNotificationList lista powiadomień
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static adminGetNotificationList({
    page,
    limit,
    clientId,
    shopIds,
    status,
    type,
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    clientId: number
    /**
     * identyfikatory sklepów
     */
    shopIds?: Array<number>
    /**
     * filtr na status powiadomienia
     */
    status?: NotificationStatus
    /**
     * filtr na typ powiadomienia
     */
    type?: Array<string>
  }): CancelablePromise<ResponseNotificationList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/notification',
      query: {
        page: page,
        limit: limit,
        clientId: clientId,
        shopIds: shopIds,
        status: status,
        type: type,
      },
    })
  }

  /**
   * Zwraca listę powiadomień dotyczących sklepów do których zalogowany użytkownik ma dostęp(dla admina jest osobny endpoint)
   * @returns ResponseNotificationList lista powiadomień
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static getNotificationList({
    page,
    limit,
    shopIds,
    status,
    type,
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * identyfikatory sklepów
     */
    shopIds?: Array<number>
    /**
     * filtr na status powiadomienia
     */
    status?: NotificationStatus
    /**
     * filtr na typ powiadomienia
     */
    type?: Array<string>
  }): CancelablePromise<ResponseNotificationList | Error> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notification',
      query: {
        page: page,
        limit: limit,
        shopIds: shopIds,
        status: status,
        type: type,
      },
    })
  }

  /**
   * Usuwa powiadomienie
   * @returns any powiadomienie usunięte
   * @throws ApiError
   */
  public static notificationDelete({
    notificationId,
  }: {
    /**
     * identyfikator powiadomienia
     */
    notificationId: number
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/notification/{notificationId}',
      path: {
        notificationId: notificationId,
      },
    })
  }

  /**
   * Oznacza jako przeczytane powiadomienia, które pochodzą ze sklepów w których zalogowany user ma rolę OWNER
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static setNotificationsAsRead(): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/notification/set-read',
    })
  }
}
