import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'

import { Text } from '../Text'
import { Tooltip } from '../Tooltip'

import { adjustPaddings, setTransition } from '../../utility'
import { ISegmentedControl, TTooltip } from './types'

export const SegmentedControl: FC<
  React.PropsWithChildren<ISegmentedControl>
> = React.memo(({ isActive, isError, tooltip, ...rest }) => {
  const [mouseDown, setMouseDown] = useState(false)

  const button = (
    <Button
      isActive={isActive}
      isError={isError}
      color="grey100"
      isMouseDown={mouseDown}
      onMouseDown={() => setMouseDown(true)}
      onBlur={() => setMouseDown(false)}
      aria-label={rest.label}
      {...rest}
    />
  )

  return tooltip ? (
    <StyledTooltip
      text={tooltip.text}
      animatable
      placement={tooltip.placement ? tooltip.placement : 'top'}
      wordBreak={tooltip.wordBreak}
      hide={tooltip.hide}
      zIndex={tooltip.zIndex}
    >
      {button}
    </StyledTooltip>
  ) : (
    button
  )
})
SegmentedControl.displayName = 'SegmentedControl'

const Button = styled(Text).attrs({ forwardedAs: 'button' })<ISegmentedControl>`
  position: relative;
  border: 0;
  width: 100%;
  height: 100%;
  font-family: inherit;
  letter-spacing: 0.2px;
  text-decoration: none;
  appearance: none;
  cursor: pointer;
  ${setTransition(['background-color', 'box-shadow', 'color'], 'productive')};
  ${adjustPaddings(['xxs', 's'])};

  &:focus,
  &:active,
  &:hover {
    z-index: 1;
    outline: none;
  }

  ${p => css`
    background-color: ${p.theme.components.segmentedControlBackgroundColor};
    border: ${p.theme.components.segmentedControlBorder};

    &:hover {
      background-color: ${p.theme.components
        .segmentedControlHoverBackgroundColor};
      border-color: ${p.theme.components[
        p.isError
          ? 'segmentedControlErrorHoverBorderColor'
          : 'segmentedControlHoverBorderColor'
      ]};
      color: ${p.theme.components.segmentedControlHoverColor};
    }

    &:active {
      opacity: ${p.theme.aliases.defaultActiveOpacity};
    }

    ${!p.isMouseDown &&
    css`
      &:focus {
        box-shadow: ${p.theme.focuses[p.isError ? 'error' : 'normal']};
        border-color: transparent;
        z-index: 3;
      }
    `}

    ${p.position === 'first' &&
    css`
      border-radius: ${p.theme.radii.rounded} 0 0 ${p.theme.radii.rounded};
    `};

    ${p.position === 'last' &&
    css`
      border-radius: 0 ${p.theme.radii.rounded} ${p.theme.radii.rounded} 0;
    `};

    ${p.isActive &&
    !p.isError &&
    css`
      background-color: ${p.theme.components
        .segmentedControlActiveBackgroundColor};
      border-color: ${p.theme.components.segmentedControlActiveBorderColor};
      color: ${p.theme.components.segmentedControlActiveColor};
      z-index: 1;

      &:hover {
        border-color: ${p.theme.components
          .segmentedControlActiveHoverBorderColor};
        color: ${p.theme.components.segmentedControlActiveHoverColor};
      }
    `};

    ${p.isError &&
    css`
      z-index: 2;
      border-color: ${p.theme.components.segmentedControlErrorBorderColor};
    `};

    ${p.isDisabled &&
    css`
      background-color: ${p.theme.components
        .segmentedControlDisabledBackgroundColor};
      border-color: ${p.theme.components.segmentedControlDisabledBorderColor};
      color: ${p.theme.components.segmentedControlDisabledColor};
      pointer-events: none;
    `};
  `};
`

const StyledTooltip = styled(Tooltip)<TTooltip>`
  display: block;
`
