/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseEntityHistory } from '../models/ResponseEntityHistory'
import type { TimelineEntityType } from '../models/TimelineEntityType'
import type { TimelineEventType } from '../models/TimelineEventType'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class TimelineService {
  /**
   * pobiera historię edycji dowolnego obiektu
   *
   * @returns any Historia edycji dowolnego obiektu
   * @throws ApiError
   */
  public static getEntityHistory({
    entityId,
    eventType,
    entityType,
  }: {
    /**
     * identyfikator obiektu
     */
    entityId: number
    /**
     * identyfikator obiektu
     */
    eventType?: Array<TimelineEventType>
    /**
     * identyfikator obiektu
     */
    entityType?: TimelineEntityType
  }): CancelablePromise<Array<ResponseEntityHistory>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/timeline/get-history',
      query: {
        entityId: entityId,
        eventType: eventType,
        entityType: entityType,
      },
    })
  }
}
