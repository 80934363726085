import React, { FC } from 'react'

const IconEn: FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8987 9.10569H11.8327V1.0393C11.3773 0.972776 10.912 0.937012 10.4381 0.937012C9.96437 0.937012 9.49918 0.972776 9.04402 1.0393V9.10569H8.1143H0.977318C0.910876 9.56085 0.875 10.0261 0.875 10.4999C0.875 10.9737 0.910876 11.439 0.977318 11.8942H8.1143H9.04402V19.9606C9.49918 20.0272 9.96437 20.063 10.4381 20.063C10.912 20.063 11.3773 20.0272 11.8327 19.9606V11.8942H12.7617H19.8988C19.9653 11.439 20.001 10.9737 20.001 10.4999C20.001 10.0261 19.9653 9.56085 19.8987 9.10569Z"
      fill="#DA2226"
    />
    <path
      d="M2.0074 5.9832C1.63937 6.66833 1.35312 7.40373 1.16016 8.17621H4.964L2.0074 5.9832Z"
      fill="#2B3D8A"
    />
    <path
      d="M8.11414 1.22291C6.40416 1.64993 4.87611 2.5357 3.66992 3.74414L8.11414 7.04047V1.22291Z"
      fill="#2B3D8A"
    />
    <path
      d="M12.7617 19.7772C14.4718 19.3501 16 18.4641 17.206 17.2557L12.7617 13.9596V19.7772Z"
      fill="#2B3D8A"
    />
    <path
      d="M18.8687 15.0167C19.2368 14.3314 19.523 13.5961 19.716 12.8237H15.9121L18.8687 15.0167Z"
      fill="#2B3D8A"
    />
    <path
      d="M19.716 8.17621C19.523 7.40373 19.2367 6.66833 18.8686 5.9832L15.9121 8.17621H19.716Z"
      fill="#2B3D8A"
    />
    <path
      d="M4.96391 12.8237H1.16016C1.35312 13.5961 1.63937 14.3314 2.0074 15.0167L4.96391 12.8237Z"
      fill="#2B3D8A"
    />
    <path
      d="M3.66992 17.2558C4.87611 18.4643 6.40416 19.3501 8.11414 19.7772V13.9597L3.66992 17.2558Z"
      fill="#2B3D8A"
    />
    <path
      d="M17.2059 3.74414C15.9996 2.5357 14.4718 1.64993 12.7617 1.22291V7.04047L17.2059 3.74414Z"
      fill="#2B3D8A"
    />
    <path
      d="M7.30608 8.17621L2.7582 4.8029C2.57334 5.05174 2.40053 5.30963 2.24023 5.57603L5.74582 8.17621H7.30608Z"
      fill="#DA2226"
    />
    <path
      d="M13.5723 12.8237L18.1198 16.1968C18.3045 15.948 18.4774 15.69 18.6376 15.4236L15.1326 12.8237H13.5723Z"
      fill="#DA2226"
    />
    <path
      d="M8.11478 12.8236H7.30533L2.75781 16.1967C2.94289 16.4461 3.1399 16.6863 3.34798 16.9162L8.11478 13.3805V12.8236Z"
      fill="#DA2226"
    />
    <path
      d="M12.7617 8.17621H13.5711L18.1187 4.80312C17.9334 4.55362 17.7366 4.3134 17.5282 4.08342L12.7617 7.61888V8.17621Z"
      fill="#DA2226"
    />
    <path
      d="M19.8982 11.8942H11.832V19.9606C12.1471 19.9146 12.4569 19.8531 12.7611 19.777V13.9595L17.2054 17.2556C17.5348 16.926 17.8398 16.5718 18.1183 16.1968L13.5708 12.8237H15.1311L18.6361 15.4236C18.7164 15.2899 18.7936 15.1544 18.8674 15.0167L15.9108 12.8237H19.7146C19.7905 12.5194 19.8522 12.2092 19.8982 11.8942Z"
      fill="white"
    />
    <path
      d="M19.7146 8.17621H15.9108L18.8672 5.9832C18.6459 5.5709 18.3955 5.17649 18.1181 4.80312L13.5704 8.17621H12.7611V7.61888L17.5276 4.08342C17.4229 3.96783 17.3156 3.85475 17.2053 3.74418L12.7611 7.0405V1.22294C12.4569 1.14694 12.1471 1.08533 11.832 1.03931V9.10571H12.7611H19.8981C19.8522 8.79057 19.7905 8.48066 19.7146 8.17621Z"
      fill="white"
    />
    <path
      d="M0.976562 11.8942C1.02248 12.2092 1.08404 12.5194 1.16007 12.8237H4.96382L2.00731 15.0167C2.2286 15.4291 2.47914 15.8234 2.75658 16.1968L7.30409 12.8237H8.11354V13.3806L3.34674 16.9162C3.45153 17.0321 3.55908 17.1453 3.66933 17.2557L8.11354 13.9596V19.777C8.418 19.8531 8.72813 19.9146 9.04327 19.9606V11.8942H8.11354H0.976562Z"
      fill="white"
    />
    <path
      d="M8.11354 1.22294V7.0405L3.66933 3.74418C3.33993 4.07404 3.03516 4.42811 2.75665 4.8029L7.30453 8.17621H5.74426L2.23868 5.57603C2.15831 5.70972 2.08108 5.84548 2.00731 5.9832L4.96391 8.17621H1.16007C1.08404 8.48066 1.02261 8.79057 0.976562 9.10571H8.11354H9.04327V1.03931C8.72813 1.08533 8.418 1.14694 8.11354 1.22294Z"
      fill="white"
    />
  </svg>
)

export default IconEn
